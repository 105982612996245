import { ExclamationIcon } from '@heroicons/react/solid';

export default function NoResutsPage({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) {
  return (
    <div className="rounded-md bg-yellow-50 p-4 w-full">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationIcon
            className="h-5 w-5 text-orange-500"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-orange-800">{title}</h3>
          {subtitle && (
            <div className="mt-2 text-sm text-orange-700">
              <p>{subtitle}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
