import React, { ReactNode, useContext } from 'react';
import { useMonthlyCalendar } from './MonthlyCalendar';

import { format, getDay, isSameDay, Locale } from 'date-fns';

import { de } from 'date-fns/locale';
import { classNames } from '../../../../../styles/utils';
// import { ClockIcon } from '@heroicons/react/solid';

type DaysInWeekProps = {
  locale?: Locale;
};

export const daysInWeek = ({ locale = de }: DaysInWeekProps) => {
  // Adjust the order of days to start the week on Monday
  return [
    { day: 1, label: locale.localize?.day(1).slice(0, 2) }, // Monday
    { day: 2, label: locale.localize?.day(2).slice(0, 2) }, // Tuesday
    { day: 3, label: locale.localize?.day(3).slice(0, 2) }, // Wednesday
    { day: 4, label: locale.localize?.day(4).slice(0, 2) }, // Thursday
    { day: 5, label: locale.localize?.day(5).slice(0, 2) }, // Friday
    { day: 6, label: locale.localize?.day(6).slice(0, 2) }, // Saturday
    { day: 0, label: locale.localize?.day(0).slice(0, 2) }, // Sunday
  ];
};

const MonthlyBodyContext = React.createContext({} as any);
type BodyState<DayData> = {
  day: Date;
  events: DayData[];
};

export function useMonthlyBody<DayData>() {
  return useContext<BodyState<DayData>>(MonthlyBodyContext);
}

type OmittedDaysProps = {
  days: Date[];
  omitDays?: number[];
  locale?: Locale;
};

export const handleOmittedDays = ({
  days,
  omitDays,
  locale,
}: OmittedDaysProps) => {
  let headings = daysInWeek({ locale });
  let daysToRender = [...days];
  let firstDayOfMonth = getDay(daysToRender[0]) as number;

  // next lines is to the start of the week from Sun to Mon look at daysInWeek ... how the days are rendered
  if (firstDayOfMonth === 0) {
    // If the first day is Sunday
    firstDayOfMonth = 6; // Since the week starts on Monday, Sunday should be the last day of the week, hence padding is 6
  } else {
    // For other days, calculate padding as usual
    firstDayOfMonth = firstDayOfMonth - 1; // Adjust for Monday being the first day
  }
  let padding = new Array(firstDayOfMonth).fill(0);

  return { headings, daysToRender, padding };
};

//to prevent these from being purged in production, we make a lookup object
const headingClasses = {
  l3: 'lg:grid-cols-3',
  l4: 'lg:grid-cols-4',
  l5: 'lg:grid-cols-5',
  l6: 'lg:grid-cols-6',
  l7: 'lg:grid-cols-7',
};

type MonthlyBodyProps<DayData> = {
  /*
    skip days, an array of days, starts at sunday (0), saturday is 6
    ex: [0,6] would remove sunday and saturday from rendering
  */
  omitDays?: number[];
  events: (DayData & { date: Date })[];
  children: ReactNode;
};

export function MonthlyBody<DayData>({
  omitDays,
  events,
  children,
}: MonthlyBodyProps<DayData>) {
  let { days, locale } = useMonthlyCalendar();
  let { headings, daysToRender, padding } = handleOmittedDays({
    days,
    omitDays,
    locale,
  });
  // let { day } = useMonthlyBody<DayData>();
  // let dayNumber = format(day, 'd', { locale });
  // console.log('🚀 ===========:', days);

  return (
    <div className="lg:bg-white border-l border-t lg:border-l-0 lg:border-t-0 ">
      {/* mobile view */}
      <div className="grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-extrabold leading-6 text-gray-700 lg:flex-none">
        <div className="bg-white py-2 lg:hidden block">
          S<span className="sr-only sm:not-sr-only">un</span>
        </div>
        <div className="bg-white py-2 lg:hidden block">
          M<span className="sr-only sm:not-sr-only">on</span>
        </div>
        <div className="bg-white py-2 lg:hidden block">
          T<span className="sr-only sm:not-sr-only">ue</span>
        </div>
        <div className="bg-white py-2 lg:hidden block">
          W<span className="sr-only sm:not-sr-only">ed</span>
        </div>
        <div className="bg-white py-2 lg:hidden block">
          T<span className="sr-only sm:not-sr-only">hu</span>
        </div>
        <div className="bg-white py-2 lg:hidden block">
          F<span className="sr-only sm:not-sr-only">ri</span>
        </div>
        <div className="bg-white py-2 lg:hidden block">
          S<span className="sr-only sm:not-sr-only">at</span>
        </div>
      </div>

      <div className="lg:hidden w-full grid-cols-7 grid-rows-5 gap-px grid font-semibold">
        {padding.map((day, index) => (
          <div
            key={index}
            className={
              'border p-2 block lg:hidden  bg-gray-50 text-xs leading-6 text-gray-700 flex-auto'
            }
            title="Empty Day"></div>
        ))}
        {daysToRender.map((day) => (
          <MonthlyBodyContext.Provider
            key={day.toISOString()}
            value={{
              day,
              events: events.filter((data) => isSameDay(data.date, day)),
            }}>
            {children}
          </MonthlyBodyContext.Provider>
        ))}
      </div>

      {/* mobile view  ends*/}
      <div
        className={`hidden lg:grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 ${
          //@ts-ignore
          headingClasses[`l${headings.length}`]
        }`}>
        {headings.map((day) => (
          <div
            key={day.day}
            className={
              'text-center border-b p-2 border-r lg:block hidden text-gray-900 font-semibold text-sm'
            }
            title="Day of Week">
            {day.label}
          </div>
        ))}
        {padding.map((day, index) => (
          <div
            key={index}
            className={
              'border p-2  lg:block hidden  bg-gray-50 text-xs leading-6 text-gray-700 lg:flex-auto'
            }
            title="Empty Day"></div>
        ))}
        {daysToRender.map((day) => (
          <MonthlyBodyContext.Provider
            key={day.toISOString()}
            value={{
              day,
              events: events.filter((data) => isSameDay(data.date, day)),
            }}>
            {children}
          </MonthlyBodyContext.Provider>
        ))}
      </div>
    </div>
  );
}

type MonthlyDayProps<DayData> = {
  renderDay: (events: DayData[], dayNumber: string) => ReactNode;
  onDaySelect?: (events: any) => void;
};
export function MonthlyDay<DayData>({
  renderDay,
  onDaySelect,
}: MonthlyDayProps<DayData>) {
  let { locale } = useMonthlyCalendar();
  let { day, events } = useMonthlyBody<DayData>();
  let dayNumber = format(day, 'd', { locale });

  const isSame = isSameDay(new Date(), day);

  // console.log('isSame.', isSame);
  return (
    <>
      {/* mobile day btn */}
      <button
        // key={day.date}
        type="button"
        onClick={() => typeof onDaySelect === 'function' && onDaySelect(events)}
        className={classNames(
          !isSame ? 'bg-white' : 'bg-gray-100',
          // (day.isSelected || day.isToday) && 'font-semibold',
          // day.isSelected && 'text-white',
          // !day.isSelected && day.isToday && 'text-indigo-600',
          // !day.isSelected && day.isCurrentMonth && !day.isToday && 'text-gray-900',
          // !day.isSelected && !day.isCurrentMonth && !day.isToday && 'text-gray-500',
          'border-b border-r lg:hidden h-16 flex-col px-3 py-2 hover:bg-gray-200 focus:z-10 flex bg-white text-gray-500',
        )}>
        <time
          // dateTime={day.date}
          className={classNames(
            isSame &&
              'flex h-7 w-7 items-center justify-center rounded-full text-white',
            // day.isSelected && day.isToday && 'bg-indigo-600',
            isSame && 'bg-orange-400',
            'ml-auto',
          )}>
          {dayNumber}
        </time>
        <span className="sr-only">{events.length} Reservierungen</span>
        {events.length > 0 && (
          <span className="-mx-0.5 mt-auto flex flex-wrap-reverse">
            {
              <span
                //@ts-ignore
                // key={event.id}
                className="mx-0.5 mb-1 h-2 w-2 rounded-full bg-orange-600"
              />
            }
          </span>
        )}
      </button>
      {/* end mobile day btn */}
      <div
        onClick={() => typeof onDaySelect === 'function' && onDaySelect(events)}
        // title={`Events for day ${dayNumber}`}
        className="p-2 border-b border-r lg:block hidden">
        <div className="flex justify-between">
          {/* <div className="font-bold">{dayNumber}</div> */}
          <span
            className={classNames(
              'items-center justify-center py-1 ml-1.5 font-semibold text-sm',
              isSame
                ? 'rounded-full bg-orange-400  flex h-7 w-7  text-gray-900'
                : 'text-gray-700',
            )}>
            {dayNumber}
          </span>
        </div>
        {/* {events.length ? (
          <div className="my-1">
            <p className="text-gray-600 ml-2 text-xs font-semibold">
              Reservierungen: {events.length}
            </p>
          </div>
        ) : null} */}
        <ul
          className={`overflow-hidden ${
            events.length ? '' : 'h-36'
          } overflow-y-auto hidden lg:block list-outside py-2`}>
          {renderDay(events, dayNumber)}
        </ul>
      </div>
    </>
  );
}
