import { Spinner } from '../../styles/icons';

import { isFunction } from '../../utils';
import NoResutsPage from './empty_results';
import ErrorPage from './error_page';

type handlerProps = {
  children: React.ReactNode;
  isError: boolean;
  data: any;
  isLoading: boolean;
  isArray?: boolean;
  mailList?: boolean;
  emptyDataMessage?: {
    title?: string;
    subtitle?: string;
  };
  onResetBtnCLick?: any;
};
const FetchHandlerWrapper = ({
  isError,
  data,
  isLoading,
  children,
  isArray = true,
  mailList = false,
  emptyDataMessage = {
    title: '',
    subtitle: '',
  },
  onResetBtnCLick = undefined,
}: handlerProps) => {
  const { title, subtitle } = emptyDataMessage;
  const hasResetBtn = typeof onResetBtnCLick === 'function';
  if (isLoading) {
    return (
      <div className="h-96 items-center flex justify-center align-center w-full">
        <Spinner className="text-orange-700" />
      </div>
    );
  }
  if (isError) {
    return (
      <div className="h-screen bg-gray-100 flex justify-center">
        <ErrorPage />
      </div>
    );
  }
  const isEmpty = data
    ? isArray
      ? Boolean(mailList ? data.emails.length === 0 : data.records.length === 0)
      : !data
    : false;

  if (isEmpty) {
    return (
      <div className={`h-96 flex py-5 w-full ${hasResetBtn ? 'flex-col' : ''}`}>
        <NoResutsPage
          title={title ? title : 'Keine Ergebnisse für input'}
          subtitle={subtitle}
        />
        {hasResetBtn && (
          <div className="mt-5">
            <button
              onClick={onResetBtnCLick}
              type="button"
              className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 sm:text-sm">
              Filter zurücksetzen
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      {isFunction(children)
        ? (children as (data: any) => React.ReactNode)(data)
        : null}
    </>
  );
};

export default FetchHandlerWrapper;
