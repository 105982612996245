import { TrashIcon } from '@heroicons/react/solid';
import { classNames } from '../../../../styles/utils';
import { ScandeDocProp } from './index';

const CaptureDocumentButton = ({
  isSelectedScan,
  selectedScan,
  setOpenModal,
  setSelectedScanId,
  onImageshowClick,
}: {
  isSelectedScan: boolean;
  setSelectedScanId: any;
  onImageshowClick: () => void;
  selectedScan: ScandeDocProp[] | undefined;
  setOpenModal: (open: boolean) => void;
}) => {
  return (
    <div className="w-full max-w-sm px-4 flex align-middle">
      <label
        htmlFor="scan-by-camera"
        className={classNames(
          isSelectedScan
            ? 'text-gray-500 cursor-not-allowed'
            : 'text-orange-500 hover:text-orange-900 cursor-pointer',
          'relative bg-white font-medium focus-within:outline-none text-sm w-full',
        )}>
        <span
          className="text-center"
          onClick={
            isSelectedScan
              ? () => null
              : selectedScan
              ? () => onImageshowClick()
              : () => setOpenModal(true)
          }>
          {selectedScan ? 'Bilder ansehen' : 'Kamera benutzen'}
        </span>
      </label>

      {selectedScan && (
        <TrashIcon
          className="h-5 w-5 ml-2 cursor-pointer hover:text-orange-600 text-gray-700"
          onClick={() => setSelectedScanId(null)}
        />
      )}
    </div>
  );
};

export default CaptureDocumentButton;
