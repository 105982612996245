import { getClasses } from '../../utils';

type avatarProp = {
  height: string;
  width: string;
  src: string;
  shape?: 'rounded-full' | 'rounded-md';
  display?: string;
  hasNotification?: boolean;
  status?: 'bg-red-400' | 'bg-green-400' | 'bg-gray-300';
};

const Avatar = ({
  src,
  width,
  height,
  shape = 'rounded-full',
  display = 'inline-block',
  hasNotification = false,
  status = 'bg-gray-300',
}: avatarProp) => {
  const cobinedClasses = getClasses({
    height,
    width,
    shape,
  });
  let Avatar;
  if (hasNotification) {
    Avatar = (
      <span className="inline-block relative">
        <img className={cobinedClasses} src={src} alt="" />
        <span
          className={`absolute top-0 right-0 block h-2.5 w-2.5 rounded-full ring-2 ring-white ${status}`}
        />
      </span>
    );
  } else {
    Avatar = (
      <img className={`${display} ${cobinedClasses}`} src={src} alt="" />
    );
  }

  // leter on we add Link and events based on needs ..
  return <div>{Avatar}</div>;
};

export default Avatar;
