import { useState } from 'react';
import {
  // ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ClockIcon,
  ExclamationIcon,
  //   EllipsisHorizontalIcon,
} from '@heroicons/react/solid';
// import { Menu, Transition } from '@headlessui/react';

import { dateRangeBasedOnReservation } from '../../../../utils';
import dayjs from 'dayjs';

import { format, startOfMonth, subMonths, addMonths } from 'date-fns';
import {
  MonthlyBody,
  MonthlyDay,
  MonthlyCalendar,
  DefaultMonthlyEventItem,
} from './month_calender';
import { EventType } from '@testing-library/react';
import { Slideover } from '../../../../styles/components';
import BookCar from '../vehicles_table/book_car';
import { useParams } from 'react-router-dom';
import { Query } from 'shared';
import { FormikProps } from 'formik';
import { bookCarValidationschema } from '../../admin/utils';
import {
  Form,
  NotificationMessage,
  // Modal,
  // ContactStatus,
  // NotificationMessage,
} from '../../../shared';
import { de } from 'date-fns/locale';

type BookCarProps = {
  brand: string;
  city: string;
  name: String;
  description: string;
  end_date: string;
  start_date: string;
};

//[...dateRangeGenerator(new Date('2021-06-01'), new Date('2021-06-04'))];

export default function CalenderEvent({
  isCalenderAvaliable,
  isLoadingCalenderInfo,
  currentUerEmail,
}: {
  isCalenderAvaliable: boolean;
  isLoadingCalenderInfo: boolean;
  currentUerEmail?: string;
}) {
  const [open, setOpen] = useState(false);
  const [notification, setNotfication] = useState({
    title: '',
    subTitle: '',
  });
  const [showNotification, setShowNotification] = useState(false);
  let [currentMonth, setCurrentMonth] = useState<Date>(
    startOfMonth(new Date()),
  );
  const [selectedDailyEvents, setEvents] = useState([]);
  const [selectedSingleEvent, setSingleEvent] = useState({});
  const [isNewReservation, setNewReservation] = useState(false);
  const { id } = useParams<{ id: string }>();

  const {
    FetchVehiclesById,
    FetchReservationsByVehiclesId,
    SubmitDataToserver,
    UpdateUserData,
    DeleteVehiclesorReservation,
  } = Query();

  // const promiseOptions = async (inputValue: string) => {
  //   const getBrands = FetchVehiclesBrands({
  //     searchQuery: inputValue ? inputValue : undefined,
  //   });
  //   const data = await getBrands();
  //   //@ts-ignore
  //   return data.records;
  //   // return datae;
  // };
  const { mutateAsync: createReservation } = SubmitDataToserver({
    url: 'reservations',
  });
  const { data: vehiclesData } = FetchVehiclesById({
    key: `vehicles-data-${id}`,
    id: id,
  });

  const { data: vehiclesEvents, refetch } = FetchReservationsByVehiclesId({
    key: `vehicles-events-${id}`,
    id: id,
  });
  const { mutateAsync: updateVehiclesReservation } = UpdateUserData({
    //@ts-ignore
    url: `reservations/${selectedSingleEvent?.id}`,
  });
  // //@ts-ignore
  // console.log(
  //   '🚀 ~ currentMonth=======.selectedSingleEvent',
  //   selectedSingleEvent,
  // );

  const { mutateAsync: deleteReservation } = DeleteVehiclesorReservation();

  const handleDeleteReservation = async () => {
    try {
      //@ts-ignore
      await deleteReservation(`reservations/${selectedSingleEvent?.id}`);

      setSingleEvent({});
      setOpen(false);
      setNotfication({
        title: 'Reservierung gelöscht',
        subTitle: 'Ihre Reservierung wurde erfolgreich gelöscht.',
      });
      setShowNotification(true);
      refetch();
    } catch (e) {
      console.log('erroremove reser', e);
    }
  };

  const handleSubmitCarBooking = async (
    values: BookCarProps,
    formikBag: any,
  ) => {
    const { setErrors } = formikBag;
    const reservationData = {
      reservation: {
        customer_id: '',
        vehicle_id: id,
        start_date: values.start_date,
        end_date: values.end_date,
        note: values.description,
      },
    };
    // console.log('reservationData', reservationData);
    try {
      if (isNewReservation) {
        await createReservation(reservationData);
        setNotfication({
          title: 'Reservierung gespeichert',
          subTitle: 'Das Fahrzeug wurde erfolgreich reserviert.',
        });
        setNewReservation(false);
      } else {
        // console.log('values==========', values);
        await updateVehiclesReservation(reservationData);
        setNotfication({
          title: 'Reservierung aktualisiert',
          subTitle: 'Ihre Reservierung wurde bearbeitet.',
        });
      }

      setShowNotification(true);
      refetch();
      setOpen(false);
    } catch (e) {
      //@ts-ignore
      if (e.response && e.response.status === 422) {
        // console.log('eror reservation', e.response.data?.errors?.base[0]);
        setErrors({
          error:
            //@ts-ignore
            e.response.data?.errors?.base[0] ||
            'Die Reservierung ist nicht möglich. Der gewünschte Zeitraum überschneidet sich mit vorhandenen Reservierungen.',
        });
      } else {
        setErrors({
          error: 'Bitte nehmen Sie die Reservierung erneut vor.',
        });
      }
    }
  };

  const dateRange = dateRangeBasedOnReservation(vehiclesEvents?.reservations);
  // console.log('🚀 ~ file: dateRange ~dateRangeeventsFinal:', currentUerEmail);
  const stats = [
    {
      name: 'Marke',
      value: vehiclesData?.brand,
      change: '+4.75%',
      changeType: 'positive',
    },
    {
      name: 'Modell',
      value: vehiclesData?.name,
      change: '-1.39%',
      changeType: 'positive',
    },
    {
      name: 'FIN/VIN',
      value: vehiclesData?.vin,
    },
  ];

  // console.log('eventseventsevents==========', flattenDeep(dateRange));
  if (isLoadingCalenderInfo) return null;
  if (!isCalenderAvaliable)
    return (
      <div className={`h-96 flex py-5 w-full`}>
        <div className="rounded-md bg-yellow-50 p-4 w-full">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-orange-500"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-orange-800">
                Die Fahrzeug-Reservierung ist für Ihre Autohaus nicht aktiviert
              </h3>

              <div className="mt-2 text-sm text-orange-700">
                <p>
                  Wenn Sie die Fahrzeug-Reservierung in CheckTastic nutzen
                  möchten, nehmen Sie gerne mit uns{' '}
                  <a
                    href="https://www.checktastic.de/privacy/contact"
                    target="_blank"
                    rel="noreferrer">
                    Kontakt
                  </a>{' '}
                  auf.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <NoResutsPage
          title="Die Fahrzeug-Reservierung ist für Ihre Autohaus nicht aktiviert"
          subtitle="Wenn Sie die Fahrzeug-Reservierung in CheckTastic nutzen möchten, nehmen Sie gerne mit uns Kontakt (Link zum Kontaktformular) auf."
        /> */}
      </div>
    );
  return (
    <div className="flex h-full flex-col">
      <dl className="grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-3 my-2 border">
        {stats.map((stat) => (
          <div
            key={stat.name}
            className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-gray-50 px-6 py-5">
            <dt className="text-base font-medium leading-6 text-gray-500">
              {stat.name}
            </dt>

            <dd className="w-full flex-none text-xl font-medium leading-10 tracking-tight text-gray-900">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
      {/* <div className="sm:col-span-6">
        <p className={'font-bold text-blue-gray-900 mb-1'}>Fahrzeug Details:</p>
        <h3 className="font-bold mt-2">{vehiclesData?.brand}</h3>
        <div className="col-span-1 mb-1">
          <dt className="text-md font-light text-gray-500">
            {vehiclesData?.name}
          </dt>
        </div>
      </div>
      <div className="sm:col-span-6 pb-2">
        <div className="col-span-1 mb-2">
          <dt className="text-md font-light text-gray-500">
            kind: {vehiclesData?.kind}
          </dt>
          <dt className="text-md font-light text-gray-500">
            FIN/VIN: {vehiclesData?.license_plate}
          </dt>
        </div>
      </div> */}
      <div className="flex h-full flex-col bg-gray-50 border my-2">
        <MonthlyCalendar
          locale={de}
          currentMonth={currentMonth}
          onCurrentMonthChange={(date) => {
            // console.log('dateeee', date);
            setCurrentMonth(date);
          }}>
          {/*@ts-ignore */}

          <header className="flex items-center justify-between border-gray-200  py-4 pb-8 lg:flex-none px-6">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              <time dateTime={dayjs().date().toString()}>
                {dayjs(currentMonth).format('MMMM YYYY')}
              </time>
            </h1>
            <div className="flex items-center">
              <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch border-t border-b">
                <button
                  onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
                  type="button"
                  className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50">
                  <span className="sr-only">Previous week</span>
                  <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                </button>
                <button
                  onClick={() => setCurrentMonth(new Date())}
                  type="button"
                  className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">
                  Heute
                </button>
                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
                <button
                  onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
                  type="button"
                  className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50">
                  <span className="sr-only">Next week</span>
                  <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
              <div className="ml-6 h-6 w-px bg-gray-300" />
              <button
                onClick={() => {
                  setOpen(true);
                  setNewReservation(true);
                  setSingleEvent({});
                }}
                type="button"
                className="ml-6 rounded-md bg-orange-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                hinzufügen
              </button>
            </div>
          </header>
          {/* <MonthlyNav /> */}
          <MonthlyBody
            // omitDays={[0]}
            //@ts-ignore
            events={dateRange}>
            <MonthlyDay<EventType>
              onDaySelect={(e) => setEvents(e)}
              renderDay={(data, number) => {
                // console.log('dagaaadadamonth', data, number);
                return data.map((item: any, index: number) => (
                  <DefaultMonthlyEventItem
                    user={item?.user}
                    start_time={item.from}
                    ent_time={item.to}
                    onEventClick={() => {
                      // console.log('event single', item);
                      setNewReservation(false);
                      setSingleEvent(item);
                      setOpen(true);
                    }}
                    key={item.id}
                    variantColorId={index}
                    //@ts-ignore
                    title={item?.title}
                    // Format the date here to be in the format you prefer
                    //@ts-ignore
                    date={format(item?.date, 'hh:mm')}
                  />
                ));
              }}
            />
          </MonthlyBody>
        </MonthlyCalendar>
        {/* mobile event */}
        {selectedDailyEvents.length > 0 && (
          <div className="py-8 sm:px-6 lg:hidden">
            <ol className="divide-y divide-gray-100 overflow-hidden rounded-lg bg-white text-sm shadow ring-1 ring-black ring-opacity-5">
              {selectedDailyEvents.map((event: any) => (
                <li
                  key={event.id}
                  className="group flex p-4 pr-6 focus-within:bg-gray-50 hover:bg-gray-50">
                  <div className="flex-auto">
                    <p className="font-semibold text-gray-900">
                      {event?.title}
                    </p>
                    <time
                      dateTime={event?.date}
                      className="mt-2 flex items-center text-gray-700">
                      <ClockIcon
                        className="mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <div className="flex text-sm flex-1 flex-col group  overflow-y-auto rounded-lg bg-orange-100 p-2  leading-5 hover:bg-orange-300 hover:text-gray-700">
                        {/* <p className="text-gray-500">{date}</p> */}
                        <p className="text-gray-700 block text-xs ml-2">
                          <span className="font-bold">vom {''}</span>{' '}
                          {dayjs(event.start_time).format(
                            'dd,DD.MM.YYYY HH:mm',
                          )}
                        </p>
                        <p className="text-gray-700 block text-xs ml-2">
                          <span className="font-bold">bis {''}</span>{' '}
                          {dayjs(event.ent_time).format('dd,DD.MM.YYYY HH:mm')}
                        </p>
                        <p className="text-gray-700 block text-xs ml-2">
                          <span className="font-bold">durch {''}</span>{' '}
                          {event?.user}
                        </p>
                      </div>
                    </time>
                  </div>
                  <button
                    onClick={() => {
                      setNewReservation(false);
                      setSingleEvent(event);
                      setOpen(true);
                    }}
                    className="ml-6 flex-none self-center rounded-md bg-white px-3 py-2 font-semibold text-gray-900 opacity-0 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400 focus:opacity-100 group-hover:opacity-100">
                    Edit<span className="sr-only">, {event?.title}</span>
                  </button>
                </li>
              ))}
            </ol>
          </div>
        )}

        <Slideover
          open={open}
          toggle={setOpen}
          title="Fahrzeug reservieren"
          style={{
            // subtitleStyle: carStatus === 'NEWCAR' ? 'text-white' : undefined,
            // titleStyle: carStatus === 'NEWCAR' ? 'text-white' : undefined,
            closeIconColor: 'text-gray-900',
            headerStyle: 'bg-white border-b-2',
          }}>
          <Form
            submitForm={handleSubmitCarBooking}
            validationSchema={bookCarValidationschema}
            initialValues={{
              brand: vehiclesData?.brand,
              license_plate: vehiclesData?.vin,
              // @ts-ignore
              start_date: isNewReservation ? null : selectedSingleEvent?.from,
              name: vehiclesData?.name,
              // @ts-ignore
              end_date: isNewReservation ? null : selectedSingleEvent?.to,
              // @ts-ignore
              description: isNewReservation ? null : selectedSingleEvent?.title,
              city: vehiclesData?.location?.city,
              isPickupdateBeforeReturnDate: true,
              // fuel_kind: vehiclesData?.fuel_kind,
            }}>
            {({ values }: FormikProps<BookCarProps>) => {
              return (
                //@ts-ignore
                <BookCar
                  canDeleteReservation={
                    //@ts-ignore
                    selectedSingleEvent?.user_email === currentUerEmail
                  }
                  {...selectedSingleEvent}
                  isNewReservation={isNewReservation}
                  setOpen={setOpen}
                  handleAddCar={() => null}
                  handleDeleteReservation={handleDeleteReservation}
                />
              );
            }}
          </Form>
        </Slideover>
        <NotificationMessage
          // isError={isCSVuploadError}
          title={notification.title}
          subTitle={notification.subTitle}
          notify={showNotification}
          setShow={setShowNotification}
        />
        <div className="h-5 xl:h-10 block text-white">
          <p>.</p>
          <p>.</p>
        </div>
      </div>
    </div>
  );
}
