/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useState } from 'react';
import { Field, FormikProps } from 'formik';
import { useAbility } from '@casl/react';
import { Query, BranchOfficesResponseItem } from 'shared';
import { Form, NotificationMessage } from '../../shared';
import { classNames } from '../../../styles/utils';
import FileUpload from './file_upload';
import { DocumentTextIcon, DownloadIcon } from '@heroicons/react/outline';
import { headquarterValidationSchema } from './utils';
import { Link } from 'react-router-dom';
import { AbilityContext } from '../../../auth';
import config from 'shared/config';

const actions = [
  {
    icon: DocumentTextIcon,
    name: 'Auftragsdatenverarbeitung',
    title: (
      <>
        Hier finden Sie ihre Auftragsdatenverarbeitung. Haben Sie Fragen hierzu,
        nehmen Sie bitte Kontakt mit uns auf{' '}
      </>
    ),
    href: (
      <Link to="/privacy/contact">
        <span className="font-semibold cursor-pointer z-50">
          Kontaktformular
        </span>
      </Link>
    ),
    iconForeground: 'text-orange-500',
    iconBackground: 'bg-orange-100',
  },
  {
    icon: undefined,
    name: 'Vertragsinformationen',
    title: (
      <>
        Für einen aktuellen Vertragsstatus, nehmen Sie bitte Kontakt mit uns auf{' '}
      </>
    ),
    href: (
      <Link to="/privacy/contact">
        <span className="font-semibold cursor-pointer z-50">
          Kontaktformular
        </span>
      </Link>
    ),
    iconForeground: 'text-white',
    iconBackground: 'bg-gray-700',
  },
];

const HeadQuarter = ({
  headQuearterData,
  productType,
  refetch,
}: {
  headQuearterData: BranchOfficesResponseItem;
  refetch: () => void;
  productType?: string;
}) => {
  const [showNotification, setShow] = useState(false);
  const {
    UpdateUserAvatar,
    UpdateUserData,
    DeleteAvatar,
    // FetchDictionarysData,
  } = Query();
  // const { data: dictionaryData } = FetchDictionarysData({
  //   key: 'dictionary-data',
  // });
  // console.log('🚀 ~ dictionaryData:', dictionaryData);
  const { mutateAsync } = UpdateUserAvatar({
    url: `dealer/headquarters/${headQuearterData.id}/logo_image`,
  });
  const { mutateAsync: deleteAvatar } = DeleteAvatar();

  const {
    mutateAsync: updateUserData,
    // error: errorUpdatingUserData,
    // data: updatedUserData,
  } = UpdateUserData({
    url: `dealer/headquarters/${headQuearterData.id}`,
  });
  const handleProflileDataSubmit = async (values: any, formikBag: any) => {
    const { setErrors } = formikBag;
    const formData = {
      dealer_headquarter: {
        name: values.name,
      },
    };

    try {
      if (
        typeof values.profileurl !== 'string' &&
        typeof values.profileurl !== undefined
      ) {
        await mutateAsync({
          ...values.profileurl,
        });
      }

      if (
        headQuearterData &&
        headQuearterData.logo_image &&
        headQuearterData.logo_image.thumbnail &&
        values.profileurl === ''
      ) {
        await deleteAvatar(
          `dealer/headquarters/${headQuearterData.id}/logo_image`,
        );
      }

      if (headQuearterData && headQuearterData.name !== values.name) {
        //@ts-ignore
        await updateUserData({
          ...formData,
        });
      }
      setShow(true);
      refetch();
    } catch (e) {
      setErrors({
        error:
          'Beim Aktualisieren Ihres Profiles ist ein Fehler aufgetreten. Bitte versuchen Sie zu einem späteren Zeitpunkt erneut',
      });
      // console.log(
      //   'error from orm submition',
      //   Object.values(e.response.data)[0],
      //   e.response.data,
      // );
    }
    // resetForm();
  };

  const filedClassName =
    'p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500';
  const labelClassName = 'block text-sm font-medium text-blue-gray-900';

  //@ts-ignore
  const ability = useAbility(AbilityContext);

  return (
    <div className="py-2 lg:py-1 md:px-6 flex flex-col px-2">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 pb-3">
        Zentrale
      </h1>

      <div className="mt-5 rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
        {/* <h2 className="sr-only" id="quick-links-title">
          Quick links
        </h2> */}
        {actions.map((action, actionIdx) => (
          <div key={action.name} className={'bg-white p-6'}>
            <div className="flex justify-between">
              {action.icon ? (
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    'rounded-lg inline-flex p-3 ring-4 ring-white',
                  )}>
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              ) : (
                <a href={undefined}>
                  <span className="rounded-lg inline-flex py-3 px-5 ring-4 ring-white bg-gray-700 text-white">
                    {productType ? productType.toUpperCase() : 'Unbekannt'}{' '}
                  </span>
                </a>
              )}
              {ability.can('download', 'contract') && (
                <div>
                  {actionIdx === 0 && (
                    <a
                      target="_blank"
                      href={
                        headQuearterData.adv_file &&
                        headQuearterData.adv_file.orignal
                          ? config.baseURL + headQuearterData.adv_file.orignal
                          : undefined
                      }
                      // type="button"
                      className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                      rel="noreferrer">
                      <DownloadIcon
                        className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span>Download</span>
                    </a>
                  )}
                </div>
              )}
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">{action.name}</h3>
              <p className="mt-2 text-sm text-gray-500 z-50">
                {action.title}
                {action.href}
              </p>
            </div>
          </div>
        ))}
      </div>

      <Form
        submitForm={handleProflileDataSubmit}
        validationSchema={headquarterValidationSchema}
        initialValues={{
          removedata: [],
          name: headQuearterData ? headQuearterData.name : '',
          profileurl:
            headQuearterData && headQuearterData.logo_image
              ? headQuearterData.logo_image.thumbnail
              : undefined,
        }}>
        {({
          errors,
          isSubmitting,
          dirty,
          values,
          touched,
          setFieldValue,
        }: FormikProps<any>) => {
          // console.log('values', values);
          return (
            <div className="mt-6 space-y-8">
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                <div className="sm:col-span-6">
                  <div>
                    <h1 className="text-lg leading-6 font-medium text-gray-900">
                      Unternehmensinformationen
                    </h1>
                    <p className="mt-1 text-sm text-gray-500">
                      Die Angaben hier beziehen sich auf Ihren Zentralaccount.
                      Das Logo wird auch für die Standortaccounts verwendet,
                      sollten Sie Standorte hinzugefügt haben.
                    </p>
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="name" className={labelClassName}>
                    Unternehmensname
                  </label>
                  <Field
                    disabled={!ability.can('update', 'HeadQuarter_info')}
                    className={`${filedClassName} w-full`}
                    type="text"
                    name="name"
                  />
                </div>
                {headQuearterData && headQuearterData.vehicle_brands.length ? (
                  <div className="sm:col-span-6">
                    <div>
                      <label htmlFor="name" className={labelClassName}>
                        Marke
                      </label>
                      <ul className="mt-2 leading-8">
                        {headQuearterData.vehicle_brands.map(
                          (brand: { name: string; id: number }) => (
                            <li className="inline" key={brand.id}>
                              <div
                                // href="#"
                                className="relative bg-white inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                                <div className="mx-2 text-sm font-medium text-gray-900">
                                  {brand.name}
                                </div>
                              </div>{' '}
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                  </div>
                ) : null}
                {/* <div className="sm:col-span-3" /> */}
                <div className="sm:col-span-6  border-gray-200 pb-10">
                  <div className="mt-4 flex flex-col">
                    <div className="flex-shrink-0 ">
                      <FileUpload
                        isImagePreview
                        canUpdate={ability.can('update', 'HeadQuarter_info')}
                        avatarClassName="h-15 w-15"
                        title="Logo Unternehmen"
                        filedName={'profileurl'}
                        value={values['profileurl']}
                        setFieldValue={setFieldValue}
                        labelClassName={labelClassName}
                      />
                    </div>
                  </div>
                </div>
                {headQuearterData && headQuearterData.delete_after_n_months ? (
                  <div className="sm:col-span-6 pb-20 lg:border-b-2">
                    <label htmlFor="removedata" className={labelClassName}>
                      Daten löschen
                    </label>
                    <span className="mt-3 text-xs text-gray-500 ">
                      Personenbezogene Daten aus den Checks werden nach der
                      nachstehend genannten Frist automatisch vollständig und
                      dauerhaft gelöscht.
                    </span>
                    <div className="mt-2 max-w-xs">
                      <div
                        // href="#"
                        className="relative bg-white inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5">
                        <div className="mx-2 text-sm font-medium text-gray-900">
                          {headQuearterData.delete_after_n_months} Monate
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="sm:col-span-6  pb-4">
                  {touched && Object.keys(errors).length > 0 && (
                    <div className="sm:col-span-3">
                      <p className="text-red-400 pt-2 text-sm font-semibold">
                        {Object.values(errors)[0]}
                      </p>
                    </div>
                  )}

                  {ability.can('update', 'HeadQuarter_info') && (
                    <div className="pt-8 flex justify-end">
                      <button
                        disabled={!dirty || isSubmitting}
                        className={classNames(
                          dirty &&
                            Object.keys(errors).length === 0 &&
                            !isSubmitting
                            ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                            : 'bg-gray-400 cursor-not-allowed',
                          'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                        )}
                        type="submit">
                        Speichern
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </Form>
      <NotificationMessage
        title="Ihr Unternehmenslogo wurde gespeichert."
        subTitle=""
        notify={showNotification}
        setShow={setShow}
      />
    </div>
  );
};

export default React.memo(HeadQuarter);
