const idCheck = ({ className = 'h-6 w-6' }: { className?: string }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.75 24C18.336 24 18 23.664 18 23.25C18 22.836 18.336 22.5 18.75 22.5H21.75C22.164 22.5 22.5 22.164 22.5 21.75V18.75C22.5 18.336 22.836 18 23.25 18C23.664 18 24 18.336 24 18.75V21.75C24 22.991 22.991 24 21.75 24H18.75Z"
        fill="white"
      />
      <path
        d="M23.25 6C22.836 6 22.5 5.664 22.5 5.25V2.25C22.5 1.836 22.164 1.5 21.75 1.5H18.75C18.336 1.5 18 1.164 18 0.75C18 0.336 18.336 0 18.75 0H21.75C22.991 0 24 1.009 24 2.25V5.25C24 5.664 23.664 6 23.25 6Z"
        fill="white"
      />
      <path
        d="M0.75 6C0.336 6 0 5.664 0 5.25V2.25C0 1.009 1.009 0 2.25 0H5.25C5.664 0 6 0.336 6 0.75C6 1.164 5.664 1.5 5.25 1.5H2.25C1.836 1.5 1.5 1.836 1.5 2.25V5.25C1.5 5.664 1.164 6 0.75 6Z"
        fill="white"
      />
      <path
        d="M2.25 24C1.009 24 0 22.991 0 21.75V18.75C0 18.336 0.336 18 0.75 18C1.164 18 1.5 18.336 1.5 18.75V21.75C1.5 22.164 1.836 22.5 2.25 22.5H5.25C5.664 22.5 6 22.836 6 23.25C6 23.664 5.664 24 5.25 24H2.25Z"
        fill="white"
      />
      <path
        d="M14.9 21C14.486 21 14.15 20.664 14.15 20.25V16.876C14.15 16.655 14.247 16.446 14.416 16.303C14.551 16.188 14.724 16.126 14.902 16.126C14.942 16.126 14.983 16.129 15.023 16.136L15.725 16.254C15.799 16.266 15.874 16.273 15.949 16.273C16.229 16.273 16.499 16.186 16.73 16.021C17.023 15.811 17.218 15.5 17.277 15.144C17.289 15.071 17.295 14.997 17.295 14.924V13.2C17.295 12.856 17.528 12.557 17.862 12.473L18.516 12.309C18.596 12.289 18.66 12.242 18.701 12.173C18.743 12.103 18.755 12.025 18.735 11.946C18.727 11.913 18.713 11.882 18.694 11.854L17.426 9.945C17.344 9.823 17.3 9.679 17.3 9.53V9.005C17.3 7.88 16.798 6.802 15.887 5.971C14.847 5.022 13.422 4.499 11.877 4.499C11.669 4.499 11.458 4.509 11.25 4.528C9.77401 4.662 8.43901 5.361 7.49001 6.498C6.54101 7.635 6.09201 9.073 6.22501 10.547C6.38601 12.341 7.41401 13.95 8.97501 14.851C9.20601 14.984 9.35001 15.233 9.35001 15.5V20.25C9.35001 20.664 9.01401 21 8.60001 21C8.18601 21 7.85001 20.664 7.85001 20.25V15.917C6.08301 14.742 4.92201 12.801 4.73101 10.682C4.56201 8.808 5.13301 6.981 6.33901 5.536C7.54401 4.092 9.24001 3.203 11.114 3.035C11.366 3.012 11.623 3 11.876 3C13.797 3 15.581 3.662 16.898 4.863C18.125 5.982 18.8 7.453 18.8 9.005V9.303L19.941 11.021C20.056 11.192 20.139 11.381 20.19 11.584C20.306 12.05 20.234 12.534 19.986 12.945C19.739 13.356 19.346 13.647 18.879 13.764L18.794 13.785V14.923C18.794 15.078 18.781 15.235 18.755 15.389C18.63 16.14 18.221 16.797 17.602 17.24C17.115 17.588 16.544 17.772 15.95 17.772C15.85 17.772 15.75 17.767 15.65 17.756V20.249C15.65 20.664 15.313 21 14.9 21Z"
        fill="white"
      />
    </svg>
  );
};

export default idCheck;
