import { useRef, useState } from 'react';
import {
  useParams,
  useHistory,
  // Link
} from 'react-router-dom';
import {
  Modal,
  NotificationMessage,
  DigitalSignature,
  ErrorPage,
} from '../../../shared';
import { Button } from '../../../../styles/components';
import Steps from '../new_contract/steps';
import * as yup from 'yup';

import Action from './action';
import { Query } from 'shared';
import config from 'shared/config';
import { Spinner } from '../../../../styles/icons';

export const validationSchema = yup.object({
  test: yup.string(),
});
type paramsProps = {
  id: string;
};

const PdfSignature = () => {
  const [openTab, setOpenTab] = useState<string | number>(0);
  const [showNotification, setShow] = useState(false);
  const [serverError, setError] = useState('');
  const [headlinError, setHeadlinError] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [signatureData, setSignatureData] = useState<any>(null);

  const { id } = useParams<paramsProps>();
  const history = useHistory();
  const digitalSignatureComponentRef = useRef(null);
  //@ts-ignore

  //   const onNextStep = () => {
  //     if (digitalSignatureComponentRef?.current) {
  //       //@ts-ignore
  //       // digitalSignatureComponentRef.current?.trim();
  //     }
  //   };
  const onClearDigitalSignature = () => {
    if (digitalSignatureComponentRef?.current) {
      //@ts-ignore
      digitalSignatureComponentRef.current?.clear();
    }
  };
  const { FetchContractDataById, SubmitDataToserver } = Query();

  const {
    mutateAsync,
    reset,
    isLoading: isSubmitting,
  } = SubmitDataToserver({
    url: `${config.apiURL}/contracts/${id}/add_signatures`,
  });

  const { data, isLoading } = FetchContractDataById({
    url: `${config.apiURL}/contracts/${id}/new_signatures`,
    key: `new_signatures-${id}`,
    enabled: !!id,
  });
  //@ts-ignore
  //   console.log('🚀 ~ configObject:', data);
  //   const fieldClassName =
  //     'mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm';
  //   const labelClass = 'block text-sm font-medium text-gray-700';
  //   const propsClass = {
  //     fieldClassName,
  //     labelClass,
  //   };
  //   const { name } = configObject.length ? configObject[Number(openTab)] : '';

  const handleSubmitContract = async () => {
    const formData = {
      contract: {
        signatures_attributes: Object.entries(signatureData).map(
          (file: any) => {
            return {
              kind: file[0],
              image: file[1],
            };
          },
        ),
      },
    };
    //  console.log('🚀 ~ signatureData:', formData);
    try {
      const res = await mutateAsync(formData);
      // console.log('res', res);
      if (res) {
        if (showNotification) {
          setShow(false);
        }

        setOpenDialog(true);
        reset();
      }
    } catch (e) {
      //@ts-ignore
      if (e.response) {
        //@ts-ignore
        //   console.log('🚀 ~ handleSubmitContract ~ e.response:', e.response);
        //@ts-ignore
        setError(e.response.data.error);
        //@ts-ignore
        if (e.response.data?.headline) {
          //@ts-ignore
          setHeadlinError(e.response.data.headline);
        }
        setShow(true);
      }
    }
    // console.log('🚀 ~ handleSubmitContract ~ formData:', formData);
  };

  const getFinallConfigObject = (data: any): Array<any> => {
    if (!data) return [];
    return [
      ...data,
      {
        name: '-Vorschau',
        fullNAme: 'Vorschau',
        // this item should be the last item in the array since it's the overview
        index: data.length,
        kind: 'overview',
        skip: false,
      },
    ].map((item: any, index: number, obj: any) => {
      return {
        name: item.name.split('-')[1],
        fullNAme: item.name,
        id: item.index,
        description:
          obj.length - 1 === index
            ? 'Alles im Überblick'
            : `Unterschrift ${item.index + 1} von ${obj.length}`,
        type: item.kind,
        skip: item.is_optional,
      };
    });
  };

  if (isLoading)
    return (
      <div className="flex justify-center align-middle">
        <Spinner className="h-4 w-4 text-orange-400" />
      </div>
    );
  if (!data) return <ErrorPage />;
  const finalConfigObject = getFinallConfigObject(data);
  const isLastStep =
    finalConfigObject && finalConfigObject.length - 1 === openTab;
  let openCOmp =
    finalConfigObject.length && finalConfigObject[Number(openTab)].id;

  //   console.log(
  //     '🚀 ~ handleSubmitContract ~ finalConfigObject:',
  //     finalConfigObject,
  //   );
  //   console.log('🚀 ~ openCOmp ~ openCOmp:', openCOmp);
  return (
    <div className="flex flex-col -m-5 pb-8 h-full">
      <Steps openTab={openTab} currentStepObject={finalConfigObject} />
      <div className="flex  mx-6 lg:mx-8 py-5">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Digital unterschreiben
        </h2>
      </div>
      <div className="mx-2 sm:mx-6 lg:mx-8 shadow-md rounded-md bg-white lg:w-12/12 lg:p-4 h-full">
        <div className="h-full flex flex-col">
          <div className={'py-6 px-4'}>
            {isLastStep ? (
              <div className="border-b border-gray-200 pb-5">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Alle digitalen Unterschriften im Überblick
                </h3>
                {/* <p className="mt-2 max-w-4xl text-sm text-gray-500">
                  Workcation is a property rental website. Etiam ullamcorper
                  massa viverra consequat, consectetur id nulla tempus.
                  Fringilla egestas justo massa purus sagittis malesuada.
                </p> */}
              </div>
            ) : (
              <h3 className="text-xl font-semibold text-gray-900">
                {finalConfigObject[Number(openTab)].fullNAme}
              </h3>
            )}
          </div>
          <div
            className={
              //@ts-ignore
              openTab === openCOmp && finalConfigObject.length - 1 !== openTab
                ? 'h-full flex px-4 flex-col justify-center align-middle'
                : 'hidden'
            }>
            {/* dynamically render the signature component based on finalConfigObject except from the last step  << data might come from server >> */}
            {finalConfigObject[Number(openTab)] && (
              <DigitalSignature
                ref={digitalSignatureComponentRef}
                //@ts-ignore
                name={finalConfigObject[Number(openTab)].type}
                onSignatureData={setSignatureData}
                imageSrc={
                  signatureData
                    ? signatureData[finalConfigObject[Number(openTab)].type]
                    : null
                }
              />
            )}
          </div>

          <div className={isLastStep ? 'h-full flex px-4 flex-col' : 'hidden'}>
            <ul
              //   role="list"
              className="grid md:grid-cols-2 gap-x-4 gap-y-8 grid-cols-1 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
              {signatureData &&
                Object.entries(signatureData).map((file: any, index) => {
                  return (
                    <li key={file[0]} className="relative">
                      <div className="group md:h-60 h-32 w-full  block overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-orange-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                        {file[1] && (
                          <img
                            alt="keine Unterschrift"
                            src={file[1]}
                            className="pointer-events-none object-contain group-hover:opacity-75 h-full w-full"
                          />
                        )}
                        <button
                          onClick={() => setOpenTab(index)}
                          type="button"
                          className="absolute inset-0 focus:outline-none">
                          <span className="sr-only">
                            View details for {file[0]}
                          </span>
                        </button>
                      </div>
                      <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                        {
                          finalConfigObject.find(
                            (item: any) => item.type === file[0],
                          )?.fullNAme
                        }
                      </p>
                      {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">
                      test
                    </p> */}
                    </li>
                  );
                })}
            </ul>
          </div>

          <div className={'py-6 px-4'}>
            <Action
              isLoading={isSubmitting}
              lastStepNumber={finalConfigObject.length - 1}
              onClearDigitalSignature={() => onClearDigitalSignature()}
              canSkipSignature={finalConfigObject[Number(openTab)]?.skip}
              canClearSignature={
                signatureData
                  ? Boolean(
                      signatureData[finalConfigObject[Number(openTab)].type],
                    )
                  : false
              }
              handleSubmitContract={handleSubmitContract}
              openTab={openTab}
              setOpenTab={setOpenTab}
            />
          </div>
        </div>

        <Modal
          hasIcon
          setOpen={setOpenDialog}
          open={openDialog}
          title="Sie haben alle erforderlichen Unterschriften geleistet."
          // subtile="Sie können den Unterschrift  Vorgang gehen."
        >
          {/* <div className="mt-5 sm:mt-6">
            <Button
              onClick={() => null}
              label="Ja, sicher"
              type="primary"
              className="w-full justify-center "
            />
          </div> */}
          <div className="mt-5 sm:mt-6">
            <Button
              onClick={() => {
                setOpenDialog(false);
                history.push(`/online_signature_pdf/${id}`);
              }}
              label="Zur Startseite"
              type="default"
              className="w-full justify-center "
            />
          </div>
        </Modal>
      </div>
      <NotificationMessage
        isError
        title={headlinError || 'Server Error'}
        subTitle={serverError}
        notify={showNotification}
        setShow={setShow}
      />
    </div>
  );
};

export default PdfSignature;
