import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { classNames } from '../../utils';
import { Fragment } from 'react';

type slideProp = {
  title?: string | React.ReactElement;
  children: React.ReactChild;
  toggle: (open: boolean) => void;
  open: boolean;
  subTitle?: string;
  style?: {
    titleStyle?: String;
    subtitleStyle?: string;
    closeIconColor?: string;
    headerStyle?: string;
    bodyStyle?: string;
  };
};

const SlideOver: React.FunctionComponent<slideProp> = ({
  title,
  children,
  open,
  toggle,
  subTitle,
  style = {
    titleStyle: 'text-gray-800',
    subtitleStyle: 'text-sm text-white',
    closeIconColor: 'text-gray-800',
    headerStyle: 'bg-white',
    bodyStyle: 'bg-white',
  },
}) => {
  const { titleStyle, subtitleStyle, closeIconColor, headerStyle, bodyStyle } =
    style;

  return (
    <Transition.Root
      show={open}
      //@ts-ignore
      as={Fragment}>
      <Dialog
        as="div"
        static
        className="relative z-10"
        open={open}
        onClose={toggle}>
        {/* <div className="fixed inset-0" /> */}
        <Transition.Child
          //@ts-ignore
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed overflow-hidden">
          <div className="absolute overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                //@ts-ignore
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div
                    className={classNames(
                      bodyStyle ? bodyStyle : 'bg-white',
                      'flex h-full flex-col overflow-y-scroll pb-6  shadow-xl ',
                    )}>
                    <div
                      className={classNames(
                        headerStyle ? headerStyle : 'bg-white',
                        'px-4 sm:px-6 py-6',
                      )}>
                      <div className="flex items-start justify-between">
                        <Dialog.Title
                          className={classNames(
                            titleStyle ? titleStyle : '',
                            'text-lg font-medium',
                          )}>
                          {/* @ts-ignore */}
                          {title}
                        </Dialog.Title>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            className={classNames(
                              closeIconColor ? closeIconColor : 'text-white',
                              `rounded-md md:hover:text-gray-500 focus:outline-non focus:ring-2 focus:ring-inset focus:ring-orange-500`,
                            )}
                            onClick={() => toggle(false)}>
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                      {subTitle && (
                        <div className="mt-1">
                          <p className={subtitleStyle}>{subTitle}</p>
                        </div>
                      )}
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOver;
