const FormInputCurrencyField = ({
  label,
  field,
  type = 'text',
  isKm = false,
  defaultValue,
  disabled = false,
}: {
  field: any;
  label: string;
  type?: 'text' | 'number';
  isKm?: boolean;
  defaultValue?: number;
  disabled?: boolean;
}) => {
  const { name, value, onChange, onBlur } = field;
  return (
    <div>
      <label
        htmlFor="price"
        className="block text-sm font-medium text-gray-700 mt-3 md:mt-1">
        {label}
      </label>
      <div className="mt-1 relative rounded-md shadow-sm">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          {!isKm && <span className="text-gray-500 sm:text-sm">€</span>}
        </div>
        <input
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          className="py-2 border border-gray-200 focus:ring-orange-500 focus:border-orange-500 block w-full pl-7 pr-12 sm:text-sm  rounded-md"
          placeholder="0.00"
          aria-describedby="price-currency"
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <span className="text-gray-500 sm:text-sm" id="price-currency">
            {isKm ? 'KM' : 'EUR'}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FormInputCurrencyField;
