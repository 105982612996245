export const generateHours = ({
  type = 'full',
  hoursValue,
}: {
  type: 'short' | 'full';
  hoursValue?: number;
}) => {
  const hoursdata = {
    short: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
    full: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23,
    ],
  };

  return hoursdata[type]
    .filter((h) => {
      if (hoursValue) {
        return h > hoursValue;
      }
      return true;
    })
    .map((h: number) => ({
      value: `${h}`,
      label: `${h}`,
    }));
};
export const generatMinutes = ({
  type = 'full',
}: {
  type: 'full' | 'short';
}) => {
  let minutesData = [];
  if (type === 'short') {
    minutesData = [
      { value: '00', label: '00' },
      { value: '15', label: '15' },
      { value: '30', label: '30' },
      { value: '45', label: '45' },
    ];
  } else {
    for (let i = 0; i < 60; i++) {
      minutesData[i] = {
        value: `${i}`,
        label: `${i < 10 ? `0${i}` : i}`,
      };
    }
  }

  return minutesData;
};
