// import { useState } from 'react';
import { privacyDataPro } from 'shared';
// import { Tabs, currentTabProp } from '../../shared';

// const tabs = [
//   { name: 'Impressum', id: 0, status: 'Impressum' },

//   // {
//   //   name: 'AGB',
//   //   id: 1,
//   //   status: 'AGB',
//   // },
// ];

const Impressum = ({
  title,
  __html,
  id,
  subtitle,
  __html2,
}: privacyDataPro) => {
  // const [current, setCurrent] = useState<currentTabProp | null>({
  //   status: '',
  //   id: 0,
  // });
  // const handleTabSelect = (selectedTabItem: currentTabProp) => {
  //   setCurrent(selectedTabItem);
  // };
  return (
    <div className="flex flex-col">
      <div className="flex flex-col pb-8">
        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          {title}
        </h2>
        <p className="mt-4 text-md text-gray-500 max-w-xl">
          Wenn Sie Fragen zu uns oder unseren Produkten haben, nehmen Sie mit
          uns Kontakt auf. Wir freuen uns auf Sie.
        </p>
      </div>

      {/* <Tabs
        tabs={tabs}
        onSelect={handleTabSelect}
        current={current ? current.id : null}
        counter={10}
      /> */}
      <div className="h-full">
        <div className={'flex flex-col pb-5'}>
          {/* <h1 className="py-5 font-bold text-2xl">{title}</h1> */}
          <p className="py-5" dangerouslySetInnerHTML={{ __html: __html }} />
        </div>

        {/* <div
          className={
            current && current.id === 1
              ? 'flex items-start flex-col py-5'
              : 'hidden'
          }>
          <p
            className="py-5"
            //@ts-ignore
            dangerouslySetInnerHTML={{ __html: __html2 }}
          />
        </div> */}
      </div>
    </div>
  );
};

export default Impressum;
