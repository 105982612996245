import { classNames } from '../../utils';
import Pagination, { IPagination } from './pagination';

export const TableContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <div
      //overflow-x-auto
      className="-my-2 sm:-mx-6 lg:-mx-8 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
      {children}
    </div>
  );
};

interface ITableProp extends IPagination {
  children: React.ReactNode;
  hasPagination?: boolean;
  isFetching?: boolean;
}
export const Table = ({
  children,
  hasPagination = false,
  isFetching = false,
  ...props
}: ITableProp) => {
  return (
    // the parent div for table border.. we can change it later if needed other tables with no border
    <div className="py-2 overflow-x-auto">
      <div
        className="border border-gray-200 shadow sm:rounded-t-lg bg-white flex  items-center justify-between"
        //overflow-x-auto md:overflow-hidden
      >
        <table className="min-w-full divide-y divide-gray-200">
          {children}
        </table>
      </div>

      <div className="border-t-gray-200 shadow sm:rounded-t-lg bg-white flex">
        {hasPagination && <Pagination {...props} isFetching={isFetching} />}
      </div>
    </div>
  );
};

export const TableHead = ({ children }: { children: React.ReactNode }) => {
  return (
    <thead className="bg-gray-50">
      <tr>{children}</tr>
    </thead>
  );
};
export const TableCell = ({
  children,
  className,
}: {
  children: React.ReactChild | string;
  className?: string;
}) => {
  const thClass =
    'px-6 py-3 text-left text-xs font-lg text-gray-600 uppercase tracking-wider';
  return (
    <th scope="col" className={className ? className : thClass}>
      {children}
    </th>
  );
};

export const TableBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <tbody className="bg-white divide-y divide-gray-200">{children}</tbody>
  );
};

export const TableRow = ({
  action,
  children,
}: {
  action: any;
  children: React.ReactNode;
}) => (
  <tr onClick={action} className="clickable-row">
    {children}
  </tr>
);

export const TableData = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const tdClass = 'px-6 py-4 whitespace-nowrap text-sm';
  return <td className={classNames(tdClass, className)}>{children}</td>;
};
