import { Field, useFormikContext } from 'formik';

import { classNames } from '../../../../styles/utils';
//@ts-ignore
// import AsyncSelect from 'react-select/async';
// //@ts-ignore
// import makeAnimated from 'react-select/animated';
// import { useParams } from 'react-router-dom';
import DatePicker from '../../ID_check/new_contract/date_picker';
import { useEffect, useState } from 'react';
import TimePicker from '../../ID_check/new_contract/time_picker';
import moment from 'moment';
import dayjs from 'dayjs';

// const animatedComponents = makeAnimated();
// const filedClassName =
// 'w-full p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500';
const labelClassName = 'block text-sm font-semibold text-blue-gray-900 mb-1';

// const colourStyles = {
//   option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
//     return {
//       ...styles,
//       backgroundColor: isDisabled
//         ? null
//         : isSelected
//         ? '#f97316'
//         : isFocused
//         ? '#f3f4f6'
//         : null,
//       // color: isDisabled
//       //   ? '#ccc'
//       //   : isSelected
//       //   ? chroma.contrast(color, 'white') > 2
//       //     ? 'white'
//       //     : 'black'
//       //   : data.color,
//       // cursor: isDisabled ? 'not-allowed' : 'default',
//     };
//   },
//   control: (styles: any, state: any) => ({
//     ...styles,
//     boxShadow: 0,
//     borderColor: state.isFocused ? 'orange' : 'rgba(209, 213, 219)',
//     '&:hover': {
//       borderColor: state.isFocused ? 'orange' : '#CED4DA',
//     },
//   }),
//   // input: styles => ({ ...styles, ...dot() }),
//   // placeholder: styles => ({ ...styles, ...dot() }),
//   // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
// };

const BookCar = ({
  setOpen,
  handleAddCar,
  from,
  to,
  start_time,
  ent_time,
  isNewReservation = false,
  handleDeleteReservation,
  canDeleteReservation,
}: //@ts-ignore

// isNewCar,
{
  setOpen: any;
  handleAddCar: any;
  from: any;
  to: any;
  ent_time: any;
  start_time: any;
  canDeleteReservation?: boolean;
  isNewReservation: boolean;
  handleDeleteReservation: () => void;
  //   car?: Vehicle; // User type
}) => {
  // console.log('🚀 ~ userId:', canDeleteReservation);
  const deletebtnClassName =
    'bg-red-600 py-2 px-6 border border-red-500 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700';
  const disabledBtnClassName =
    'px-6 py-2 rounded-md text-sm leading-4 border bg-gray-100 text-gray-400 cursor-not-allowed';
  const {
    values: {
      //@ts-ignore
      brand,
      //@ts-ignore
      city,
      //@ts-ignore
      name,
      //@ts-ignore
      // end_date,
      //@ts-ignore
      // description,
      //@ts-ignore
      license_plate,
      //@ts-ignore,
      start_date,
      //@ts-ignore,
      end_date,
    },
    touched,
    errors,
    isSubmitting,
    dirty,
    submitCount,
    setFieldValue,
  } = useFormikContext();

  const [startTime, setStartTime] = useState<any>(
    start_time
      ? {
          hours: start_time.$H,
          minutes: start_time.$m,
        }
      : null,
  );
  const [endTime, setEndTime] = useState<any>(
    ent_time
      ? {
          hours: ent_time.$H,
          minutes: ent_time.$m,
        }
      : null,
  );
  const [startDateObj, setStartDate] = useState(
    // we'r keep using moment JS because of the date lib we use is requqiring it.
    isNewReservation ? null : moment(from),
  );
  const [endtDateObj, setEndDate] = useState(
    isNewReservation ? null : moment(to),
  );

  const isPickupdateBeforeReturnDate = dayjs(end_date).isBefore(start_date);

  useEffect(() => {
    // in the next line we are checking if the pickupdate is before the return date and if it is we are setting the error message.
    if (isPickupdateBeforeReturnDate) {
      // setErrors({}} is used to set the error message for the field usually,
      //  but it did't work for this case  so we are greating a new isPickupdateBeforeReturnDate field in the formik values and we are setting the error message for this field.
      setFieldValue('isPickupdateBeforeReturnDate', '');
    } else {
      setFieldValue('isPickupdateBeforeReturnDate', 'false');
    }
    // eslint-disable-next-line
  }, [isPickupdateBeforeReturnDate]);

  useEffect(() => {
    if (startDateObj && startTime) {
      //@ts-ignore
      const combinedObject = startDateObj!.set({
        hour: Number(startTime.hours),
        minute: startTime.minutes ? Number(startTime.minutes) : 0,
      });
      //@ts-ignore
      const formatedDate = combinedObject._d;
      // combinedObject.toISOString();
      setFieldValue('start_date', formatedDate);
    }
  }, [startTime, startDateObj, setFieldValue]);

  useEffect(() => {
    if (endtDateObj && endTime) {
      //@ts-ignore
      const combinedObject = endtDateObj!.set({
        hour: Number(endTime.hours),
        minute: endTime.minutes ? Number(endTime.minutes) : 0,
      });
      //@ts-ignore
      const formatedDate = combinedObject._d;

      setFieldValue('end_date', formatedDate);
    }
  }, [endTime, endtDateObj, setFieldValue]);

  return (
    <div className="-mt-2">
      <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-6 sm:gap-x-6">
        <div className="sm:col-span-6">
          <p className={'font-bold text-lg text-blue-gray-900 mb-1'}>
            Fahrzeug Details:
          </p>
          <h3 className="font-bold mt-2">{brand}</h3>
          <div className="col-span-1 mb-1">
            <dt className="text-md font-light text-gray-500">{name}</dt>
          </div>
        </div>
        <div className="sm:col-span-6 border-b pb-2">
          <div className="col-span-1 mb-2">
            <dt className="text-md font-light text-gray-500">
              Standort: {city}
            </dt>
            <dt className="text-md font-light text-gray-500">
              FIN/VIN: {license_plate}
            </dt>
          </div>
        </div>
        {/* pickup time  */}
        <>
          <p className={'font-bold text-lg text-blue-gray-900'}>Abholung:</p>
          <div className="sm:col-span-6 mb-2">
            <label
              htmlFor={'start_date'}
              className={`block text-sm font-semibold text-gray-700 mb-1`}>
              Datum auswählen:
            </label>
            <div className="w-full">
              {/* @ts-ignore */}

              <Field name={'start_date'} type="textarea">
                {({ field }: any) => (
                  <DatePicker
                    isOutsideRange={(date: any) => {
                      const isbefore = date.isAfter(
                        //@ts-ignore
                        endtDateObj,
                        'day',
                      );
                      const isToday = date.isBefore(moment(), 'day');

                      return isbefore || isToday;
                    }}
                    id="start"
                    dateObject={startDateObj}
                    setDate={setStartDate}
                  />
                )}
              </Field>
            </div>
          </div>

          <div className="sm:col-span-6">
            <label
              htmlFor={'start_date'}
              className={`block text-sm font-semibold text-gray-700 mb-1`}>
              Uhrzeit auswählen:
            </label>

            <div className="w-full">
              <TimePicker
                // cb={({ name, value }) => {
                //   if (!isNewReservation) {
                //     //@ts-ignore
                //     const combinedObject = returnAtObject!.set({
                //       hour: name === 'hours' ? Number(value) : startTime!.hours,
                //       //@ts-ignore
                //       minute: name === 'minutes' && Number(value),
                //     });
                //     //@ts-ignore
                //     const formatedDate = combinedObject._d;

                //     setFieldValue('vehicle_returned_at', formatedDate);
                //   }
                // }}
                type="short"
                disabled={!startDateObj}
                hoursValue={
                  isNewReservation
                    ? undefined
                    : startTime
                    ? Number(startTime.hours)
                    : Number(start_time.$H)
                }
                minutesValue={
                  isNewReservation
                    ? undefined
                    : startTime
                    ? Number(startTime.minutes)
                    : Number(start_time.$m)
                }
                defaultValueMood={!isNewReservation}
                pickerValue={startTime}
                setValue={setStartTime}
                // disabled={!startDateObj && !start_date}
              />
            </div>
          </div>
        </>

        {/* return time  */}
        <>
          <div className="sm:col-span-6 w-[100%] pt-2 mt-3 border-t">
            <p className={'font-bold text-lg text-blue-gray-900'}>Rückgabe:</p>
          </div>
          <div className="sm:col-span-6 mb-2">
            <label
              htmlFor={'end_date'}
              className={`block text-sm font-semibold text-gray-700 mb-1`}>
              Datum auswählen:
            </label>
            <div className="w-full">
              {/* @ts-ignore */}

              <Field name={'end_date'} type="textarea">
                {({ field }: any) => (
                  <DatePicker
                    isOutsideRange={(date: any) => {
                      return date.isBefore(
                        //@ts-ignore
                        startDateObj,
                        'day',
                      );
                    }}
                    disabled={!startTime || !startDateObj}
                    dateObject={endtDateObj}
                    id="end"
                    setDate={setEndDate}
                  />
                )}
              </Field>
            </div>
          </div>

          <div className="sm:col-span-6">
            <label
              htmlFor={'end_date'}
              className={`block text-sm font-semibold text-gray-700 mb-1`}>
              Uhrzeit auswählen:
            </label>

            <div className="w-full">
              <TimePicker
                disabledHours={
                  isNewReservation
                    ? startTime &&
                      //@ts-ignore
                      dayjs(endtDateObj).date() === dayjs(startDateObj).date()
                      ? startTime.hours
                      : 0
                    : undefined
                }
                type="short"
                defaultValueMood={!isNewReservation}
                hoursValue={
                  isNewReservation
                    ? undefined
                    : endTime
                    ? Number(endTime.hours)
                    : Number(ent_time.$H)
                }
                minutesValue={
                  isNewReservation
                    ? undefined
                    : endTime
                    ? Number(endTime.minutes)
                    : Number(ent_time.$m)
                }
                pickerValue={endTime}
                setValue={setEndTime}
                disabled={!endtDateObj}
              />
            </div>
          </div>
        </>

        <div className="sm:col-span-6 mt-3">
          <label htmlFor="description" className={labelClassName}>
            Name, Anschrift und Tel-Nr. des Kunden
          </label>
          <Field name="description" type="textarea">
            {({ field }: any) => (
              <textarea
                {...field}
                className="p-1 md:h-32 shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 border rounded-md"
              />
            )}
          </Field>
        </div>
      </div>
      {touched && Object.keys(errors).length > 0 && (
        <div className="pt-5">
          <p className="text-red-400 text-sm font-semibold">
            {Object.values(errors)[0]}
          </p>
        </div>
      )}
      <div className={`py-5 flex justify-between`}>
        {!isNewReservation && (
          <button
            disabled={!canDeleteReservation}
            onClick={handleDeleteReservation}
            className={
              !canDeleteReservation ? disabledBtnClassName : deletebtnClassName
            }
            type="button">
            Löschen
          </button>
        )}
        <button
          onClick={() => setOpen(false)}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          type="button">
          Abbrechen
        </button>

        <button
          disabled={!dirty || isSubmitting || submitCount > 4}
          className={classNames(
            dirty &&
              Object.keys(errors).length === 0 &&
              !isSubmitting &&
              submitCount <= 4
              ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
              : 'bg-gray-400 cursor-not-allowed',
            'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
          )}
          type="submit">
          Speichern
        </button>
      </div>
    </div>
  );
};

export default BookCar;
