import { useState, useEffect } from 'react';
import { Query, Customer } from 'shared';
import { FetchHandlerWrapper } from '../../../shared';
import { List, Pagination } from '../../../../styles/components';
// import { UserIcon } from '@heroicons/react/solid';
// import { classNames } from '../../../styles/utils';
// import { statusStyles } from '../ID_check/utils';
import { dataFormat } from '../../../../utils';
import { MailIcon, DeviceMobileIcon } from '@heroicons/react/solid';
const CustomersSearch = ({
  searchQuery,
  onItemClick,
}: {
  searchQuery: string | string[] | null;
  onItemClick?: (item: any) => void;
}) => {
  const [page, setPage] = useState(1);
  const { FetchCustomerssData } = Query();
  const {
    isLoading,
    isError,
    data: customersData,
    isFetching,
    isSuccess,
    refetch,
  } = FetchCustomerssData({
    key: 'customers',
    page: page,
    searchQuery: searchQuery ? searchQuery : undefined,
    perPage: 5,
    // sortBy: sorteValue ? sorteValue.value : undefined,
  });

  useEffect(() => {
    if (customersData && !isFetching) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);
  // console.log('customersData', customersData);
  return (
    <div className="flex flex-col">
      <div className="flex flex-col my-2">
        <FetchHandlerWrapper
          isError={isError}
          isLoading={isLoading}
          data={customersData ? customersData : undefined}>
          {(data: Customer[]) => (
            <List
              onItemClick={onItemClick}
              //@ts-ignore
              items={data.records}
              styles={{
                ul:
                  'my-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0',
                li: 'cursor-pointer',
              }}>
              {(customer: Customer) => {
                //   console.log('data =====', item);
                return (
                  <div className="group block w-full">
                    <div className="flex items-center py-5 sm:py-6 sm:px-0">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="text-sm font-medium text-orange-600 truncate">
                              {customer.salutation} {customer.prename}{' '}
                              {customer.surname}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500">
                              <MailIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="truncate">{customer.email}</span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              <p className="text-sm text-gray-900">
                                Created at{' '}
                                {dataFormat({
                                  date: customer.created_at,
                                  option: {
                                    //  hour: false,
                                    month: 'long',
                                  },
                                })}
                              </p>
                              <p className="mt-2 flex items-center text-sm text-gray-500">
                                <DeviceMobileIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5"
                                  aria-hidden="true"
                                />
                                {customer.telephone_number}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </List>
          )}
        </FetchHandlerWrapper>
      </div>
      <div className="my-2 flex flex-col">
        {isSuccess && (
          <div className="md:-m-5">
            <Pagination
              styles={{
                navClassName:
                  'px-4 py-8 flex  items-center justify-between sm:px-6 min-w-full align-middle  border-t-2',
              }}
              {...customersData.pagination}
              isFetching={isFetching}
              setPage={setPage}
              currentpage={page}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomersSearch;
