import { FormikValues } from 'formik';
import { Fragment } from 'react';
import moment from 'moment';
import SBContractOverview from '../../sb_contract/overview';

type IContractOverview = { hasSBInsurance: string } & FormikValues;
const ContractOverview = ({
  isFree,
  prename,
  surname,
  identity_document_type,
  identity_document_number,
  date_of_birth,
  identity_document_number_drive,
  // identity_document_type_drive,
  drivers_liscense_issuing_authority,
  drivers_license_classes,
  street,
  postal_code,
  city,
  house,
  telephone_number,
  email,
  start_of_use,
  end_of_use,
  vin,
  pre_damage,
  short_description,
  odometer_value,
  license_plate,
  name,
  brand,
  additional_notes,
  tagesgrundpreis,
  additional_costs_attributes,
  stundenpauschale,
  fahrzeugkaution,
  zusatz_km,
  teilkasko_selbstbeteiligung_amount,
  vollkasko_selbstbeteiligung_amount,
  with_teilkasko,
  with_vollkasko,
  kind,
  hasSBInsurance,
  disscount,
  sb_sum,
  payment_method,
  SBInsurance,
}: // contract_insurence_cost,
IContractOverview) => {
  const ddClassName = 'mt-1 text-sm text-gray-900 ';
  const dtClassName = 'text-sm font-medium text-gray-500';
  const identity_document_type_translated =
    identity_document_type === 'IDENTITY_CARD'
      ? 'Personalausweis'
      : 'Reisepass';
  const identity_document_type_drive_translated = 'Führerschein';
  return (
    <>
      <div className="col-span-6 inset-0 flex w-full" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="grid grid-cols-6 gap-1 py-6 sm:p-6">
        {/* personal */}
        <div className="col-span-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Persönliche Daten
          </h3>
        </div>
        <div className="col-span-3">
          <dl className="pb-4">
            <div className="pb-0">
              <dt className={dtClassName}>Ausweistyp</dt>

              <dd className={ddClassName}>
                {identity_document_type_translated}
              </dd>
            </div>
          </dl>
        </div>

        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-0">
              <dt className={dtClassName}>Ausweisnummer</dt>

              <dd className={ddClassName}>{identity_document_number}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-4">
            <div className="pb-0">
              <dt className={dtClassName}>Ausweistyp</dt>

              <dd className={ddClassName}>
                {identity_document_type_drive_translated}
              </dd>
            </div>
          </dl>
        </div>

        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-0">
              <dt className={dtClassName}>Führerscheinnummer</dt>

              <dd className={ddClassName}>{identity_document_number_drive}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-4">
            <div className="pb-0">
              <dt className={dtClassName}>Ausstellungsbehörde</dt>

              <dd className={ddClassName}>
                {drivers_liscense_issuing_authority}
              </dd>
            </div>
          </dl>
        </div>

        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-0">
              <dt className={dtClassName}>Führerscheinklassen</dt>

              <dd className={ddClassName}>{drivers_license_classes}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-6">
          <dl className="pb-1">
            <div className="pb-0">
              <dt className={dtClassName}>Anmerkung:</dt>
              <dt className={ddClassName}>
                Die Führerscheinklasse wurde überprüft und ist gültig.
              </dt>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-0">
              <dt className={dtClassName}>Kunde</dt>

              <dd className={ddClassName}>
                {prename} {surname}
              </dd>
              <dd className={ddClassName}>{telephone_number}</dd>
              <dd className={ddClassName}>{email}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-0">
              <dt className={dtClassName}>Adresse</dt>

              <dd className={ddClassName}>
                {street}, {house}
              </dd>
              <dd className={ddClassName}>
                {postal_code} {city}
              </dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-0">
              <dt className={dtClassName}>Geburtsdatum</dt>

              <dd className={ddClassName}>{date_of_birth}</dd>
            </div>
          </dl>
        </div>
      </div>
      {/* Fahrzug */}
      <div className="col-span-6 inset-0 flex w-full" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="grid grid-cols-6 gap-1 py-6  sm:p-6">
        <div className="col-span-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Fahrzeug
          </h3>
        </div>
        <div className="col-span-3">
          <dl className="py-2">
            <div className="pb-0">
              <dt className={dtClassName}>Marke & Typ</dt>

              <dd className={ddClassName}>
                {name} {brand}
              </dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-1">
              <dt className={dtClassName}>Amtliches Kennzeichen</dt>

              <dd className={ddClassName}>{license_plate}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-4">
            <div className="pb-1">
              <dt className={dtClassName}>FIN</dt>

              <dd className={ddClassName}>{vin}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-1">
              <dt className={dtClassName}>Schäden</dt>

              <dd className={ddClassName}>
                {pre_damage ? pre_damage : 'keine Angabe'}
              </dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-1">
              <dt className={dtClassName}>KM-Stand (Übergabe)</dt>

              <dd className={ddClassName}>{odometer_value}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3">
          {/* <dl className="pb-1">
            <div className="pb-1">
              <dt className={dtClassName}>
                KM-Stand (Rückgabe)
              </dt>

              <dd className={ddClassName}>{''}</dd>
            </div>
          </dl> */}
        </div>
        <div className="col-span-3">
          <dl className="pb-1">
            <div className="pb-1">
              <dt className={dtClassName}>Sonstige Notizen</dt>

              <dd className={ddClassName}>{short_description}</dd>
            </div>
          </dl>
        </div>
      </div>
      {/* weiter info */}
      <div className="col-span-6 inset-0 flex w-full" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="grid grid-cols-6 gap-1 py-6  sm:p-6">
        <div className="col-span-6 mt-5">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Weitere Vertragsdaten
          </h3>
        </div>
        <div className="col-span-6">
          <dl className="py-1">
            <div className="py-1 ">
              <dt className={dtClassName}>Vertragsart</dt>

              <dd className={ddClassName}>{kind}</dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3 ">
          <dl className="py-1">
            <div className="py-1 ">
              <dt className={dtClassName}>Übergabe</dt>

              <dd className={ddClassName}>
                {moment(start_of_use).format('DD.MM.YYYY')} |{' '}
                {moment(start_of_use).format('HH:mm')}
              </dd>
            </div>
          </dl>
        </div>
        <div className="col-span-3 ">
          <dl className="py-1">
            <div className="py-1 ">
              <dt className={dtClassName}>Rückgabe</dt>

              <dd className={ddClassName}>
                {end_of_use
                  ? `${moment(end_of_use).format('DD.MM.YYYY')} | ${moment(
                      end_of_use,
                    ).format('HH:mm')}`
                  : 'Keine Angabe'}
              </dd>
            </div>
          </dl>
        </div>
        {!isFree && (
          <div className="col-span-3 ">
            <dl className="py-1">
              {tagesgrundpreis && tagesgrundpreis !== 0 ? (
                <div className="py-1 ">
                  <dt className={dtClassName}>Tagesgrundpreis</dt>

                  <dd className={ddClassName}>{tagesgrundpreis} €</dd>
                </div>
              ) : null}
              {stundenpauschale ? (
                <div className="py-1 ">
                  <dt className={dtClassName}>Stundenpauschale</dt>

                  <dd className={ddClassName}>{stundenpauschale} €</dd>
                </div>
              ) : null}
              {zusatz_km ? (
                <div className="py-1 ">
                  <dt className={dtClassName}>Zusatzkilometer</dt>

                  <dd className={ddClassName}>{zusatz_km} €</dd>
                </div>
              ) : null}
              {fahrzeugkaution && fahrzeugkaution !== 0 ? (
                <div className="py-1 ">
                  <dt className={dtClassName}>Fahrzeugkaution</dt>

                  <dd className={ddClassName}>{fahrzeugkaution} €</dd>
                </div>
              ) : null}
              {additional_costs_attributes &&
              additional_costs_attributes.length > 0 ? (
                <div className="py-1 ">
                  {additional_costs_attributes.map((item: any) => {
                    if (item.name !== '') {
                      return (
                        <>
                          <dt className={dtClassName}>{item.name}</dt>

                          <dd className={ddClassName}>{item.value} €</dd>
                        </>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : null}
            </dl>
          </div>
        )}
        <div className="col-span-3 ">
          <dl className="py-1">
            <div className="py-1 ">
              <dt className={dtClassName}>Versicherung</dt>

              {with_teilkasko ? (
                <dd className={ddClassName}>
                  Teilkasko mit {teilkasko_selbstbeteiligung_amount},- EUR
                  Selbstbeteiligung
                </dd>
              ) : null}
              {with_vollkasko ? (
                <dd className={ddClassName}>
                  Vollkasko mit {vollkasko_selbstbeteiligung_amount},- EUR
                  Selbstbeteiligung
                </dd>
              ) : null}
              {!with_teilkasko && !with_vollkasko && (
                <dd className={ddClassName}>keine Angabe</dd>
              )}
            </div>
          </dl>
        </div>
        {additional_notes ? (
          <div className="col-span-6">
            <dl className="py-1">
              <div className="py-1 ">
                <dt className={dtClassName}>Sonstige Notizen</dt>

                <dd className={ddClassName}>{additional_notes}</dd>
              </div>
            </dl>
          </div>
        ) : null}
        {/* <div className="col-span-6 py-1">
          <dl className="py-1">
            <dt className={dtClassName}>Vereinbarung</dt>
            <dd className={ddClassName}>
              Der Benutzer ist damit einverstanden, dass seine Daten aus diesem
              Vertrag an den Fahrzeugimporteur bzw. Hersteller weiter gegeben
              werden dürfen und dort zur Kundenbetreuung genutzt werden. Die
              Vertragsbedingungen werden vom Benutzer anerkannt. Der Benutzer
              behandelt das Fahrzeug pfleglich und wird nicht in dem Fahrzeug
              rauchen.
            </dd>
          </dl>
        </div> */}
      </div>

      {hasSBInsurance && SBInsurance === 'selected' && (
        <>
          <div className="col-span-6 inset-0 flex w-full" aria-hidden="true">
            <div className="w-full border-t border-gray-300" />
          </div>
          {/* SB overview */}
          <SBContractOverview
            isPremiumCustomer
            start_of_use={start_of_use}
            end_of_use={end_of_use}
            license_plate={license_plate}
            kind={kind}
            vin={vin}
            disscount={disscount}
            sb_sum={sb_sum}
            payment_method={payment_method}
            dtClassName={dtClassName}
            ddClassName={ddClassName}
          />
        </>
      )}
    </>
  );
};
export default ContractOverview;
