import dayjs from 'dayjs';

/*
  Over time there may be more useful event item components that can be included
  in the library
*/

// const variant = [
//   'bg-orange-50',
//   'bg-indigo-50',
//   'bg-green-100',
//   'bg-indigo-100',
//   'bg-orange-200',
//   'bg-indigo-200',
//   'bg-green-200',
//   'bg-orange-300',
// ];
type DefaultEventItemProps = {
  title: string;
  date: string;
  start_time: any;
  ent_time: any;
  id?: string;
  onEventClick: () => void;
  variantColorId: number;
  user: string;
};

export const DefaultMonthlyEventItem = ({
  title,
  // date,
  start_time,
  ent_time,
  user,
  // id,
  onEventClick,
}: // variantColorId,
DefaultEventItemProps) => {
  // const selectedVariant = variant[variantColorId % variant.length];
  return (
    <li className="cursor-pointer py-1" onClick={onEventClick}>
      {/* justify-between */}
      <div
        className={`flex text-sm flex-1 flex-col group  overflow-y-auto rounded-lg bg-orange-50 px-2 py-3  leading-5 hover:bg-orange-300 hover:text-gray-700`}>
        <h2 className="font-bold">{title}</h2>
        {/* <p className="text-gray-500">{date}</p> */}
        <p className="text-gray-700 block text-xs py-1">
          <span className="font-bold text-red-800">von {''}</span>{' '}
          {dayjs(start_time).format('dd, DD.MM.YYYY HH:mm')} Uhr
        </p>
        <p className="text-gray-700 block text-xs pb-1">
          <span className="font-bold  text-red-800">bis {''}</span>{' '}
          {dayjs(ent_time).format('dd, DD.MM.YYYY HH:mm')} Uhr
        </p>
        <p className="text-gray-700 block text-xs">
          <span className="font-bold  text-red-800">durch {''}</span> {user}
        </p>
      </div>
    </li>
  );
};
