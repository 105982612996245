import { useState, useEffect, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import {
  Button,
  Slideover,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableData,
} from '../../../styles/components';
import {
  Query,
  ServerResponseType,
  IDtableDataResponseItem,
  SBContract,
} from 'shared';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import useStore from '../../../ui-state/ui';
import ContractDetails from './contract_details';
// import { useAuthContext } from '../../../hooks';
import { classNames } from '../../../styles/utils';
import { dataFormat } from '../../../utils';
import {
  FetchHandlerWrapper,
  Tabs,
  currentTabProp,
  NotificationMessage,
  Modal,
  Form,
} from '../../shared';
import ReturnVehicles from './return_vehicles';
import DownLoadContract from './download_contract';
import {
  columns,
  secureColumns,
  statusStyles,
  tabs,
  contractState,
} from './utils';
import PopOverFilterMenu from './popover_filter_menu';
import { useAuthContext } from '../../../hooks';

// import { FormikProps } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
export const downloadContractValidationSchema = yup.object({
  start_date: yup.date().required('Das Rückgabedatum muss ausgefüllt werden.'),

  end_date: yup
    .date()
    .typeError('Das Rückgabedatum muss ausgefüllt werden.')
    .required('Das Rückgabedatum muss ausgefüllt werden.'),
});
// import MultiSelectTableFilter from './table_multi_select_filter';

// const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';

type MainProp = {
  contractType?:
    | 'contractPremium'
    | 'lightWithSBInsurance'
    | 'premumWithSBInsurance';
  useDigitalSigning: boolean;
};
type ExtraAction = {
  title: string;
  url: string;
  actionType: string;
};
type ActionType = {
  to?: string;
  disable: boolean;
  type: string;
  mainBtnLabel: string;
  processWithoutContract: boolean;
  extraAction?: ExtraAction[];
};
const Main: React.FunctionComponent<MainProp> = ({
  contractType,
  useDigitalSigning,
}) => {
  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  const { plan_type, plan_version } = user;
  const isSecure = plan_type === 'premium';
  const isLightProduct = plan_version === 'light';

  //@ts-ignore
  const tableColumns = !isSecure ? columns : secureColumns;
  const [isClosingWithNoContract, setCloseModalWithoutContract] =
    useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const [tokenModal, setTokenModalOpen] = useState(false);

  const [isDownloadContractSlideoverOpen, toggleDowloadContractSlideover] =
    useState(false);
  const [isReturnVehicles, setReturnVehicles] = useState(false);
  const [showNotification, setShow] = useState(false);
  const [showStatusFilter, setStatusFilter] = useState(false);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState([]);
  const shouldFetchIdCheckTableDataOnNewLegitimationUpdate = useStore(
    (state) => state.shouldFetchIdCheckTableDataOnNewLegitimationUpdate,
  );
  // const [showLegitimationFilter, setLegitimationFilter] = useState(false);
  const [selectedLegitimationFilter, setSelectedLegitimationFilter] = useState(
    [],
  );
  const [fetchData, setFetchNewData] = useState(false);
  const history = useHistory();
  const { url } = useRouteMatch();
  const {
    FetchIDtableDate,
    disCardLegitimation,
    FetchLegtimationCount,
    FetchDictionarysData,
  } = Query();
  const currentIDCheckTableTab = useStore(
    (state) => state.currentIDCheckTableTab,
  );
  const setCurrentIdCheckTableTabs = useStore(
    (state) => state.setCurrentIdCheckTableTabs,
  );
  const idCheckSingleValue = useStore((state) => state.idCheckSingleValue);
  const sorteValue = useStore((state) => state.sorteValue);
  // console.log('🚀 ~ sorteValue:', sorteValue);
  const setIdCheckValue = useStore((state) => state.setIdCheckValue);
  const isClosingLegitimationWithoutContractDone = useStore(
    (state) => state.isClosingLegitimationWithoutContractDone,
  );
  const setClosingWithoutContract = useStore(
    (state) => state.setClosingWithoutContract,
  );
  const idCheckTablePageNumber = useStore(
    (state) => state.idCheckTablePageNumber,
  );
  const setCheckIDTablePageNumber = useStore(
    (state) => state.setCheckIDTablePageNumber,
  );
  const setUserToStorage = useStore((state) => state.setUserToStorage);
  const setSBContractData = useStore((state) => state.setSBContractData);
  const setContractId = useStore((state) => state.setContractId);
  const { data: notificationCountData, error: legitCountError } =
    FetchLegtimationCount({
      key: 'count',
    });
  // TODO: create a csv shared function to be used in the whole app where csv is needed for example in the vehicles table.

  const {
    isLoading,
    isError,
    error,
    data: idCheckData,
    isFetching,
    refetch,
  } = FetchIDtableDate({
    key: 'ID_Check_table',
    page: idCheckTablePageNumber,
    searchQuery: undefined,
    sortBy: sorteValue ? sorteValue.value : 'asc',
    context: currentIDCheckTableTab.status,
    status: selectedStatusFilter,
    // enabled: false
    // approval_state: selectedLegitimationFilter
    //   ? selectedLegitimationFilter
    //   : undefined,
  });
  const { data: dictionaryData, error: dictDataError } = FetchDictionarysData({
    key: 'dictionary-data',
  });
  // console.log('idCheckData', idCheckData);
  useEffect(() => {
    //@ts-ignore
    // console.log('errorrrr from dash', error.response.status === 401);
    //@ts-ignore
    if (error && error.response && error.response.status === 401) {
      setTokenModalOpen(true);
    }
  }, [error, legitCountError, dictDataError, auth, setUserToStorage]);

  const isFilterSelectedAndNoResults =
    [...selectedStatusFilter, ...selectedLegitimationFilter].length > 0 &&
    idCheckData &&
    idCheckData.records.length === 0;

  useEffect(() => {
    if (idCheckData) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIDCheckTableTab, sorteValue]);

  useEffect(() => {
    if (fetchData && idCheckData && idCheckData.records.length === 0) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData, isFilterSelectedAndNoResults]);

  useEffect(() => {
    if (isClosingLegitimationWithoutContractDone) {
      setShow(true);
      setClosingWithoutContract(false);
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClosingLegitimationWithoutContractDone]);

  useEffect(() => {
    if (showNotification) {
      //refetch table date after car returned
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNotification]);
  useEffect(() => {
    if (shouldFetchIdCheckTableDataOnNewLegitimationUpdate) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchIdCheckTableDataOnNewLegitimationUpdate]);
  const handleTabSelect = (selectedTabItem: currentTabProp) => {
    setCurrentIdCheckTableTabs(selectedTabItem);
  };
  const handleRowSelect = (rowValue: IDtableDataResponseItem) => {
    setIdCheckValue(rowValue);
    //  setSelectedRow(e);
  };
  const handlelogout = () => auth.signout();
  const handleDetailsClick = ({
    action,
    customerId,
    contractId,
    isLightProduct,
    hasSBInsurance,
    sbContractData,
  }: {
    action?: ActionType;
    customerId?: string;
    contractId?: number;
    isLightProduct?: boolean;
    hasSBInsurance?: boolean;
    sbContractData?: SBContract;
  }) => {
    const { to, disable } = action!;

    if (!disable && to === 'contract_details') {
      if (!isLightProduct) {
        //@ts-ignore
        setContractId(contractId);
        history.push(`${url}/contract_details/${contractId}`);
        setReturnVehicles(false);
      }
      // setOpen(!open);
    } else {
      // console.log('hadSb', `${url}/sb_contract_details/${contractId}`);
      if (hasSBInsurance && isLightProduct && sbContractData) {
        // set the contract id to the store for the header page
        //@ts-ignore
        setContractId(contractId);
        // @ts-ignore
        setSBContractData(sbContractData);
        history.push(`${url}/sb_contract_details/${contractId}`);
      } else {
        history.push(`${url}/${customerId}`);
      }
    }
  };
  const closeWithoutContract = async (id: number) => {
    setReturnVehicles(false);
    await disCardLegitimation({ id: id });
    setShow(true);

    refetch();
  };
  const handleTableAction = (
    action: ExtraAction,
    id: number,
    status: string,
  ) => {
    if (action.actionType === 'API') {
      // when type === 'API' means the user click on ohne Vertrag abschließen
      setCloseModalWithoutContract(true);
      setModalOpen(true);
    }
    if (action.actionType === 'Redirect') {
      if (status === 'REJECTED') {
        setCloseModalWithoutContract(false);
        setModalOpen(true);
      } else {
        history.push(action.url);
      }
    }
    if (action.actionType === 'EndTask') {
      setReturnVehicles(true);
      setOpen(!open);
    }
    return null;
  };
  const onAccept = (id: number) => {
    if (isClosingWithNoContract) {
      //@ts-ignore
      closeWithoutContract(id);
      history.push(`${url}`);
      setModalOpen(false);
    } else {
      history.push(`${url}/new_contract/${id}`);
      setModalOpen(false);
    }
  };
  const statusOptions = dictionaryData
    ? dictionaryData.id_check_filter_options_state.map(
        (data: { key: string; i18n: string }, index: number) => ({
          value: data.key,
          label: data.i18n,
          hasBtn:
            index === dictionaryData.id_check_filter_options_state.length - 1
              ? true
              : false,
          onClick: async () => {
            await refetch();
            setStatusFilter(!showStatusFilter);
          },
        }),
      )
    : [];

  // const legitimationFilterOptions = dictionaryData
  //   ? dictionaryData.id_check_filter_options_approval_state.map(
  //       (data: { key: string; i18n: string }, index: number) => ({
  //         value: data.key,
  //         label: data.i18n,
  //         hasBtn:
  //           index ===
  //           dictionaryData.id_check_filter_options_approval_state.length - 1
  //             ? true
  //             : false,
  //         onClick: async () => {
  //           await refetch();
  //           setLegitimationFilter(!showLegitimationFilter);
  //         },
  //       }),
  //     )
  //   : [];

  const handleSelectedStatusFilter = (filter: any) => {
    const filterValue = filter.map(
      (data: { value: string; label: string }) => ({ value: data.value }),
    );
    setSelectedStatusFilter(filterValue);
  };
  // const handleSelectedLegitimationFilter = (filter: any) => {
  //   const filterValue = filter.map(
  //     (data: { value: string; label: string }) => ({ value: data.value }),
  //   );
  //   setSelectedLegitimationFilter(filterValue);
  // };
  // ];
  const renderNameBasedOnLegitimationStatus = (
    data: IDtableDataResponseItem,
  ) => {
    if (data.customer_short) {
      return `${data.customer_short.prename} ${data.customer_short.surname}`;
    }
    if (
      data.prename_from_id_document &&
      data.prename_from_id_document !== 'Not Available'
    ) {
      return `${data.prename_from_id_document} ${data.surname_from_id_document}`;
    }
    return 'N/a';
  };
  return (
    <div className="pt-0">
      <div className="pb-3">
        {/* <h2 className="text-2xl font-bold  text-gray-900 sm:text-3xl sm:truncate">
          Alle Fahrzeuge.
        </h2> */}
        {!isLightProduct ? (
          <p className="mt-0 font-semibold text-gray-900 text-sm break-words pr-2">
            Export der Fahrzeugüberlassungsverträge als CSV-Datei. Klicken Sie
            <div
              onClick={() => toggleDowloadContractSlideover(true)}
              className="text-orange-400 m-1 inline cursor-pointer">
              hier.
            </div>
          </p>
        ) : null}
      </div>

      <Tabs
        tabs={isLightProduct ? [tabs[3]] : tabs}
        onSelect={handleTabSelect}
        current={currentIDCheckTableTab ? currentIDCheckTableTab.id : null}
        counter={notificationCountData ? notificationCountData.active : ''}
      />

      <div className="mt-5 flex flex-col overflow-x-auto overflow-y-hidden md:overflow-hidden">
        <FetchHandlerWrapper
          emptyDataMessage={{
            title: isFilterSelectedAndNoResults
              ? 'Es gibt leider  kein Ergebnis für Ihren ausgewählten Filter'
              : 'Alles erledigt für heute!',
            subtitle: isFilterSelectedAndNoResults
              ? 'Bitte setzten Sie die Sucheinstellungen zurück'
              : 'Genießen Sie Ihren Tag.',
          }}
          onResetBtnCLick={
            isFilterSelectedAndNoResults
              ? () => {
                  setSelectedLegitimationFilter([]);
                  setSelectedStatusFilter([]);
                  setFetchNewData(true);
                }
              : undefined
          }
          isError={isError}
          isLoading={isLoading}
          data={idCheckData ? idCheckData : undefined}>
          {(data: ServerResponseType) => (
            <TableContainer>
              <Table
                isFetching={isFetching}
                hasPagination
                setPage={setCheckIDTablePageNumber}
                currentpage={idCheckTablePageNumber}
                {...data.pagination}>
                <TableHead>
                  {tableColumns.map((item: string, index: number) => {
                    if (index === 4) {
                      if (
                        isLightProduct &&
                        contractType !== 'lightWithSBInsurance'
                      )
                        return null;

                      // hide the SB insurance column when the plan type is premium light product
                      if (isSecure && contractType === 'lightWithSBInsurance')
                        return null;
                      if (
                        isLightProduct &&
                        contractType === 'lightWithSBInsurance' &&
                        plan_type !== 'premium'
                      )
                        return (
                          <TableCell key={item}>SB Versicherung</TableCell>
                        );
                      return (
                        <TableCell key={item}>
                          <PopOverFilterMenu
                            selectedName="status"
                            popupTriggerBtnLabel="STATUS"
                            show={showStatusFilter}
                            selectValue={selectedStatusFilter}
                            setShow={setStatusFilter}
                            setSelectFilter={handleSelectedStatusFilter}
                            filterOptions={statusOptions}
                          />
                        </TableCell>
                      );
                    } else if (index === 2) {
                      if (isLightProduct) return null;
                      return <TableCell key={item}>{item}</TableCell>;
                    } else {
                      return <TableCell key={item}>{item}</TableCell>;
                    }
                  })}
                </TableHead>
                <TableBody>
                  {data &&
                    //@ts-ignore
                    data.records.map((data) => {
                      // const { created_at } = data.contract_short;
                      // console.log('🚀 ~ data=========:', data);
                      const id = data.id;
                      const hasSBInsurance = !!data.sb_insurance_contract;
                      const sbInsuranceContractId = data.sb_insurance_contract
                        ? data.sb_insurance_contract.id
                        : null;
                      // console.log(
                      //   '🚀 ~ data.records.map ~ sbInsuranceContractId:',
                      //   data,
                      // );
                      const isActive = data.contract_short
                        ? data.contract_short.active
                        : undefined;
                      const { address } = data.dealer;
                      const { label, color, progress, action } = contractState(
                        data.approval_state,
                        Boolean(data.contract_short),
                        data.discarded_at,
                        id,
                        isActive,
                        url,
                      );

                      // console.log('data', action);
                      const hasAction = action && action.extraAction.length > 0;
                      const contractId = data.contract_short
                        ? data.contract_short.id
                        : null;
                      // console.log(
                      //   'hasAction()hasAction()',
                      //   hasAction,
                      //   prename,
                      //   surname,
                      //   action!.extraAction,
                      // );
                      // const { brand, kind, name } = data.vehicle_short;
                      return (
                        <TableRow
                          key={data.id}
                          action={() => handleRowSelect(data)}>
                          <TableData className={`text-gray-500 font-sm`}>
                            {dataFormat({ date: data.created_at })}
                          </TableData>
                          <TableData className={`text-gray-500`}>
                            <div className="ml-0">
                              {/* {id && (
                                <div className="text-sm font-bold text-black">{`ID${id}`}</div>
                              )} */}
                              <div className="text-sm font-medium text-gray-500">
                                {renderNameBasedOnLegitimationStatus(data)}
                              </div>
                            </div>
                            {/* <Link to={`${url}/${data.customer_short.id}`}>
                              
                            </Link> */}
                          </TableData>
                          {!isLightProduct && (
                            <TableData className={`text-gray-500`}>
                              {data.vehicle_short && (
                                <div className="ml-0">
                                  <div className="text-sm font-bold text-gray-900">
                                    {data.vehicle_short.brand}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {data.vehicle_short.name}
                                  </div>
                                </div>
                              )}
                            </TableData>
                          )}
                          <TableData className={`text-gray-500`}>
                            {address.city}
                          </TableData>
                          {!isLightProduct && (
                            <TableData className={`text-gray-500 w-3/12`}>
                              <span className="text-sm">{label}</span>
                              <div className="relative pt-1">
                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-100">
                                  <div
                                    style={{
                                      width: progress,
                                    }}
                                    className={classNames(
                                      //@ts-ignore
                                      color,
                                      'shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
                                    )}></div>
                                </div>
                              </div>
                            </TableData>
                          )}
                          {contractType === 'lightWithSBInsurance' &&
                            plan_type !== 'premium' && (
                              <TableData className={`text-gray-500 w-3/12`}>
                                <span className="text-sm">
                                  {data.sb_insurance_contract ? 'JA' : 'N/A'}
                                </span>
                                {/* <div className="relative pt-1">
                                <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-gray-100">
                                  <div
                                    style={{
                                      width: progress,
                                    }}
                                    className={classNames(
                                      //@ts-ignore
                                      color,
                                      'shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center',
                                    )}></div>
                                </div>
                              </div> */}
                              </TableData>
                            )}
                          <TableData className={`text-gray-500 w-2/12`}>
                            <span
                              className={classNames(
                                //@ts-ignore
                                statusStyles[data.approval_state].status,
                                'px-2 w-full justify-center inline-flex text-xs leading-5 font-semibold rounded-full ',
                              )}>
                              {/* @ts-ignore */}
                              {statusStyles[data.approval_state].label}
                            </span>
                          </TableData>

                          {isSecure && (
                            <TableData className={`text-gray-500 w-2/12`}>
                              {data.drivers_liscense &&
                              data.drivers_liscense.approval_state ? (
                                <span
                                  className={classNames(
                                    //@ts-ignore
                                    statusStyles[
                                      data.drivers_liscense.approval_state
                                    ].status,
                                    'px-2 w-full justify-center inline-flex text-xs leading-5 font-semibold rounded-full',
                                  )}>
                                  {
                                    //@ts-ignore
                                    statusStyles[
                                      data.drivers_liscense &&
                                      data.drivers_liscense.approval_state
                                        ? data.drivers_liscense.approval_state
                                        : data.approval_state
                                    ].label
                                  }
                                </span>
                              ) : (
                                <span className="bg-gray-700 text-white px-2 w-full justify-center inline-flex text-xs leading-5 font-semibold rounded-full">
                                  KEINE ANGABE
                                </span>
                              )}
                            </TableData>
                          )}
                          <TableData className={`text-right font-medium`}>
                            <div className="flex flex-row-reverse  justify-end">
                              {!isLightProduct && (
                                <div>
                                  <Menu
                                    as="div"
                                    className="relative flex justify-end items-center">
                                    {({ open }) => (
                                      <>
                                        <Menu.Button
                                          disabled={!hasAction}
                                          className={classNames(
                                            hasAction
                                              ? 'text-gray-400 hover:text-gray-500 cursor-pointer'
                                              : 'text-gray-200',
                                            'w-8 h-8 bg-white inline-flex items-center justify-center  rounded-full  focus:outline-none cursor-default',
                                          )}>
                                          <span className="sr-only">
                                            Open options
                                          </span>
                                          <DotsVerticalIcon
                                            className="w-5 h-5"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                        <Transition
                                          show={open}
                                          //@ts-ignore
                                          as={Fragment}
                                          enter="transition ease-out duration-100"
                                          enterFrom="transform opacity-0 scale-95"
                                          enterTo="transform opacity-100 scale-100"
                                          leave="transition ease-in duration-75"
                                          leaveFrom="transform opacity-100 scale-100"
                                          leaveTo="transform opacity-0 scale-95">
                                          <Menu.Items
                                            static
                                            className="mx-3 origin-top-right absolute right-3 top-1 w-48 mt-1 rounded-md shadow-lg z-10 bg-white ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                                            <div className="py-1">
                                              {hasAction &&
                                                action!.extraAction.map(
                                                  (btnAction: ExtraAction) => {
                                                    return (
                                                      //@ts-ignore
                                                      <Menu.Item
                                                        key={btnAction.title}>
                                                        {({ active }) => (
                                                          <Link
                                                            to={'#'}
                                                            // to={
                                                            //   btnAction.actionType ===
                                                            //   'Redirect'
                                                            //     ? btnAction.url
                                                            //     : '#'
                                                            // }
                                                            onClick={() =>
                                                              handleTableAction(
                                                                btnAction,
                                                                id,
                                                                data.approval_state,
                                                              )
                                                            }
                                                            className={classNames(
                                                              active
                                                                ? 'bg-gray-100 text-gray-900'
                                                                : 'text-gray-700',
                                                              'group flex items-center px-4 py-2 text-sm',
                                                            )}>
                                                            {/* <PencilAltIcon
                                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                        aria-hidden="true"
                                                      /> */}
                                                            {btnAction.title}
                                                          </Link>
                                                        )}
                                                      </Menu.Item>
                                                    );
                                                  },
                                                )}
                                            </div>
                                          </Menu.Items>
                                        </Transition>
                                      </>
                                    )}
                                  </Menu>
                                </div>
                              )}

                              <Button
                                onClick={() =>
                                  handleDetailsClick({
                                    action,
                                    customerId: String(id),
                                    contractId:
                                      isLightProduct && hasSBInsurance
                                        ? sbInsuranceContractId
                                        : contractId,
                                    isLightProduct,
                                    // add hasSBInsurance to the function to redirect to the contract detail when there is SB and light product
                                    hasSBInsurance,
                                    sbContractData: data.sb_insurance_contract,
                                  })
                                }
                                disabled={action?.disable}
                                label={action?.mainBtnLabel}
                                //@ts-ignore
                                type={action?.type}
                                size="small"
                                className="md:w-9/12 flex justify-center"
                              />
                            </div>
                          </TableData>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </FetchHandlerWrapper>
      </div>
      <Slideover
        open={open}
        toggle={setOpen}
        title={
          isReturnVehicles ? 'Fahrzeugrückgabe' : 'Fahrzeugüberlassungsvertrag'
        }
        subTitle={
          isReturnVehicles
            ? 'Tragen Sie hier die Daten für die Rückgabe ein.'
            : undefined
        }
        style={{
          subtitleStyle: isReturnVehicles ? 'text-white' : undefined,
          titleStyle: isReturnVehicles ? 'text-white' : undefined,
          closeIconColor: isReturnVehicles ? 'text-white' : 'text-gray-900',
          headerStyle: isReturnVehicles ? 'bg-orange-500' : undefined,
        }}>
        {isReturnVehicles ? (
          <ReturnVehicles
            setOpen={setOpen}
            setShowNotification={setShow}
            useDigitalSigning={useDigitalSigning}
          />
        ) : (
          <ContractDetails />
        )}
      </Slideover>
      <Slideover
        open={isDownloadContractSlideoverOpen}
        toggle={toggleDowloadContractSlideover}
        title={'Verträge exportieren'}
        subTitle={
          'Bitte geben Sie den gewünschten Zeitraum für den Export Ihrer Daten an.'
        }
        style={{
          subtitleStyle: 'text-white',
          titleStyle: 'text-white',
          closeIconColor: 'text-white',
          headerStyle: 'bg-orange-500',
        }}>
        <Form
          submitForm={() => null}
          validationSchema={downloadContractValidationSchema}
          initialValues={{
            end_date: moment(),
            start_date: moment(),
          }}>
          <DownLoadContract setOpen={toggleDowloadContractSlideover} />
        </Form>
      </Slideover>
      <NotificationMessage
        title={
          isReturnVehicles
            ? 'Fahrzeugrückgabe erfolgreich'
            : 'Dieser Vorgang wurde bearbeitet'
        }
        subTitle={
          isReturnVehicles
            ? 'Sie finden den Vertrag nun unter abgeschlossene Vorgänge'
            : ''
        }
        notify={showNotification}
        setShow={setShow}
      />
      <Modal
        setOpen={setModalOpen}
        open={isModalOpen}
        title={
          isClosingWithNoContract
            ? 'Ohne Vertrag abschließen?'
            : 'Die Legitimation ist abgelehnt worden. Sind Sie sich sicher, dass Sie den Vertrag trotz abgelehnter Legitimation erstellen wollen?'
        }
        // subtile="lorem ipsum brety breaken testing subtitle to go for anothre place to go"
      >
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => onAccept(idCheckSingleValue!.id)}
            label="Ja, sicher"
            type="primary"
            className="w-full justify-center "
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setModalOpen(false);
              setCloseModalWithoutContract(false);
            }}
            label="Abbrechen"
            type="default"
            className="w-full justify-center "
          />
        </div>
      </Modal>
      <Modal
        setOpen={setTokenModalOpen}
        open={tokenModal}
        title="Ihre Sicherheit ist uns wichtig!"
        subtile="Ihre Zwei-Faktor-Authentifizierung (2FA) ist abgelaufen oder Ihre Sitzung wurde beendet. Bitte melden Sie sich erneut an, um fortzufahren.">
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              handlelogout();
              // window.location.reload();
              // history.push(`${url}/login`);
            }}
            label="Jetzt anmelden"
            type="primary"
            className="w-full justify-center "
          />
        </div>
      </Modal>
    </div>
  );
};

export default Main;

// {showStatusFilter ? (
//   <MultiSelectTableFilter
//     placeHolder="Status"
//     value={selectedStatusFilter}
//     handleChange={handleSelectedStatusFilter}
//     options={statusOptions}
//   />
// ) : (

//   <div className="w-full flex justify-between ">
//     <div>
//       <label
//         htmlFor="filter-table"
//         className="relative cursor-pointer rounded-md text-left font-semibold  text-gray-600 hover:text-gray-900 focus-within:outline-none">
//         <span>{item}</span>
//         {/* @ts-ignore */}
//         {selectedStatusFilter.length > 0 ? (
//           <span
//             className={
//               'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block bg-orange-200 text-orange-600'
//             }>
//             {/* @ts-ignore */}
//             {selectedStatusFilter.length}
//           </span>
//         ) : null}
//       </label>
//     </div>
//     <div>
//       <button
//         onClick={() => {
//           console.log('click');
//           setStatusFilter(!showStatusFilter);
//         }}
//         className="flex items-center pr-2">
//         <SelectorIcon
//           className="h-5 w-5 text-orange-500"
//           aria-hidden="true"
//         />
//       </button>
//     </div>
//   </div>
// )}
