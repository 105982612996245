import create from 'zustand';
import { IDtableDataResponseItem, SBContract } from 'shared';
import { currentTabProp } from '../components/shared';

type sorteValue = {
  id: number;
  name: string;
  value: string;
};
type State = {
  sorteValue?: sorteValue;
  idCheckSingleValue?: IDtableDataResponseItem;
  saveUserToStorage?: boolean;
  isClosingLegitimationWithoutContractDone?: boolean;
  shouldFetchIdCheckTableDataOnNewLegitimationUpdate?: boolean;
  idCheckTablePageNumber: number;
  currentIDCheckTableTab: currentTabProp;
  selectedContractId?: number;
  iD_drivelicense_frontSide?: any;
  passOrIdSrc: any;
  sbContractData?: SBContract;
  setPassOrIdSrc: (value: any) => void;
  setContractId: (value: number) => void;
  setFrontSideIDcarAndDriveLicense: (value: any) => void;
  setUserToStorage: (value: boolean) => void;
  setCheckIDTablePageNumber: (page: number) => void;
  onSelectSortValue: (value: sorteValue) => void;
  setIdCheckValue: (value: IDtableDataResponseItem) => void;
  setFetchIdCheckTableDataOnNewLegitimationUpdate: (
    notification: boolean,
  ) => void;
  setClosingWithoutContract: (isClosing: boolean) => void;
  setCurrentIdCheckTableTabs: (current: currentTabProp) => void;
  setSBContractData: (value: SBContract) => void;
};
const useStore = create<State>((set) => ({
  iD_drivelicense_frontSide: undefined,
  saveUserToStorage: false,
  sorteValue: undefined,
  idCheckSingleValue: undefined,
  isClosingLegitimationWithoutContractDone: false,
  idCheckTablePageNumber: 1,
  shouldFetchIdCheckTableDataOnNewLegitimationUpdate: false,
  currentIDCheckTableTab: {
    status: 'all',
    id: 0,
  },
  selectedContractId: undefined,
  passOrIdSrc: null,
  sbContractData: undefined,
  setPassOrIdSrc: (value) => set(() => ({ passOrIdSrc: value })),
  setFrontSideIDcarAndDriveLicense: (value) =>
    set(() => ({ iD_drivelicense_frontSide: value })),
  setContractId: (value) => set(() => ({ selectedContractId: value })),
  setUserToStorage: (value) => set(() => ({ saveUserToStorage: value })),
  setCurrentIdCheckTableTabs: (value) =>
    set(() => ({ currentIDCheckTableTab: value })),
  setCheckIDTablePageNumber: (value) =>
    set(() => ({ idCheckTablePageNumber: value })),
  onSelectSortValue: (value) => set((state) => ({ sorteValue: value })),
  setIdCheckValue: (value) => set(() => ({ idCheckSingleValue: value })),
  setFetchIdCheckTableDataOnNewLegitimationUpdate: (value) =>
    set(() => ({ shouldFetchIdCheckTableDataOnNewLegitimationUpdate: value })),
  setClosingWithoutContract: (value) =>
    set(() => ({ isClosingLegitimationWithoutContractDone: value })),
  setSBContractData: (value) => set(() => ({ sbContractData: value })),
}));

export default useStore;
