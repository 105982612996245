/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Query, IDictionary } from 'shared';
import Downshift from 'downshift';
import { Form } from '../../../../../shared';
import FieldWithFeedBack from './field_input_with_feedback';
import { personalDataFormArray } from '../../form_data';
import { classNames } from '../../../../../../styles/utils';

type PersonalProp = {
  dictionaryData: IDictionary;
  labelClass: string;
  fieldClassName: string;
  documentType: 'IDENTITY_CARD' | 'PASSPORT';
};

const PersonalInfo = ({
  labelClass,
  fieldClassName,
  dictionaryData,
  documentType,
}: PersonalProp) => {
  const [searchResult, setResult] = useState([]);
  const {
    //@ts-ignore
    values: { email },
    setFieldValue,
    errors,
    dirty,
    touched,
  } = useFormikContext();

  const { getCustomers } = Query();

  useEffect(() => {
    async function fetchCustomerData(query: string) {
      const data = await getCustomers(1, query, 'email', 50);
      //@ts-ignore
      return data.records;
    }
    let isCurrent = true;
    // your business logic around when to fetch goes here.

    if (email !== '') {
      fetchCustomerData(email).then((user) => {
        if (!!isCurrent && user) {
          setResult(user);
        }
      });
    }
    return () => {
      isCurrent = false;
      // setFieldValue('email', '');
    };
  }, [email]);

  const docunebtTypeDataTranslationData = () => {
    return dictionaryData
      ? [dictionaryData?.identity_document_types].map((type) => [
          {
            value: type.identity_card.key,
            label: type.identity_card.i18n,
          },
          {
            value: type.passport.key,
            label: type.passport.i18n,
          },
          //@ts-ignore
        ])[0]
      : [];
  };

  const renderFiled = (fieldItem: any) => {
    switch (fieldItem.type) {
      case 'auto_complente_text':
        return (
          <div className="overflow-auto">
            <Downshift
              onChange={(user: any) => {
                // setFieldValue('salutation', user && user.salutation);
                // setFieldValue('prename', user && user.prename);
                // setFieldValue('surname', user && user.surname);
                setFieldValue('email', user && user.email);
                // setFieldValue(
                //   'telephone_number',
                //   user && user.telephone_number,
                // );
              }}
              itemToString={(item: any) => (item ? item.email : '')}>
              {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                getRootProps,
              }) => {
                // console.log('isOpen===', isOpen);
                return (
                  <div className="overflow-auto">
                    <div
                      className="w-full"
                      style={{ display: 'inline-block' }}
                      //@ts-ignore
                    >
                      <Field type={'text'} name={fieldItem.name}>
                        {({ field }: any) => (
                          <input
                            {...getInputProps({
                              ...field,
                            })}
                            placeholder="E-Mail"
                            className={fieldClassName}
                            // {...field}
                          />
                        )}
                      </Field>
                    </div>
                    <ul
                      className={classNames(
                        isOpen && searchResult.length > 0 ? 'h-auto' : '',
                        'divide-y divide-gray-200 shadow overflow-auto',
                      )}>
                      {isOpen && searchResult.length > 0
                        ? searchResult

                            // .filter(
                            //   (item) =>
                            //     !inputValue ||
                            //     //@ts-ignore
                            //     item.email.includes(inputValue),
                            // )
                            .map((item, index) => (
                              <li
                                // className="p-2 "
                                {...getItemProps({
                                  //@ts-ignore
                                  key: item.id,
                                  index,
                                  item,
                                  style: {
                                    backgroundColor:
                                      highlightedIndex === index
                                        ? '#6b6b6b1f'
                                        : 'white',
                                    fontWeight:
                                      //@ts-ignore
                                      selectedItem === item ? 'bold' : 'normal',
                                  },
                                })}>
                                <p className="px-4 py-4 bg-white hover:bg-gray-50">
                                  {' '}
                                  {/* @ts-ignore */}
                                  {item.email}
                                </p>
                              </li>
                            ))
                        : null}
                    </ul>
                  </div>
                );
              }}
            </Downshift>
          </div>
        );
      case 'list':
        return (
          <div className="w-full block pr-3">
            <Field
              autoComplete="off"
              name={fieldItem.name}
              component={Form.SelectField}
              options={
                dictionaryData
                  ? dictionaryData.salutations.map((salutation) => ({
                      value: salutation.name,
                      label: salutation.name,
                    }))
                  : []
              }
            />
          </div>
        );
      case 'checkbox':
        return (
          <div className="w-full block pr-3">
            <div className="flex items-start lg:mt-3">
              <div className="h-5 flex items-center">
                <Field
                  autoComplete="off"
                  type="checkbox"
                  id={fieldItem.name}
                  name={fieldItem.name}
                  className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-2 text-sm">
                <label className="text-gray-900" htmlFor={fieldItem.name}>
                  Die Führerscheinklasse wurde überprüft und ist gültig.
                </label>
              </div>
            </div>

            {/* <Field
              autoComplete="off"
              name={fieldItem.name}
              component={Form.SelectField}
              options={
                dictionaryData
                  ? dictionaryData.salutations.map((salutation) => ({
                      value: salutation.name,
                      label: salutation.name,
                    }))
                  : []
              } 
            /> */}
          </div>
        );
      case 'drive_liscense_translated':
        return (
          <div className="w-full block pr-3">
            <Field
              autoComplete="off"
              name={fieldItem.name}
              component={Form.SelectField}
              options={
                dictionaryData
                  ? [dictionaryData?.identity_document_types].map((type) => ({
                      value: type.drivers_liscense.key,
                      label: type.drivers_liscense.i18n,
                    }))
                  : []
              }
            />
          </div>
        );
      case 'other_type_translated':
        return (
          <div className="w-full block pr-3">
            <Field
              autoComplete="off"
              name={fieldItem.name}
              component={Form.SelectField}
              options={dictionaryData ? docunebtTypeDataTranslationData() : []}
            />
          </div>
        );
      default:
        return (
          <div className="mt-1 relative rounded-md shadow-sm">
            <FieldWithFeedBack
              //@ts-ignore
              active={fieldItem.required && touched[fieldItem.name]}
              error={
                //@ts-ignore
                !dirty
                  ? false
                  : //@ts-ignore
                    errors[fieldItem.name]
              }>
              <Field
                disabled={fieldItem.disabled}
                type="text"
                name={fieldItem.name}
                autoComplete="off"
                className={fieldClassName}
              />
            </FieldWithFeedBack>
          </div>
        );
    }
  };
  return (
    <div className="grid grid-cols-6 gap-6">
      {personalDataFormArray.map((fieldItem: any) => {
        return (
          // @ts-ignore
          <Fragment key={fieldItem.name}>
            {fieldItem.subTitle && (
              <div className="col-span-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {fieldItem.subTitle}
                </h3>
              </div>
            )}

            <div className={fieldItem.gridClass}>
              <label htmlFor={fieldItem.name} className={labelClass}>
                {fieldItem.label ? fieldItem.label : null}
                {fieldItem.required && (
                  <span className="text-red-500 text-md p-0 ml-1">*</span>
                )}
              </label>
              {renderFiled(fieldItem)}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default PersonalInfo;
