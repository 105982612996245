interface IConfig {
  apiURL?: string;
  baseURL?: string;
  mobileIdCheckUrl?: string;
  socketUrl?: string;
}
// const PROD_URL = process.env.REACT_APP_PROD_URL;
// const CDEV_URL = process.env.REACT_APP_DEV_URL;

const devConfig: IConfig = {
  apiURL: 'https://check-und-track.voidmod.com/api',
  baseURL: 'https://check-und-track.voidmod.com/',
  // for local testing for the mobile ID check replace next line with http://192.168.2.103:3000/ or check your terminal for (On Your Network:....)
  mobileIdCheckUrl: 'https://check-n-track-web-mobile-client.netlify.app/',
  socketUrl: 'wss://check-und-track.voidmod.com/',
};

// change the production url next line
const prodConfig: IConfig = {
  apiURL:
    process.env.REACT_APP_API_URL || 'https://check-und-track.voidmod.com/api',
  baseURL: process.env.REACT_APP_API_URL
    ? 'https://www.checktastic.de/'
    : 'https://check-und-track.voidmod.com/',
  mobileIdCheckUrl: process.env.REACT_APP_API_URL
    ? 'https://checktastic.de/'
    : 'https://check-n-track-web-mobile-client.netlify.app/',
  socketUrl: process.env.REACT_APP_API_URL
    ? 'wss://www.checktastic.de/'
    : 'wss://check-und-track.voidmod.com/',
};

const getConfig = (): IConfig => {
  if (process.env.NODE_ENV === 'development') return devConfig;
  return prodConfig;
};

export default getConfig();
