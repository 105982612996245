import { Link, Redirect, useHistory } from 'react-router-dom';

import * as yup from 'yup';
import { Field } from 'formik';

import { useAuthContext } from '../../../hooks';
import { Spinner } from '../../../styles/icons';
import { Form } from '../../shared';
// import Logo from 'assets/images/logo.jpeg';
import LogoTastic from 'assets/images/logo-tastic-new.png';
import ConsusLogo from 'assets/images/logo.jpeg';
import useStore from '../../../ui-state/ui';
import { useEffect } from 'react';
import { useStores } from 'shared';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse ein')
    .defined('Ihre E-Mail Adresse ist erforderlich')
    .required(),
  password: yup
    .string()
    .min(
      7,
      'Bitte beachten Sie, dass Ihr Passwort mindestens 7 Zeichen lang sein muss',
    )
    .defined('Bitte geben Sie Ihr Passwort ein')
    .required(),
});

// const Links = [
//   {
//     name: 'Impressum',
//     to: '/',
//   },
//   {
//     name: 'Datenschutz',
//     to: '/',
//   },
//   {
//     name: 'Kontakt',
//     to: '/',
//   },
// ];
type FieldProps = {
  field: { name: string; value: string };
  form: any;
  meta: {
    touched: boolean;
    error: any;
  };
};

// function updateAbility(ability: any, user: User) {
//   const { can, rules } = new AbilityBuilder(Ability);
//   console.log('user', user);
//   if (user && user.role === 'Admin') {
//     can('manage', 'all');
//   } else {
//     can('read', 'all');
//   }

//   if (ability) {
//     console.log('updating....');
//     ability.update(rules);
//   }
// }

const LoginPage = () => {
  let history = useHistory();
  const { ui } = useStores();
  // const { setToken } = Query();
  const setUserToStorage = useStore((state) => state.setUserToStorage);
  // let location = useLocation();
  const { signin, isLoading, isError, error } = useAuthContext();
  // console.log('error login', error?.response);
  //@ts-ignore
  // let { from } = location.state || { from: { pathname: '/' } };
  const login = async (values: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { email, password, save_token_instorage } = values;
    // the customer always want the setUserToStorage(true); to be true but hide the checkbox from lggin page.
    setUserToStorage(true);
    await signin(email, password);
    // console.log('user login======', user);
    window.history.replaceState(null, '', '/');
    history.replace('/dashboard');
  };
  useEffect(() => {
    if (
      error &&
      //@ts-ignore
      error?.response?.status === 307 &&
      //@ts-ignore
      error?.response?.headers.authorization
    ) {
      // console.log(
      //   'set token to storage',
      //   //@ts-ignore
      //   error?.response?.headers.authorization,
      // );

      ui.setToken(error ? error?.response?.headers.authorization : null);
      localStorage.setItem(
        `token`,
        //@ts-ignore
        window.JSON.stringify(error?.response?.headers.authorization),
      );
      // setToken(error?.response?.headers.authorization);
      history.replace('/login/two_factor_auth_challenge');
      window.location.reload();
      // window.location.reload();
    }
  }, [error, history, ui, isLoading]);

  // useEffect(() => {
  //   // dissallow user to go back to loggin page when token is present in storage
  //   // const localStorageUser = window.localStorage.getItem('user');
  //   const localStorageToken = window.localStorage.getItem('token');
  //   if (localStorageToken) {
  //     history.replace('/dashboard');
  //   }
  // }, []);
  // console.log('error', error);
  // console.log('🚀 ~ Navigations ~ localStorageToken:', token);
  const localStorageToken = window.localStorage.getItem('token');
  if (localStorageToken) {
    // console.log('🚀 ~ Navigations ~ localStorageToken:', localStorageToken);
    return (
      <Redirect
        to={{
          pathname: '/dashboard',
        }}
        exact
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-24 w-auto" src={LogoTastic} alt="Workflow" />
        <h2 className="mt-6 text-center text-2xl font-semibold text-gray-900">
          Loggen Sie sich in Ihr Konto ein
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md mx-3">
        <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
          <Form
            initialValues={{
              email: '',
              password: '',
              save_token_instorage: true,
            }}
            validationSchema={validationSchema}
            submitForm={login}
            // className="space-y-6"
          >
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700">
                E-Mail-Adresse
              </label>
              <div className="mt-1">
                <Field name="email">
                  {({
                    field,
                    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                    meta,
                  }: FieldProps) => {
                    return (
                      <Form.CustomInputItem
                        field={field}
                        meta={meta}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                        type="email"
                      />
                    );
                  }}
                </Field>
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700">
                Passwort
              </label>
              <div className="mt-1">
                <Field name="password">
                  {({ field, meta }: FieldProps) => (
                    <Form.CustomInputItem
                      type="password"
                      className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      field={field}
                      meta={meta}
                    />
                  )}
                </Field>
              </div>
            </div>
            {isError ? (
              <div className="text-red-400 text-sm font-semibold">
                {error.response ? error.response.data.error : 'server error'}
              </div>
            ) : null}
            <div className="flex items-center justify-between">
              <div className="flex items-center sr-only">
                <Field
                  // onChange={() => }
                  value={true}
                  id="save_token_instorage"
                  name="save_token_instorage"
                  type="checkbox"
                  className="sr-only h-4 w-4 text-orange-600 focus:ring-orange-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="save_token_instorage"
                  className="ml-2 block text-sm text-gray-900 sr-only">
                  Angemeldet bleiben
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/reset_password"
                  className="font-medium text-orange-500 hover:text-orange-600 hover:underline">
                  Passwort vergessen?
                </Link>
              </div>
            </div>

            <div>
              <button
                disabled={isLoading}
                type="submit"
                className="w-full  justify-center px-4 py-2 text-base text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white inline-flex items-center rounded-md shadow-sm border border-transparent font-medium">
                {isLoading ? <Spinner /> : 'Anmelden'}
              </button>
            </div>
          </Form>
          <div className="mt-6">
            <p className="mt-2 text-sm text-gray-800">
              Hinweis: Es gelten die Datenschutzbestimmungen und
              Nutzungsbedingungen des Produktes checktastic.
            </p>
            <p className="mt-2 text-sm text-gray-800">
              Kein Account?
              <a
                href="https://www.24relations.de/kontakt"
                target="_blank"
                className="mr-3 ml-3 font-bold text-orange-600 hover:text-orange-800"
                rel="noreferrer">
                Kontakt
              </a>
              <a
                href="https://www.checktastic.de/impressum.html"
                target="_blank"
                className="font-bold text-black hover:text-orange-600"
                rel="noreferrer">
                Impressum
              </a>
              <a
                href="https://www.checktastic.de/datenschutz.html"
                target="_blank"
                className="ml-3 font-bold text-black hover:text-orange-600"
                rel="noreferrer">
                Datenschutz
              </a>
            </p>
          </div>
          <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-md flex flex-row justify-items-center justify-center">
            <h2 className="text-gray-500 mr-5">powered by</h2>
            <img className="h-8 w-auto" src={ConsusLogo} alt="Workflow" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
