const FormHeader = ({
  title,
  subTitle,
}: {
  title: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
}) => {
  return (
    <div>
      <h3 className="text-2xl leading-6 font-bold text-gray-900">{title}</h3>
      <p className="mt-2 text-sm text-gray-500 max-w-3xl font-medium">
        {subTitle}
      </p>
    </div>
  );
};
export default FormHeader;
