import { Fragment } from 'react';
import { UserCircleIcon, TrashIcon } from '@heroicons/react/outline';
import config from 'shared/config';

type FileUploadProp = {
  value: any;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  labelClassName?: string;
  filedName: string;
  avatarClassName?: string;
  title?: string;
  canUpdate?: boolean;
  isImagePreview?: boolean;
};
export const RenderThumb = ({
  file,
  avatarClassName = 'inline-block h-12 w-12 rounded-full',
  isImagePreview = false,
}: {
  file: any;
  avatarClassName?: string;
  isImagePreview?: boolean;
}) => {
  if (!file) return <UserCircleIcon className="h-10 w-10 m-2" />;
  const url =
    typeof file === 'string' ? `${config.baseURL}${file}` : file.base64_string;
  // console.log('url', url);
  return (
    <div className={`flex-shrink-0 ${isImagePreview ? 'w-48' : ''}`}>
      <img className={avatarClassName} src={url} alt="" />
    </div>
  );
};

const FileUpload = ({
  value,
  setFieldValue,
  filedName,
  labelClassName,
  avatarClassName,
  title = 'Profilbild',
  canUpdate = true,
  isImagePreview = false,
}: FileUploadProp) => {
  const handleFileChange = (e: any) => {
    // setFileValueForThumb()
    let file = e.currentTarget.files![0];
    // Make new FileReader
    let reader = new FileReader();

    // Convert the file to base64 text
    reader.readAsDataURL(file);
    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      let fileInfo = {
        mime_type: file.type,
        base64_string: reader.result, //base64_string
      };

      setFieldValue(filedName, fileInfo);
    };
  };
  return (
    <Fragment>
      <label htmlFor="photo" className={`${labelClassName} py-2`}>
        {title}
      </label>
      <div className="mt-1 flex lg:items-center flex-col lg:flex-row">
        <RenderThumb
          file={value}
          avatarClassName={avatarClassName}
          isImagePreview={isImagePreview}
        />
        {canUpdate && (
          <div className="lg:ml-4 flex mt-4 lg:mt-0">
            <div className="relative bg-white py-2 px-3 border border-blue-gray-300 rounded-md shadow-sm flex items-center cursor-pointer hover:bg-blue-gray-50 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-blue-gray-50 focus-within:ring-blue-500">
              <label
                htmlFor="profileurl"
                className="relative text-sm font-medium text-blue-gray-900 pointer-events-none">
                <span>Bearbeiten</span>
                <span className="sr-only"> user photo</span>
              </label>
              <input
                onChange={handleFileChange}
                name="profileurl"
                type="file"
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer border-gray-300 rounded-md"
              />
            </div>
            <button
              disabled={value === ''}
              onClick={() => setFieldValue(filedName, '')}
              type="button"
              className="ml-3 bg-transparent py-2 px-3 border border-transparent rounded-md text-sm font-medium text-blue-gray-900 hover:text-blue-gray-700 focus:outline-none focus:border-blue-gray-300 focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-gray-50 focus:ring-blue-500">
              <TrashIcon className="w-6 h-6 text-gray-500" />
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default FileUpload;
