import { useRouteMatch } from 'react-router-dom';

import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import NavItems from '../../../layout/nav_with_items';
// import { Button, Nav, SelectList } from '../../../../styles/components';
// import { IdCheck } from '../../../styles/icons';

import { Nav } from '../../../../styles/components';

const CalenderHeader = (props: any) => {
  // console.log('🚀 ~ CalenderHeader ~ props:', props);
  let { url } = useRouteMatch();

  const items = [
    {
      name: 'Fahrzeuge',
      url: url,
      icon: ChevronRightIcon,
    },
    {
      name: 'Reservierung',
      url: `#`,
      icon: undefined,
      // disabled:true
    },
  ];

  return (
    <div>
      <nav className="sm:hidden" aria-label="zurück">
        {/* mobile btn */}
        <Nav.Item
          label="zurück"
          to={url}
          icon={
            <ChevronLeftIcon
              color="#000"
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-900"
              aria-hidden="true"
            />
          }
          className="flex items-center text-sm font-medium text-gray-900 hover:text-gray-800"
        />
      </nav>

      <NavItems
        // verticalitems={false}
        selectedClass="text-black"
        items={items}
        navClassName="hidden sm:flex flex items-center"
        //   iconClassName="text-gray-900 h-4 w-4"
        itemClassName="flex flex-row-reverse items-center text-sm font-medium text-gray-500 hover:text-gray-700"
      />
    </div>
  );
};

export default CalenderHeader;
