export type apiType = 'LOGIN' | 'LOGOUT';

export enum APIendPoints {
  LOGIN = '/users/login',
  LOGOUT = '/users/logout',
}

export type generalProp = {
  type: apiType;
};

export type Stats = {
  all_id_checks_monthly_count: number;
  all_id_checks_yearly_count: number;
  accepted_id_checks_monthly_count: number;
  accepted_id_checks_yearly_count: number;
  declined_id_checks_monthly_count: number;
  declined_id_checks_yearly_count: number;
  discarded_id_checks_monthly_count: number;
  discarded_id_checks_yearly_count: number;
  contracts_monthly_count: number;
  contracts_yearly_count: number;
  contracts_with_vehicle_returned_monthly_count: number;
  contracts_with_vehicle_returned_yearly_count: number;
  contracts_in_progress_monthly_count: number;
  contracts_in_progress_yearly_count: number;
  branch_office: BranchOfficesResponseItem;
};
export interface Statsresponse extends Stats {
  branch_offices_stats: [Stats];
}
export interface User {
  id: number;
  salutation: string;
  prename: string;
  surname: string;
  email: string;
  user_owner_id: number;
  user_owner_type: string;
  role: string;
  confirmed_at: string;
  confirmation_sent_at: null | string;
  admin_confirmed_at: string;
  adv_file: {
    url: string;
  };
  uses_sb_insurance: boolean;
  uses_import_from_eautoseller_csv: boolean;
  headquarter: Dealer;
  avatar_image: Logo;
  created_at: string;
  updated_at: string;
  last_sign_in_at: string;
  location: Address;
  locked_at: string;
  plan_type: 'basic' | 'premium';
  is_plan_type_light: boolean;
  extend_with_drivers_license: boolean;
  plan_version: 'full' | 'light';
  is_plan_version_light: boolean;
}

export type Dealer = {
  address: Address;
  created_at: string;
  headquarter_id: number;
  id: number;
  locked_at: null;
  logo_file_url: null;
  name: string;
  tax_number: string;
  type: string;
  updated_at: string;
  plan_type: 'basic' | 'premium';
  is_plan_type_light: boolean;
  uses_digital_signing: boolean;
  plan_version: 'full' | 'light';
  is_plan_version_light: boolean;
};
export type Versions = {
  changed_from: string;
  changed_to: string;
  created_at: string;
  whodunnit: {
    id: number;
    salutation: string;
    prename: string;
    surname: string;
  };
};
export type Vehicle = {
  brand: string;
  created_at: string;
  id: number;
  kind: string;
  name: string;
  short_description: string;
  updated_at: string;
  vehicle_owner: Dealer;
  vehicle_owner_id: number;
  vehicle_owner_type: string;
  license_plate: string;
  odometer_value: string;
  predamage: string;
  // check if it's changed eveyplace
  pre_damage: string;
  fuel_kind: string;
  vin: string;
  main_driver: string;
  next_reservation_at: string;
  status: string;
  location: Address;
  versions: Array<{
    pre_damage: Array<Versions>;
    short_description: Array<Versions>;
  }>;
};
export interface Contract {
  contract_number: string;
  created_at: string;
  customer: Customer;
  customer_id: number;
  dealer: Dealer;
  dealer_id: number;
  dealer_type: string;
  end_of_use: string;
  id: number;
  kind: string;
  signed_contract_file: { url: null };
  start_of_use: string;
  unsigned_contract_file: { url: null };
  updated_at: string;
  vehicle: Vehicle;
  id_check_short: IDCheck;
  vehicle_id: number;
  odometer_value_at_end: number;
  odometer_value_at_start: number;
  additional_notes: string;
  contract_insurence: IInsurance;
  with_teilkasko: boolean;
  uses_digital_signing: boolean;
  teilkasko_selbstbeteiligung_amount_cents: {
    cents: number;
    currency_iso: string;
  };
  with_vollkasko: boolean;
  vollkasko_selbstbeteiligung_amount_cents: {
    cents: number;
    currency_iso: string;
  };
  tagesgrundpreis: {
    cents: number;
    currency_iso: string;
  };
  stundenpauschale: {
    cents: number;
    currency_iso: string;
  };
  zusatz_km: {
    cents: number;
    currency_iso: string;
  };
  fahrzeugkaution: {
    cents: number;
    currency_iso: string;
  };
  additional_costs: any[];
  sb_insurance_contract: SBContract;
  has_contract_signatures: boolean;
}

export type SBContract = {
  contract_data_json: {
    payment_method: string;
    disscount: number;
    license_plates: string;
    sb_sum: string;
    [any: string]: any;
  };
  id_check_short?: IDCheck;
  customer: Customer;
  signed_contract_file: { url: null };
  contract_id: 216;
  created_at: string;
  end_date: string;
  id: number;
  id_check_id: number;
  kind: string;
  price_cents: number;
  price_currency: string;
  start_date: string;
  updated_at: string;
  user_id: number;
};
export type ContractShortProp = {
  contract_number: string;
  created_at: string;
  active: boolean;
  id: number;
  kind: string;
  end_of_use: string;
  updated_at: string;
  start_of_use: string;
  customer_prename: string;
  customer_surname: string;
  vehicle_returned_at: string;
};
export type NotificationRes = {
  all: string;
  active: string;
  done: string;
};

export interface IDCheck {
  id: number;
  approval_state: string;
  created_at: string;
  updated_at: string;
  prename: string;
  surname: string;
  identity_document_type: string;
  identity_document_number: string;
  date_of_birth: string;
  surname_from_id_document: string;
  prename_from_id_document: string;
  id_check_owner: {
    name: string;
  };
  user: {
    name: string;
  };
  // scan_to_mail_email: {
  //   url: string;
  // };
  scan_to_mail_email1: {
    url: string;
  };
  scan_to_mail_email2: {
    url: string;
  };
  drivers_liscense_id_check: {
    date_of_birth: string;
    approval_state: string;
    identity_document_number: string;
    identity_document_type: string;
    drivers_license_classes: string;
    drivers_liscense_issuing_authority: string;
    id: number;
    is_persons_name_matched: boolean;
    prename_from_id_document: string;
    surname_from_id_document: string;
    // scan_to_mail_email: {
    //   pdf_north_slide: {
    //     url: string;
    //   };
    //   pdf_south_slide: {
    //     url: string;
    //   };
    //   pdf_thumb: {
    //     url: string;
    //   };
    //   url: string;
    // };
    scan_to_mail_email1: {
      pdf_north_slide: {
        url: string;
      };
      pdf_south_slide: {
        url: string;
      };
      pdf_thumb: {
        url: string;
      };
      url: string;
    };
    scan_to_mail_email2: {
      pdf_north_slide: {
        url: string;
      };
      pdf_south_slide: {
        url: string;
      };
      pdf_thumb: {
        url: string;
      };
      url: string;
    };
  };
  contract: Contract | null;
}
export type IDtableDataResponseItem = {
  approval_state: string;
  contract_short: any;
  id: number;
  created_at: string;
  updated_at: string;
  customer_short: any;
  vehicle_short: any;
  prename_from_id_document: string;
  surname_from_id_document: string;
  prename: string;
  surname: string;
  identity_document_type: string;
  identity_document_number: number | null;
  dealer: Dealer;
  discarded_at: string | null;
  sb_insurance_contract: SBContract;
  drivers_liscense: {
    approval_state: string;
    scan_to_mail_email: {
      url: string;
      pdf_thumb: {
        url: string;
      };
    };
  };
};
export interface Address {
  id: number;
  street: string;
  house: string;
  postal_code: string;
  city: string;
  address_owner_type: string;
  address_owner_id: number;
  country: string;
  created_at: string;
  updated_at: string;
}
export interface Customer {
  id: number;
  customer_number: string;
  salutation: string;
  prename: string;
  surname: string;
  email: string;
  telephone_number: string;
  created_at: string;
  updated_at: string;
  address: Address;
  last_id_check: IDCheck;
  id_checks: [IDCheck];
  contracts: [ContractShortProp];
  uses_two_k_auth: boolean;
  date_of_birth: string;
}
export interface IPaginationType {
  page: string;
  per_page: number;
  total_entries: number;
  total_pages: number;
  isFetching?: boolean;
}
export interface ServerResponseType {
  records: IDtableDataResponseItem[];
  pagination: IPaginationType;
}
type Logo = {
  orignal: string;
  thumbnail: string;
};
export interface BranchOfficesResponseItem {
  id: number;
  name: string;
  tax_number: number;
  headquarter_id?: number;
  logo_image: Logo;
  created_at: string;
  updated_at: string;
  locked_at: string;
  plan_type: 'basic' | 'premium';
  is_plan_type_light: boolean;
  delete_after_n_months: number;
  plan_version: 'full' | 'light';
  is_plan_version_light: boolean;
  address: Address;
  adv_file: {
    orignal: string;
    thumbnail: string;
  };
  download_unsigned_adv_file: null | string;
  vehicle_brands: Array<{ name: string; id: number }>;
}
export interface BranchOfficesResponse {
  records: BranchOfficesResponseItem[];
  pagination: IPaginationType;
}
export interface VehiclesResp {
  records: Vehicle[];
  pagination: IPaginationType;
}
export type Emails = {
  id: number;
  subject: string;
  pdf_attachment_file: {
    url: string;
    pdf_thumb: {
      url: string;
    };
  };
  recieved_at: string;
};
export type IInsurance = {
  id: number;
  kind: string;
  selbstbeteiligung: {
    cents: number;
    currency_iso: string;
  };
};
export interface IEmailsList {
  emails: Emails[];
  pagination?: IPaginationType;
}
export interface IDictionary {
  identity_document_types: {
    identity_card: {
      key: string;
      i18n: string;
    };
    passport: {
      key: string;
      i18n: string;
    };
    drivers_liscense: {
      key: string;
      i18n: string;
    };
  };
  contract_kinds: [{ name: string }];
  contract_insurences_kinds: Array<{ name: string }>;
  salutations: Array<{ name: string }>;
  user_roles: Array<{ name: string }>;
  id_check_filter_options_approval_state: Array<{ key: string; i18n: string }>;
  id_check_filter_options_state: Array<{ key: string; i18n: string }>;
}
export type Brands = {
  id: number;
  name: string;
};

export type Costs = {
  id: number;
  kind: string;
  zusatz_km: {
    cents: number;
    currency_iso: string;
  };
  inkl_km_tagesgrundpreis: number;
  inkl_km_stundenpauschale: number;
  tagesgrundpreis: {
    cents: number;
    currency_iso: string;
  };
  stundenpauschale: {
    cents: number;
    currency_iso: string;
  };
  fahrzeugkaution: {
    cents: number;
    currency_iso: string;
  };
  is_free_of_charge: boolean;
};
export type UserOwner = {
  user_owner_id: number;
  user_owner_type: string;
  user_owner_name: string;
  id: number;
  is_current: true;
};

export type Notifications = {
  body: string;
  id: number;
  title: string;
  created_at: string;
  updated_at: string;
  icon: string;
};
export interface NotificationListRes {
  records: Notifications[];
  pagination: IPaginationType;
}
export interface BrandsRes {
  records: Brands[];
  pagination: IPaginationType;
}
export interface DefaultCostRes {
  records: Costs[];
  contract_insurences: Array<IInsurance>;
  sonstige_vereinbarungen: {
    text: string;
  };
}

export type ExternalCaptureProcess = {
  external_image_capturing_process: {
    token: string;
    status: string;
    completed_at: null;
    payload: {
      identity_document_images: [];
    };
  };
};
