//@ts-ignore

// import tw from 'twin.macro'
import Header from './header';
import Sidebadr from './sidebar';

const Layout: React.FC<any> = ({ children }) => {
  return (
    <div className="h-screen bg-gray-50 flex overflow-hidden">
      <Sidebadr />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Header />
        <div className="flex-1 flex items-stretch overflow-hidden">
          <main className="flex-1 overflow-y-auto" id="main-scroll-container">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
