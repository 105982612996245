import { forwardRef } from 'react';
import { Spinner } from '../../../../../styles/icons';

const FormAction = forwardRef(
  (
    {
      openTab,
      handleNextStep,
      isLoading,
      hideBtn = false,
      isExtraImageWithFlash,
    }: {
      openTab: number | string;
      handleNextStep: () => void;
      isLoading?: boolean;
      hideBtn: boolean;
      isExtraImageWithFlash?: boolean;
    },
    ref,
  ) => {
    const btnClassName =
      'w-full border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-600';

    return (
      <div className="py-1 text-right flex justify-between">
        {Number(openTab) < 2 && hideBtn && !isExtraImageWithFlash && (
          <button
            // @ts-ignore
            ref={ref}
            onClick={() => {
              // we should validate on mount by adding validateonmount prop to form
              handleNextStep();
            }}
            disabled={isLoading}
            type="button"
            className={`${btnClassName} ${
              isLoading ? 'bg-gray-400' : 'bg-orange-500'
            }`}>
            {isLoading ? (
              <div className="flex items-center align-center">
                <span>Loading</span> <Spinner className="h-4 w-4 ml-2" />
              </div>
            ) : (
              'Weiter'
            )}
          </button>
        )}
        {Number(openTab) < 4 && hideBtn && isExtraImageWithFlash && (
          <button
            // @ts-ignore
            ref={ref}
            onClick={() => {
              // we should validate on mount by adding validateonmount prop to form
              handleNextStep();
            }}
            disabled={isLoading}
            type="button"
            className={`${btnClassName} ${
              isLoading ? 'bg-gray-400' : 'bg-orange-500'
            }`}>
            {isLoading ? (
              <div className="flex items-center align-center">
                <span>Loading</span> <Spinner className="h-4 w-4 ml-2" />
              </div>
            ) : (
              'Weiter'
            )}
          </button>
        )}
      </div>
    );
  },
);

export default FormAction;
