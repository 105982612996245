const contractState = (
  status: string,
  isContract: boolean,
  discarded_at: string | null,
  id?: number,
  active?: boolean,
  url?: string,
) => {
  let label, progress, color, action;
  if (isContract) {
    // when there is a contract then should be just this 2 status avaliable;
    if (!active) {
      if (status === 'SUCCESSFULLY_COMPLETED') {
        progress = '100%';
        label = 'Vorgang beendet';
        color = 'bg-green-400';

        action = {
          to: 'contract_details',
          mainBtnLabel: 'Vertrag Details',
          disable: false,
          type: 'secondary',
          processWithoutContract: false,
          extraAction: [],
        };
      } else if (status === 'REJECTED') {
        progress = '100%';
        label = 'Vorgang beendet';
        color = 'bg-green-400';
        action = {
          to: 'contract_details',
          mainBtnLabel: 'Vertrag Details',
          disable: false,
          type: 'secondary',
          processWithoutContract: false,
          extraAction: [],
        };
      } else {
        progress = '100%';
        label = 'Legitimation abgelehnt';
        color = 'bg-red-600';
        action = {
          to: 'contract_details',
          mainBtnLabel: 'Vertrag Details',
          disable: false,
          type: 'secondary',
          processWithoutContract: false,
          extraAction: [],
        };
      }
    } else {
      progress = '80%';
      label = 'Fahrzeug unterwegs';
      color = 'bg-orange-400';
      action = {
        to: 'contract_details',
        mainBtnLabel: 'Vertrag Details',
        disable: false,
        type: 'secondary',
        processWithoutContract: false,
        extraAction: [
          {
            title: 'Fahrzeugrückgabe',
            url: 'test',
            actionType: 'EndTask',
          },
        ],
      };
    }
  } else {
    if (discarded_at) {
      progress = '100%';
      label = 'Ohne Vertrag abgeschlossen';
      color = 'bg-red-600';
      action = {
        to: 'customer_details',
        mainBtnLabel: 'Vorgang Details',
        disable: false,
        type: 'secondary',
        processWithoutContract: false,
        extraAction: [],
      };
    } else {
      if (status === 'SUCCESSFULLY_COMPLETED') {
        progress = '60%';
        label = 'Jetzt Vertrag erstellen';
        color = 'bg-orange-400';
        action = {
          to: 'customer_details',
          mainBtnLabel: 'Vorgang Details',
          disable: false,
          type: 'secondary',
          processWithoutContract: true,
          extraAction: [
            {
              title: 'Vertrag erstellen',
              url: `${url}/new_contract/${id}`,
              actionType: 'Redirect',
            },
            {
              title: 'ohne Vertrag abschließen',
              url: 'to be defined later',
              actionType: 'API',
            },
          ],
        };
      }
      if (status === 'IN_PROGRESS') {
        progress = '30%';
        label = 'Legitimation wird überprüft';
        color = 'bg-orange-200';
        action = {
          to: undefined,
          disable: true,
          mainBtnLabel: 'Vorgang Details',
          type: 'disabled',
          processWithoutContract: false,
          extraAction: [],
        };
      }
      if (status === 'REJECTED') {
        progress = '30%';
        label = 'Legitimation abgelehnt';
        color = 'bg-red-600';
        action = {
          to: 'customer_details',
          mainBtnLabel: 'Vorgang Details',
          disable: false,
          type: 'secondary',
          processWithoutContract: false,
          extraAction: [
            {
              title: 'Vertrag erstellen',
              url: `${url}/new_contract/${id}`,
              actionType: 'Redirect',
            },
            {
              title: 'ohne Vertrag abschließen',
              url: 'to be defined later',
              actionType: 'API',
            },
          ],
        };
      }
      if (status === 'NOT_AVAILABLE') {
        progress = '10%';
        label = 'Legitimation nicht verfügbar';
        color = 'bg-red-600';
        action = {
          to: 'customer_details',
          mainBtnLabel: 'Vorgang Details',
          disable: true,
          type: 'disabled',
          processWithoutContract: false,
          extraAction: [
            {
              title: 'ohne Vertrag abschließen',
              url: 'to be defined later',
              actionType: 'API',
            },
          ],
        };
      }
    }
  }

  return {
    label,
    progress,
    color,
    action,
  };
};
export default contractState;
