import { useRef } from 'react';
import { Query, Notifications } from 'shared';
import { NewspaperIcon } from '@heroicons/react/solid';
import { classNames } from '../../../styles/utils';
// import useStore from '../../../ui-state/ui';
import { dataFormat } from '../../../utils';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { Spinner } from '../../../styles/icons';

const NotificationCenter = ({ message }: { message: any }) => {
  // console.log('message', message);
  const { FetchPushMessagesListInfinit } = Query();
  const {
    isSuccess,
    status,
    data: newData,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = FetchPushMessagesListInfinit({
    key: 'notifications',
  });

  // const containerRef = useRef();
  const loadMoreButtonRef = useRef();

  const emptyList =
    //@ts-ignore
    !message &&
    isSuccess &&
    //@ts-ignore
    newData.pages.length === 0 &&
    //@ts-ignore
    newData.pages[0].records.length === 0;
  useIntersectionObserver({
    // root: containerRef,
    target: loadMoreButtonRef,
    onIntersect: () => {
      fetchNextPage();
    },
    enabled: hasNextPage,
  });
  const finalData =
    newData && newData.pages.length > 1
      ? newData.pages.reduce((acc, cur) => {
          let final = [];
          if (acc.records) {
            final = acc.records.concat(cur.records);
          } else {
            final = acc.concat(cur.records);
          }

          return final;
        })
      : newData?.pages[0].records;
  // console.log('newData', finalData);
  return (
    <div className="flow-root">
      <ul className="-mb-8">
        {message ? (
          <li>
            <div className="relative pb-9">
              {message.eventIdx !== message.length - 1 ? (
                <span
                  className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                  aria-hidden="true"
                />
              ) : null}
              <div className="relative flex space-x-3">
                <div>
                  {message.href ? (
                    <img
                      className="h-8 w-8 rounded-full"
                      src={message.href}
                      alt=""
                    />
                  ) : (
                    <span
                      className={classNames(
                        message.iconBackground,
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                      )}>
                      <message.icon
                        className="h-8 w-8 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  )}
                </div>
                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                  <div>
                    <p className="text-sm text-gray-500">
                      {message.content}{' '}
                      {/* <a
                        href={message.href}
                        className="font-medium text-gray-900">
                        {message.target}
                      </a> */}
                    </p>
                  </div>
                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                    <time dateTime={message.datetime}>
                      {dataFormat({
                        date: message.datetime,
                        option: {
                          hour: true,
                          minute: true,
                        },
                      })}
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ) : emptyList ? (
          <li>keine Angabe</li>
        ) : null}
        {status === 'loading' ? (
          <p>Loading...</p>
        ) : status === 'error' ? (
          <span>Error...</span>
        ) : (
          finalData.map((notificationItem: Notifications, eventIdx: number) => (
            <li key={notificationItem.id}>
              <div className="relative pb-9">
                {eventIdx !== finalData.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    {notificationItem.icon ? (
                      <img
                        className="h-8 w-8 rounded-full"
                        src={notificationItem.icon}
                        alt=""
                      />
                    ) : (
                      <span
                        className={classNames(
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                        )}>
                        <NewspaperIcon
                          className="h-5 w-5 text-blue-600"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </div>
                  <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-500">
                        {notificationItem.body}{' '}
                        {/* <a
                    href={message.href}
                    className="font-medium text-gray-900">
                    {message.target}
                  </a> */}
                      </p>
                    </div>
                    <div className="text-right text-sm whitespace-nowrap text-gray-500">
                      <time dateTime={'2020-09-20'}>
                        {dataFormat({
                          date: notificationItem.created_at,
                          option: {
                            hour: true,
                            minute: true,
                          },
                        })}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
      <div className="p-3 mt-2 mb-2">
        <button
          className="text-right text-sm whitespace-nowrap text-gray-400"
          //@ts-ignore
          ref={loadMoreButtonRef}
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}>
          {isFetchingNextPage ? (
            <Spinner className="h-4 w-4 ml-1" />
          ) : hasNextPage ? (
            'Loading New'
          ) : (
            ''
          )}
        </button>
      </div>
      {/* <div>
        {isFetching && !isFetchingNextPage ? 'Background Updating...' : null}
      </div> */}
    </div>
  );
};

export default NotificationCenter;
