import * as yup from 'yup';

export const customerPageValidationSchema = yup.object({
  prename: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass Ihr Name mindestens 7 Zeichen lang sein muss',
    )
    .defined('Der Vorname ist erforderlich')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  surname: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass Ihr Name mindestens 7 Zeichen lang sein muss',
    )
    .defined('Bitte geben Sie den Nachnamen ein')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  type: yup.string(),
  id: yup.string(),
  street: yup.string(),
  postal_code: yup.string(),
  telephone_number: yup.string(),
  email: yup.string(),
});

export const creatLegitimationFormSchema = yup.object({
  // prename: yup
  //   .string()
  //   .min(
  //     3,
  //     'Bitte beachten Sie, dass der Vorname mindestens 3 Zeichen lang sein mus',
  //   )
  //   .defined('Vorname ist erforderlich.')
  //   .required()
  //   .matches(
  //     /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
  //     'Leerzeichen oder Sonderzeichen sind nicht möglich',
  //   ),
  // surname: yup
  //   .string()
  //   .min(
  //     3,
  //     'Bitte beachten Sie, dass Ihr Nachname mindestens 3 Zeichen lang sein muss',
  //   )
  //   .defined('Nachname ist erforderlich')
  //   .required()
  //   .matches(
  //     /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
  //     'Leerzeichen oder Sonderzeichen sind nicht möglich',
  //   ),
  decumenttype: yup.string().required('Bitte geben Sie den Ausweistyp an'),
  drive_liscense: yup.string().required('Bitte geben Sie den Ausweistyp an'),
  // .defined('Bitte geben Sie Ihr Ausweistyp ein'),
  // .required(),
  // /eingangscan: yup.string(),
});
