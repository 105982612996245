import { Field, FormikProps } from 'formik';
import React, { useState } from 'react';

import { Query } from 'shared';
import { Form, NotificationMessage } from '../../shared';
import { classNames } from '../../../styles/utils';

import FileUpload from './file_upload';

import { personalInfovalidationSchema } from './utils';
import { useAuthContext } from '../../../hooks';
import useStore from '../../../ui-state/ui';

const PersonalInfo = ({ id }: { id: number }) => {
  const {
    UpdateUserData,
    UpdateUserAvatar,
    FetchUserDataById,
    FetchDictionarysData,
    DeleteAvatar,
  } = Query();
  const [showNotification, setShow] = useState(false);
  const setUserToStorage = useStore((state) => state.setUserToStorage);

  const auth = useAuthContext();

  const { data: userData, refetch } = FetchUserDataById({
    key: `user-${id}`,
    id: id,
  });
  const { mutateAsync: deleteAvatar } = DeleteAvatar();
  const { mutateAsync } = UpdateUserAvatar({
    url: `users/${id}/avatar`,
  });
  const { data: dictionaryData } = FetchDictionarysData({
    key: 'dictionary-data',
  });

  const loggout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    setUserToStorage(false);
    auth.signout();
  };
  const {
    mutateAsync: updateUserData,
    // error: errorUpdatingUserData,
    // data: updatedUserData,
  } = UpdateUserData({
    url: `users/${id}`,
  });
  // console.log('user', userData);
  const handleProflileDataSubmit = async (values: any, formikBag: any) => {
    const { setErrors } = formikBag;
    const formData = {
      salutation: values.salutation,
      prename: values.prename,
      surname: values.surname,
      telephone_number: values.telephone_number,
      current_password: values.currentpassword ? values.currentpassword : null,
      password: values.newpassword ? values.newpassword : null,
      password_confirmation: values.password_confirmation
        ? values.password_confirmation
        : null,
    };

    try {
      if (
        typeof values.profileurl !== 'string' &&
        typeof values.profileurl !== undefined
      ) {
        await mutateAsync({
          ...values.profileurl,
        });
      }
      if (
        userData &&
        userData.avatar_image &&
        userData.avatar_image.thumbnail &&
        values.profileurl === ''
      ) {
        await deleteAvatar(`users/${userData.id}/avatar`);
      }
      //@ts-ignore
      await updateUserData({
        ...formData,
      });
      setShow(true);

      if (
        values.newpassword &&
        values.currentpassword &&
        values.password_confirmation
      ) {
        // console.log('calling loggout');
        setTimeout(() => loggout(), 2000);
      } else {
        refetch();
      }
    } catch (e) {
      //@ts-ignore
      const errorObject = e.response && e.response.data;
      const fieldName = Object.keys(errorObject)[0];

      const value = Object.values(errorObject)[0];

      if (fieldName && value) {
        setErrors({
          error: `${value}`,
        });
      } else {
        setErrors({
          error: `Beim Aktualisieren Ihres Profiles ist ein Fehler aufgetreten. Bitte versuchen Sie zu einem späteren Zeitpunkt erneut`,
        });
      }
    }
  };

  const filedClassName =
    'p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500';
  const labelClassName = 'block text-sm font-medium text-blue-gray-900 mb-2';
  if (!userData) return null;
  return (
    <div className="py-2 lg:py-0 px-6 flex flex-col overflow-hidden">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 pb-3">
        Persönliche Einstellungen
      </h1>
      <Form
        submitForm={handleProflileDataSubmit}
        validationSchema={personalInfovalidationSchema}
        initialValues={{
          salutation: userData.salutation || '',
          prename: userData.prename || '',
          surname: userData.surname || '',
          email: userData.email || '',
          role: userData.role
            ? userData.user_owner_type === 'Dealer::BranchOffice' &&
              userData.role === 'Admin'
              ? 'Admin Filiale'
              : userData.role
            : '',
          telephone_number: userData.telephone_number || '',
          profileurl: userData.avatar_image
            ? userData.avatar_image.thumbnail
            : undefined,
          currentpassword: '',
          newpassword: '',
          password_confirmation: '',
        }}>
        {({
          errors,
          isSubmitting,
          dirty,
          values,
          submitCount,
          touched,
          setFieldValue,
        }: FormikProps<any>) => {
          // console.log('error', errors);
          // console.log('form errors', errors);
          // console.log('profileurl', values['profileurl']);
          return (
            <div className="mt-6 space-y-8">
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                <div className="sm:col-span-6 md:flex justify-between">
                  <div className="md:w-2/12 w-full mb-5 lg:mb-0">
                    <label htmlFor="prename" className={labelClassName}>
                      Anrede
                    </label>
                    <Field
                      name={'salutation'}
                      component={Form.SelectField}
                      options={
                        dictionaryData
                          ? dictionaryData.salutations.map(
                              (salutation: { name: string }) => ({
                                value: salutation.name,
                                label: salutation.name,
                              }),
                            )
                          : []
                      }
                    />
                  </div>
                  <div className="md:w-5/12  md:ml-8 w-full mb-5 lg:mb-0">
                    <label htmlFor="prename" className={labelClassName}>
                      Vorname
                    </label>
                    <Field
                      className={`${filedClassName} md:w-11/12 w-full`}
                      type="text"
                      name="prename"
                    />
                  </div>

                  <div className="md:w-5/12 w-full">
                    <label htmlFor="surname" className={labelClassName}>
                      Nachname
                    </label>
                    <Field
                      className={`${filedClassName} w-full`}
                      type="text"
                      name="surname"
                    />
                  </div>
                </div>
                <div className="sm:col-span-6">
                  <div className="md:w-6/12 w-full">
                    <label htmlFor="Mobilnummer" className={labelClassName}>
                      Mobilnummer
                    </label>
                    <Field
                      autoComplete="off"
                      // readonly="readonly"
                      // placeHolder="Mobil"
                      className={`${filedClassName} w-full`}
                      type="text"
                      name="telephone_number"
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="email" className={labelClassName}>
                    E-Mail Adresse
                  </label>
                  <Field
                    autoComplete="off"
                    disabled={true}
                    className={`p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm bg-gray-100 w-full`}
                    type="text"
                    name="email"
                  />
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="Role" className={labelClassName}>
                    Rolle
                  </label>
                  <Field
                    disabled={true}
                    className={`p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm bg-gray-100 w-full`}
                    type="text"
                    name="role"
                  />
                </div>
                <div className="sm:col-span-6">
                  <div className="flex-shrink-0">
                    <FileUpload
                      avatarClassName="h-16 w-ful rounded-full"
                      filedName={'profileurl'}
                      value={
                        values['profileurl'] ? values['profileurl'] : undefined
                      }
                      setFieldValue={setFieldValue}
                      labelClassName={labelClassName}
                    />
                  </div>
                </div>
                <div className="sm:col-span-6  border-t-2 mt-2 pt-3">
                  <h2 className="text-xl font-medium text-blue-gray-900">
                    Passwort ändern
                  </h2>
                </div>
                <div className="sm:col-span-3">
                  <label htmlFor="currentpassword" className={labelClassName}>
                    Aktuelles Passwort
                  </label>
                  <Field
                    autoComplete="off"
                    className={`${filedClassName} w-full`}
                    type="password"
                    name="currentpassword"
                  />
                </div>
                <div className="sm:col-span-3" />

                <div className="sm:col-span-3">
                  <label htmlFor="newpassword" className={labelClassName}>
                    Neues Passwort
                  </label>
                  <Field
                    autoComplete="off"
                    className={`${filedClassName} w-full`}
                    type="password"
                    name="newpassword"
                  />
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="password_confirmation"
                    className={labelClassName}>
                    Neues Passwort wiederholen
                  </label>
                  <Field
                    autoComplete="off"
                    className={`${filedClassName} w-full`}
                    type="password"
                    name="password_confirmation"
                  />
                </div>
              </div>

              <div
                className={`pt-8 border-t-2 flex ${
                  touched && Object.keys(errors).length > 0
                    ? 'justify-between'
                    : 'justify-end'
                }`}>
                {touched && Object.keys(errors).length > 0 && (
                  <div className="sm:col-span-3">
                    <p className="text-red-400 pt-2 text-sm font-semibold">
                      {Object.values(errors)[0]}
                    </p>
                  </div>
                )}
                <button
                  disabled={!dirty || isSubmitting || submitCount > 4}
                  className={classNames(
                    dirty &&
                      Object.keys(errors).length === 0 &&
                      !isSubmitting &&
                      submitCount <= 4
                      ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                      : 'bg-gray-400 cursor-not-allowed',
                    'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                  )}
                  type="submit">
                  Speichern
                </button>
              </div>
            </div>
          );
        }}
      </Form>
      <NotificationMessage
        title="NutzerIn erfolgreich aktualisiert"
        subTitle=""
        notify={showNotification}
        setShow={setShow}
      />
    </div>
  );
};

export default React.memo(PersonalInfo);

// {errorUpdatingUserData ? (
//   <div className="text-red-400 text-sm font-semibold">
//     {/* @ts-ignore */}
//     {errorUpdatingUserData.response
//       ? // @ts-ignore
//         errorUpdatingUserData.response.data.error
//       : 'server error'}
//   </div>
// ) : null}
