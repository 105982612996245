import * as React from 'react';

import UIStore from './stores/ui'; // eslint-disable-line import/no-cycle

export interface IStores {
  [key: string]: any;
  ui: UIStore;
}

export const stores: IStores = {
  ui: new UIStore(),
};

// @ts-ignore
// export const history = syncHistoryWithStore(createBrowserHistory, stores.router);

Object.keys(stores).forEach((storeName: string) => {
  if (storeName !== 'router') {
    stores[storeName].stores = stores;
  }
});

export const storesContext = React.createContext(stores);
export const useStores = () => React.useContext(storesContext);
