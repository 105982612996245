import { useState, useEffect, createRef, Fragment, useRef } from 'react';
import { useAbility } from '@casl/react';
//@ts-ignore
import { CSVLink } from 'react-csv';
// import { Link, useRouteMatch } from 'react-router-dom';
import {
  PlusIcon,
  RefreshIcon,
  TrashIcon,
  PencilIcon,
  CalendarIcon,
} from '@heroicons/react/outline';
import {
  Button,
  Slideover,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableData,
} from '../../../../styles/components';
import { Query, VehiclesResp, Vehicle } from 'shared';
import ChangeCar from './add_new_car';
//@ts-ignore
import VorlagData from 'assets/csv/Vorlage-Fahrzeugverwaltung.csv';
// import { classNames } from '../../../styles/utils';

import {
  FetchHandlerWrapper,
  SearchSortHeader,
  NotificationMessage,
  Modal,
} from '../../../shared';
import { AbilityContext } from '../../../../auth';
import {
  vehiclesPageTablesColumns,
  vehiclesSortSelectObject,
} from '../../admin/utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { classNames } from '../../../../styles/utils';
import { Spinner } from '../../../../styles/icons';

const useDebounce = (value: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState('');
  const timerRef = useRef();

  useEffect(() => {
    //@ts-ignore
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

// type VehiclesProp = {};
export const statusStyles = {
  verfügbar: {
    status: 'bg-green-200 text-green-800',
    label: 'verfügbar'.toUpperCase(),
    contractStaus: {
      border: 'border-green-600',
      bg: 'bg-green-500',
    },
  },
  // ONTHEWAY: {
  //   status: 'bg-orange-200 text-yellow-800',
  //   label: 'Fahrzeug unterwegs',
  //   contractStaus: {
  //     border: 'border-orange-400',
  //     bg: 'bg-orange-400',
  //   },
  // },
  // REJECTED: {
  //   status: 'bg-red-200 text-red-800',
  //   label: 'Abgelehnt',
  //   contractStaus: {
  //     border: 'border-orange-600',
  //     bg: 'bg-red-400',
  //   },
  // },
  'nicht verfügbar': {
    status: 'bg-red-200 text-red-800',
    label: 'nicht verfügbar'.toUpperCase(),
    contractStaus: {
      border: 'border-orange-600',
      bg: 'bg-gray-700',
    },
  },
};
const tableBtnClassName =
  'px-1 py-2 rounded-md text-sm leading-4 border hover:bg-orange-400 hover:text-white';
const disabledBtnClassName =
  'px-1 py-2 rounded-md text-sm leading-4 border bg-gray-100 text-gray-400 cursor-not-allowed';
const tableIconClassName = 'w-5 h-5 mx-3';
const Vehicles = ({
  csvImportFromSeller,
}: {
  csvImportFromSeller: boolean;
}) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  // we have a multi car status to display the notification text based on this status.
  const [carStatus, setNewCar] = useState<
    | 'NEWCAR'
    | 'DELETECAR'
    | 'UPDATECAR'
    | 'CSVUPLOADERROR'
    | 'CSVSYNCWITHSELLER'
    | 'CSVSYNCWITHSELLERERROR'
  >('NEWCAR');
  const [showNotification, setShow] = useState(false);
  // const [isErro,setError] = useState()
  const [selectedCar, setSelectedCar] = useState<Vehicle>();
  const [csvData, setData] = useState<any>('');
  const [active, setActive] = useState(false);
  const csvLink = createRef();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [selected, setSelected] = useState<{
    name: string;
    status: string;
    id: number;
  }>();
  const [query, setSearchQuery] = useState('');
  const debounceValue = useDebounce(query, 300);
  //@ts-ignore
  const ability = useAbility(AbilityContext);
  const {
    FetchVehicles,
    SubmitDataToserver,
    UpdateUserData,
    getCSVfile,
    DeleteVehiclesorReservation,
    ImportCarsFromSellerCsv,
  } = Query();
  const { mutateAsync: deleteVehicles } = DeleteVehiclesorReservation();

  const {
    isLoading,
    isError,
    data: vehiclesData,
    isFetching,
    // isSuccess,

    refetch,
  } = FetchVehicles({
    key: 'vehicles-index',
    page: page,
    perPage: 15,
    searchQuery: debounceValue !== '' ? debounceValue : undefined,
    sortBy: selected ? selected.status : undefined,
    sort_direction: 'asc',
  });
  const {
    data: CSVSyncData,
    isFetching: isFetchingCSVdata,
    isLoading: isSyncCSV,
    isError: isCSVSyncError,
    refetch: syncCSVdate,
    remove,
  } = ImportCarsFromSellerCsv({
    key: 'vehicles-csv-sync',
  });
  // console.log('🚀 ~ vehiclesData:', vehiclesData);
  // console.log('🚀 ~ file: index.tsx:80 ~ isSuccess:', isSuccess);
  // const {data:csvData, } = FetchCSVdata({
  //   key:'csv-download'
  // })
  useEffect(() => {
    if (vehiclesData) {
      if (vehiclesData.pagination.total_pages > page) {
        setPage(1);
      }
    }
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue, selected]);

  const { mutateAsync } = SubmitDataToserver({
    url: 'vehicles',
  });
  const { mutateAsync: uploadCSV, isError: isCSVuploadError } =
    SubmitDataToserver({
      url: 'vehicles/from_csv_file',
    });
  const { mutateAsync: updateVehicles } = UpdateUserData({
    url: `vehicles/${selectedCar?.id}`,
  });

  const handleRowSelect = (rowValue: Vehicle) => {
    setSelectedCar(rowValue);
  };
  const handleDetailsClick = () => {
    getCSVfile().then((res) => {
      setData(res.data);
      // setActive(true);
    });

    // done();
  };
  const handleAddCar = async (values: any, formikBag: any) => {
    const { setErrors } = formikBag;
    const {
      brand,
      vin,
      name,
      CarKind,
      odometer_value,
      short_description,
      license_plate,
      pre_damage,
      fuel_kind,
      main_driver,
    } = values;
    const formData = {
      vehicle: {
        brand: brand,
        vin: vin,
        kind: CarKind,
        name: name,
        odometer_value: odometer_value,
        short_description: short_description,
        license_plate: license_plate,
        pre_damage: pre_damage,
        fuel_kind: fuel_kind,
        main_driver: main_driver,
      },
    };
    // console.log('formData', formData);
    if (carStatus === 'NEWCAR') {
      try {
        await mutateAsync(formData);
        refetch();
        setOpen(false);
        setShow(true);
      } catch (e) {
        //@ts-ignore
        // console.log('error', e.response.data);
        //@ts-ignore
        let error = e.response?.data?.errors[0];
        setErrors({
          error:
            error ||
            'Es ist ein API Fehler aufgetreten,Bitte versuche es später noch einmal',
        });
      }
    } else {
      try {
        await updateVehicles(formData);
        refetch();
        setOpen(false);
        setShow(true);
      } catch (e) {
        //@ts-ignore
        // console.log('error', e.response.data);
        //@ts-ignore
        let error = e.response?.data?.errors[0];
        setErrors({
          error:
            error ||
            'Es ist ein API Fehler aufgetreten,Bitte versuche es später noch einmal',
        });
      }
    }

    // refetch()
    // console.log('value', value);
  };

  const handleUploadCVS = async ({ target: { files } }: { target: any }) => {
    try {
      // console.log(files[0]);
      let data = new FormData();
      data.append('csv_file', files[0]);
      // console.log('data scg', data);
      await uploadCSV(data);
      setNewCar('NEWCAR');
      setShow(true);
      refetch();
    } catch (e) {
      // console.log('eror upload cdv', e);
      setNewCar('CSVUPLOADERROR');
      setShow(true);
    }
    // await uploadCSV(data)
  };
  const handleDelete = async (id: number) => {
    try {
      await deleteVehicles(`vehicles/${id}`);
      setOpenModal(false);
      setNewCar('DELETECAR');
      setShow(true);
      refetch();
    } catch (e) {
      console.log('error delete', e);
    }
  };

  // this sleep function to delay the response for a better UI.
  function sleep(ms = 4000): Promise<void> {
    // console.log('Kindly remember to remove `sleep`');
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const handleCarSyncFromSeller = () => {
    sleep();
    syncCSVdate();
  };
  useEffect(() => {
    if (csvData.length) {
      setActive(true);
    }
  }, [csvData]);

  useEffect(() => {
    if (CSVSyncData === 200) {
      setNewCar('CSVSYNCWITHSELLER');
      setShow(true);
      // refetch();
    } else {
      if (isCSVSyncError) {
        setNewCar('CSVSYNCWITHSELLERERROR');

        setShow(true);
      }
    }
    return remove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CSVSyncData, isCSVSyncError]);

  useEffect(() => {
    if (active) {
      //@ts-ignore
      csvLink.current.link.click();
      setActive(false);
    }
  }, [active, csvLink]);

  const notificationTextBasedOnCarStatus = {
    NEWCAR: {
      title: 'Fahrzeug gespeichert',
      subtitle: 'Die Daten für dieses Fahrzeug wurden erfolgreich gespeichert.',
    },
    DELETECAR: {
      title: 'Fahrzeug gelöscht',
      subtitle: 'Die Daten des Fahrzeugs wurden aus dem System entfernt.',
    },
    UPDATECAR: {
      title: 'Fahrzeugdaten aktualisiert',
      subtitle: 'Die Daten für dieses Fahrzeug wurden erfolgreich gespeichert.',
    },
    CSVSYNCWITHSELLER: {
      title: 'Fahrzeugdaten werden aktualisiert',
      subtitle:
        'Die Daten werden im Hintergrund verarbeitet. Dieser Vorgang kann bis zu 45 Sekunden dauern.',
    },
    CSVSYNCWITHSELLERERROR: {
      title: 'Es ist ein Fehler aufgetreten.',
      subtitle: 'Bitte versuche es später noch einmal.',
    },
    CSVUPLOADERROR: {
      title: 'Fehler beim Hochladen der CSV-Datei.',
      subtitle: 'Bitte überprüfen Sie Ihre Datei.',
    },
  };

  return (
    <div className="pt-2">
      <div className="mt-4 lg:mt-0 flex flex-col lg:flex-row justify-between">
        <div className="py-1 lg:py-0">
          <h2 className="text-2xl font-bold  text-gray-900 sm:text-3xl sm:truncate">
            Alle Fahrzeuge.
          </h2>
          {!csvImportFromSeller ? (
            <p className="mt-1 font-semibold text-gray-900 text-sm break-words pr-2">
              Sie möchten mehrere Fahrzeuge importieren? Nutzen Sie bitte diese
              <a href={VorlagData} className="text-orange-400 m-1">
                Vorlage.
              </a>
            </p>
          ) : null}
        </div>

        <div className="flex flex-col lg:flex-row-reverse lg:align-middle">
          <div className="mt-2 lg:mt-0">
            <Button
              disabled={!ability.can('create', 'vehicles')}
              className="w-56 lg:w-52 ml-2"
              startIcon={<PlusIcon className="w-5 h-5 mr-3" />}
              type={!ability.can('create', 'vehicles') ? 'disabled' : 'primary'}
              size="small"
              onClick={() => {
                setOpen(!open);
                setNewCar('NEWCAR');
              }}
              label="Fahrzeug hinzufügen"
            />
          </div>
          {!csvImportFromSeller ? (
            ability.can('create', 'vehicles') ? (
              <>
                <div className="lg:mx-3 my-2 lg:my-0">
                  <Button
                    className="px-3 py-2 btn-default w-56 lg:w-52"
                    label="CSV Export"
                    //@ts-ignore
                    onClick={handleDetailsClick}
                  />
                  <CSVLink
                    ref={csvLink}
                    filename={'vehicles.csv'}
                    data={String(csvData)}
                    // /asyncOnClick={true}
                  />
                </div>
                <div>
                  <div className="flex">
                    <label
                      htmlFor="file-upload"
                      className="px-3 py-2 btn-default w-56 lg:w-52">
                      <span>CSV Import</span>
                      <input
                        onChange={handleUploadCVS}
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                  </div>
                </div>
              </>
            ) : null
          ) : (
            <div className="mt-2 lg:mt-0">
              <Button
                className="w-64"
                startIcon={
                  isSyncCSV || isFetchingCSVdata ? (
                    <Spinner className="h-4 w-4 mr-3" />
                  ) : (
                    <RefreshIcon className="w-5 h-5 mr-3" />
                  )
                }
                type="primary"
                size="small"
                onClick={handleCarSyncFromSeller}
                label="Fahrzeuge aktualisieren"
              />
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col mt-5 xl:mt-8 justify-between">
        <SearchSortHeader
          divider={false}
          title={
            vehiclesData
              ? `${vehiclesData.pagination.total_entries} Fahrzeuge`
              : undefined
          }
          searchPLaceholder="Suche"
          selectMenuItem={vehiclesSortSelectObject}
          onSelect={setSelected}
          selected={selected}
          handleSearch={setSearchQuery}
        />
      </div>
      <div
        className={`mt-2 lg:mt-5 flex flex-col overflow-x-auto overflow-y-hidden lg:overflow-hidden lg:w-auto`}>
        <FetchHandlerWrapper
          isError={isError}
          isLoading={isLoading}
          data={vehiclesData ? vehiclesData : undefined}>
          {(data: VehiclesResp) => (
            <TableContainer>
              <Table
                isFetching={isFetching}
                hasPagination
                setPage={setPage}
                currentpage={page}
                {...data.pagination}>
                <TableHead>
                  {vehiclesPageTablesColumns.map((item: string) => (
                    <TableCell key={item}>{item}</TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {data &&
                    //@ts-ignore
                    data.records.map((data: Vehicle) => {
                      // const { created_at } = data.contract_short;
                      const {
                        name,
                        id,
                        license_plate,
                        brand,
                        location,
                        main_driver,
                        // vehicle_owner_type,
                        vin,
                      } = data;

                      // const { brand, kind, name } = data.vehicle_short;
                      // const statusData = {
                      //   'approval_state': 'AVALIABLE',
                      // };
                      return (
                        <TableRow key={id} action={() => handleRowSelect(data)}>
                          <TableData
                            className={`text-gray-500 w-2/12 font-medium`}>
                            <div className="ml-0">
                              <div className="text-sm font-bold text-gray-900">
                                {name}
                              </div>
                              <div className="text-sm text-gray-500">
                                {brand}
                              </div>
                            </div>
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-2/12 font-medium`}>
                            {location ? location.street : ''}
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-2/12 font-medium`}>
                            <div className="ml-0">
                              <div className="text-sm font-semibold text-gray-900">
                                {vin}
                              </div>
                              <div className="text-sm text-gray-500">
                                {license_plate}
                              </div>
                            </div>
                          </TableData>

                          <TableData
                            className={`text-gray-500 w-2/12 font-medium`}>
                            {main_driver ? main_driver : ''}
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            <span
                              className={classNames(
                                //@ts-ignore
                                statusStyles[data.status].status,
                                'px-3 lg:px-0 w-full py-1 justify-center inline-flex text-xs leading-5 font-semibold rounded-full ',
                              )}>
                              {/* @ts-ignore */}
                              {statusStyles[data.status].label}
                            </span>
                          </TableData>
                          <TableData
                            className={`text-right font-medium w-2/12`}>
                            <div className="flex">
                              <Button
                                className={tableBtnClassName}
                                onClick={() =>
                                  history.push(`${url}/calender/${id}`)
                                }
                                startIcon={
                                  <CalendarIcon
                                    className={tableIconClassName}
                                  />
                                }
                              />

                              <Button
                                className={`${tableBtnClassName} mx-2`}
                                onClick={() => {
                                  setOpen(!open);
                                  setNewCar('UPDATECAR');
                                }}
                                disabled={!ability.can('update', 'vehicles')}
                                startIcon={
                                  <PencilIcon className={tableIconClassName} />
                                }
                                // label={'Bearbeiten'}
                                // //@ts-ignore
                                // type={
                                //   !ability.can('update', 'vehicles')
                                //     ? 'disabled'
                                //     : 'default'
                                // }
                                // className="px-1 py-2 rounded-md text-sm leading-4"
                                // className="md:w-11/12 flex justify-center"
                              />

                              <Button
                                // type={
                                //   !ability.can('delete', 'vehicles')
                                //     ? 'disabled'
                                //     : 'default'
                                // }
                                disabled={!ability.can('delete', 'vehicles')}
                                className={
                                  !ability.can('delete', 'vehicles')
                                    ? disabledBtnClassName
                                    : tableBtnClassName
                                }
                                onClick={() => setOpenModal(true)}
                                startIcon={
                                  <TrashIcon className={tableIconClassName} />
                                }
                              />
                            </div>
                          </TableData>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </FetchHandlerWrapper>
      </div>
      <Slideover
        open={open}
        toggle={setOpen}
        title={
          carStatus === 'NEWCAR'
            ? 'Fahrzeuge hinzufügen'
            : selectedCar
            ? `${selectedCar.brand} ${selectedCar.name}`
            : 'Edit'
        }
        subTitle={
          carStatus === 'NEWCAR'
            ? 'Hier können Sie weitere Fahrzeuge hinzufügen.'
            : undefined
        }
        style={{
          subtitleStyle: carStatus === 'NEWCAR' ? 'text-white' : undefined,
          titleStyle: carStatus === 'NEWCAR' ? 'text-white' : undefined,
          closeIconColor:
            carStatus === 'NEWCAR' ? 'text-white' : 'text-gray-900',
          headerStyle:
            carStatus === 'NEWCAR' ? 'bg-orange-500' : 'bg-white border-b-2',
        }}>
        {carStatus === 'NEWCAR' ? (
          <ChangeCar isNewCar setOpen={setOpen} handleAddCar={handleAddCar} />
        ) : (
          <ChangeCar
            setOpen={setOpen}
            handleAddCar={handleAddCar}
            car={selectedCar}
          />
        )}
      </Slideover>
      <Modal
        type="warning"
        hasIcon
        setOpen={setOpenModal}
        open={openModal}
        title={'Fahrzeug löschen'}
        subtile={
          'Möchten Sie das Fahrzeug wirklich löschen? Alle Reservierung werden dadurch gelöscht'
        }>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => (selectedCar ? handleDelete(selectedCar?.id) : null)}
            label={'Fahrzeug löschen'}
            type="primary"
            className={classNames(
              false ? '' : 'focus:ring-red-500 bg-red-600 hover:bg-red-700',
              'w-full justify-center focus:outline-none focus:ring-2 focus:ring-offset-2',
            )}
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            label="Abbrechen"
            type="default"
            className="w-full justify-center hover:bg-gray-50 hover:text-black"
          />
        </div>
      </Modal>
      <NotificationMessage
        isError={isCSVuploadError}
        title={notificationTextBasedOnCarStatus[carStatus].title}
        subTitle={notificationTextBasedOnCarStatus[carStatus].subtitle}
        notify={showNotification}
        setShow={setShow}
      />
    </div>
  );
};

export default Vehicles;
