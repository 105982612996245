/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useAuthContext } from '../hooks';
import {
  StartPage,
  ScanToMailPage,
  CheckIdPage,
  AdminPannel,
  SearchPage,
  Privacy,
} from '../components/screens';
import {
  requestFirebaseNotificationPermission,
  // sendTokenToServer,
} from '../firebaseInit';
import VehiclesRoute from '../components/screens/vehicles';
import { AbilityContext, ability } from '../auth';
//@ts-ignore
import { ActionCableProvider } from 'react-actioncable-provider';
import config from 'shared/config';
//@ts-ignore
// import actionCable from 'actioncable';

// const CableApp = {};
// //@ts-ignore
// CableApp.cable = actionCable.createConsumer('ws://localhost:28080/cable?ws_token=oGC7DHErCDvuKKjsxX3KxVen')
// //@ts-ignore
// const ActionCableContext = createContext();

export default function PrivateRoute() {
  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const localStorageToken = window.localStorage.getItem('token');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const parsedToken = localStorageToken
    ? JSON.parse(localStorageToken)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  // let { url } = useRouteMatch();

  const token = auth ? auth.token : undefined;
  const combinedToken = token ? token : parsedToken;
  //@ts-ignore
  // console.log('combinedToken', (combinedToken));
  useEffect(() => {
    async function getFirebaseToken() {
      try {
        await requestFirebaseNotificationPermission(combinedToken);
      } catch (e) {
        console.log('railed premision', e);
      }
    }
    if (combinedToken) {
      getFirebaseToken();
    }
  }, []);
  // useEffect(() => {
  //   if (user && combinedToken) {
  //     history.replace('/dashboard');
  //   }
  // }, [user, combinedToken]);

  return (
    <Route
      render={({ location }) =>
        user && combinedToken ? (
          //@ts-ignore
          <AbilityContext.Provider value={ability(user)}>
            <Switch>
              <Route exact path="/dashboard">
                <StartPage {...user} />
              </Route>
              <Route exact path="/scans">
                <ScanToMailPage />
              </Route>
              <Route path={'/privacy'}>
                <Privacy />
              </Route>
              <Route path={'/vehicles'}>
                <VehiclesRoute {...user} />
              </Route>
              <Route path={'/id_check'}>
                {/* @ts-ignore */}
                <ActionCableProvider
                  url={`${config.socketUrl}cable?ws_token=${user.ws_token}`}>
                  <CheckIdPage {...user} />
                </ActionCableProvider>
              </Route>
              <Route path={'/setting_page'}>
                <AdminPannel />
              </Route>
              <Route path={'/search'} component={SearchPage} />
              {/* <Route path="/test.html" render={() => Camera} /> */}
              <Redirect
                to={{
                  pathname: '/dashboard',
                  state: { from: '/' },
                }}
                exact
              />
              {/* <Route path="*">{() => <h1>NOT found page</h1>}</Route> */}
            </Switch>
          </AbilityContext.Provider>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
