import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, ExclamationIcon } from '@heroicons/react/outline';
import { classNames } from '../../styles/utils';

type ModalProps = {
  title?: any;
  subtile?: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  hasIcon?: boolean;
  children?: ReactNode;
  type?: 'success' | 'warning';
  planDialgo?: boolean;
};
export default function Modal({
  title,
  subtile,
  open,
  setOpen,
  hasIcon = false,
  type = 'success',
  children,
  planDialgo = false,
}: ModalProps) {
  return (
    <Transition.Root show={open} as={Fragment}>
      {/* @ts-ignore */}
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={open}
        onClose={() => null}>
        {open && (
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Dialog.Overlay
                as="div"
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                onClick={() => null}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <div
                className={classNames(
                  planDialgo
                    ? 'md:max-w-3xl xl:overflow-hidden overflow-scroll mx-auto'
                    : 'sm:max-w-sm overflow-hidden px-4',
                  'inline-block align-bottom bg-white rounded-lg  pt-5 pb-4 text-left  shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:w-full sm:p-6',
                )}>
                {!planDialgo && (
                  <div>
                    {hasIcon &&
                      (type === 'success' ? (
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                          <CheckIcon
                            className="h-6 w-6 text-green-600"
                            aria-hidden="true"
                          />
                        </div>
                      ) : (
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                          <ExclamationIcon
                            className="h-6 w-6 text-red-600"
                            aria-hidden="true"
                          />
                        </div>
                      ))}
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      {subtile && (
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">{subtile}</p>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {children}
              </div>
            </Transition.Child>
          </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}
