// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { action, observable } from 'mobx';

/* {
keeping MObx implimentation for now just to compare how to do stuff 
and check the performance 
  
}
*/
type IImageSrc = {
  type?: string;
  url: string;
  height: number;
  width: number;
};
class UIStore {
  @observable token: string | null = null;
  @observable imageSrcMobileIDCheck: Array<IImageSrc> = [];
  @observable imagesBeforeCrop: Array<IImageSrc> = [];
  @observable log: any | null = null;
  @action setToken(token: string) {
    this.token = token;
  }

  @action setImages(timeStamp: number) {
    let lastCroppedImage = this.imagesBeforeCrop.filter(
      (imageObject) =>
        //@ts-ignore
        imageObject.timeStamp >= timeStamp,
    );

    // console.log('lastCroppedImage', lastCroppedImage);
    // this.log = imageSrc;
    this.imageSrcMobileIDCheck = [
      lastCroppedImage[0],
      ...this.imageSrcMobileIDCheck,
    ];
  }

  //we use this action to selected the final image the user cropped
  // because when user remove the cropp around the id framee it creates a new image
  @action setCropedImage(imageSrc: any) {
    // console.log('imageSrcstore', imageSrc);
    // this.log = imageSrc;
    this.imagesBeforeCrop = [imageSrc, ...this.imagesBeforeCrop];
  }

  // reset the store when user submit images
  @action restImages() {
    this.imageSrcMobileIDCheck = [];
  }
}

export default UIStore;
