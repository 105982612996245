import { useState } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import {
  InformationCircleIcon,
  // KeyIcon,
  DocumentIcon,
  SupportIcon,
  LockClosedIcon,
} from '@heroicons/react/outline';

import { VerticalSteps } from '../../shared';
import PrivacyPages from './privacy_pages';
import HelpAndContact from './help_contact';
const subNavigation = [
  {
    name: 'Hilfe und Kontakt',
    description: 'Kontaktformular und Kontaktinformationen.',
    icon: SupportIcon,
    id: 'contact',
    link: '/privacy',
  },
  {
    name: 'Über Checktastic',
    description: 'Aktuelle Softwareinformationen.',
    icon: InformationCircleIcon,
    id: 'about',
    link: '/privacy/about',
  },
  {
    name: 'Datenschutz',
    description: 'Alles rund um den Datenschutz.',
    icon: LockClosedIcon,
    id: 'datenschutz',
    link: '/privacy/datenschutz',
  },
  {
    name: 'Impressum',
    description: 'Kleingedrucktes.',
    icon: DocumentIcon,
    id: 'impressum',
    link: '/privacy/impressum',
  },
];

export default function Privacy() {
  const [openTab, setOpenTab] = useState<string | number>('contact');
  let { path } = useRouteMatch();

  return (
    <VerticalSteps
      openTab={openTab}
      setOpenTab={setOpenTab}
      subNavigation={subNavigation}
      title="Mehr">
      <div className="p-8 h-full w-screen lg:w-auto lg:overflow-auto md:max-w-2xl lg:max-w-none">
        <Switch>
          <Route exact path={path}>
            <HelpAndContact />
          </Route>
          <Route exact path={`${path}/:itemId`}>
            <PrivacyPages />
          </Route>
        </Switch>
      </div>
    </VerticalSteps>
  );
}
