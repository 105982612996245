//@ts-ignore
import Select, { ReactSelectProps, components } from 'react-select';

const colourStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? null
        : isFocused
        ? null
        : null,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : data.color,
      // cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  control: (styles: any, state: any) => ({
    ...styles,
    boxShadow: 0,
    border: 0,
    borderColor: state.isFocused ? '#fff' : '#fff',
    '&:hover': {
      borderColor: state.isFocused ? 'rgb(249, 115, 22)' : '#fff',
    },
  }),
  input: (styles: any) => ({ ...styles, border: 0 }),
  // placeholder: styles => ({ ...styles, ...dot() }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const Option = (props: any) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="relative flex items-start align-top">
          <div className="flex items-center h-5">
            <input
              id={props.innerProps.id ? props.innerProps.id : ''}
              aria-describedby="comments-description"
              name={props.value ? props.value : ''}
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}
              className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
            />
          </div>
          <div className="ml-3 text-sm">
            <label
              htmlFor={props.label ? props.label : ''}
              className="font-normal text-black capitalize text-xs">
              {props.label}
            </label>
          </div>
        </div>
      </components.Option>
      <div>
        {props.data.hasBtn ? (
          <div className="felx pt-2 pb-2  border-t mr-1">
            <div className="flex justify-end">
              <button
                className="justify-self-end px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                onClick={props.data.onClick}>
                Speichern
              </button>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const MultiSelectTableFilter: React.FC<ReactSelectProps> = ({
  options,
  name,
  placeHolder,
  handleChange,
  value,
  menuIsOpen,
}) => {
  return (
    <Select
      options={options}
      closeMenuOnSelect={false}
      isMulti
      hideSelectedOptions={false}
      backspaceRemovesValue={false}
      menuIsOpen={menuIsOpen}
      controlShouldRenderValue={false}
      styles={colourStyles}
      components={{ Option, IndicatorSeparator: () => null }}
      name={name}
      placeholder={placeHolder}
      value={value}
      onChange={handleChange}
      theme={(theme: any) => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary25: '#f97315ad',
          primary: 'rgb(249, 115, 22)',
          primary75: '#f97315c9',
          primary50: '#f973159e',
        },
      })}
      //  onBlur={field.onBlur}
    />
  );
};

export default MultiSelectTableFilter;
