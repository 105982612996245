import React from 'react';
import { Notifier } from '@airbrake/browser';
import ErrorPage from './error_page';

type ErrorBoundaryProps = {
  children: any;
};
type ErrorBoundaryState = {
  hasError: boolean; // like this
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  airbrake: any;
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = new Notifier({
      projectId: 362733,
      projectKey: 'a96b9d9c4b0382933d4b7dec07cd3eb8',
    });
  }

  componentDidCatch(error: any, info: any) {
    console.log('error from boundary', error);
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    this.airbrake.notify({
      error: error,
      params: { info: info },
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage />;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
