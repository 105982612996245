import { useState } from 'react';
import { SelectList } from '../../styles/components';

import { classNames } from '../../styles/utils';

export type currentTabProp = {
  id: number | null;
  status: string | string[];
};

type TabsProp = {
  id: number;
  status: string | string[];
  name: string;
  hasCounter?: boolean;
  icon?: any;
};

const Tabs = ({
  current,
  onSelect,
  counter,
  tabs,
}: {
  onSelect: ({ id, status }: currentTabProp) => void;
  current: number | null;
  counter: number;
  tabs: TabsProp[];
}) => {
  const [selected, setSelected] = useState();
  return (
    <div>
      <div className="sm:hidden bg-white">
        {/* Mobile select menu */}
        <SelectList
          items={tabs}
          selected={selected}
          defaultValue={'Alle'}
          onSelect={(value: any) => {
            setSelected(value);
            onSelect({
              id: value.id,
              status: value.status,
            });
          }}
        />
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div className="flex items-center" key={tab.id}>
                <p
                  onClick={() =>
                    onSelect({
                      id: tab.id,
                      status: tab.status,
                    })
                  }
                  key={tab.name}
                  className={classNames(
                    current === tab.id
                      ? 'border-orange-500 text-orange font-bold'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                    'flex items-center whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer',
                  )}
                  aria-current={tab.id ? 'page' : undefined}>
                  {tab.icon && (
                    <tab.icon
                      className={classNames(
                        current === tab.id
                          ? 'text-orange-500'
                          : 'text-gray-400',
                        'flex-shrink-0 mr-1.5 h-5 w-5 ',
                      )}
                      aria-hidden="true"
                    />
                  )}
                  {tab.name}
                </p>
                {tab.hasCounter && counter > 0 && (
                  <span className="flex flex-shrink-0 px-2 py-0.5 items-center  rounded-full text-sm font-sm bg-orange-200 text-orange-800">
                    {counter}
                  </span>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
