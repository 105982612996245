import { useEffect, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { useStores } from 'shared';
import * as yup from 'yup';
import Spinner from '../../../styles/icons/spinner';
import { Field, FieldProps } from 'formik';
import { Form } from '../../shared';

import { useAuthContext } from '../../../hooks';

const styles = {
  inputField: `form-control block w-full px-4 py-4 text-sm text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`,
};

const validationSchema = yup.object({
  token: yup
    .number()
    .required('Bitte geben Sie den Code aus der E-Mail ein.')
    .typeError('Der Bestätigungscode darf nur aus Zahlen bestehen.'),
});
const Validate2faPage = () => {
  let history = useHistory();

  const [errorFormSUbmit, setError] = useState(null);
  const { ui } = useStores();
  const localStorageToken = window.localStorage.getItem('token') || undefined;
  // console.log('Validate2faPageToken', localStorageToken);
  const parsedObje = localStorageToken
    ? JSON.parse(localStorageToken)
    : undefined;

  const token = ui.token ? ui.token : parsedObje;
  // console.log('Validate2faPage query', localStorageToken);
  // let { url } = useRouteMatch();

  const { signin, error } = useAuthContext();

  const validate2fa = async ({ token }: { token: number }) => {
    // console.log('tokeeen', token);
    try {
      if (token) {
        await signin(undefined, undefined, token);
        // we clear the history to avoid the user to go back to the 2facauth page, because then if the user click browserback btn then the token will be removed.
        window.history.replaceState(null, '', '/');
        history.push('/dashboard');
      }
    } catch (e) {
      //@ts-ignore[]
      if (e?.response?.data?.error) {
        let errorMessage =
          //@ts-ignore
          e?.response?.data?.error?.two_k_challenge_code[0] === 'wrong code'
            ? 'Bestätigungscode ist falsch.'
            : //@ts-ignore
              e?.response?.data?.error?.two_k_challenge_code[0];

        setError(errorMessage);
      }

      // console.log(
      //   '======error token from validate page',
      //   //@ts-ignore
      //   e?.response?.data?.error?.two_k_challenge_code[0],
      //   error,
      // );
    }
  };

  // useEffect(() => {
  //   if (
  //     error &&
  //     //@ts-ignore
  //     error?.response?.status === 307 &&
  //     //@ts-ignore
  //     error?.response?.headers.authorization
  //   ) {
  //     console.log(
  //       'set token to storage=======',
  //       //@ts-ignore
  //       error?.response?.headers.authorization,
  //     );
  //     //@ts-ignore
  //     ui.setToken(error ? error?.response?.headers.authorization : null);
  //     localStorage.setItem(
  //       `token`,
  //       //@ts-ignore
  //       window.JSON.stringify(error?.response?.headers.authorization),
  //     );
  //     setToken(error?.response?.headers.authorization);
  //   }
  // }, [error, isLoading, ui]);
  const onBackClick = () => {
    window.history.replaceState(null, '', '/login');
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };
  useEffect(() => {
    if (!token && !error?.response?.headers?.authorizationa) {
      history.replace('/login');
    }
    // else {
    //   history.replace('/dashboard');
    // }
    // return () => {
    //   console.log('clean up');
    //   localStorage.removeItem('token');
    // };
  }, [history, token, error]);

  useEffect(() => {
    // call back next line to remove the token if the user decide to leave the 2facauth with closing the browser window or if the user decide to leave the 2facauth page with clicking the browser back btn
    // if we did't clean the storage then we got the data for the user from the first ettempt to login.
    const removeTokenWhenleaveThepage = (event: any) => {
      console.log('removeTokenWhenleaveThepage', event);
      window.history.replaceState(null, '', '/login');
      // console.log('clean up');
      event.preventDefault();
      // window.alert('unload');
      localStorage.removeItem('token');
      localStorage.removeItem('user');

      return '';
    };

    // adding popstate event lister did't work as expected so we use the  window.onpopstate
    window.onpopstate = (e) => {
      removeTokenWhenleaveThepage(e);
      // }
    };

    // window.addEventListener('popstate', removeTokenWhenleaveThepage);
    window.addEventListener('beforeunload', removeTokenWhenleaveThepage);
    return () => {
      console.log('remove events');
      // window.removeEventListener('popstate', removeTokenWhenleaveThepage);
      window.removeEventListener('beforeunload', removeTokenWhenleaveThepage);
      window.onpopstate = null;
    };
  }, []);

  return (
    <section className="bg-gray-50 min-h-screen grid place-items-center">
      <div className="w-full">
        <h1 className="text-4xl lg:text-6xl text-center font-medium text-ct-yellow-600 mb-4">
          Willkommen zurück
        </h1>
        <h2 className="text-lg text-center mb-4 text-gray-900 font-medium">
          Anmeldung verifizieren
        </h2>
        <div className="max-w-md w-full mx-auto overflow-hidden shadow-lg bg-white rounded-2xl p-8 space-y-5">
          <Form
            initialValues={{
              token: '',
            }}
            validationSchema={validationSchema}
            submitForm={validate2fa}
            // className="max-w-md w-full mx-auto overflow-hidden shadow-lg bg-ct-dark-200 rounded-2xl p-8 space-y-5"
            // className="space-y-6"
          >
            <h2 className="text-center text-3xl font-semibold text-[#142149]">
              Zwei-Faktor-Authentifizierung
            </h2>
            <p className="text-center text-sm">
              Öffnen Sie die Bestätigungs-E-Mail und geben Sie den
              Bestätigungscode hier ein, den Sie erhalten haben.
            </p>

            <Field name="token">
              {({ field, meta }: FieldProps) => (
                <Form.CustomInputItem
                  type="text"
                  className={styles.inputField}
                  field={field}
                  meta={meta}
                />
              )}
            </Field>
            {/* <p className="mt-2  text-red-600">
              {errorFormSUbmit ? errorFormSUbmit : null}
            </p> */}
            {errorFormSUbmit ? (
              <div className="text-red-400 text-sm font-semibold">
                {errorFormSUbmit}
              </div>
            ) : null}
            <button
              disabled={false}
              type="submit"
              className="w-full  justify-center px-4 py-2 text-base text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white inline-flex items-center rounded-md shadow-sm border border-transparent font-medium">
              {false ? <Spinner /> : 'Anmelden'}
            </button>
            <span className="block text-center">
              <Link
                to=""
                onClick={onBackClick}
                className="text-gray-800 font-semibold">
                zurück
              </Link>
            </span>
          </Form>
        </div>
      </div>
    </section>
  );
};

export default Validate2faPage;
