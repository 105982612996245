import { useState } from 'react';
import { useAbility } from '@casl/react';
import { PlusIcon } from '@heroicons/react/outline';
import {
  Button,
  Slideover,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableData,
} from '../../../../styles/components';
import {
  Query,
  BranchOfficesResponse,
  BranchOfficesResponseItem,
} from 'shared';
import ChangeBranchOffice from './add_new_branchoffice';
import { AbilityContext } from '../../../../auth';
// import { classNames } from '../../../styles/utils';
// import { dataFormat } from '../../../utils';
import { FetchHandlerWrapper, NotificationMessage } from '../../../shared';

export const columns = ['NAME', 'MARKE', 'ORT', 'STRASSE', 'AKTIONEN'];

type BranchOfficesProp = {
  headquarterId: number;
};

const BranchOffices: React.FunctionComponent<BranchOfficesProp> = ({
  headquarterId,
}) => {
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [newBranch, setNewBranch] = useState(false);
  const [selectedBranch, setselectedBranch] =
    useState<BranchOfficesResponseItem>();
  const [showNotification, setShow] = useState(false);
  //@ts-ignore
  const ability = useAbility(AbilityContext);
  // let { url } = useRouteMatch();
  const { FetchBranchOfficesData, SubmitDataToserver, UpdateUserData } =
    Query();

  const {
    isLoading,
    isError,
    data: branchOfficesData,
    isFetching,
    refetch,
  } = FetchBranchOfficesData({
    key: 'branch-offices-index',
    page: page,
  });

  const { mutateAsync } = SubmitDataToserver({
    url: 'dealer/branch_offices',
  });
  const { mutateAsync: updateBranch } = UpdateUserData({
    url: `dealer/branch_offices/${selectedBranch?.id}`,
  });
  const handleRowSelect = (rowValue: BranchOfficesResponseItem) => {
    setselectedBranch(rowValue);
  };

  const handleAddBranch = async (values: any, formikBag: any) => {
    const { setErrors } = formikBag;
    const formData = {
      dealer_branch_office: {
        headquarter_id: headquarterId,
        name: values.name,
        tax_number: values.tax_number,
        address_attributes: {
          id:
            selectedBranch &&
            selectedBranch.address &&
            selectedBranch.address.id,
          street: values.street,
          house: values.house,
          postal_code: values.postal_code,
          city: values.city,
        },
        vehicle_brand_ids: values.vehicle_brands,
      },
    };

    if (newBranch) {
      // console.log('formData', formData);
      try {
        await mutateAsync(formData);
        refetch();
        setOpen(false);
        setShow(true);
      } catch (e) {
        //@ts-ignore
        const errorObject = e.response && e.response.data.errors;
        const fieldName = Object.keys(errorObject)[0];
        const value = Object.values(errorObject)[0];

        if (errorObject && fieldName && value) {
          setErrors({
            error: `${fieldName} ${value}`,
          });
        } else {
          setErrors({
            error: 'Die Filiale wurde nicht erstelt',
          });
        }
      }
    } else {
      try {
        setNewBranch(false);
        await updateBranch(formData);
        refetch();
        setOpen(false);
        setShow(true);
      } catch (e) {
        setErrors({
          error: 'error updating',
        });
      }
    }

    // refetch()
    // console.log('value', value);
  };

  return (
    <div className="pt-5">
      <div className="mt-1 flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div className="flex">
          <h2 className="text-2xl font-bold leading-5 text-gray-900 sm:text-3xl sm:truncate">
            Filialen
          </h2>
        </div>

        {ability.can('create', 'branch_offices') && (
          <div className="mt-2 flex lg:mt-0 lg:ml-4 lg:w-96">
            <div className="flex flex-shrink w-full lg:justify-end">
              <Button
                startIcon={<PlusIcon className="w-5 h-5 mr-3" />}
                type="primary"
                size="small"
                onClick={() => {
                  setOpen(!open);
                  setNewBranch(true);
                }}
                label="Filiale hinzufügen"
                className="lg:ml-3"
              />
            </div>
          </div>
        )}
      </div>
      {/* <div className="my-8 py-8 rounded-md shadow-md bg-white md:flex  md:justify-between px-5">
        <div className="flex">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Logo
          </h2>
        </div>

        <div className="mt-4 flex md:mt-0 md:ml-4 md:w-96 sm:w-full">
          <div className="flex flex-shrink w-full justify-end">
            <Button
              type="default"
              size="small"
              onClick={() => null}
              label="Einstellungen"
            /> 
          </div>
        </div>
      </div> */}
      <div
        className={`mt-2 lg:mt-5 flex flex-col overflow-x-auto overflow-y-hidden lg:overflow-hidden`}>
        <FetchHandlerWrapper
          isError={isError}
          isLoading={isLoading}
          data={branchOfficesData ? branchOfficesData : undefined}>
          {(data: BranchOfficesResponse) => (
            <TableContainer>
              <Table
                isFetching={isFetching}
                hasPagination
                setPage={setPage}
                currentpage={page}
                {...data.pagination}>
                <TableHead>
                  {columns.map((item: string) => (
                    <TableCell key={item}>{item}</TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {data &&
                    //@ts-ignore
                    data.records.map((data) => {
                      // const { created_at } = data.contract_short;
                      const {
                        name,
                        id,
                        address,

                        vehicle_brands,
                      } = data;
                      // const { street, postal_code, city } = address;

                      // const { brand, kind, name } = data.vehicle_short;
                      return (
                        <TableRow key={id} action={() => handleRowSelect(data)}>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            {name}
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            <div className="flex flex-wrap">
                              {vehicle_brands.map((brand, index) => (
                                <p
                                  key={brand.id}
                                  className="font-semibold py-1 mr-1">
                                  {brand.name}
                                  {vehicle_brands.length - 1 === index
                                    ? ''
                                    : ','}
                                </p>
                              ))}
                            </div>
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            {address ? address.postal_code : ''} -{' '}
                            {address && address.city}
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            {address ? address.street : '-'}
                          </TableData>

                          <TableData
                            className={`text-right font-medium w-3/12`}>
                            <Button
                              disabled={
                                !ability.can('update', 'branch_offices')
                              }
                              onClick={() => {
                                setOpen(!open);
                                setNewBranch(false);
                              }}
                              // disabled={action?.disable}
                              label={'Bearbeiten'}
                              //@ts-ignore
                              type={
                                !ability.can('update', 'branch_offices')
                                  ? 'disabled'
                                  : 'default'
                              }
                              size="small"
                              className="md:w-11/12 flex justify-center"
                            />
                          </TableData>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </FetchHandlerWrapper>
      </div>
      <Slideover
        open={open}
        toggle={setOpen}
        title={
          newBranch
            ? 'Filiale hinzufügen'
            : selectedBranch
            ? `${selectedBranch.name}`
            : 'Edit'
        }
        subTitle={
          newBranch ? 'Hier können Sie weitere Filialen hinzufügen.' : undefined
        }
        style={{
          subtitleStyle: newBranch ? 'text-white' : undefined,
          titleStyle: newBranch ? 'text-white' : undefined,
          closeIconColor: newBranch ? 'text-white' : 'text-gray-900',
          headerStyle: newBranch ? 'bg-orange-500' : 'bg-white border-b-2',
        }}>
        {newBranch ? (
          <ChangeBranchOffice
            setOpen={setOpen}
            handleAddBranch={handleAddBranch}
            isNewBranch
          />
        ) : (
          <ChangeBranchOffice
            branchOffice={selectedBranch}
            setOpen={setOpen}
            handleAddBranch={handleAddBranch}
          />
        )}
      </Slideover>
      <NotificationMessage
        title={
          newBranch
            ? 'Standort erfolgreich hinzugefügt.'
            : 'Standorte wurde bearbeitet'
        }
        subTitle={
          newBranch
            ? 'Sie können nun in der Nutzerverwaltung diesem Standort weitere Nutzer hinzufügen.'
            : ''
        }
        notify={showNotification}
        setShow={setShow}
      />
    </div>
  );
};

export default BranchOffices;
