import { ReactNode } from 'react';

type ContactStatusProp = {
  title: string;
  status: {
    status: string;
    label: string;
    contractStaus: {
      border: string;
      bg: string;
    };
  };
  children: ReactNode;
};

const ContactStatus = ({ title, status, children }: ContactStatusProp) => {
  const { status: bgTex, label, contractStaus } = status;
  return (
    <div
      className={`${contractStaus.border} border-2 ${bgTex} rounded-lg py-6`}>
      <div className="text-center items-center flex flex-col justify-items-center">
        <h3 className="text-bold leading-6 font-medium text-gray-700">
          {title}
        </h3>
        <div className="mt-2 sm:mt-3 xl:w-8/12">
          <p
            className={`${contractStaus.bg} w-full rounded-full border border-transparent shadow-sm px-4 py-2 text-bold font-medium text-white sm:text-sm`}>
            {label}
          </p>
        </div>
        <div className="mt-3 items-center flex flex-col justify-center">
          {children}
        </div>
      </div>
    </div>
  );
};

export default ContactStatus;
