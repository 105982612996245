import firebase from 'firebase/app';
import 'firebase/messaging';
import axios from 'axios';
import ApiConfig from 'shared/config';

const config = {
  apiKey: 'AIzaSyAgSN1671BBxbaAUjr2l2hEsDg-r4FEswE',
  authDomain: 'check-n-track-2bea6.firebaseapp.com',
  projectId: 'check-n-track-2bea6',
  storageBucket: 'check-n-track-2bea6.appspot.com',
  messagingSenderId: '399766864337',
  appId: '1:399766864337:web:787f2706658a187727a0f0',
};
// console.log('intifafafkahflhahf');

firebase.initializeApp(config);
const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export const requestFirebaseNotificationPermission = (userToken: any) =>
  new Promise((resolve, reject) => {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        if (messaging) {
          messaging
            .getToken()
            // .then(() => {
            //   console.log('Have premission');
            //   return messaging.getToken();
            // })
            .then((firebaseToken) => {
              // console.log('get broswer premistion firebaseToken', firebaseToken);
              resolve(firebaseToken);
              sendTokenToServer(firebaseToken, userToken);
            })
            .catch((err) => {
              // console.log('error broswer premistion', err);
              reject(err);
            });
        }
      } else {
        console.log('firebase pr mission denied');
      }
    });
  });

export const onMessageListener = () =>
  new Promise((resolve, reject) => {
    if (messaging) {
      messaging.onMessage((payload) => {
        resolve(payload);
      });
    } else {
      reject('firebase message unsuported in this browser');
    }
  });

export const sendTokenToServer = async (token: any, userToken: any) => {
  // console.log('sendTokenToServer -> token: ', token);
  // console.log('request ', userToken);
  try {
    //@ts-ignore
    const { data } = axios.post(
      `${ApiConfig.baseURL}api/push_services/register`,
      {
        push_token: token,
        provider: 'browser',
      },
      {
        headers: {
          Authorization: userToken,
        },
      },
    );

    return data;
  } catch (e) {
    console.log('error sending token to server', e);
  }
};
