export interface privacyDataPro {
  id: string;
  title: string;
  __html: string;
  __html2?: string;
  subtitle?: string;
}

export const PrivacyStaticData: privacyDataPro[] = [
  {
    id: 'datenschutz',
    title: 'Datenschutzerklärung',
    __html: `
    <h2 style="margin-top: 24px; margin-bottom: 24px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 24px"><strong>1. Datenschutz auf einen Blick</strong></span>
  </h2>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Allgemeine Hinweise</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Die folgenden Hinweise geben einen einfachen &Uuml;berblick dar&uuml;ber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Ausf&uuml;hrliche Informationen zum
      Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgef&uuml;hrten Datenschutzerkl&auml;rung.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Datenerfassung auf dieser Website</strong></span>
  </h3>
  <h4 style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0); font-size: 16px"><strong>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser Website?</strong></span>
  </h4>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Abschnitt &bdquo;Hinweis zur Verantwortlichen Stelle&ldquo; in dieser Datenschutzerkl&auml;rung entnehmen.</span>
  </p>
  <h4 style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0); font-size: 16px"><strong>Wie erfassen wir Ihre Daten?</strong></span>
  </h4>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.&nbsp;B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</span></p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.&nbsp;B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese
      Website betreten.</span
    >
  </p>
  <h4 style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0); font-size: 16px"><strong>Wof&uuml;r nutzen wir Ihre Daten?</strong></span>
  </h4>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gew&auml;hrleisten. Andere Daten k&ouml;nnen zur Analyse Ihres Nutzerverhaltens verwendet werden.</span>
  </p>
  <h4 style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0); font-size: 16px"><strong>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</strong></span>
  </h4>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Sie haben jederzeit das Recht, unentgeltlich Auskunft &uuml;ber Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung oder L&ouml;schung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
      Datenverarbeitung erteilt haben, k&ouml;nnen Sie diese Einwilligung jederzeit f&uuml;r die Zukunft widerrufen. Au&szlig;erdem haben Sie das Recht, unter bestimmten Umst&auml;nden die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
      Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde zu.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Hierzu sowie zu weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit an uns wenden.</span></p>
  <h2 style="margin-top: 24px; margin-bottom: 24px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 24px"><strong>2. Hosting</strong></span>
  </h2>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</span></p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Hetzner</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Anbieter ist die Hetzner Online GmbH, Industriestr. 25, 91710 Gunzenhausen (nachfolgend Hetzner).</span></p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Details entnehmen Sie der Datenschutzerkl&auml;rung von Hetzner: <a href="https://www.hetzner.com/de/rechtliches/datenschutz" target="_blank" rel="noopener noreferrer">https://www.hetzner.com/de/rechtliches/datenschutz</a>.</span>
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Die Verwendung von Hetzner erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer m&ouml;glichst zuverl&auml;ssigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf
      Grundlage von Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endger&auml;t des Nutzers (z.&nbsp;B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit
      widerrufbar.</span
    >
  </p>
  <h4 style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0); font-size: 16px"><strong>Auftragsverarbeitung</strong></span>
  </h4>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Wir haben einen Vertrag &uuml;ber Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gew&auml;hrleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach
      unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.</span
    >
  </p>
  <h2 style="margin-top: 24px; margin-bottom: 24px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 24px"><strong>3. Allgemeine Hinweise und Pflicht&shy;informationen</strong></span>
  </h2>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Datenschutz</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerkl&auml;rung.</span>
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir erheben und wof&uuml;r wir sie
      nutzen. Sie erl&auml;utert auch, wie und zu welchem Zweck das geschieht.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.&nbsp;B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht m&ouml;glich.</span>
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Hinweis zur verantwortlichen Stelle</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Die verantwortliche Stelle f&uuml;r die Datenverarbeitung auf dieser Website ist:</span></p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">24relations marketing solutions gmbh<br />Rennweg 60<br />56626 Andernach</span>
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Telefon: 02632 4988830<br />E-Mail: info@24relations.de</span>
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Verantwortliche Stelle ist die nat&uuml;rliche oder juristische Person, die allein oder gemeinsam mit anderen &uuml;ber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.&nbsp;B. Namen, E-Mail-Adressen o. &Auml;.) entscheidet.</span>
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Speicherdauer</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Soweit innerhalb dieser Datenschutzerkl&auml;rung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck f&uuml;r die Datenverarbeitung entf&auml;llt. Wenn Sie ein berechtigtes L&ouml;schersuchen geltend machen oder eine Einwilligung zur
      Datenverarbeitung widerrufen, werden Ihre Daten gel&ouml;scht, sofern wir keine anderen rechtlich zul&auml;ssigen Gr&uuml;nde f&uuml;r die Speicherung Ihrer personenbezogenen Daten haben (z.&nbsp;B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
      L&ouml;schung nach Fortfall dieser Gr&uuml;nde.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdr&uuml;cklichen
      Einwilligung in die &Uuml;bertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung au&szlig;erdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endger&auml;t (z.&nbsp;B. via
      Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zus&auml;tzlich auf Grundlage von &sect; 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserf&uuml;llung oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich,
      verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage
      unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. &Uuml;ber die jeweils im Einzelfall einschl&auml;gigen Rechtsgrundlagen wird in den folgenden Abs&auml;tzen dieser Datenschutzerkl&auml;rung informiert.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Datenschutz&shy;beauftragter</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Wir haben einen Datenschutzbeauftragten benannt.</span></p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">DSB Rainer Faldey<br />Datenschutzbeauftragter GDDcert. EU<br />Im Handwerkerhof 1<br />54338 Schweich</span>
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Telefon: 06502 6019061<br />E-Mail: rainer.faldey@datenschutz-trier.com</span>
  </p>
  <h3 style="text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0)"><strong>Empf&auml;nger von personenbezogenen Daten</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Im Rahmen unserer Gesch&auml;ftst&auml;tigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist teilweise auch eine &Uuml;bermittlung von personenbezogenen Daten an diese externen Stellen erforderlich. Wir geben personenbezogene Daten nur dann an externe Stellen weiter, wenn
      dies im Rahmen einer Vertragserf&uuml;llung erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z.&nbsp;B. Weitergabe von Daten an Steuerbeh&ouml;rden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben oder wenn eine sonstige Rechtsgrundlage
      die Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines g&uuml;ltigen Vertrags &uuml;ber Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag &uuml;ber gemeinsame Verarbeitung
      geschlossen.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Viele Datenverarbeitungsvorg&auml;nge sind nur mit Ihrer ausdr&uuml;cklichen Einwilligung m&ouml;glich. Sie k&ouml;nnen eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
      unber&uuml;hrt.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Widerspruchsrecht gegen die Datenerhebung in besonderen F&auml;llen sowie gegen Direktwerbung (Art. 21 DSGVO)</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GR&Uuml;NDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH F&Uuml;R EIN AUF
      DIESE BESTIMMUNGEN GEST&Uuml;TZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKL&Auml;RUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR K&Ouml;NNEN
      ZWINGENDE SCHUTZW&Uuml;RDIGE GR&Uuml;NDE F&Uuml;R DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN &Uuml;BERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUS&Uuml;BUNG ODER VERTEIDIGUNG VON RECHTSANSPR&Uuml;CHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH F&Uuml;R DAS PROFILING, SOWEIT ES MIT SOLCHER
      DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Beschwerde&shy;recht bei der zust&auml;ndigen Aufsichts&shy;beh&ouml;rde</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Im Falle von Verst&ouml;&szlig;en gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbeh&ouml;rde, insbesondere in dem Mitgliedstaat ihres gew&ouml;hnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutma&szlig;lichen Versto&szlig;es zu. Das Beschwerderecht
      besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Recht auf Daten&shy;&uuml;bertrag&shy;barkeit</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erf&uuml;llung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem g&auml;ngigen, maschinenlesbaren Format aush&auml;ndigen zu lassen. Sofern Sie die direkte &Uuml;bertragung der Daten an
      einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Auskunft, Berichtigung und L&ouml;schung</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder L&ouml;schung dieser
      Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten k&ouml;nnen Sie sich jederzeit an uns wenden.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Recht auf Einschr&auml;nkung der Verarbeitung</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Sie haben das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu k&ouml;nnen Sie sich jederzeit an uns wenden. Das Recht auf Einschr&auml;nkung der Verarbeitung besteht in folgenden F&auml;llen:</span>
  </p>
  <ul style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <li style="color: rgb(0, 0, 0)">
      Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, ben&ouml;tigen wir in der Regel Zeit, um dies zu &uuml;berpr&uuml;fen. F&uuml;r die Dauer der Pr&uuml;fung haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu
      verlangen.
    </li>
    <li style="color: rgb(0, 0, 0)">Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtm&auml;&szlig;ig geschah/geschieht, k&ouml;nnen Sie statt der L&ouml;schung die Einschr&auml;nkung der Datenverarbeitung verlangen.</li>
    <li style="color: rgb(0, 0, 0)">
      Wenn wir Ihre personenbezogenen Daten nicht mehr ben&ouml;tigen, Sie sie jedoch zur Aus&uuml;bung, Verteidigung oder Geltendmachung von Rechtsanspr&uuml;chen ben&ouml;tigen, haben Sie das Recht, statt der L&ouml;schung die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu
      verlangen.
    </li>
    <li style="color: rgb(0, 0, 0)">
      Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abw&auml;gung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen &uuml;berwiegen, haben Sie das Recht, die Einschr&auml;nkung der Verarbeitung Ihrer
      personenbezogenen Daten zu verlangen.
    </li>
  </ul>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschr&auml;nkt haben, d&uuml;rfen diese Daten &ndash; von ihrer Speicherung abgesehen &ndash; nur mit Ihrer Einwilligung oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen oder zum Schutz der Rechte
      einer anderen nat&uuml;rlichen oder juristischen Person oder aus Gr&uuml;nden eines wichtigen &ouml;ffentlichen Interesses der Europ&auml;ischen Union oder eines Mitgliedstaats verarbeitet werden.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>SSL- bzw. TLS-Verschl&uuml;sselung</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie daran, dass
      die Adresszeile des Browsers von &bdquo;http://&ldquo; auf &bdquo;https://&ldquo; wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist, k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten mitgelesen werden.</span></p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Widerspruch gegen Werbe-E-Mails</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle
      der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</span
    >
  </p>
  <h2 style="margin-top: 24px; margin-bottom: 24px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 24px"><strong>4. Datenerfassung auf dieser Website</strong></span>
  </h2>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Cookies</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Unsere Internetseiten verwenden so genannte &bdquo;Cookies&ldquo;. Cookies sind kleine Datenpakete und richten auf Ihrem Endger&auml;t keinen Schaden an. Sie werden entweder vor&uuml;bergehend f&uuml;r die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
      Endger&auml;t gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gel&ouml;scht. Permanente Cookies bleiben auf Ihrem Endger&auml;t gespeichert, bis Sie diese selbst l&ouml;schen oder eine automatische L&ouml;schung durch Ihren Webbrowser erfolgt.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Cookies k&ouml;nnen von uns (First-Party-Cookies) oder von Drittunternehmen stammen (sog. Third-Party-Cookies). Third-Party-Cookies erm&ouml;glichen die Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z.&nbsp;B. Cookies zur Abwicklung von
      Zahlungsdienstleistungen).</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren w&uuml;rden (z.&nbsp;B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies k&ouml;nnen zur Auswertung des Nutzerverhaltens oder zu
      Werbezwecken verwendet werden.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Cookies, die zur Durchf&uuml;hrung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erw&uuml;nschter Funktionen (z.&nbsp;B. f&uuml;r die Warenkorbfunktion) oder zur Optimierung der Website (z.&nbsp;B. Cookies zur Messung des Webpublikums) erforderlich sind
      (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung
      seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschlie&szlig;lich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und &sect; 25 Abs. 1 TTDSG); die Einwilligung ist
      jederzeit widerrufbar.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder generell ausschlie&szlig;en sowie das automatische L&ouml;schen der Cookies beim
      Schlie&szlig;en des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt sein.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Welche Cookies und Dienste auf dieser Website eingesetzt werden, k&ouml;nnen Sie dieser Datenschutzerkl&auml;rung entnehmen.</span></p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Server-Log-Dateien</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)">Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns &uuml;bermittelt. Dies sind:</span>
  </p>
  <ul style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <li style="color: rgb(0, 0, 0)">Browsertyp und Browserversion</li>
    <li style="color: rgb(0, 0, 0)">verwendetes Betriebssystem</li>
    <li style="color: rgb(0, 0, 0)">Referrer URL</li>
    <li style="color: rgb(0, 0, 0)">Hostname des zugreifenden Rechners</li>
    <li style="color: rgb(0, 0, 0)">Uhrzeit der Serveranfrage</li>
    <li style="color: rgb(0, 0, 0)">IP-Adresse</li>
  </ul>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px"><span style="color: rgb(0, 0, 0)">Eine Zusammenf&uuml;hrung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</span></p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website &ndash; hierzu m&uuml;ssen die Server-Log-Files erfasst werden.</span
    >
  </p>
  <h3 style="margin-top: 16px; margin-bottom: 16px; text-align: start; color: rgb(0, 0, 0)">
    <span style="color: rgb(0, 0, 0); font-size: 18px"><strong>Anfrage per E-Mail, Telefon oder Telefax</strong></span>
  </h3>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung
      weiter.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erf&uuml;llung eines Vertrags zusammenh&auml;ngt oder zur Durchf&uuml;hrung vorvertraglicher Ma&szlig;nahmen erforderlich ist. In allen &uuml;brigen F&auml;llen beruht die
      Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.</span
    >
  </p>
  <p style="text-align: start; color: rgb(0, 0, 0); font-size: medium; margin-bottom: 12px">
    <span style="color: rgb(0, 0, 0)"
      >Die von Ihnen an uns per Kontaktanfragen &uuml;bersandten Daten verbleiben bei uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung entf&auml;llt (z.&nbsp;B. nach abgeschlossener Bearbeitung Ihres Anliegens).
      Zwingende gesetzliche Bestimmungen &ndash; insbesondere gesetzliche Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.</span
    >
  </p>`,
    __html2: 'testing part 2 of decument',
  },
  {
    id: 'impressum',
    title: 'Impressum',
    __html: `<p dir="auto" data-sourcepos="1:1-1:14"><strong>Impressum:</strong></p>
    <p dir="auto" data-sourcepos="16:1-19:26">&nbsp;</p>
    <p dir="auto" data-sourcepos="3:1-7:14">Impressum Angaben gem&auml;&szlig; &sect; 5 TMG: 24relations marketing solutions gmbh<br> Rennweg 60<br> 56626 Andernach<br></p>
    <p dir="auto" data-sourcepos="9:1-10:18">Vertreten durch: Herr Manfred Sattler<br></p>
    <p dir="auto" data-sourcepos="12:1-14:27">Kontakt:<br> Telefon: +49 2632 4988830 <br> E-Mail: <a href="mailto:info@24relations.de">info@24relations.de</a></p>
    <p dir="auto" data-sourcepos="12:1-14:27">&nbsp;</p>
    <p dir="auto" data-sourcepos="16:1-19:26">Registereintrag: Eintragung im Handelsregister. Registergericht: Amtsgericht Koblenz Registernummer: HRB 28485</p>
    <p dir="auto" data-sourcepos="16:1-19:26">&nbsp;</p>
    <p dir="auto" data-sourcepos="21:1-23:14">Umsatzsteuer: Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect;27 a Umsatzsteuergesetz: DE 287 718 960</p>
    <p dir="auto" data-sourcepos="21:1-23:14">&nbsp;</p>
    <p dir="auto" data-sourcepos="25:1-27:51">Streitschlichtung Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="nofollow noreferrer noopener">https://ec.europa.eu/consumers/odr</a>. Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
    <p dir="auto" data-sourcepos="25:1-27:51">&nbsp;</p>
    <p dir="auto" data-sourcepos="29:1-29:123">Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
    <p dir="auto" data-sourcepos="31:1-32:365">&nbsp;</p>
    <p dir="auto" data-sourcepos="31:1-32:365">Haftung f&uuml;r Inhalte Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7 Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder gespeicherte fremde Informationen zu &uuml;berwachen oder nach Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit hinweisen.</p>
    <p dir="auto" data-sourcepos="31:1-32:365">&nbsp;</p>
    <p dir="auto" data-sourcepos="34:1-34:351">Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
    <p dir="auto" data-sourcepos="34:1-34:351">&nbsp;</p>
    <p dir="auto" data-sourcepos="36:1-37:472">Haftung f&uuml;r Links Unser Angebot enth&auml;lt Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen. F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
    <p dir="auto" data-sourcepos="36:1-37:472">&nbsp;</p>
    <p dir="auto" data-sourcepos="39:1-39:225">Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
    <p dir="auto" data-sourcepos="39:1-39:225">&nbsp;</p>
    <p dir="auto" data-sourcepos="41:1-42:414">Urheberrecht Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht kommerziellen Gebrauch gestattet.</p>
    <p dir="auto" data-sourcepos="41:1-42:414">&nbsp;</p>
    <p dir="auto" data-sourcepos="44:1-44:385">Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
    `,
    __html2: 'testing part 2 of decument',
  },
  {
    id: 'about',
    title: 'Über Checktastic',
    __html: '<p>test </p>',
  },
  {
    id: 'contact',
    title: 'Kontaktformular',
    subtitle: 'Fragen zu lorem ipsum? Sende uns eine Nachricht.',
    __html: `<strong><h3>Kontaktformular</h3>
    <span ></span>
    <span>lorem ipsum begegnet der komplexen Mediensuche f&uuml;r den Schulunterricht, die sich aus den bundeslandspezifischen Schulsystemen, Lehrpl&auml;nen und Kompetenzmodellen sowie den fachspezifischen Didaktiken und Methodiken des Religionsunterrichts ergibt.</span>
    <span>lorem ipsum bietet die besten Voraussetzungen, dass Lehrkr&auml;fte f&uuml;r den katholischen Religionsunterricht schnell und erfolgreich an Medien gelangen, die zu ihrem Unterrichtsvorhaben passen.</span>
    `,
  },
];
