import { useRef, useEffect } from 'react';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

const ImageCropp = ({
  src,
  setCanvasData,
}: {
  src: any;
  setCanvasData: any;
}) => {
  const imgRef = useRef(null);

  useEffect(() => {
    // const handleClick = (cropper: any) => {
    //   const canvas = cropper.getCroppedCanvas();

    //   setCanvasData({
    //     //@ts-ignore
    //     url: canvas.toDataURL('image/jpeg'),
    //     height: canvas.height,
    //     width: canvas.width,
    //   });
    // };
    if (imgRef.current) {
      const cropper = new Cropper(imgRef.current, {
        aspectRatio: 16 / 9,
        autoCropArea: 1,
        zoomOnWheel: false,
        zoomOnTouch: false,
        zoomable: false,
        autoCrop: true,
        guides: true,
        dragMode: 'none',
        viewMode: 1,
        checkOrientation: true,
        rotatable: true,
        scalable: true,
        // minCropBoxHeight: 300,
        cropBoxResizable: false,
        cropBoxMovable: true,
        // minCropBoxHeight: ,
        ready: (event: any) => {
          const canvas = cropper.getCroppedCanvas();
          // console.log('crop', event.timeStamp);
          // console.log('crop', event.timeStamp, canvas);
          setCanvasData({
            //@ts-ignore
            url: canvas.toDataURL('image/jpeg'),
            height: canvas.height,
            width: canvas.width,
            //@ts-ignore
            timeStamp: event.timeStamp,
          });
        },
        cropend(event: any) {
          const canvas = cropper.getCroppedCanvas();
          // console.log('crop', event.timeStamp);
          setCanvasData({
            //@ts-ignore
            url: canvas.toDataURL('image/jpeg'),
            height: canvas.height,
            width: canvas.width,
            //@ts-ignore
            timeStamp: event.timeStamp,
          });
        },
      });

      // if (btnRef && btnRef.current) {
      //   //@ts-ignore
      //   btnRef.current.addEventListener('click', () => handleClick(cropper));
      // }
    }
    // return () => {
    //   window.removeEventListener('click', handleClick);
    // };
  }, [src, setCanvasData]);

  return (
    //max-w-lg mx-auto
    // adding  max-h-96 to the container to make screen smaller so btn will apear on safari browser
    <div className="rounded-lg overflow-hidden block max-h-96">
      <img
        ref={imgRef}
        src={src}
        alt={'captureedimage'}
        className="object-center object-contain h-full w-full max-w-full"
      />
    </div>
  );
};

export default ImageCropp;
