import {
  UserIcon,
  OfficeBuildingIcon,
  LocationMarkerIcon,
  UserGroupIcon,
  AdjustmentsIcon,
} from '@heroicons/react/outline';
// import { CarIcon } from '../../../../styles/icons';

export const membersPageTablesColumns = [
  'Benutzer/in',
  'Rolle',
  'Standort',
  'Letzte Anmeldung',
  'AKTIONEN',
];
export const memberPageSortSelectObject = [
  { name: 'Alle', status: '', id: 0 },
  { name: 'Rolle', status: 'role', id: 1 },
  {
    name: 'Name',
    status: 'prename',
    id: 2,
  },
  { name: 'E-Mail', status: 'email', id: 3 },
];
// type MembersProp = {};
export const MembersPagetabs = [
  { name: 'Aktive', status: '', id: 0, hasCounter: false },
  { name: 'Eingeladene', status: 'IN_PROGRESS', id: 1, hasCounter: true },
  {
    name: 'Deaktivierte',
    status: 'SUCCESSFULLY_COMPLETED',
    id: 2,
    hasCounter: true,
  },
];

export const vehiclesPageTablesColumns = [
  'Fahrzeug',
  'Standort',
  'FIN/VIN & Kennzeichen',
  'hauptfahrer',
  'AKTUELLE VERFÜGBARKEIT',
  'AKTIONEN',
];
export const vehiclesSortSelectObject = [
  { name: 'Alle', status: '', id: 0 },
  // { name: 'kind', status: 'kind', id: 1 },
  {
    name: 'Marke',
    status: 'brand',
    id: 2,
  },
  { name: 'Standort', status: 'location', id: 3 },
];

export const subNavigation = [
  {
    name: 'Persönliche Einstellungen',
    description: 'Name, Profilbild und Ihre Zugangsdaten.',
    icon: UserIcon,
    id: 0,
  },
  {
    name: 'Sicherheit',
    description: 'Zwei-Faktor-Authentifizierung',
    icon: AdjustmentsIcon,
    id: 1,
  },
  {
    name: 'Zentrale',
    description:
      'Logo, Unternehmensname, Auftragsdatenverarbeitung und Vertragsinformationen.',
    icon: OfficeBuildingIcon,
    id: 2,
  },
  {
    name: 'Standorte',
    description: 'Verwaltung Ihrer Standorte.',
    icon: LocationMarkerIcon,
    id: 3,
  },
  {
    name: 'NutzerInnen',
    description: 'Nutzerverwaltung und Standortzuweisung.',
    icon: UserGroupIcon,
    id: 4,
  },
  // {
  //   name: 'Fahrzeuge',
  //   description: 'Fahrzeugverwaltung und Fahrzeuginformationen.',
  //   icon: CarIcon,
  //   id: 5,
  // },
];
