import React, { createContext, ReactChild } from 'react';
import { useAuthentication } from '../hooks';
import { IAuthObject } from './types';

export const authContext = createContext<IAuthObject>({
  signin: () => null,
  token: undefined,
  user: null,
  authorization: null,
  isError: false,
  isLoading: false,
  signout: () => null,
  error: null,
});

export function ProvideAuth({ children }: { children: ReactChild }) {
  const auth = useAuthentication();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
