import { useState } from 'react';
import { Field, FormikProps } from 'formik';
import * as yup from 'yup';
import { DigitalSignatueReturnVehicles, Form } from '../../shared';
import { Query } from 'shared';
import { classNames } from '../../../styles/utils';
import DatePicker from './new_contract/date_picker';
import TimePicker from './new_contract/time_picker';
import useStore from '../../../ui-state/ui';
import moment from 'moment';
import { Spinner } from '../../../styles/icons';
import config from 'shared/config';

const filedClassName =
  'w-full p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:ring-blue-500 focus:border-blue-500';
const labelClassName = 'block text-sm font-semibold text-blue-gray-900 mb-1';

const ReturnVehicles = ({
  setOpen,
  setShowNotification,
  useDigitalSigning,
}: {
  setOpen: (open: boolean) => void;
  setShowNotification: (show: boolean) => void;
  useDigitalSigning: boolean;
}) => {
  const [returnAtObject, setReturnedAt] = useState(moment());
  const [startTime, setStartTime] = useState<any>();
  const idCheckSingleValue = useStore((state) => state.idCheckSingleValue);
  const contractId = idCheckSingleValue?.contract_short.id;
  const { ReturnVehiclesR, FetchContractDataById, SubmitDataToserver } =
    Query();
  const { mutateAsync, reset } = ReturnVehiclesR({
    url: `/contracts/${contractId}`,
  });
  const {
    mutateAsync: SubmitSignature,
    reset: restSignature,
    // isLoading: isSubmitting,
  } = SubmitDataToserver({
    url: `${config.apiURL}/contracts/${contractId}/add_signatures`,
  });
  const { data: contractData } = FetchContractDataById({
    url: `${config.apiURL}/contracts/${contractId}`,
    key: `contract-${contractId}`,
  });

  const validationSchema = yup.object({
    vehicle_returned_at: yup
      .mixed()
      .required('Rückgabe und Zeit Rückgabe ist erforderlich'),
    odometer_value_at_end: yup
      .number()
      .integer()
      .min(
        contractData && contractData.odometer_value_at_start,
        'KM Stand (Rückgabe) muss größer als KM-Stand (Übergabe) sein',
      )
      .required('KM Stand (Rückgabe) ist erforderlich'),
    isNeuDamage: yup.number(),
    pre_damage: yup
      .string()
      .when('isNeuDamage', (isNeuDamage: number, schema: any) => {
        if (isNeuDamage !== 0)
          return schema.required('Schaden ist erforderlich.');
      }),
    short_description: yup.string().nullable(),
    vehicle_return_customer: useDigitalSigning
      ? yup.string().typeError('').required('vehicle_return_dealer is required')
      : yup.string().nullable(),
    // .typeError('')
    // .required('vehicle_return_customer is required')
    vehicle_return_dealer: useDigitalSigning
      ? yup.string().typeError('').required('vehicle_return_dealer is required')
      : yup.string().nullable(),
  });

  const handleReturnVehicle = async (values: any, formikBag: any) => {
    // console.log('🚀 ~ handleReturnVehicle ~ values:', values);
    const {
      vehicle_returned_at,
      odometer_value_at_end,
      pre_damage,
      short_description,
      vehicle_return_dealer,
      vehicle_return_customer,
    } = values;
    try {
      const res = await mutateAsync({
        contract: {
          odometer_value_at_end: odometer_value_at_end,
          vehicle_returned_at: vehicle_returned_at,
          vehicle_attributes: {
            pre_damage: pre_damage,
            short_description: short_description,
          },
        },
      });
      if (
        res &&
        !useDigitalSigning &&
        vehicle_return_customer === null &&
        vehicle_return_customer === null
      ) {
        // console.log('🚀 ~ handleReturnVehicle ~ res:', res);
        setShowNotification(true);
        setOpen(false);
        reset();
        restSignature();
      } else {
        const signatureRes = await SubmitSignature({
          contract: {
            signatures_attributes: [
              {
                kind: 'vehicle_return_customer',
                image: vehicle_return_customer,
              },
              {
                kind: 'vehicle_return_dealer',
                image: vehicle_return_dealer,
              },
            ],
          },
        });

        if (res && signatureRes) {
          setShowNotification(true);
          setOpen(false);
          reset();
          restSignature();
        }
      }

      // console.log('🚀 ~ handleReturnVehicle ~ signatureRes:', signatureRes);
      // console.log('🚀 ~ handleReturnVehicle ~ res:', res);
    } catch (e) {
      //do somthing with error or
    }
  };
  if (!contractData)
    return (
      <div className="flex justify-center align-middle">
        <Spinner className="h-4 w-4 text-orange-400" />
      </div>
    );
  // console.log('🚀 ~ ReturnVehicles ~ contractData', contractData);
  return (
    <Form
      submitForm={handleReturnVehicle}
      validationSchema={validationSchema}
      className="h-full"
      initialValues={{
        //@ts-ignore
        vehicle_returned_at: moment()._d,
        odometer_value_at_end: '',
        isNeuDamage: 0,
        short_description:
          contractData && contractData.vehicle
            ? contractData.vehicle.short_description
            : '',
        pre_damage: contractData ? contractData.vehicle.pre_damage : '',
        vehicle_return_dealer: null,
        vehicle_return_customer: null,
      }}>
      {({
        errors,
        isSubmitting,
        dirty,
        values,
        submitCount,
        touched,
        setFieldValue,
      }: FormikProps<any>) => {
        // console.log('🚀 ~ values:', values);
        return (
          <div className="mt-4">
            <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor={'vehicle_returned_at'}
                  className={`${labelClassName} mb-1`}>
                  Rückgabe
                </label>
                <Field name={'vehicle_returned_at'} type="textarea">
                  {({ field }: any) => (
                    <DatePicker
                      isOutsideRange={(date: any) => {
                        return date.isBefore(
                          moment(contractData && contractData.start_of_use),
                        );
                      }}
                      id="returned_at"
                      dateObject={returnAtObject}
                      setDate={setReturnedAt}
                    />
                  )}
                </Field>
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor={'returned_time'}
                  className={`${labelClassName} mb-1`}>
                  Zeit Rückgabe
                </label>
                <TimePicker
                  hoursValue={
                    startTime ? Number(startTime.hours) : moment().get('hour')
                  }
                  minutesValue={
                    startTime
                      ? Number(startTime.minutes)
                      : moment().get('minutes')
                  }
                  defaultValueMood
                  pickerValue={startTime}
                  setValue={setStartTime}
                  cb={({ name, value }) => {
                    if (returnAtObject) {
                      //@ts-ignore
                      const combinedObject = returnAtObject!.set({
                        hour:
                          name === 'hours' ? Number(value) : startTime?.hours,
                        //@ts-ignore
                        minute: name === 'minutes' && Number(value),
                      });
                      //@ts-ignore
                      const formatedDate = combinedObject._d;

                      setFieldValue('vehicle_returned_at', formatedDate);
                    }
                  }}
                  disabled={!returnAtObject}
                />
              </div>
              <div className="sm:col-span-6">
                <label
                  htmlFor="odometer_value_at_end"
                  className={labelClassName}>
                  KM Stand (Rückgabe)
                </label>
                <Field
                  className={filedClassName}
                  type="number"
                  name="odometer_value_at_end"
                />
              </div>

              <div className="sm:col-span-6">
                <label htmlFor="role" className={labelClassName}>
                  Neue Schäden?
                </label>
                <Form.Select
                  placeholder="Bitte auswählen"
                  id="isNeuDamage"
                  name="isNeuDamage"
                  className={`form-select-fix w-full rounded-md shadow-sm `}>
                  <option value={0}>kein neuer Schaden</option>
                  <option value={1}>Schäden hinzufügen/bearbeiten</option>
                </Form.Select>
              </div>
              {Boolean(Number(values['isNeuDamage'])) ? (
                <div className="sm:col-span-6">
                  <label htmlFor="pre_damage" className={labelClassName}>
                    Schäden
                  </label>
                  {/* <Field
                    className={filedClassName}
                    type="text"
                    name="pre_damage"
                  /> */}
                  <Field name="pre_damage" type="textarea">
                    {({ field }: any) => (
                      <textarea
                        {...field}
                        className={`${filedClassName} md:h-24`}
                      />
                    )}
                  </Field>
                </div>
              ) : null}
              <div className="sm:col-span-6">
                <label htmlFor="short_description" className={labelClassName}>
                  Sonstige Notizen
                </label>
                <Field name="short_description" type="textarea">
                  {({ field }: any) => (
                    <textarea
                      {...field}
                      className={`${filedClassName} md:h-24`}
                    />
                  )}
                </Field>
                {/* <Field
                    className={filedClassName}
                    type="text"
                    name="short_description"
                  /> */}
              </div>

              {useDigitalSigning && (
                <>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="vehicle_return_customer"
                      className={labelClassName}>
                      Unterschrift Autohaus-Mitarbeiter (erforderlich)
                    </label>
                    <div className="flex w-full flex-col">
                      <DigitalSignatueReturnVehicles
                        height={120}
                        width={450}
                        name="vehicle_return_customer"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {/* <span
                  onClick={() => onClearDigitalSignature()}
                  className="mt-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-pink-700/10">
                  remove
                </span> */}
                    <div className="sr-only">
                      {' '}
                      <Field name="vehicle_return_customer" type="img">
                        {({ field }: any) => (
                          <img
                            {...field}
                            className={filedClassName}
                            alt="return_customer"
                          />
                        )}
                      </Field>
                    </div>
                    {/* <Field
                    className={filedClassName}
                    type="text"
                    name="short_description"
                  /> */}
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="vehicle_return_dealer"
                      className={labelClassName}>
                      Unterschrift Kunde (erforderlich)
                    </label>
                    <div className="flex w-full">
                      <DigitalSignatueReturnVehicles
                        height={120}
                        width={450}
                        // imageSrc=""
                        name="vehicle_return_dealer"
                        setFieldValue={setFieldValue}
                      />
                    </div>
                    {/* <span
                  onClick={() => onClearDigitalSignatureDealer()}
                  className="mt-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-pink-700/10">
                  clear
                </span> */}
                    <div className="sr-only">
                      {' '}
                      <Field name="vehicle_return_dealer" type="img">
                        {({ field }: any) => (
                          <img
                            {...field}
                            className={filedClassName}
                            alt="return_dealer"
                          />
                        )}
                      </Field>
                    </div>
                  </div>{' '}
                </>
              )}
            </div>

            {touched && Object.keys(errors).length > 0 && (
              <div className="pt-5">
                <p className="text-red-400 text-sm font-semibold">
                  {Object.values(errors)[0]}
                </p>
              </div>
            )}
            <div className={`pt-5 flex justify-end `}>
              <button
                onClick={() => setOpen(false)}
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                type="button">
                Abbrechen
              </button>
              <button
                disabled={!dirty || isSubmitting || submitCount > 4}
                className={classNames(
                  dirty &&
                    Object.keys(errors).length === 0 &&
                    !isSubmitting &&
                    submitCount <= 4
                    ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                    : 'bg-gray-400 cursor-not-allowed',
                  'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
                )}
                type="submit">
                Speichern
              </button>
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default ReturnVehicles;
