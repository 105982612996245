export interface Rows {
  name: string;
  id: number;
  date: string;
  car?: string;
  place?: string;
  status: string;
  Legitimation: string;
}
export const tabs = [
  { name: 'Neu', status: 'new', id: 1, hasCounter: false },
  {
    name: 'Offen',
    status: 'active',
    id: 2,
    hasCounter: true,
  },
  { name: 'Abgeschlossen', status: 'done', id: 3, hasCounter: false },
  { name: 'Alle', status: 'all', id: 0, hasCounter: false },
];

export const columns = [
  'Datum',
  'Kunde',
  'Fahrzeug',
  'Standort',
  'Status',
  'Legitimation',
  'Aktionen',
];

export const secureColumns = [
  'Datum',
  'Kunde',
  'Fahrzeug',
  'Standort',
  'Status',
  'Ausweisdokument',
  'Führerschein',
  'Aktionen',
];
export const columnsUserDetailsPage = [
  'Datum',
  'Fahrzeug',
  'Standort',
  'Status',
  'Legitimation',
  'Aktionen',
];
export const statusStyles = {
  SUCCESSFULLY_COMPLETED: {
    status: 'bg-green-200 text-green-800',
    label: 'Akzeptiert',
    contractStaus: {
      border: 'border-green-600',
      bg: 'bg-green-500',
    },
  },
  IN_PROGRESS: {
    status: 'bg-orange-200 text-yellow-800',
    label: 'In Bearbeitung',
    contractStaus: {
      border: 'border-orange-400',
      bg: 'bg-orange-400',
    },
  },
  REJECTED: {
    status: 'bg-red-200 text-red-800',
    label: 'Abgelehnt',
    contractStaus: {
      border: 'border-orange-600',
      bg: 'bg-red-400',
    },
  },
  NOT_AVAILABLE: {
    status: 'bg-gray-400 text-white',
    label: 'In Bearbeitung'.toUpperCase(),
    contractStaus: {
      border: 'border-orange-600',
      bg: 'bg-gray-700',
    },
  },
};
export const rows: Rows[] = [
  {
    name: 'Autohaus XYZ',
    date: '22.03.2022',
    car: '53474 - Bad Neuenahr',
    place: 'Bad Neuenahr',
    id: 1323,
    status: '42',
    Legitimation: '1132',
  },
  {
    name: 'Autohaus test',
    date: '22.03.2022',
    car: '53434 - Bad Neuenahr',
    place: 'Bad Neuenahr',
    id: 142324,
    status: '43',
    Legitimation: '1321',
  },
  {
    name: 'Autohaus YZ',
    date: '22.03.2022',
    car: '53484 - Bad Neuenahr',
    place: 'Bad Neuenahr',
    id: 23534,
    status: '77',
    Legitimation: '1233',
  },
  {
    name: 'Autohaus XY',
    date: '22.03.2022',
    car: '56474 - Bad Neuenahr',
    place: 'Bad Neuenahr',
    id: 43e3,
    status: '43',
    Legitimation: '1232',
  },
  {
    name: 'Autohaus',
    date: '22.03.2022',
    car: '53472 - Bad Neuenahr',
    place: 'Bad Neuenahr',
    id: 343434,
    status: '434',
    Legitimation: '1543',
  },
];

export const customerPageFormField = [
  {
    name: 'prename',
    label: 'Vorname',
  },
  {
    name: 'surname',
    label: 'Name',
  },
  {
    name: 'identity_document_type',
    label: 'Ausweistyp',
  },
  {
    name: 'identity_document_number',
    label: 'Ausweisnummer',
  },
  {
    name: 'date_of_birth',
    label: 'Geburtsdatum',
  },
  // {
  //   name: 'street',
  //   label: 'Straße',
  // },
  // {
  //   name: 'postal_code',
  //   label: 'PLZ / Ort',
  // },
  // {
  //   name: 'telephone_number',
  //   label: 'Telefon',
  // },
  // {
  //   name: 'email',
  //   label: 'E-Mail-Adresse',
  // },
];
