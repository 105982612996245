//@ts-ignore
import { QueryClientProviderClient } from 'shared';
import { Navigations } from './navigation';

import { ErrorBoundary } from './components/shared';

function App() {
  return (
    <QueryClientProviderClient>
      {/*ViewportProvider can be removed later  */}
      {/* @ts-ignore */}
      <ErrorBoundary>
        <Navigations />
      </ErrorBoundary>
    </QueryClientProviderClient>
  );
}

export default App;
