import { useState } from 'react';
import * as yup from 'yup';
import { Field } from 'formik';
import { useHistory } from 'react-router-dom';

import Logo from 'assets/images/logo-tastic-new.png';
import { Form, Modal } from '../../shared';
import { Spinner } from '../../../styles/icons';
import axios from 'axios';
import { Button } from '../../../styles/components';
import config from 'shared/config';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse ein')
    .defined('Ihre E-Mail Adresse ist erforderlich')
    .required(),
});

const ResetPasswordPage = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const passwordReset = async (values: any) => {
    const { email } = values;

    try {
      await axios.get(`${config.apiURL}/users/reset_password?email=${email}`);
      setOpen(true);
    } catch (e) {
      console.log('e', e);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-24 w-auto" src={Logo} alt="logo" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Passwort vergessen
          </h2>
          <p className="mt-2 text-center text-md text-gray-700">
            Geben Sie hier Ihre E-Mail-Adresse an, die mit Ihrem Account
            verbunden ist. Wir schicken Ihnen dann einen Link, mit dem Sie Ihr
            Passwort zurücksetzen können.
          </p>
        </div>
        <Form
          initialValues={{ email: '' }}
          validationSchema={validationSchema}
          submitForm={passwordReset}
          // className="space-y-6"
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700 sr-only">
              E-Mail-Adresse
            </label>
            <div className="mt-1">
              <Field name="email">
                {({
                  field,
                  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  meta,
                }: any) => {
                  return (
                    <Form.CustomInputItem
                      field={field}
                      meta={meta}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                      type="email"
                      placeholder="E-Mail-Adresse"
                    />
                  );
                }}
              </Field>
            </div>
          </div>

          {/* {isError ? (
              <div className="text-red-400 text-sm font-semibold">
                {error.response ? error.response.data.error : 'server error'}
              </div>
            ) : null} */}

          <div>
            <button
              disabled={false}
              type="submit"
              className="w-full  justify-center px-4 py-2 text-base text-white bg-orange-500 hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white inline-flex items-center rounded-md shadow-sm border border-transparent font-medium">
              {false ? <Spinner /> : 'Senden'}
            </button>
          </div>
        </Form>
      </div>
      <Modal
        hasIcon
        setOpen={setOpen}
        open={open}
        title="Wir haben Ihnen einen Link geschickt, mit dem Sie Ihr Passwort zurücksetzen können."
        // subtile="lorem ipsum brety breaken testing subtitle to go for anothre place to go"
      >
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setOpen(false);
              history.push('/login');
            }}
            label="okay"
            type="primary"
            className="w-full justify-center "
          />
        </div>
      </Modal>
    </div>
  );
};

export default ResetPasswordPage;
