import { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import queryString from 'query-string';
import { Tabs, currentTabProp } from '../../shared';
import IDcheckSearch from './id_check_search';
import CustomersSearch from './customer/customer_search';
import VehiclesSearch from './vehicles/vehicles_search';
import { UserIcon, KeyIcon, IdentificationIcon } from '@heroicons/react/solid'; //IdentificationIcon,
import { Slideover } from '../../../styles/components';
import CustomerDetails from './customer/details';
import VehiclesDetails from './vehicles/details';
import { useAuthContext } from '../../../hooks';

type ComposedProps = RouteComponentProps<{
  //   query?: string;
}>;

const tabs = [
  //   { name: 'Alle', id: 0, status: 'Alle' },
  { name: 'Kunde', id: 0, status: '6', icon: UserIcon },
  { name: 'Fahrzeug', id: 1, status: 'Fahrzeug', icon: KeyIcon },
];
const SearchPage = ({ location }: ComposedProps) => {
  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  const { plan_version } = user;
  const isLightProduct = plan_version === 'light';

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const { search } = location;
  const { query } = queryString.parse(search);
  const [current, setCurrent] = useState<currentTabProp | null>({
    status: isLightProduct ? 'ID Check' : '',
    id: isLightProduct ? 2 : 0,
  });
  const [searchQuery, setSerachQuery] = useState<any>(query ? query : '');

  const handleTabSelect = (selectedTabItem: currentTabProp) => {
    setCurrent(selectedTabItem);
  };
  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const query = (target as HTMLInputElement).value;
    setSerachQuery(query);
  };

  return (
    <div className="max-w-5xl mx-auto mt-8 bg-white shadow-md rounded-lg">
      {/* Tabs */}
      <div className="px-4 sm:px-6 lg:px-8 border">
        <Tabs
          tabs={
            !isLightProduct
              ? tabs
              : [
                  {
                    name: 'ID Check',
                    id: 2,
                    status: 'ID Check',
                    icon: IdentificationIcon,
                  },
                ]
          }
          onSelect={handleTabSelect}
          current={current ? current.id : null}
          counter={10}
        />
      </div>

      <div className="p-8">
        <p id="general_search" className="sr-only">
          Search box
        </p>
        <div className="flex justify-around align-middle">
          <div className="flex-grow">
            <input
              //@ts-ignore
              value={searchQuery}
              onChange={handleChange}
              name="search"
              id="search_main"
              className="p-3 border w-full shadow-sm focus:ring-light-blue-500 focus:border-light-blue-500 sm:text-sm border-gray-300 rounded-md"
              placeholder="Suchen"
              aria-describedby="general_search"
            />
          </div>
          {/* <span className="ml-3 w-2/12 flex">
            <button
              type="button"
              className="w-full justify-items-center bg-orange-500 flex justify-center items-center  border border-gray-300 shadow-sm text-md font-medium rounded-lg text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500">
              <SearchIcon
                className="mx-2 h-5 w-5 text-white"
                aria-hidden="true"
              />
              <span>Suchen</span>
            </button>
          </span> */}
        </div>
      </div>

      <div className="relative hidden md:block">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
      </div>

      {/* Stacked list */}
      <div className="px-4 sm:px-6 lg:px-8">
        {/* all field */}

        {current && current.id === 2 && isLightProduct && (
          <div>
            <IDcheckSearch searchQuery={searchQuery} />
          </div>
        )}

        {current && current.id === 0 && !isLightProduct && (
          <div>
            <CustomersSearch
              searchQuery={searchQuery}
              onItemClick={(item: any) => {
                setSelectedItem(item);
                setOpen(!open);
              }}
            />
          </div>
        )}

        {current && current.id === 1 && !isLightProduct && (
          <div>
            <VehiclesSearch
              searchQuery={searchQuery}
              onItemClick={(item: any) => {
                setSelectedItem(item);
                setOpen(!open);
              }}
            />
          </div>
        )}
      </div>
      <Slideover
        open={open}
        toggle={setOpen}
        title={
          selectedItem
            ? current && current.id === 0
              ? //@ts-ignore
                `${selectedItem!.salutation} ${selectedItem!.prename} ${
                  //@ts-ignore
                  selectedItem!.surname
                }`
              : //@ts-ignore
                `${selectedItem!.brand} ${selectedItem!.name}`
            : undefined
        }
        // subTitle={
        //   current && current.id === 1 && selectedItem
        //     ? //@ts-ignore
        //       `${selectedItem!.salutation} ${selectedItem!.prename} ${
        //         //@ts-ignore
        //         selectedItem!.surname
        //       }`
        //     : undefined
        // }
        style={{
          subtitleStyle: 'text-white',
          titleStyle: 'text-white',
          closeIconColor: 'text-white',
          headerStyle: 'bg-orange-500',
        }}>
        {current && current.id === 0 ? (
          <CustomerDetails
            //@ts-ignore
            item={selectedItem}
          />
        ) : (
          <VehiclesDetails
            //@ts-ignore
            item={selectedItem}
          />
        )}
      </Slideover>
    </div>
  );
};

export default SearchPage;
