import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { classNames } from '../../../../styles/utils';
import ScanToMialList from './scan_to_mail_list';
import { dataFormat } from '../../../../utils';
import config from 'shared/config';
import { ScandeDocProp } from './index';
import { TrashIcon } from '@heroicons/react/solid';

type ISelectdPopover = {
  selectedScan: ScandeDocProp[] | undefined;
  data: any;
  setPopoverOpen: (open: boolean) => void;
  setSelectedScanId: any;
  showPopover: boolean;
  isSuccess: boolean;
  shouldOpenPopover: boolean;
  isSelectScanPossible: boolean;
  type?: string;
  shouldCloseSelectMenu?: boolean;
};
const SelectedScanPopover = ({
  data,
  setPopoverOpen,
  selectedScan,
  setSelectedScanId,
  showPopover,
  isSuccess,
  shouldOpenPopover,
  isSelectScanPossible,
  shouldCloseSelectMenu = false,
  type,
}: ISelectdPopover) => {
  const onScanItemSelect = (
    id: number,
    subject: string,
    pdf_attachment_file?: {
      url: string;
    },
    recieved_at?: string,
  ) => {
    //@ts-ignore
    setSelectedScanId((scan: ScandeDocProp) => {
      const scanArray = [
        //@ts-ignore
        ...scan,
        {
          id,
          subject,
          pdf_attachment_file,
          recieved_at,
        },
      ];

      if (
        scanArray.length > 0 &&
        type !== 'IDENTITY_CARD' &&
        type !== 'RESIDENCE_PERMIT'
      ) {
        setPopoverOpen(false);
      }
      if (
        scanArray.length > 1 &&
        (type === 'IDENTITY_CARD' || type === 'RESIDENCE_PERMIT')
      ) {
        setPopoverOpen(false);
      }

      return scanArray;
    });
  };
  // console.log(type);
  return (
    <Popover as="div" className="relative">
      {({ open }) => (
        <>
          <div>
            <Popover.Button
              onClick={
                shouldOpenPopover
                  ? () => {
                      setPopoverOpen(true);
                    }
                  : undefined
              }
              className="bg-white flex text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 w-full">
              <label
                htmlFor="id-scan-upload"
                className="relative cursor-pointer bg-white 
             rounded-md font-medium text-gray-500 hover:text-gray-900 
             focus-within:outline-none w-full">
                <span>
                  {selectedScan && selectedScan.length ? (
                    //@ts-ignore
                    selectedScan.map((scan) => (
                      <Fragment key={scan.id}>
                        <span className="block w-full font-bold py-1">
                          {scan.subject}
                        </span>
                        {scan.recieved_at && (
                          <span>
                            {dataFormat({
                              //@ts-ignore
                              date: scan.recieved_at,
                              option: {
                                hour: true,
                              },
                            })}
                          </span>
                        )}
                        <div className="py-3 text-sm flex align-middle justify-center">
                          <div
                            onClick={() =>
                              window.open(
                                `${config.baseURL}${scan.pdf_attachment_file?.url}`,
                              )
                            }
                            className="text-orange-500 font-semibold hover:text-orange-600">
                            {' '}
                            Scan ansehen <span aria-hidden="true">&rarr;</span>
                          </div>

                          <TrashIcon
                            className="h-5 w-5 ml-2 cursor-pointer hover:text-orange-600"
                            onClick={() =>
                              setSelectedScanId(
                                //@ts-ignore
                                selectedScan.filter(
                                  (scaned) => scaned.id !== scan.id,
                                ),
                              )
                            }
                          />
                        </div>
                      </Fragment>
                    ))
                  ) : (
                    <span
                      className={classNames(
                        isSelectScanPossible
                          ? 'text-orange-600 hover:text-orange-700 cursor-pointer'
                          : 'text-gray-500 cursor-not-allowed',
                        ' font-semibold ',
                      )}>
                      {data ? 'Scan auswählen' : 'no avaliable scans'}
                    </span>
                  )}
                </span>
              </label>
            </Popover.Button>
            {/* {shouldCloseSelectMenu
           ? selectedScan.map((scan) => (
               <div className="py-3 text-sm flex align-middle">
                 <a
                   rel="noreferrer"
                   target="_blank"
                   href={`${config.baseURL}${scan.pdf_attachment_file?.url}`}
                   className="text-orange-500 font-semibold hover:text-orange-600">
                   Scan ansehen{' '}
                   <span aria-hidden="true">
                     &rarr;
                   </span>
                 </a>
                 <TrashIcon
                   className="h-5 w-5 ml-2 cursor-pointer hover:text-orange-600"
                   onClick={() =>
                     setSelectedScanId(
                       selectedScan.filter(
                         (scaned) =>
                           scaned.id !== scan.id,
                       ),
                     )
                   }
                 />
               </div>
             ))
           : null} */}
          </div>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <div>
              {showPopover && (
                <Popover.Panel
                  static
                  className="overflow-auto h-96 absolute bottom-0 z-10 w-screen border border-gray-300 bg-gray-100
                  transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-3xl rounded-lg mx-auto shadow-lg">
                  <div className="ring-1 ring-black ring-opacity-5">
                    {isSuccess && (
                      <ScanToMialList
                        onScanSelect={onScanItemSelect}
                        selectedScan={selectedScan}
                        emails={data}
                      />
                    )}
                  </div>
                </Popover.Panel>
              )}
            </div>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default SelectedScanPopover;
