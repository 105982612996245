import { Fragment } from 'react';
import { Transition, Popover } from '@headlessui/react';
import MultiSelectTableFilter from './table_multi_select_filter';
import { SelectorIcon } from '@heroicons/react/solid';

type PopOverProps = {
  setShow: (show: boolean) => void;
  show: boolean;
  setSelectFilter: (filter: any) => void;
  filterOptions: any;
  popupTriggerBtnLabel: string;
  selectedName?: string;
  selectValue?: any;
  menuIsOpen?: boolean;
};
const PopOverFilterMenu = ({
  setShow,
  show,
  setSelectFilter,
  filterOptions,
  popupTriggerBtnLabel,
  selectValue,
  menuIsOpen = true,
  selectedName,
}: PopOverProps) => {
  return (
    <Popover as="div" className="relative inline-block w-full">
      {() => (
        <>
          <div
            onClick={() => {
              setShow(!show);
            }}>
            <Popover.Button className="flex text-sm focus:outline-none  focus:ring-offset-2 w-full">
              <div className="w-full flex justify-between">
                <div>
                  <label
                    htmlFor="filter-table"
                    className="relative cursor-pointer rounded-md text-left font-semibold  text-gray-600 hover:text-gray-900 focus-within:outline-none ">
                    <span>{popupTriggerBtnLabel}</span>

                    {selectValue.length > 0 ? (
                      <span
                        className={
                          'hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block bg-orange-200 text-orange-600'
                        }>
                        {selectValue.length}
                      </span>
                    ) : null}
                  </label>
                </div>
                <div>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon
                      className="h-5 w-5 text-orange-500"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              </div>
            </Popover.Button>
          </div>

          <Transition
            show={show}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1">
            <div>
              {show && (
                <Popover.Panel
                  static
                  className="w-full absolute overflow-hidden h-80 z-50 sm:px-0 lg:max-w-3xl">
                  <div className="-mt-8">
                    <MultiSelectTableFilter
                      name={selectedName}
                      menuIsOpen={menuIsOpen}
                      value={selectValue}
                      handleChange={setSelectFilter}
                      options={filterOptions}
                    />
                  </div>
                </Popover.Panel>
              )}
            </div>
          </Transition>
        </>
      )}
    </Popover>
  );
};
export default PopOverFilterMenu;
