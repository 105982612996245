//@ts-ignore
// import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import { useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
// import Webcam from 'react-webcam';
import { Button } from '../../../../../styles/components';
// import useStore from '../../../../ui-state/ui';
// import CircleButton from './circle_btn';
// import svgIcon from './svg_overlay';
// import imageCompression from 'browser-image-compression';
// import { Spinner } from '../../../../../styles/icons';
import ImageCropp from './image-cropp';
import { useStores } from 'shared';

const WebcamCapture = ({
  getImageSrc,
  setCameraMode,
  mobileImgsrc,
  setImgSrc,
  imageSrcType,
  documentType,
  canvasData,
  setCanvasData,
}: // documentType,
{
  imageSrcType?: string;
  isCameraMode?: boolean;
  documentType?: string;
  isLoading: any;
  setCameraMode: any;
  mobileImgsrc: any;
  setImgSrc: any;
  src?: {
    url: string;
    height: number;
    width: number;
  } | null;
  getImageSrc: (
    src: {
      url: string;
      height: number;
      width: number;
    } | null,
  ) => void;
  canvasData: any;
  setCanvasData: any;
}) => {
  const { ui } = useStores();
  useEffect(() => {
    if (canvasData) {
      if (imageSrcType) {
        ui.setCropedImage({
          //@ts-ignore
          image: canvasData.url,
          //@ts-ignore
          height: canvasData.height,
          cropped: 0,
          device_type: 5,
          identity_document_side: imageSrcType.includes('_BACK_')
            ? 'back_side'
            : 'front_side',
          identity_document_type: documentType,
          //@ts-ignore
          width: canvasData.width,
          image_type: imageSrcType.includes('WITH_FLASH') ? 'flash' : 'visible',
          //@ts-ignore
          timeStamp: canvasData.timeStamp,
        });
      }
    }
  }, [canvasData, ui, imageSrcType, documentType]);
  // console.log(
  //   '🚀 ~ file: react_camera.tsx ~ line 139 ~ WebcamCapture ~',
  //   ui.imagesBeforeCrop,
  //   canvasData,
  // );
  return (
    <>
      {!mobileImgsrc ? null : (
        <div className="my-2">
          <ImageCropp
            src={mobileImgsrc}
            setCanvasData={setCanvasData}
            // mainSrc={src}
          />
          <div className="mt-4 max-w-full mx-auto">
            <Button
              endIcon={<TrashIcon className="w-6 h-6 text-red-600" />}
              onClick={() => {
                setImgSrc(null);
                getImageSrc(null);
                //tis step might not be neesecey when the api is working
                setCameraMode(false);
                // setIsShowVideo(true);
                // setDevices([]);
                // setDeviceId({});
              }}
              label=""
              type="default"
              className="justify-center w-full hover:bg-white" //w-full
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WebcamCapture;
