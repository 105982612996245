/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import FieldWithFeedBack from './field_input_with_feedback';
import { selectCarDataArray } from '../../form_data';
import { Query } from 'shared';
import type { Vehicle } from 'shared';
// import { Form } from '../../../../../shared';
import Downshift from 'downshift';
import { classNames } from '../../../../../../styles/utils';

//useDebounce should be remove to utiles folder ... check vehicles_data.tsx line 45
const useDebounce = (value: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState('');
  const timerRef = useRef();

  useEffect(() => {
    //@ts-ignore
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

type VehicleProp = {
  labelClass: string;
  fieldClassName: string;

  setSelectedCar: (car: any) => void;
};

const VehicleInfo = ({
  labelClass,
  fieldClassName,

  setSelectedCar,
}: VehicleProp) => {
  const [currentSearchCar, setCurrentSearchCar] = useState<any>();
  const { FetchVehicles, FetchVehiclesById } = Query();

  const {
    //@ts-ignore
    values: { brand, searchCar },

    errors,
    dirty,
    setFieldValue,
    validateForm,
    setErrors,
  } = useFormikContext();
  // console.log('🚀 ~ errors:', errors);

  const debounceValue = useDebounce(searchCar, 500);
  const { data: vehiclesData, refetch } = FetchVehicles({
    key: 'vehicles-index',
    // page: idCheckTablePageNumber,
    searchQuery: debounceValue !== '' ? debounceValue : undefined,
    // sortBy: 'brand',
    sort_direction: 'asc',
    perPage: 20,
    // enabled: searchCar !== '',
  });

  // we need to refetch the data when the searchCar value changes because the vehicle_owner_id need to be provided in the contract creation
  const { data } = FetchVehiclesById({
    id: currentSearchCar?.id,
    key: `vehicle-${brand}`,
    refetch,
    enabled: currentSearchCar?.id !== undefined,
  });

  useEffect(() => {
    let isCurrent = true;

    if (debounceValue !== '' && isCurrent) {
      // console.log('test', debounceValue, isCurrent);
      refetch();
    }
    return () => {
      isCurrent = false;
      // setFieldValue('email', '');
    };
  }, [debounceValue]);

  useEffect(() => {
    if (data) {
      // we validate form here to remove the error message of the license_plate field since it's required
      validateForm();
      const { id, name, vehicle_owner_id } = data;

      setSelectedCar({
        name: name,
        brand: brand,
        vehicle_owner_id: vehicle_owner_id,
        id,
      });
    }
  }, [data]);

  const renderFiled = (fieldItem: any) => {
    switch (fieldItem.type) {
      case 'search-box':
        return (
          <div className="overflow-auto">
            <Downshift
              // inputValue={query}
              //@ts-ignore
              onChange={(vehiclesData: Vehicle) => {
                // console.log('search result', vehiclesData);
                setCurrentSearchCar(vehiclesData);
                setFieldValue(
                  'odometer_value',
                  vehiclesData?.odometer_value || 0,
                );
                setFieldValue('vin', vehiclesData?.vin);
                setFieldValue('brand', vehiclesData?.brand);
                setFieldValue('license_plate', vehiclesData?.license_plate);

                setFieldValue(
                  'short_description',
                  vehiclesData?.short_description,
                );
                vehiclesData?.predamage
                  ? setFieldValue('pre_damage', vehiclesData?.predamage)
                  : setFieldValue('pre_damage', '');
                setFieldValue('searchCar', vehiclesData?.name);

                //fetch the vehicle data
                refetch();
              }}
              itemToString={(item: any) => (item ? item.brand : '')}>
              {({
                getInputProps,
                getItemProps,
                getLabelProps,
                getMenuProps,
                isOpen,
                inputValue,
                highlightedIndex,
                selectedItem,
                getRootProps,
              }) => {
                // console.log('isOpen===', inputValue);

                return (
                  <div className={'overflow-auto'}>
                    <div
                      className="w-full"
                      style={{ display: 'inline-block' }}
                      //@ts-ignore
                    >
                      <Field
                        type={'text'}
                        name={'searchCar'}
                        //@ts-ignore
                      >
                        {({ field }: any) => (
                          <input
                            {...getInputProps({
                              ...field,
                            })}
                            placeholder="Fahrzeugsuche ..."
                            className={fieldClassName}
                          />
                        )}
                      </Field>
                    </div>
                    <ul
                      className={classNames(
                        isOpen &&
                          vehiclesData &&
                          vehiclesData.records.length > 0
                          ? 'h-80'
                          : '',
                        'divide-y divide-gray-200 shadow overflow-auto',
                      )}>
                      {isOpen && vehiclesData && vehiclesData.records.length > 0
                        ? vehiclesData.records

                            // .filter(
                            //   (item: any) =>
                            //     !inputValue ||
                            //     //@ts-ignore
                            //     item.name.includes(inputValue),
                            // )
                            .map((item: any, index: number) => (
                              <li
                                // className="p-2 "
                                {...getItemProps({
                                  //@ts-ignore
                                  key: item.id,
                                  index,
                                  item,
                                  style: {
                                    backgroundColor:
                                      highlightedIndex === index
                                        ? '#6b6b6b1f'
                                        : 'white',
                                    fontWeight:
                                      //@ts-ignore
                                      selectedItem === item ? 'bold' : 'normal',
                                  },
                                })}>
                                <p className="px-4 py-4 bg-white hover:bg-gray-50">
                                  <span className="block text-black font-semibold">
                                    {' '}
                                    {item.name}
                                  </span>
                                  <span className="block font-semibold text-gray-400">
                                    {item.brand}
                                    <span className="font-extrabold text-xl mx-1">
                                      &#183;
                                    </span>
                                    {item.vin}
                                    <span className="font-extrabold text-xl mx-1">
                                      &#183;
                                    </span>
                                    {item.license_plate}
                                  </span>
                                </p>
                              </li>
                            ))
                        : null}
                    </ul>
                  </div>
                );
              }}
            </Downshift>
          </div>
        );
      // case 'list':
      //   return (
      //     <div className="w-full block pr-3">
      //       <Field
      //         autoComplete="off"
      //         name={fieldItem.name}
      //         component={Form.SelectField}
      //         options={
      //           dictionaryData
      //             ? dictionaryData.salutations.map((salutation) => ({
      //                 value: salutation.name,
      //                 label: salutation.name,
      //               }))
      //             : []
      //         }
      //       />
      //     </div>
      //   );
      // case 'checkbox':
      //   return (
      //     <div className="w-full block pr-3">
      //       <Field
      //         name={fieldItem.name}
      //         component={Form.SelectField}
      //         value={{
      //           //@ts-ignore
      //           value: currentSearchCar?.id,
      //           //@ts-ignore
      //           label: currentSearchCar?.brand,
      //         }}
      //         options={
      //           currentSearchCar
      //             ? {
      //                 //@ts-ignore
      //                 value: currentSearchCar?.id,
      //                 //@ts-ignore
      //                 label: currentSearchCar?.brand,
      //               }
      //             : []
      //         }
      //       />
      //     </div>
      //   );
      case 'textarea':
        return (
          <Field name={fieldItem.name} type="textarea">
            {({ field }: any) => (
              <FieldWithFeedBack
                //  @ts-ignore
                error={!dirty ? false : errors[fieldItem.name]}>
                <textarea
                  {...field}
                  className="p-1 md:h-32 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 border rounded-md"
                />
              </FieldWithFeedBack>
            )}
          </Field>
        );

      default:
        return (
          <>
            <Field
              type={fieldItem.type}
              name={fieldItem.name}
              disabled={fieldItem.disabled}
              autoComplete="given-name"
              className={fieldClassName}
            />
            {fieldItem.name === 'license_plate' && (
              <label className={`${labelClass} mt-2`}>
                (Eingabe-Format{' '}
                <span className="text-gray-900 font-bold">D XY 1234</span> oder{' '}
                <span className="text-red-500 font-bold">D 0123</span>)
              </label>
            )}
          </>
        );
    }
  };
  return (
    <div className="grid grid-cols-6 gap-6">
      {selectCarDataArray.map((fieldItem: any) => {
        return (
          // @ts-ignore
          <Fragment key={fieldItem.name}>
            {fieldItem.subTitle && (
              <div className="col-span-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  {fieldItem.subTitle}
                </h3>
              </div>
            )}

            <div className={fieldItem.gridClass}>
              <label htmlFor={fieldItem.name} className={labelClass}>
                {fieldItem.label ? fieldItem.label : null}
                {fieldItem.required && (
                  <span className="text-red-500 text-md p-0 ml-1">*</span>
                )}
              </label>
              {renderFiled(fieldItem)}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default VehicleInfo;
