import { useState, useEffect } from 'react';
import { Query, Vehicle, VehiclesResp } from 'shared';
import { FetchHandlerWrapper } from '../../../shared';
import { List, Pagination } from '../../../../styles/components';

const VehiclesSearch = ({
  searchQuery,
  onItemClick,
}: {
  searchQuery: string | string[] | null;
  onItemClick?: (item: any) => void;
}) => {
  const [page, setPage] = useState(1);
  const { FetchVehicles } = Query();
  const {
    isLoading,
    isError,
    data: vehicleData,
    isFetching,
    isSuccess,
    refetch,
  } = FetchVehicles({
    key: 'vehicles-index',
    page: page,
    searchQuery: searchQuery ? searchQuery : undefined,
    perPage: 5,
    // sortBy: sorteValue ? sorteValue.value : undefined,
  });

  useEffect(() => {
    if (vehicleData && !isFetching) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className="flex flex-col">
      <div className="my-2 flex flex-col">
        <FetchHandlerWrapper
          isError={isError}
          isLoading={isLoading}
          data={vehicleData ? vehicleData : undefined}>
          {(data: VehiclesResp) => (
            <List
              onItemClick={onItemClick}
              //@ts-ignore
              items={data.records}
              styles={{
                ul: 'my-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0',
                li: 'cursor-pointer',
              }}>
              {(vehicle: Vehicle) => {
                //   console.log('data =====', item);
                return (
                  <div className="group block w-full">
                    <div className="flex items-center py-5 sm:py-6 sm:px-0">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                          <div>
                            <p className="text-sm font-medium text-orange-600 truncate">
                              {vehicle.name} {vehicle.brand}
                            </p>
                            <p className="mt-2 flex items-center text-sm text-gray-500 font-bold">
                              {/* <MailIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            /> */}
                              Art:{''}
                              <span className="ml-2 text-gray-800">
                                {vehicle.kind}
                              </span>
                            </p>
                          </div>

                          <div className="hidden md:block">
                            <div>
                              {/* <p className="text-sm text-gray-500 font-bold">
                                Palate:
                                <span className="ml-2 text-blue-800 font-semibold">
                                  {vehicle.license_plate}
                                </span>
                              </p> */}
                              <p className="mt-2 text-sm font-bold text-gray-500">
                                {/* <DeviceMobileIcon
                                className="flex-shrink-0 mr-1.5 h-5 w-5"
                                aria-hidden="true"
                              /> */}
                                VIN:
                                <span className="ml-2 text-gray-800 font-semibold">
                                  {vehicle.vin}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </List>
          )}
        </FetchHandlerWrapper>
      </div>
      <div className="my-2 flex flex-col">
        {isSuccess && (
          <div className="md:-m-5">
            <Pagination
              styles={{
                navClassName:
                  'px-4 py-8 flex  items-center justify-between sm:px-6 min-w-full align-middle  border-t-2',
              }}
              {...vehicleData.pagination}
              isFetching={isFetching}
              setPage={setPage}
              currentpage={page}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VehiclesSearch;
