export default function RegistrationPage() {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg w-screen h-screen">
      <div className="px-4 py-5 sm:p-6">
        <h1 className="align-middle justify-items-center flex w-full">
          Register
        </h1>
      </div>
    </div>
  )
}
