import { IEmailsList } from 'shared';
import { dataFormat } from '../../../../utils';
import config from 'shared/config';
import { classNames } from '../../../../styles/utils';

interface IscanToMailList extends IEmailsList {
  onScanSelect: (
    id: number,
    subject: string,
    pdf_attachment_file?: { url: string },
    recieved_at?: string,
  ) => void;
  selectedScan?: any;
}
export default function ScanToMialList({
  emails,
  onScanSelect,
  selectedScan,
}: IscanToMailList) {
  return (
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2 sm:gap-x-1 lg:grid-cols-4 xl:gap-x-8 p-5">
      {emails.map(
        ({ id, subject, pdf_attachment_file, recieved_at }, index) => {
          const isScanAvaliable = selectedScan !== undefined;

          const isSelectd = isScanAvaliable
            ? selectedScan.filter((scan: any) => scan.id === id).length > 0
            : undefined;

          return (
            <li
              onClick={() =>
                onScanSelect(id, subject, pdf_attachment_file, recieved_at)
              }
              key={id}
              className="relative">
              <div
                className="group block w-full aspect-w-10 aspect-h-7 rounded-lg 
              bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 
              focus-within:ring-orange-500 overflow-hidden">
                <img
                  src={
                    pdf_attachment_file.pdf_thumb
                      ? `${config.baseURL}${pdf_attachment_file.pdf_thumb.url}`
                      : ''
                  }
                  alt=""
                  className={classNames(
                    isSelectd ? 'border-4 border-orange-400' : '',
                    'object-cover pointer-events-none group-hover:opacity-75',
                  )}
                />
                <button
                  type="button"
                  className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {subject}</span>
                </button>
              </div>
              <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
                {subject}
              </p>
              <p className="block text-sm font-medium text-gray-500 pointer-events-none">
                {' '}
                <time
                  dateTime={recieved_at}
                  className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                  {dataFormat({
                    date: recieved_at,
                    option: {
                      hour: true,
                    },
                  })}
                </time>
              </p>

              {/* <div className="mt-1">
              <p className="line-clamp-2 text-sm text-gray-600">{message.preview}</p>
            </div> */}
            </li>
          );
        },
      )}
    </ul>
  );
}
