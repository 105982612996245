import { IPaginationType } from 'shared';
// import { Spinner } from '../../../styles/icons';
import { Button } from '../../../styles/components';
export interface IPagination extends IPaginationType {
  currentpage: number;
  setPage: (page: number) => void;
  styles?: {
    navClassName: string;
  };
}

const Pagination = ({
  currentpage,
  page,
  per_page,
  total_entries,
  // total_pages,
  setPage,
  // isFetching,
  styles = {
    navClassName:
      'shadow rounded-b-lg border border-gray-200 border-t-0 bg-white px-4 py-3 flex items-center justify-between sm:px-6 min-w-full align-middle',
  },
}: IPagination) => {
  const isNext = Number(page) * per_page < total_entries;
  const isPrevious = currentpage > 1;
  const { navClassName } = styles;
  return (
    <nav className={navClassName} aria-label="Pagination">
      <div className="hidden sm:block">
        <p className="text-sm font-semibold text-gray-700">
          Seite <span className="text-md font-extrabold">{page}</span> von{' '}
          {/* <span className="text-md font-extrabold">{total_pages}</span> of{' '} */}
          <span className="text-md font-extrabold">
            {Math.ceil(total_entries / per_page)}
          </span>{' '}
        </p>
      </div>
      {/* {isFetching && (
        <div className="bg-white w-full items-center justify-self-center flex justify-center py-2">
          <Spinner className="text-orange-700" />
        </div>
      )} */}
      <div className="flex-1 flex justify-between sm:justify-end">
        <Button
          disabled={!isPrevious}
          label={'Vorherige'}
          type={isPrevious ? 'default' : 'disabled'}
          onClick={() => setPage(currentpage - 1)}
          // className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        />

        <Button
          disabled={!isNext}
          label="Nächste"
          type={isNext ? 'default' : 'disabled'}
          onClick={() => setPage(currentpage + 1)}
          className="ml-3"
        />
      </div>
    </nav>
  );
};

export default Pagination;
