import { useState, useEffect } from 'react';
import { Query, ServerResponseType, IDCheck } from 'shared';
import { FetchHandlerWrapper } from '../../shared';
import { List, Pagination } from '../../../styles/components';
import { UserIcon } from '@heroicons/react/solid';
import { classNames } from '../../../styles/utils';
import { statusStyles } from '../ID_check/utils';
import { dataFormat } from '../../../utils';
import { Link } from 'react-router-dom';

const IDcheckSearch = ({
  searchQuery,
}: {
  searchQuery: string | string[] | null;
}) => {
  const [page, setPage] = useState(1);
  const { FetchIDtableDate } = Query();
  const {
    isLoading,
    isError,
    data: idCheckData,
    isFetching,
    isSuccess,
    refetch,
  } = FetchIDtableDate({
    key: 'ID_Check_table',
    page: page,
    searchQuery: searchQuery ? searchQuery : undefined,
    perPage: 5,
    // sortBy: sorteValue ? sorteValue.value : undefined,
  });
  useEffect(() => {
    if (idCheckData && !isFetching) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col my-2">
        <FetchHandlerWrapper
          isError={isError}
          isLoading={isLoading}
          data={idCheckData ? idCheckData : undefined}>
          {(data: ServerResponseType) => (
            <div>
              <List
                items={data.records}
                styles={{
                  ul:
                    'my-4 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0',
                }}>
                {(item: IDCheck) => {
                  //   console.log('data =====', item);
                  return (
                    <Link to={`id_check/${item.id}`}>
                      <div className="group block w-full">
                        <div className="flex items-center py-4 sm:py-4 sm:px-0">
                          <div className="min-w-0 flex-1 flex items-center">
                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <p className="text-orange-500 text-md font-semibold truncate">
                                  {`ID${item.id}`}
                                </p>
                                <p className="mt-2 flex items-center text-sm text-gray-500">
                                  <UserIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="truncate text-gray-500">
                                    {item.prename} {item.surname}
                                  </span>
                                </p>
                              </div>
                              <div className="hidden md:block">
                                <div>
                                  <div className="mt-2 flex items-center text-sm text-gray-500">
                                    <span
                                      className={classNames(
                                        //@ts-ignore
                                        statusStyles[item.approval_state]
                                          .status,
                                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-md font-medium uppercase',
                                      )}>
                                      {/*   @ts-ignore */}
                                      {statusStyles[item.approval_state].label}
                                    </span>
                                  </div>
                                  <p className="text-sm font-semibold text-gray-500 mt-2">
                                    {dataFormat({
                                      date: item.created_at,
                                      option: {
                                        //  hour: false,
                                        month: 'long',
                                      },
                                    })}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                }}
              </List>
            </div>
          )}
        </FetchHandlerWrapper>
      </div>
      <div className="my-2 flex flex-col">
        {isSuccess && (
          <div className="md:-m-5">
            <Pagination
              styles={{
                navClassName:
                  'px-4 py-8 flex  items-center justify-between sm:px-6 min-w-full align-middle  border-t-2',
              }}
              {...idCheckData.pagination}
              isFetching={isFetching}
              setPage={setPage}
              currentpage={page}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default IDcheckSearch;
