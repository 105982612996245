import { Field, FormikProps } from 'formik';

//@ts-ignore
import AsyncSelect from 'react-select/async';
//@ts-ignore
import makeAnimated from 'react-select/animated';
import { BranchOfficesResponseItem, Query } from 'shared';
import { Form } from '../../../shared';
import { addBracnhOfficeValidationSchema } from '../utils';
import { classNames } from '../../../../styles/utils';

const animatedComponents = makeAnimated();
const filedClassName =
  'w-full p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500';
const labelClassName = 'block text-sm font-semibold text-blue-gray-900 mb-1';
const colourStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f97316'
        : isFocused
        ? '#f3f4f6'
        : null,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : data.color,
      // cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  control: (styles: any, state: any) => ({
    ...styles,
    boxShadow: 0,
    borderColor: state.isFocused ? 'orange' : 'rgba(209, 213, 219)',
    '&:hover': {
      borderColor: state.isFocused ? 'orange' : '#CED4DA',
    },
  }),
  // input: styles => ({ ...styles, ...dot() }),
  // placeholder: styles => ({ ...styles, ...dot() }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const ChangeBranchOffice = ({
  setOpen,
  handleAddBranch,
  isNewBranch,
  branchOffice = {
    id: 0,
    name: '',
    //@ts-ignore
    address: {
      id: 0,
      street: '',
      house: '',
      postal_code: '',
      city: '',
    },
    //@ts-ignore
    tax_number: '',
  },
}: {
  setOpen: (open: boolean) => void;
  handleAddBranch: (values: any, formbag?: any) => void;
  branchOffice?: BranchOfficesResponseItem;
  isNewBranch?: boolean;
}) => {
  const { name, tax_number, address, vehicle_brands } = branchOffice;
  const { FetchVehiclesBrands } = Query();

  const promiseOptions = async (inputValue: string) => {
    const getBrands = FetchVehiclesBrands({
      searchQuery: inputValue ? inputValue : undefined,
    });
    const data = await getBrands();
    //@ts-ignore
    return data.records;
    // return datae;
  };

  return (
    <div>
      <Form
        submitForm={handleAddBranch}
        validationSchema={addBracnhOfficeValidationSchema(isNewBranch)}
        initialValues={{
          name: name,
          tax_number: tax_number,
          street: address ? address.street : '',
          house: address ? address.house : '',
          postal_code: address ? address.postal_code : '',
          city: address ? address.city : '',
          accepted: false,
          vehicle_brand_ids: vehicle_brands,
        }}>
        {({
          errors,
          isSubmitting,
          dirty,
          submitCount,
          touched,
          setFieldValue,
        }: FormikProps<any>) => {
          return (
            <div className="mt-4">
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                <div className="sm:col-span-6">
                  <label htmlFor="name" className={labelClassName}>
                    Name
                  </label>
                  <Field className={filedClassName} type="text" name="name" />
                </div>
                <div className="sm:col-span-6">
                  <AsyncSelect
                    onChange={
                      (data: any) =>
                        setFieldValue(
                          'vehicle_brands',
                          data.map((it: any) => it.id),
                        )
                      //  setFieldValue()
                    }
                    placeholder="Marke"
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    isMulti
                    name="vehicle_brands"
                    cacheOptions
                    noOptionsMessage={() => 'Suche nach Marke'}
                    defaultValue={vehicle_brands}
                    loadOptions={promiseOptions}
                    styles={colourStyles}
                    components={animatedComponents}
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="tax_number" className={labelClassName}>
                    USt-IdNr
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="tax_number"
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="street" className={labelClassName}>
                    Straße
                  </label>
                  <Field className={filedClassName} type="text" name="street" />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="house" className={labelClassName}>
                    Hausnummer
                  </label>
                  <Field className={filedClassName} type="text" name="house" />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="postal_code" className={labelClassName}>
                    PLZ
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="postal_code"
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="city" className={labelClassName}>
                    Ort
                  </label>
                  <Field className={filedClassName} type="text" name="city" />
                </div>
                {isNewBranch && (
                  <div className="sm:col-span-6">
                    <div className="flex align-middle">
                      <div className="h-5 mr-2">
                        <Field
                          type="checkbox"
                          name="accepted"
                          className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                        />
                      </div>
                      <div>
                        <p className="text-sm font-semibold text-blue-gray-900">
                          Bitte beachten Sie, dass zusätzliche Filialen weitere
                          Kosten verursachen können.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {touched && Object.keys(errors).length > 0 && (
                <div className="pt-5">
                  <p className="text-red-400 text-sm font-semibold">
                    {Object.values(errors)[0]}
                  </p>
                </div>
              )}
              <div className={`pt-5 flex justify-end `}>
                <button
                  onClick={() => setOpen(false)}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  type="button">
                  Abbrechen
                </button>
                <button
                  disabled={!dirty || isSubmitting || submitCount > 4}
                  className={classNames(
                    dirty &&
                      Object.keys(errors).length === 0 &&
                      !isSubmitting &&
                      submitCount <= 4
                      ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                      : 'bg-gray-400 cursor-not-allowed',
                    'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
                  )}
                  type="submit">
                  {isNewBranch ? 'Kostenpflichtig bestätigen' : 'Speichern'}
                </button>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default ChangeBranchOffice;
