import { useRef, useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface DigitalSignatureProps {
  penColor?: string;
  name: string;
  onEnd?: (dataUrl: string) => void;
  onSignatureData?: (dataUrl: any) => void;
  imageSrc?: string;
  setFieldValue?: any;
  width?: number;
  height?: number;
}

const DigitalSignature = ({
  penColor = 'black',
  name,
  setFieldValue,
  width,
  height,
}: DigitalSignatureProps) => {
  const canvasRef = useRef<SignatureCanvas | null>(null);
  const [showClearButton, setShowClearButton] = useState(false);
  const trimReturnCarSignature = () => {
    if (!canvasRef) return;
    //  console.log('🚀 ~ trimReturnCarSignature ~ ref', canvasRef);
    //@ts-ignore
    const trimmedCanvas = canvasRef?.current?.getTrimmedCanvas();
    if (trimmedCanvas) {
      setShowClearButton(true);
      setFieldValue(name, trimmedCanvas.toDataURL('image/png'));
    }
  };
  useEffect(() => {
    if (!canvasRef) return;
    //@ts-ignore
    canvasRef.current?.clear();
    setShowClearButton(false);
  }, [name]);
  return (
    <div className="container flex flex-col justify-center">
      <div className="h-full relative">
        <div className={`border-2 border-gray-200 sigContainer flex`}>
          <SignatureCanvas
            ref={canvasRef}
            penColor={penColor}
            onEnd={() => {
              if (canvasRef && canvasRef?.current) {
                trimReturnCarSignature();
              }
            }}
            canvasProps={{
              className: 'sigPad',
              width: width || undefined,
              height: height || undefined,
            }}
          />
        </div>
        {showClearButton && (
          <span
            onClick={() => {
              if (!canvasRef) return;
              //@ts-ignore
              canvasRef.current?.clear();
              setFieldValue(name, null);
              setShowClearButton(false);
            }}
            className="z-50 absolute left-2 bottom-2 mt-2 inline-flex items-center rounded-md  px-4 py-1 text-sm font-medium text-white  bg-red-500">
            Neu
          </span>
        )}
      </div>
    </div>
  );
};

export default DigitalSignature;
