/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { IDictionary, DefaultCostRes, Costs, IInsurance, Query } from 'shared';
import { Form } from '../../../../../shared';

import TimePicker from '../../time_picker';
import FormInputCurrencyField from './custom_input';

import DatePicker from '../../date_picker';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './react_dates_overrides.css';
import moment from 'moment';
import 'moment/locale/de';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import dayjs from 'dayjs';

type ContractProp = {
  dictionaryData: IDictionary;
  labelClass: string;
  fieldClassName: string;
  openTab?: any;
  defaltContactCost?: DefaultCostRes;
  selectedCar: any;
};

const ContractInfo = ({
  labelClass,
  fieldClassName,
  openTab,
  dictionaryData,
  defaltContactCost,
  selectedCar,
}: ContractProp) => {
  moment.locale('de');

  const {
    values: {
      //@ts-ignore
      additional_costs_attributes,
      //@ts-ignore
      isFree,
      //@ts-ignore
      start_of_use,
      //@ts-ignore
      end_of_use,
      //@ts-ignore
      with_teilkasko,
      //@ts-ignore
      with_vollkasko,
      //@ts-ignore
      kind: contractType,
    },
    setFieldValue,
    setErrors,
  } = useFormikContext();
  const [startDateObj, setStartDate] = useState(null);
  // const [finalStartDate, setFinalStartDate] = useState(null);
  const [carDateRange, setCarDateRange] = useState([]);
  // console.log('🚀 ~ carDateRange:', carDateRange);
  const [endtDateObj, setEndDate] = useState(null);
  // const [selected, setSelected] = useState('');
  const [startTime, setStartTime] = useState<any>();
  const [endTime, setEndTime] = useState<any>();
  const [disableFreeCostField, setCostField] = useState(false);
  const { FetchCarsContractDateRange } = Query();
  const isPickupdateBeforeReturnDate = dayjs(end_of_use).isBefore(start_of_use);

  // console.log('start_of_use', dayjs(start_of_use).toISOString());
  // console.log('end_of_use========', encodeURIComponent(end_of_use));
  // // console.log('carDateRange', carDateRange);

  useEffect(() => {
    if (startDateObj && startTime) {
      //@ts-ignore
      const combinedObject = startDateObj!.set({
        hour: Number(startTime.hours),
        minute: startTime.minutes ? Number(startTime.minutes) : 0,
      });
      const formatedDate = combinedObject._d;
      // setFinalStartDate(formatedDate);
      // combinedObject.toISOString();
      setFieldValue('start_of_use', formatedDate);
    }
    if (startDateObj && !startTime) {
      setErrors({ start_of_use: 'Bitte wählen Sie die Abholzeit.' });
    }
    // validateDate(isPickupdateBeforeReturnDate, 'start_of_use');
  }, [startTime, startDateObj]);

  useEffect(() => {
    if (endtDateObj && endTime) {
      //@ts-ignore
      const combinedObject = endtDateObj!.set({
        hour: Number(endTime.hours),
        minute: endTime.minutes ? Number(endTime.minutes) : 0,
      });
      const formatedDate = combinedObject._d;
      // getContractDates(formatedDate);
      // console.log('contract calling endpoint', formatedDate);
      setFieldValue('end_of_use', formatedDate);
    }
    if (endtDateObj && !endTime) {
      setErrors({ end_of_use: 'Bitte wählen Sie die Rückgabezeit.' });
    }

    // validateDate(isPickupdateBeforeReturnDate, 'end_of_use');
  }, [endTime, endtDateObj]);

  useEffect(() => {
    const getContractDates = async () => {
      //@ts-ignore
      const getDate = FetchCarsContractDateRange({
        id: selectedCar?.id,
        start_date: dayjs(start_of_use).toISOString(),
        end_date: dayjs(end_of_use).toISOString(),
      });
      const data = await getDate();
      // console.log('🚀 ~ getContractDates ~ data:', data);
      //@ts-ignore
      setCarDateRange(data);
    };
    if (start_of_use && end_of_use) {
      // console.log('run dates function');
      getContractDates();
    }
  }, [start_of_use, end_of_use, startTime, endTime, startDateObj, endtDateObj]);

  useEffect(() => {
    // in the next line we are checking if the pickupdate is before the return date and if it is we are setting the error message.
    if (isPickupdateBeforeReturnDate) {
      // setErrors({}} is used to set the error message for the field usually,
      //  but it did't work for this case  so we are greating a new isPickupdateBeforeReturnDate field in the formik values and we are setting the error message for this field.
      setFieldValue('isPickupdateBeforeReturnDate', '');
    } else {
      setFieldValue('isPickupdateBeforeReturnDate', 'false');
    }
  }, [isPickupdateBeforeReturnDate]);

  useEffect(() => {
    if (contractType && defaltContactCost && defaltContactCost.records.length) {
      const valuesbasedOnContractType = defaltContactCost.records.filter(
        (cost: Costs) => cost.kind === contractType,
      )[0];
      const {
        fahrzeugkaution,
        inkl_km_stundenpauschale,
        inkl_km_tagesgrundpreis,
        stundenpauschale,
        tagesgrundpreis,
        zusatz_km,
        is_free_of_charge,
      } = valuesbasedOnContractType;
      // console.log(
      //   '🚀 ~ useEffect ~ valuesbasedOnContractType:',
      //   valuesbasedOnContractType,
      // );

      setFieldValue('fahrzeugkaution', fahrzeugkaution.cents / 100);
      setFieldValue('inkl_km_stundenpauschale', inkl_km_stundenpauschale);
      setFieldValue('inkl_km_tagesgrundpreis', inkl_km_tagesgrundpreis);
      setFieldValue('stundenpauschale', stundenpauschale.cents / 100);
      setFieldValue('tagesgrundpreis', tagesgrundpreis.cents / 100);
      setFieldValue('zusatz_km', zusatz_km.cents / 100);
      setFieldValue('isFree', is_free_of_charge);
      if (is_free_of_charge) {
        setCostField(true);
      } else {
        setCostField(false);
      }
    } else {
      setFieldValue('isFree', false);
      setCostField(false);
    }
    if (defaltContactCost && defaltContactCost.sonstige_vereinbarungen) {
      setFieldValue(
        'additional_notes',
        defaltContactCost.sonstige_vereinbarungen.text,
      );
    }
  }, [contractType]);
  useEffect(() => {
    if (
      with_teilkasko &&
      defaltContactCost &&
      defaltContactCost.contract_insurences.length
    ) {
      const insuranceBasedOnContractType =
        defaltContactCost.contract_insurences.filter(
          (insurance: IInsurance) => insurance.kind === 'Teilkasko',
        )[0];

      setFieldValue(
        'teilkasko_selbstbeteiligung_amount',
        insuranceBasedOnContractType.selbstbeteiligung.cents / 100,
      );
      // summeErlassSbValue should not be more than 2500;
      const summeErlassSbValue =
        insuranceBasedOnContractType.selbstbeteiligung.cents / 100 > 2500
          ? 2500
          : insuranceBasedOnContractType.selbstbeteiligung.cents / 100;
      setFieldValue('disscount', summeErlassSbValue);
    }
  }, [with_teilkasko]);

  // disable SB insurance when there is no insurance selected;
  useEffect(() => {
    if (!with_vollkasko && !with_teilkasko) {
      setFieldValue('SBInsurance', 'notSelected');
      setFieldValue('disscount', 0);
    }
  }, [with_teilkasko, with_vollkasko]);

  useEffect(() => {
    if (
      with_vollkasko &&
      defaltContactCost &&
      defaltContactCost.contract_insurences.length
    ) {
      const insuranceBasedOnContractType =
        defaltContactCost.contract_insurences.filter(
          (insurance: IInsurance) => insurance.kind === 'Vollkasko',
        )[0];

      setFieldValue(
        'vollkasko_selbstbeteiligung_amount',
        insuranceBasedOnContractType.selbstbeteiligung.cents / 100,
      );
      // summeErlassSbValue should not be more than 2500;
      const summeErlassSbValue =
        insuranceBasedOnContractType.selbstbeteiligung.cents / 100 > 2500
          ? 2500
          : insuranceBasedOnContractType.selbstbeteiligung.cents / 100;
      setFieldValue('disscount', summeErlassSbValue);
      // the customer wants that if the user seledct vollkasko the SB insurance should be selected by default.
      setFieldValue('SBInsurance', 'selected');
    }
  }, [with_vollkasko]);

  return (
    <div className="grid grid-cols-6 gap-6">
      <>
        {/* fist */}
        <div className={'col-span-6'}>
          <label htmlFor={'start_of_use'} className={`${labelClass} mb-1`}>
            Abholung <span className="text-red-500 text-md p-0 ml-1">*</span>
          </label>
          <div className="flex align-middle justify-between">
            {/* @ts-ignore */}
            <div className="w-6/12">
              <Field name={'start_of_use'} type="textarea">
                {({ field }: any) => (
                  <DatePicker
                    isOutsideRange={(date: any) => {
                      const isbefore = date.isAfter(
                        //@ts-ignore
                        endtDateObj,
                        'day',
                      );
                      const isToday = date.isBefore(moment(), 'day');

                      return isbefore || isToday;
                    }}
                    id="start"
                    dateObject={openTab !== 2 ? moment() : startDateObj}
                    setDate={setStartDate}
                  />
                )}
              </Field>
            </div>
            <div className="w-6/12 ml-8">
              <TimePicker
                // disabledHours={endTime ? endTime.hours : 0}
                type="short"
                pickerValue={startTime}
                setValue={setStartTime}
                disabled={!startDateObj && !start_of_use}
              />
            </div>
          </div>
          {/* <div className={'col-span-3'}>
          
          </div> */}
        </div>

        {/* second */}
        <div className="col-span-6 pb-2">
          <label htmlFor="end_of_use" className={`${labelClass} mb-1`}>
            Rückgabe <span className="text-red-500 text-md p-0 ml-1">*</span>
          </label>
          <div className="flex align-middle justify-between">
            <div className="w-6/12">
              <Field name={'end_of_use'} type="textarea">
                {({ field }: any) => (
                  <DatePicker
                    isOutsideRange={(date: any) => {
                      return date.isBefore(
                        //@ts-ignore
                        startDateObj,
                        'day',
                      );
                    }}
                    dateObject={openTab !== 2 ? moment() : endtDateObj}
                    id="end"
                    disabled={!startTime || !startDateObj}
                    setDate={setEndDate}
                  />
                )}
              </Field>
            </div>
            <div className="w-6/12 ml-8">
              <TimePicker
                disabledHours={
                  startTime &&
                  dayjs(endtDateObj).date() === dayjs(startDateObj).date()
                    ? startTime.hours
                    : 0
                }
                type="short"
                pickerValue={endTime}
                setValue={setEndTime}
                disabled={!endtDateObj}
              />
            </div>
          </div>
        </div>
        {carDateRange.length ? (
          <div className="rounded-md bg-red-50 p-4 col-span-6 pb-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Es gibt {carDateRange.length}{' '}
                  {carDateRange.length > 1 ? 'Reservierungen' : 'Reservierung'}{' '}
                  für dieses Auto im von Ihnen gewählten Zeitraum.
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <ul className=" space-y-1 pl-5">
                    {carDateRange.map(
                      (item: {
                        id: number;
                        start_date: string;
                        end_date: string;
                        note: string;
                        user: {
                          email: string;
                          prename: string;
                          surname: string;
                        };
                      }) => {
                        return (
                          <li>
                            <span className="font-bold ">von {''}</span>{' '}
                            <span className="font-bold text-black">
                              {dayjs(item.start_date).format(
                                'dd, DD.MM.YYYY HH:mm',
                              )}
                            </span>
                            <span className="font-bold"> bis</span>{' '}
                            <span className="font-bold text-black">
                              {dayjs(item.end_date).format(
                                'dd, DD.MM.YYYY HH:mm',
                              )}
                            </span>
                            <p className="font-semibold text-black">
                              <span className="font- text-red-700 ">
                                Notiz: {''}
                              </span>{' '}
                              {item.note}
                            </p>
                            <p className="font-bold text-black">
                              durch {''}{' '}
                              {`${item.user.prename} ${item.user.surname}`}
                            </p>
                          </li>
                        );
                      },
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {/* third */}
        <div className="col-span-6 pb-2">
          <h3 className="text-2xl leading-6 font-bold text-gray-900">
            Vertragsart <span className="text-red-500 text-md p-0 ml-1">*</span>
          </h3>
          <p className="text-gray-500 my-1">
            Für welche Art von Miete wollen Sie einen Vertrag schließen?
          </p>
          <div className="mt-2 sm:col-span-2">
            <div className="max-w-lg">
              <Field
                name="kind"
                component={Form.SelectField}
                options={
                  dictionaryData
                    ? Object.values(
                        //@ts-ignore
                        dictionaryData.contract_kinds,
                      ).map((item: any) => ({
                        value: item.name,
                        label: `${item.name}`,
                      }))
                    : { value: '', label: '' }
                }
              />
            </div>
          </div>
        </div>

        <div className="col-span-6 border-t-2 pt-8 border-gray-100">
          <h3 className="text-2xl leading-6 font-bold text-gray-900">Kosten</h3>
          <p className="text-gray-500 my-1">
            Bitte wählen Sie kostenfrei, oder entscheiden Sie auf Tages- oder
            Stundenbasis. Die Kosten werden im nächsten Schritt zu einer
            Übersicht zusammengeführt.
          </p>
        </div>
        <div className="border-b-0 col-span-6 mt-1 space-y-4">
          {/* hiding the checkbox if there is cost from backed */}

          <div className="flex items-start">
            <div className="flex items-center h-5">
              <Field
                type="checkbox"
                name="isFree"
                disabled={disableFreeCostField}
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor="comments" className="font-bold text-gray-700">
                kostenfrei
              </label>
            </div>
          </div>

          {!isFree && (
            <>
              <div className="flex justify-between flex-col lg:flex-row">
                <Field type={'text'} name={'tagesgrundpreis'}>
                  {({ field }: any) => (
                    <FormInputCurrencyField
                      type="number"
                      label="Tagesgrundpreis"
                      field={field}
                    />
                  )}
                </Field>
                <Field type={'text'} name={'inkl_km_tagesgrundpreis'}>
                  {({ field }: any) => (
                    <FormInputCurrencyField
                      type="number"
                      isKm
                      label="inkl. KM (für Tagesgrundpreis)"
                      field={field}
                    />
                  )}
                </Field>
                <Field type={'text'} name={'zusatz_km'}>
                  {({ field }: any) => (
                    <FormInputCurrencyField
                      type="number"
                      label="Zusatzkilometer"
                      field={field}
                    />
                  )}
                </Field>
              </div>
              <div className="flex justify-between flex-col lg:flex-row">
                <Field type={'text'} name={'stundenpauschale'}>
                  {({ field }: any) => (
                    <FormInputCurrencyField
                      type="number"
                      label="Stundenpauschale"
                      field={field}
                    />
                  )}
                </Field>
                <Field type={'text'} name={'inkl_km_stundenpauschale'}>
                  {({ field }: any) => (
                    <FormInputCurrencyField
                      isKm
                      type="number"
                      label="inkl. KM (für Stundenpauschale)"
                      field={field}
                    />
                  )}
                </Field>
                <Field type={'text'} name={'fahrzeugkaution'}>
                  {({ field }: any) => (
                    <FormInputCurrencyField
                      type="number"
                      label="Fahrzeugkaution"
                      field={field}
                    />
                  )}
                </Field>
              </div>
            </>
          )}
        </div>

        <FieldArray name="additional_costs_attributes">
          {({ insert, remove, push }) => (
            <>
              <div className="mt-2 col-span-6">
                <label htmlFor="other" className={labelClass}>
                  Sonstige Gebühren
                  <span className="text-gray-500 block font-normal">
                    Möchten Sie weitere Kosten vereinbaren, wie zum Beispiel
                    Reifen oder die Reinigung? Sie können bis zu 5 weitere
                    Kostenfelder als Pauschalvereinbarungen hinzufügen.
                  </span>
                </label>
                {additional_costs_attributes &&
                  additional_costs_attributes.length > 0 &&
                  additional_costs_attributes.map(
                    (email: any, index: number) => (
                      <div
                        className="felx align-middle mt-2"
                        key={`${index}-${email}`}>
                        <div className="flex justify-between">
                          <div className="w-8/12">
                            <Field
                              placeholder="Bezeichnung"
                              name={`additional_costs_attributes.${index}.name`}
                              type="text"
                              className={fieldClassName}
                            />
                          </div>
                          <div className="w-3/12">
                            <Field
                              name={`additional_costs_attributes.${index}.value`}
                              type="text"
                              className={fieldClassName}>
                              {({ field }: any) => (
                                <FormInputCurrencyField
                                  type="number"
                                  label=""
                                  field={field}
                                />
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                    ),
                  )}
              </div>
              {additional_costs_attributes &&
                additional_costs_attributes.length <= 4 && (
                  <div className="mt-1 col-span-6 justify-self-end text-sm ">
                    <button
                      type="button"
                      className="bg-white border-0 text-orange-400 font-bold hover:text-orange-600"
                      onClick={() => push({ name: '', value: '' })}>
                      + weiteres Feld hinzufügen (max. 5)
                    </button>
                  </div>
                )}
            </>
          )}
        </FieldArray>

        {/* </div> */}

        {/* fourth */}
        <div className="col-span-6 border-b-2 py-8 border-t-2">
          <div>
            <h3 className="text-2xl font-bold text-gray-900">Versicherung</h3>
            <p className="text-sm text-gray-500">
              Entscheiden Sie sich hier für die Versicherungsart, die
              entsprechende Selbstbeteiligung und die Höhe der
              Selbstbeteiligung.
            </p>
          </div>

          <div className="mt-6">
            <div className="bg-white rounded-md">
              <div className="flex text-sm items-center pb-1">
                <Field
                  id="with_teilkasko"
                  type="checkbox"
                  name={`with_teilkasko`}
                  disabled={with_vollkasko}
                />
                <label
                  htmlFor="with_teilkasko"
                  className="text-sm font-bold text-gray-700 ml-2">
                  Teilkasko
                </label>
              </div>
              {/* disable one of the insurance fields when the other one is enabled */}
              {with_teilkasko && !with_vollkasko ? (
                <div className="py-2 w-4/12">
                  <Field
                    disabled
                    type="number"
                    name="teilkasko_selbstbeteiligung_amount">
                    {({ field }: any) => (
                      <FormInputCurrencyField
                        disabled
                        type="number"
                        label={'Beitrag Selbstbeteiligung'}
                        field={field}
                      />
                    )}
                  </Field>
                </div>
              ) : null}
              <div className="flex text-sm items-center py-2">
                <Field
                  id="with_vollkasko"
                  type="checkbox"
                  name={`with_vollkasko`}
                  disabled={with_teilkasko}
                />
                <label
                  htmlFor="with_vollkasko"
                  className="text-sm font-bold text-gray-700 ml-2">
                  Vollkasko
                </label>
              </div>
              {with_vollkasko && !with_teilkasko ? (
                <div className="pt-1 w-4/12">
                  <Field
                    type="number"
                    name={`vollkasko_selbstbeteiligung_amount`}>
                    {({ field }: any) => (
                      <FormInputCurrencyField
                        disabled
                        field={field}
                        type="number"
                        label={'Beitrag Selbstbeteiligung'}
                      />
                    )}
                  </Field>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* fifth */}
        <div className="col-span-6 pb-2">
          {/* <div className="py-5">
            <h3 className="text-2xl leading-6 font-bold text-gray-900 pb-1">
              Vereinbarung
            </h3>
            <p className="text-gray-500">
              Denken Sie bitte an den Datenschutz, zusätzlich können Sie hier
              weitere Vereinbarungen oder Regeln eintragen.
            </p>
          </div> */}
          <div className="pt-2">
            {/* <div className="flex items-start">
              <div className="h-5 flex items-center">
                <Field
                  type="checkbox"
                  name="legal_checked"
                  className="focus:ring-orange-500 h-4 w-4 text-orange-600 border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <p className="text-gray-500">
                  Der Benutzer ist damit einverstanden, dass seine Daten aus
                  diesem Vertrag an den Fahrzeugimporteur bzw. Hersteller weiter
                  gegeben werden dürfen und dort zur Kundenbetreuung genutzt
                  werden. Die Vertragsbedingungen werden vom Benutzer anerkannt.
                  Der Benutzer behandelt das Fahrzeug pfleglich und wird nicht
                  in dem Fahrzeug rauchen.
                </p>
              </div>
            </div> */}
            <div className="pt-5">
              <div className="ml-3 text-sm">
                <label
                  htmlFor="additional_notes"
                  className="block text-sm font-bold text-gray-700 pb-1">
                  Sonstige Vereinbarungen:
                </label>
              </div>
              <div className="mt-1">
                <Field name="additional_notes" type="textarea">
                  {({ field }: any) => (
                    <textarea
                      {...field}
                      className="p-1 md:h-32 shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 border rounded-md"
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ContractInfo;
