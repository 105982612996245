import './index.scss';

type ButtonProps = {
  label?: string;
  onClick?: (e: any) => void;
  size?: 'small' | 'medium' | 'large';
  type?: 'primary' | 'secondary' | 'default' | 'disabled';
  className?: string;
  startIcon?: any;
  endIcon?: any;
  disabled?: boolean;
  submit?: boolean;
};
enum BtnType {
  primary = 'primary',
  secondary = 'secondary',
  default = 'default',
  disabled = 'disabled',
}

const ClassesBasedOnSize = {
  small: 'px-3 py-2 text-sm leading-4',
  medium: 'px-4 py-2 text-base',
  large: ' px-6 py-3 text-base',
};

/* 
   if the Btn component got a type as prop in this case it will take our already choosen style and we can provid sizes,
   or the btn will take a className and got all the styles from outside.
   ex: provide props matching our design 
   <Button 
     size='medium'
     type='default'
     startIcon={<MenuIcon />}
    />
*/

const Button = ({
  size = 'medium',
  disabled,
  label,
  onClick,
  type,
  className,
  startIcon,
  endIcon,
  submit = false,
}: ButtonProps) => {
  const classSize = type ? ClassesBasedOnSize[size] : undefined;
  const classType = type ? `btn-${BtnType[type]}` : undefined;

  return (
    <button
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      type={submit ? 'submit' : 'button'}
      className={`${classSize && classSize} ${classType && classType} ${
        className && className
      }`}>
      {startIcon}
      {label}
      {!startIcon && endIcon}
    </button>
  );
};

export default Button;
