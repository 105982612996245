import { Field, useFormikContext } from 'formik';

import { classNames } from '../../../styles/utils';
//@ts-ignore
// import AsyncSelect from 'react-select/async';
// //@ts-ignore
// import makeAnimated from 'react-select/animated';
// import { useParams } from 'react-router-dom';
import DatePicker from './new_contract/date_picker';
import { createRef, useEffect, useState } from 'react';
//@ts-ignore;
import { CSVLink } from 'react-csv';
import { Query } from 'shared';

const DownloadContract = ({
  setOpen,
}: {
  setOpen: any;

  //   car?: Vehicle; // User type
}) => {
  const {
    setFieldValue,
    isSubmitting,
    touched,
    errors,
    //@ts-ignore
    values: { start_date, end_date },
    //  dirty,
  } = useFormikContext();
  const [startDateObj, setStartDate] = useState(start_date);
  const [endtDateObj, setEndDate] = useState(end_date);
  const [csvData, setData] = useState<any>('');

  const [active, setActive] = useState(false);
  //   console.log('🚀 ~ csvData:', csvData);
  const { getContractCSVfile } = Query();
  const csvLink = createRef();
  useEffect(() => {
    if (startDateObj) {
      //@ts-ignore
      setFieldValue('start_date', startDateObj._d);
    }
  }, [startDateObj, setFieldValue]);

  const handleDownloadContract = async ({ start_date, end_date }: any) => {
    // console.log('values', { start_date, end_date });
    const { data } = await getContractCSVfile(
      `contracts/to_csv_file?start_date=${start_date}&end_date=${end_date}`,
    );
    setData(data);
    setActive(true);
    // console.log('redfjasfasjfgasjfg;jass', data);
  };

  useEffect(() => {
    if (endtDateObj) {
      //@ts-ignore
      //  const combinedObject = endtDateObj!.set({
      //    hour: Number(endTime.hours),
      //    minute: endTime.minutes ? Number(endTime.minutes) : 0,
      //  });
      //  //@ts-ignore
      //  const formatedDate = endtDateObj._d;

      setFieldValue('end_date', endtDateObj._d);
    }
  }, [endtDateObj, setFieldValue]);

  useEffect(() => {
    if (active) {
      //@ts-ignore
      csvLink.current.link.click();
      setOpen(false);
      setActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <div className="-mt-2">
      <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-6 sm:gap-x-6">
        <div className="sm:col-span-6 mb-2">
          <label
            htmlFor={'start_date'}
            className={`block text-sm font-semibold text-gray-700 mb-1`}>
            Start Datum auswählen:
          </label>
          <div className="w-full">
            {/* @ts-ignore */}

            <Field name={'start_date'} type="textarea">
              {({ field }: any) => (
                <DatePicker
                  // isDayBlocked={(date: any) => false}
                  id="start"
                  isOutsideRange={() => false}
                  dateObject={startDateObj}
                  setDate={setStartDate}
                />
              )}
            </Field>
          </div>
        </div>

        <div className="sm:col-span-6 mb-2">
          <label
            htmlFor={'end_date'}
            className={`block text-sm font-semibold text-gray-700 mb-1`}>
            End Datum auswählen:
          </label>
          <div className="w-full">
            {/* @ts-ignore */}

            <Field name={'end_date'} type="textarea">
              {({ field }: any) => (
                <DatePicker
                  isOutsideRange={(date: any) => {
                    return date.isBefore(
                      //@ts-ignore
                      startDateObj,
                      'day',
                    );
                  }}
                  dateObject={endtDateObj}
                  id="end"
                  setDate={setEndDate}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
      {touched && Object.keys(errors).length > 0 && (
        <div className="pt-5">
          <p className="text-red-400 text-sm font-semibold">
            {Object.values(errors)[0]}
          </p>
        </div>
      )}
      <div className={`py-5 flex justify-between`}>
        <button
          onClick={() => setOpen(false)}
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
          type="button">
          Abbrechen
        </button>
        <CSVLink
          ref={csvLink}
          filename={'CSV-Datei.csv'}
          data={String(csvData)}
          asyncOnClick={true}
        />
        <button
          disabled={isSubmitting}
          className={classNames(
            Object.keys(errors).length === 0 && !isSubmitting
              ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
              : 'bg-gray-400 cursor-not-allowed',
            'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
          )}
          //  type="button"
          onClick={() => handleDownloadContract({ start_date, end_date })}>
          CSV exportieren
        </button>
        {/* <Button
                  className="px-3 py-2 btn-default w-56 lg:w-52"
                  label="CSV Export"
                  //@ts-ignore
                  onClick={handleDetailsClick}
                /> */}
      </div>
    </div>
  );
};

export default DownloadContract;
