/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { Spinner } from '../../../../styles/icons';
import { classNames } from '../../../../styles/utils';
import { handleScrollTop } from '../utils';
import { Query } from 'shared';
import config from 'shared/config';

const FormAction = ({
  openTab,
  setOpenTab,
  isLoading,
  hasSBInsurance = false,
  isLightProduct,
}: {
  openTab: number | string;
  setOpenTab: (tabs: string | number) => void;
  isLoading?: boolean;
  hasSBInsurance?: boolean;
  isLightProduct?: boolean;
}) => {
  const {
    //@ts-ignore
    // values: { brand },
    errors,
    isSubmitting,
    validateForm,
  } = useFormikContext();
  const { FetchSbContractValue } = Query();

  const isError = Object.values(errors).length > 0;

  // if the contract is light product and there is just SB contract then  we have only 2 steps
  const lastStepNumber =
    hasSBInsurance && !isLightProduct ? 4 : isLightProduct ? 2 : 3;

  let SbContractPageOpenTab = hasSBInsurance && isLightProduct ? 1 : 3;

  const { data: sbFile } = FetchSbContractValue({
    // fetch sbValues based of start and end date
    key: `sbFile-${SbContractPageOpenTab}`,
    url: `${config.apiURL}/sb_insurance/cost_sets/merkblatt`,
    enabled: hasSBInsurance && SbContractPageOpenTab === openTab,
  });
  const btnlabel = !isLightProduct ? 'Vertrag generieren' : 'SB-Schutz buchen';
  const btnClassName =
    'border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-600';
  const setTabNumberBasedOnValidation = () => {
    const error = Object.keys(errors)[0];
    // console.log('🚀 ~ setTabNumberBasedOnValidation ~ error:', error);
    // getting the error field based on the cotract type as preimum or light with SB or without SB virsicherung.

    const personal = [
      'email',
      'salutation',
      'prename',
      'surname',
      'date_of_birth',
      'identity_document_number',
      'identity_document_number_drive',
      'drivers_liscense_issuing_authority',
      'drivers_license_classes',
      'street',
      'postal_code',
      'city',
      'telephone_number',
    ];
    const car = [
      'name',
      'brand',
      'vin',
      'license_plate',
      'odometer_value',
      'pre_damage',
    ];
    const contractInfo = [
      'start_of_use',
      'end_of_use',
      'kind',
      'isPickupdateBeforeReturnDate',
      'teilkasko_selbstbeteiligung_amount',
      'vollkasko_selbstbeteiligung_amount',
      'legal_checked',
    ];
    const sbContract = [
      'kind',
      'license_plate',
      'vin',
      'start_date',
      'end_date',
      'disscount',
      'sb_sum',
      'payment_method',
    ];
    if (!hasSBInsurance && !isLightProduct) {
      if (personal.indexOf(error) !== -1) {
        return 0;
      }
      if (car.indexOf(error) !== -1) {
        return 1;
      }
      if (contractInfo.indexOf(error) !== -1) {
        if (Number(openTab) >= 2) {
          return 2;
        }
        return Number(openTab) + 1;
      }
      return 0;
    } else if (hasSBInsurance && !isLightProduct) {
      if (personal.indexOf(error) !== -1) {
        return 0;
      }
      if (car.indexOf(error) !== -1) {
        return 1;
      }
      if (contractInfo.indexOf(error) !== -1) {
        if (Number(openTab) >= 2) {
          return 2;
        }
        return Number(openTab) + 1;
      }
      if (sbContract.indexOf(error) !== -1) {
        if (Number(openTab) >= 3) {
          return 3;
        }
        return Number(openTab) + 1;
      }
      return 0;
    } else {
      // console.log('error', error);
      if (personal.indexOf(error) !== -1) {
        return 0;
      } else if (sbContract.indexOf(error) !== -1) {
        // console.log('test error', sbContract.indexOf(error));
        return 1;
      } else {
        return 0;
      }
    }
  };
  // console.log('🚀 ~ sbFile:', sbFile);
  const renderDownloadSBCorrectionButton = () => {
    // we should show the download button only if the contract opentab = SbContractPageOpenTab

    if (openTab === SbContractPageOpenTab) {
      return (
        <a
          href={sbFile && sbFile.url ? config.baseURL + sbFile.url : undefined}
          target="_blank"
          type="button"
          className=" mr-5 bg-white border rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100"
          rel="noreferrer">
          Merkblatt öffnen
        </a>
      );
    }
    return null;
  };
  const handleNextStep = () => {
    validateForm();

    //@ts-ignore
    setOpenTab((prev: number) => Number(prev) + 1);
    handleScrollTop('main-scroll-container');
    // // validateForm();
    // if (!isError) {

    //   // validateForm();
    // } else {
    //   setOpenTab(setTabNumberBasedOnValidation());
    // }
  };

  useEffect(() => {
    if (isError) {
      setOpenTab(setTabNumberBasedOnValidation());
    }
  }, [isError]);

  const handlePerv = () => {
    //@ts-ignore
    setOpenTab((prev: number) => Number(prev) - 1);
    handleScrollTop('main-scroll-container');
  };
  // console.log(
  //   'Object.setTabNumberBasedOnValidation(errors)',
  //   setTabNumberBasedOnValidation(),
  // );
  // console.log('Object.keys(errors)[0]', Object.keys(errors)[0]);
  const isErrorIntheOpenedTab =
    isError && setTabNumberBasedOnValidation() === openTab;

  return (
    <div className="py-3 text-right flex justify-between">
      <div className="order-first">
        {isErrorIntheOpenedTab ? (
          <div className="text-red-500 text-left text-sm font-semibold md:text-base">
            {/* @ts-ignore */}
            {Object.values(errors)[0]}
          </div>
        ) : openTab !== 0 ? (
          <button
            onClick={() => {
              // we should validate on mount by adding validateonmount prop to form
              handlePerv();
            }}
            type="button"
            className=" bg-white border rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-gray-800 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-100">
            Zurück
          </button>
        ) : null}
      </div>

      {Number(openTab) < lastStepNumber && (
        <div>
          {renderDownloadSBCorrectionButton()}
          <button
            onClick={() => {
              // we should validate on mount by adding validateonmount prop to form
              handleNextStep();
            }}
            disabled={isErrorIntheOpenedTab}
            type="button"
            className={`${btnClassName} ${
              isErrorIntheOpenedTab
                ? 'bg-gray-400'
                : 'bg-orange-500 hover:bg-orange-600'
            }`}>
            Weiter
          </button>
        </div>
      )}
      {openTab === lastStepNumber && (
        <button
          disabled={isSubmitting}
          type="submit"
          className={classNames(
            !Object.keys(errors).length && !isSubmitting
              ? 'bg-orange-500 hover:bg-orange-600 cursor-pointer'
              : 'bg-gray-400 cursor-not-allowed',
            'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
          )}
          // className="bg-orange-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {isSubmitting || isLoading ? (
            <div className="flex items-center align-center">
              <span>{btnlabel}</span> <Spinner className="h-4 w-4 ml-2" />
            </div>
          ) : (
            `${btnlabel}`
          )}
        </button>
      )}
    </div>
  );
};

export default FormAction;
