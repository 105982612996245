import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useAbility } from '@casl/react';
import { PlusIcon } from '@heroicons/react/outline';
import { useAuthContext } from '../../../../hooks';
import {
  Button,
  Slideover,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableData,
} from '../../../../styles/components';
import { Query, User } from 'shared';
import { dataFormat } from '../../../../utils';
import { classNames } from '../../../../styles/utils';
import {
  FetchHandlerWrapper,
  SearchSortHeader,
  Modal,
  // currentTabProp,
  NotificationMessage,
} from '../../../shared';
import {
  memberPageSortSelectObject,
  membersPageTablesColumns,
  // MembersPagetabs,
} from '../utils';
import ChangeUsers from './add_new_member';
import { AbilityContext } from '../../../../auth';
import { useHistory } from 'react-router-dom';

const blockUserClass =
  'bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:text-sm';
const Members = () => {
  // const [current, setCurrent] = useState<currentTabProp | null>({
  //   status: '',
  //   id: 0,
  // });
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [newMember, setnewMember] = useState(false);
  const [notificationType, setNotificationType] = useState('');
  const [selectedUserItem, setSelectedUserItem] = useState<User>();
  const [selected, setSelected] = useState<{
    name: string;
    status: string;
    id: number;
  }>();
  const [query, setSearchQuery] = useState('');
  const [showNotification, setShow] = useState(false);
  //@ts-ignore
  const ability = useAbility(AbilityContext);
  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;

  const { id: myId } = user;
  let history = useHistory();
  const titleBasedOnType = {
    NEUUSER: {
      title: 'NutzerIn erfolgreich hinzugefügt',
      subtile: 'Die/Der NutzerIn erhält nun eine Einladung per E-Mail.',
    },
    UPDATEUSER: {
      title: 'Dieser NutzerIn wurde bearbeitet',
      subtile: '',
    },
    BLOCK: {
      title:
        'Der Account wurde gesperrt und der/die Nutzer/in hat ab sofort keinen Zugriff mehr.',
      subtitle: '',
    },
    UNBLOCK: {
      title: '',
      subtile: '',
    },
  };
  const isUserDisabled =
    selectedUserItem && typeof selectedUserItem.locked_at === 'string'
      ? true
      : false;
  const {
    FetchUsersData,
    FetchDictionarysData,
    SubmitDataToserver,
    UpdateUserData,
  } = Query();

  const { data: dictionaryData } = FetchDictionarysData({
    key: 'dictionary-data',
  });
  // console.log('🚀 ~ sdfsdfds ~ user:', [
  //   selectedUserItem,
  //   // ...userOwnerData?.records,
  // ]);
  const {
    isLoading,
    isError,
    data: usersData,
    isFetching,
    refetch,
  } = FetchUsersData({
    key: 'users-index',
    page: page,
    perPage: 7,
    searchQuery: query !== '' ? query : undefined,
    sortBy: selected ? selected.status : undefined,
  });

  // console.log('🚀 ~ Members ~ currentUserFromList:', currentUserFromList);
  const { mutateAsync } = SubmitDataToserver({
    url: 'users',
  });
  const { mutateAsync: assinToAnotherDealerAndLogout } = SubmitDataToserver({
    url: `users/${selectedUserItem?.id}/assign_to_another_dealer_and_logout`,
  });
  const { mutateAsync: blockUser } = UpdateUserData({
    url: `users/lock_user?id=${selectedUserItem?.id}`,
  });
  const { mutateAsync: unBlockUser } = UpdateUserData({
    url: `users/unlock_user?id=${selectedUserItem?.id}`,
  });

  const { mutateAsync: updateUser } = UpdateUserData({
    url: `users/${selectedUserItem?.id}`,
  });

  const handleRowSelect = (rowValue: User) => {
    setSelectedUserItem(rowValue);
  };
  useEffect(() => {
    if (usersData) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, selected]);
  const handleAddMember = async (values: any, formikBag: any) => {
    const { setErrors } = formikBag;
    const { user_owner_id, user_owner_type } = queryString.parse(
      values['user_owner_id'],
    );

    const formData = {
      prename: values.prename,
      surname: values.surname,
      email: values.email,
      user_owner_id: user_owner_id,
      user_owner_type: user_owner_type,
      role: values.role,
      salutation: values.salutation,
    };

    if (newMember) {
      try {
        await mutateAsync({
          user: {
            ...formData,
          },
        });
        refetch();
        setNotificationType('NEUUSER');
        setOpen(false);
        setShow(true);
      } catch (e) {
        setErrors({
          error: 'Error',
        });
      }
    } else {
      try {
        await updateUser({
          prename: values.prename,
          surname: values.surname,
          role: values.role,
          salutation: values.salutation,
          // user_owner_id: user_owner_id,
          // user_owner_type: user_owner_type,
        });
        if (user_owner_id && user_owner_type) {
          await assinToAnotherDealerAndLogout({
            dealer_id: user_owner_id,
            dealer_type: user_owner_type,
          });

          if (
            selectedUserItem?.id === myId &&
            Number(user_owner_id) !== selectedUserItem?.user_owner_id
          ) {
            setNotificationType('UPDATEUSER');
            setOpen(false);
            setShow(true);
            setTimeout(() => {
              localStorage.removeItem('user');
              localStorage.removeItem('token');
              history.push('/login');
            }, 2000);
            return;
          }
        }
        // console.log('formData', user_owner_id, user_owner_type);
        setOpen(false);
        setShow(true);
        setNotificationType('UPDATEUSER');
        refetch();
      } catch (e) {
        setErrors({
          error: 'Error',
        });
      }
      setSelectedUserItem(undefined);
    }
  };
  const handleDeactivateUser = async () => {
    try {
      if (!isUserDisabled) {
        //@ts-ignore
        await blockUser(undefined);
        setNotificationType('BLOCK');
        setShow(true);
      } else {
        //@ts-ignore
        await unBlockUser();
      }
      setOpenModal(false);
      refetch();
    } catch (e) {
      // console.log('e', e);
    }
  };

  // console.log('isUserDisabled', selectedUserItem?.id === myId);
  return (
    <div className="pt-1">
      <div className="mt-1 flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <div className="flex">
          <h2 className="text-2xl font-bold leading-5 text-gray-900 sm:text-3xl sm:truncate">
            NutzerInnen
          </h2>
        </div>
        {ability.can('create', 'user') && (
          <div className="mt-2 flex lg:mt-0 lg:ml-4 lg:w-96">
            <div className="flex flex-shrink w-full lg:justify-end">
              <Button
                startIcon={<PlusIcon className="w-5 h-5 mr-3" />}
                type="primary"
                size="small"
                onClick={() => {
                  setOpen(!open);
                  setnewMember(true);
                }}
                label="Hinzufügen"
                className="lg:ml-3"
              />
            </div>
          </div>
        )}
      </div>
      {/* <div className="mt-5 xl:mt-8">
        <Tabs
          tabs={MembersPagetabs}
          onSelect={setCurrent}
          current={current ? current.id : null}
          counter={10}
        />
      </div> */}

      <div className="flex flex-col mt-5 xl:mt-8 justify-between">
        <SearchSortHeader
          divider={false}
          title={
            usersData
              ? `${usersData.pagination.total_entries} Ergebnisse`
              : undefined
          }
          searchPLaceholder="Suche"
          selectMenuItem={memberPageSortSelectObject}
          onSelect={setSelected}
          selected={selected}
          handleSearch={setSearchQuery}
        />
      </div>
      <div
        className={`mt-5 flex flex-col lg:mt-5  overflow-x-auto overflow-y-hidden lg:overflow-hidden  lg:w-auto`}>
        <FetchHandlerWrapper
          isError={isError}
          isLoading={isLoading}
          data={usersData ? usersData : undefined}>
          {(data: any) => (
            <TableContainer>
              <Table
                isFetching={isFetching}
                hasPagination
                setPage={setPage}
                currentpage={page}
                {...data.pagination}>
                <TableHead>
                  {membersPageTablesColumns.map((item: string) => (
                    <TableCell key={item}>{item}</TableCell>
                  ))}
                </TableHead>
                <TableBody>
                  {data &&
                    //@ts-ignore
                    data.records.map((data: User) => {
                      // const { created_at } = data.contract_short;
                      const {
                        prename,
                        id,
                        surname,
                        email,
                        location,
                        role,
                        last_sign_in_at,
                        locked_at,
                      } = data;

                      // const { brand, kind, name } = data.vehicle_short;
                      return (
                        <TableRow key={id} action={() => handleRowSelect(data)}>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            <div className="ml-0">
                              <div
                                className={`text-lg font-bold ${
                                  id !== 1 ? 'text-gray-900' : 'text-gray-700'
                                }`}>
                                {prename} {surname}
                              </div>
                              <div className="text-sm text-gray-500">
                                {email}
                              </div>
                            </div>
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            {role}
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            {location ? location.city : ''}
                          </TableData>
                          <TableData
                            className={`text-gray-500 w-3/12 font-medium`}>
                            {data.last_sign_in_at
                              ? dataFormat({
                                  date: last_sign_in_at,
                                  option: {
                                    hour: true,
                                    minute: true,
                                  },
                                })
                              : ''}
                          </TableData>

                          <TableData className={`text-right font-medium`}>
                            <div className="flex justify-between">
                              <Button
                                onClick={() => {
                                  setOpenModal(!openModal);
                                  setnewMember(false);
                                }}
                                disabled={
                                  !ability.can('update', 'user') ||
                                  data.id === myId
                                }
                                label={
                                  locked_at
                                    ? 'Account freigeben'
                                    : 'NutzerIn sperren'
                                }
                                //@ts-ignore
                                type={
                                  !ability.can('update', 'user') ||
                                  data.id === myId
                                    ? 'disabled'
                                    : locked_at
                                    ? 'primary'
                                    : 'default'
                                }
                                size="small"
                                className={`md:w-11/12 flex mr-2  ${
                                  ability.can('update', 'user') &&
                                  data.id !== myId &&
                                  typeof locked_at !== 'string'
                                    ? blockUserClass
                                    : ''
                                }`}
                              />

                              <Button
                                onClick={() => {
                                  setOpen(!open);
                                  setnewMember(false);
                                }}
                                disabled={
                                  !ability.can('update', 'user') ||
                                  typeof locked_at === 'string'
                                }
                                label={'Bearbeiten'}
                                //@ts-ignore
                                type={
                                  !ability.can('update', 'user') ||
                                  typeof locked_at === 'string'
                                    ? 'disabled'
                                    : 'default'
                                }
                                size="small"
                                className="md:w-11/12 flex"
                              />
                            </div>
                          </TableData>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </FetchHandlerWrapper>
      </div>
      <Slideover
        open={open}
        toggle={setOpen}
        title={
          newMember
            ? 'NutzerInnen hinzufügen'
            : selectedUserItem
            ? `${selectedUserItem.prename} ${selectedUserItem.surname}`
            : 'Edit'
        }
        subTitle={
          newMember
            ? 'Hier können Sie weitere Nutzer hinzufügen. Wenn Sie die gewünschte Filiale für den Nutzer nicht finden, legen Sie bitte zunächst die Filiale unter "Filialen" an.'
            : undefined
        }
        style={{
          subtitleStyle: newMember ? 'text-white' : undefined,
          titleStyle: newMember ? 'text-white' : undefined,
          closeIconColor: newMember ? 'text-white' : 'text-gray-900',
          headerStyle: newMember ? 'bg-orange-500' : 'bg-white border-b-2',
        }}>
        {newMember ? (
          <ChangeUsers
            loggedInUserId={myId}
            newMember
            dictionaryData={dictionaryData}
            setOpen={setOpen}
            handleAddMember={handleAddMember}
          />
        ) : (
          <ChangeUsers
            loggedInUserId={myId}
            dictionaryData={dictionaryData}
            setOpen={setOpen}
            handleAddMember={handleAddMember}
            user={selectedUserItem}
          />
        )}
      </Slideover>
      <NotificationMessage
        title={
          //@ts-ignore
          titleBasedOnType[notificationType]
            ? //@ts-ignore
              titleBasedOnType[notificationType].title
            : ''
        }
        subTitle={
          //@ts-ignore
          titleBasedOnType[notificationType]
            ? //@ts-ignore
              titleBasedOnType[notificationType].subtile
            : ''
        }
        notify={showNotification}
        setShow={setShow}
      />
      <Modal
        type="warning"
        hasIcon
        setOpen={setOpenModal}
        open={openModal}
        title={isUserDisabled ? 'Account freischalten' : 'Account sperren'}
        subtile={
          isUserDisabled
            ? 'Sind Sie sicher, dass Sie diesen Account wieder aktivieren möchten? Der/die NutzerIn erhält keine Benachrichtigung über die Freischaltung.'
            : 'Sind Sie sicher, dass Sie diesen Account sperren möchten? Der Zugang wird entsprechend verweigert. Sie können die Sperre auch wieder aufheben.'
        }>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={handleDeactivateUser}
            label={isUserDisabled ? 'Account freischalten' : 'Account sperren'}
            type="primary"
            className={classNames(
              isUserDisabled
                ? ''
                : 'focus:ring-red-500 bg-red-600 hover:bg-red-700',
              'w-full justify-center focus:outline-none focus:ring-2 focus:ring-offset-2',
            )}
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            label="Abbrechen"
            type="default"
            className="w-full justify-center hover:bg-gray-50 hover:text-black"
          />
        </div>
      </Modal>
    </div>
  );
};

export default Members;
