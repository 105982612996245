import { Vehicle, Query, Versions } from 'shared';
import { ShieldExclamationIcon, AnnotationIcon } from '@heroicons/react/solid';
import { FetchHandlerWrapper } from '../../../shared';
import { dataFormat } from '../../../../utils';
import { Fragment } from 'react';

const VehiclesDetails = ({ item }: { item: Vehicle }) => {
  const { FetchVehiclesById } = Query();
  const {
    data: vehicleData,
    isError,
    isLoading,
  } = FetchVehiclesById({
    id: item.id,
    key: `vehicle-${item.id}`,
  });

  return (
    <FetchHandlerWrapper
      isError={isError}
      isArray={false}
      isLoading={isLoading}
      data={vehicleData ? vehicleData : undefined}>
      {(vehicle: Vehicle) => {
        return (
          <div className="py-7">
            <h3 className="text-lg font-semibold mb-2">Fahrzeug</h3>
            <div className="flex  justify-between">
              <div className="sm:col-span-1 w-6/12">
                <dt className="mt-1 text-md font-medium text-gray-500">
                  Marke & Typ
                </dt>
                <dd className="mt-0 text-sm font-semibold text-gray-900">
                  {vehicle.brand} {vehicle.name}
                </dd>
                <dt className="mt-3 text-md font-medium text-gray-500">FIN</dt>
                <dd className="mt-1 text-sm text-gray-900">{vehicle.vin}</dd>
                <dt className="mt-3 text-md font-medium text-gray-500">
                  KM-Stand (Übergabe)
                </dt>
                <dd className="mt-0 text-sm font-semibold text-gray-900">
                  {vehicle.odometer_value}
                </dd>
              </div>
              <div className="sm:col-span-1 w-6/12">
                <dt className="mt-1 text-md font-medium  text-gray-500">
                  Amtliches Kennzeichen
                </dt>
                <dd className="mt-0 text-sm font-semibold text-gray-900">
                  {vehicle.license_plate}
                </dd>
                {/* <dt className="mt-3 text-md font-medium  text-gray-500">
                  Schäden
                </dt>
                <dd className="mt-1 text-sm font-semibold text-gray-900">
                  {vehicle.pre_damage}
                </dd> */}
              </div>
            </div>

            <div className="sm:col-span-1 mt-3 mb-7">
              <dt className="my-1 text-md font-medium  text-gray-500">
                Schäden
              </dt>
              {/* @ts-ignore */}
              <div className="flow-root">
                <ul className="-mb-8">
                  {vehicle.versions[0].pre_damage.length > 0
                    ? vehicle.versions[0].pre_damage.map(
                        (damage: Versions, eventIdx: number) => {
                          if (damage.changed_to.trim()) {
                            return (
                              <li key={damage.created_at}>
                                <div className="relative pb-8">
                                  {eventIdx !==
                                    vehicle.versions[0].pre_damage.length - 1 &&
                                  !damage.changed_to ? (
                                    <span
                                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                      aria-hidden="true"
                                    />
                                  ) : null}
                                  <div className="relative flex space-x-3 mt-2">
                                    <div>
                                      <span
                                        className={
                                          'bg-red-600 h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
                                          //   classNames(
                                          //  'bg-gray-400',
                                          //   'bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                          // )
                                        }>
                                        <ShieldExclamationIcon
                                          className="h-4 w-4 text-white"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </div>

                                    <div className="min-w-0 flex-1  flex justify-between space-x-4">
                                      <div>
                                        <Fragment>
                                          <p className="text-sm text-gray-500">
                                            {damage.changed_to}
                                          </p>
                                          {damage.whodunnit && (
                                            <p
                                              // href={''}
                                              className="font-medium text-gray-900">
                                              {damage.whodunnit.prename}{' '}
                                              {damage.whodunnit.surname}
                                            </p>
                                          )}
                                        </Fragment>
                                      </div>

                                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                        <time dateTime={damage.created_at}>
                                          {' '}
                                          {dataFormat({
                                            date: damage.created_at,
                                            option: { hour: true },
                                          })}
                                        </time>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                          return null;
                        },
                      )
                    : 'kein Angabe'}
                </ul>
              </div>
            </div>

            <div className="sm:col-span-1 mt-5">
              <dt className="my-1 text-md font-medium  text-gray-500">
                Sonstige Notizen
              </dt>
              <div className="flow-root">
                <ul className="-mb-8">
                  {vehicle.versions.length &&
                  vehicle.versions[1].short_description.length > 0
                    ? vehicle.versions[1].short_description.map(
                        (notes: Versions, index: number) => {
                          if (notes.changed_to.trim()) {
                            return (
                              <li key={notes.created_at}>
                                <div className="relative pb-8">
                                  {index !==
                                    vehicle.versions[1].short_description
                                      .length -
                                      1 && !notes.changed_to ? (
                                    <span
                                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                      aria-hidden="true"
                                    />
                                  ) : null}
                                  <div className="relative flex space-x-3 mt-2">
                                    <div>
                                      <span
                                        className={
                                          'bg-orange-400 h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white'
                                          //   classNames(
                                          //  'bg-gray-400',
                                          //   'bg-gray-400 h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                          // )
                                        }>
                                        <AnnotationIcon
                                          className="h-4 w-4 text-white"
                                          aria-hidden="true"
                                        />
                                      </span>
                                    </div>

                                    <div className="min-w-0 flex-1  flex justify-between space-x-4">
                                      <div>
                                        <Fragment>
                                          <p className="text-sm text-gray-500">
                                            {notes.changed_to}
                                          </p>
                                          {notes.whodunnit && (
                                            <p
                                              // href={''}
                                              className="font-medium text-gray-900">
                                              {notes.whodunnit.prename}{' '}
                                              {notes.whodunnit.surname}
                                            </p>
                                          )}
                                        </Fragment>
                                      </div>

                                      <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                        <time dateTime={notes.created_at}>
                                          {' '}
                                          {dataFormat({
                                            date: notes.created_at,
                                            option: { hour: true },
                                          })}
                                        </time>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          }
                          return null;
                        },
                      )
                    : 'kein Angabe'}
                </ul>
              </div>
              {/* {vehicle.versions.length &&
              vehicle.versions[1].short_description.length
                ? vehicle.versions[1].short_description.map(
                    (data: Versions, index: number) => {
                      console.log('description', data);
                      return (
                        <Fragment>
                          <p>
                            {data.changed_to ? data.changed_to : 'keine Angabe'}
                          </p>
                          <dt className="my-1 text-md font-medium  text-gray-500 mt-1">
                            hinzugefügt am :{' '}
                            <p className="text-m text-gray-800 mt-1">
                              {dataFormat({
                                date: data.created_at,
                                option: { hour: true },
                              })}
                            </p>
                          </dt>
                          <dt className="my-1 text-md font-medium  text-gray-500 mt-2">
                            NutzerIn:
                            <p className="text-m text-gray-800 mt-1">
                              {' '}
                              {data.whodunnit.prename} {data.whodunnit.surname}
                            </p>
                          </dt>
                        </Fragment>
                      );
                    },
                  )
                : 'keine Angabe'} */}
            </div>
          </div>
        );
      }}
    </FetchHandlerWrapper>
  );
};

export default VehiclesDetails;

// {vehicle.versions.length && vehicle.versions[0].pre_damage.length
//   ? vehicle.versions[0].pre_damage.map((data: Versions) => {
//       console.log('data535353', data);
//       return (
//         <Fragment>
//           <p>
//             {data.changed_to ? data.changed_to : 'keine Angabe'}
//           </p>
//           <dt className="my-1 text-md font-medium  text-gray-500 mt-1">
//             hinzugefügt am :{' '}
//             <p className="text-m text-gray-800 mt-1">
//               {dataFormat({
//                 date: data.created_at,
//                 option: { hour: true },
//               })}
//             </p>
//           </dt>
//           <dt className="my-1 text-md font-medium  text-gray-500 mt-2">
//             NutzerIn:
//             <p className="text-m text-gray-800 mt-1">
//               {' '}
//               {data.whodunnit.prename} {data.whodunnit.surname}
//             </p>
//           </dt>
//         </Fragment>
//       );
//     })
//   : 'keine Angabe'}
