import { Vehicle, Query } from 'shared';
import { Field, FormikProps } from 'formik';
import { Form } from '../../../shared';
import { addCarValidationSchema } from '../../admin/utils';
import { classNames } from '../../../../styles/utils';
//@ts-ignore
import AsyncSelect from 'react-select/async';
//@ts-ignore
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();
const filedClassName =
  'w-full p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500';
const labelClassName = 'block text-sm font-semibold text-blue-gray-900 mb-1';

const colourStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f97316'
        : isFocused
        ? '#f3f4f6'
        : null,
      // color: isDisabled
      //   ? '#ccc'
      //   : isSelected
      //   ? chroma.contrast(color, 'white') > 2
      //     ? 'white'
      //     : 'black'
      //   : data.color,
      // cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
  control: (styles: any, state: any) => ({
    ...styles,
    boxShadow: 0,
    borderColor: state.isFocused ? 'orange' : 'rgba(209, 213, 219)',
    '&:hover': {
      borderColor: state.isFocused ? 'orange' : '#CED4DA',
    },
  }),
  // input: styles => ({ ...styles, ...dot() }),
  // placeholder: styles => ({ ...styles, ...dot() }),
  // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const ChangeCar = ({
  setOpen,
  handleAddCar,
  //@ts-ignore
  car = {
    vin: '',
    kind: '',
    name: '',
    short_description: '',
    license_plate: '',
    pre_damage: '',
    odometer_value: '',
    fuel_kind: '',
    main_driver: '',
  },
}: // isNewCar,
{
  setOpen: any;
  handleAddCar: any;
  car?: Vehicle; // User type
  isNewCar?: boolean;
}) => {
  const {
    vin,
    kind,
    name,
    brand,
    short_description,
    license_plate,
    //@ts-ignore
    predamage,
    odometer_value,
    fuel_kind,
    main_driver,
  } = car;

  const { FetchDictionarysData, FetchVehiclesBrands } = Query();

  const promiseOptions = async (inputValue: string) => {
    const getBrands = FetchVehiclesBrands({
      searchQuery: inputValue ? inputValue : undefined,
    });
    const data = await getBrands();
    //@ts-ignore
    return data.records;
    // return datae;
  };
  const { data: dictionaryData } = FetchDictionarysData({
    key: 'dictionary-data',
  });

  return (
    <div>
      <Form
        submitForm={handleAddCar}
        validationSchema={addCarValidationSchema}
        initialValues={{
          brand: brand,
          vin: vin,
          CarKind: kind,
          name: name,
          odometer_value: odometer_value,
          short_description: short_description,
          license_plate: license_plate,
          pre_damage: predamage ? predamage : '',
          fuel_kind: fuel_kind,
          main_driver: main_driver,
        }}>
        {({
          errors,
          isSubmitting,
          dirty,
          setFieldValue,
          submitCount,
          touched,
          values,
        }: FormikProps<any>) => {
          return (
            <div className="my-4">
              <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                <div className="sm:col-span-6">
                  <label htmlFor="brand" className={labelClassName}>
                    Marke
                  </label>
                  <AsyncSelect
                    onChange={
                      (data: any) => setFieldValue('brand', data.name)
                      //  setFieldValue()
                    }
                    placeholder="Marke"
                    getOptionLabel={(option: any) => option.name}
                    getOptionValue={(option: any) => option.id}
                    // isMulti
                    name="brand"
                    // cacheOptions
                    noOptionsMessage={() => 'Suche nach Marke'}
                    defaultValue={{ name: brand, id: name }}
                    loadOptions={promiseOptions}
                    styles={colourStyles}
                    components={animatedComponents}
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="name" className={labelClassName}>
                    Modell
                  </label>

                  <Field className={filedClassName} type="text" name="name" />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="Treibstoffart" className={labelClassName}>
                    Treibstoffart
                  </label>
                  <Field
                    name="fuel_kind"
                    component={Form.SelectField}
                    options={
                      dictionaryData
                        ? dictionaryData.vehicle_fuel_kinds.map(
                            (kind: { i18n: string; key: string }) => ({
                              value: kind.key,
                              label: kind.i18n,
                            }),
                          )
                        : []
                    }
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="CarKind" className={labelClassName}>
                    Fahrzeugkategorie
                  </label>
                  <Field
                    name="CarKind"
                    component={Form.SelectField}
                    options={
                      dictionaryData
                        ? dictionaryData.vehicle_kinds.map(
                            (kind: { name: string }) => ({
                              value: kind.name,
                              label: kind.name,
                            }),
                          )
                        : []
                    }
                  />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="vin" className={labelClassName}>
                    FIN/ VIN
                  </label>
                  <Field className={filedClassName} type="text" name="vin" />
                </div>
                <div className="sm:col-span-6">
                  <label htmlFor="Kennzeichen" className={labelClassName}>
                    Kennzeichen
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="license_plate"
                  />
                  <label
                    className={`block text-sm text-blue-gray-900 mb-1 mt-2`}>
                    (Eingabe-Format{' '}
                    <span className="text-gray-900 font-bold">D XY 1234</span>{' '}
                    oder <span className="text-red-500 font-bold">D 0123</span>)
                  </label>
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="main_driver" className={labelClassName}>
                    Hauptfahrer
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="main_driver"
                  />
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="pre_damage" className={labelClassName}>
                    Schäden
                  </label>
                  <Field name="pre_damage" type="textarea">
                    {({ field }: any) => (
                      <textarea {...field} className={filedClassName} />
                    )}
                  </Field>
                  {/* <Field
                    className={filedClassName}
                    type="text"
                    name="pre_damage"
                  /> */}
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="odometer_value" className={labelClassName}>
                    KM Stand (Übergabe)
                  </label>
                  <Field
                    className={filedClassName}
                    type="text"
                    name="odometer_value"
                  />
                </div>

                <div className="sm:col-span-6">
                  <label htmlFor="short_description" className={labelClassName}>
                    Sonstige Notizen
                  </label>
                  <Field name="short_description" type="textarea">
                    {({ field }: any) => (
                      <textarea {...field} className={filedClassName} />
                    )}
                  </Field>
                  {/* <Field
                    className={filedClassName}
                    type="text"
                    name="short_description"
                  /> */}
                </div>
              </div>
              {touched && Object.keys(errors).length > 0 && (
                <div className="pt-5">
                  <p className="text-red-400 text-sm font-semibold">
                    {Object.values(errors)[0]}
                  </p>
                </div>
              )}
              <div className={`pt-5 flex justify-end `}>
                <button
                  onClick={() => setOpen(false)}
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                  type="button">
                  Abbrechen
                </button>
                <button
                  disabled={!dirty || isSubmitting || submitCount > 4}
                  className={classNames(
                    dirty &&
                      Object.keys(errors).length === 0 &&
                      !isSubmitting &&
                      submitCount <= 4
                      ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                      : 'bg-gray-400 cursor-not-allowed',
                    'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
                  )}
                  type="submit">
                  Speichern
                </button>
              </div>
            </div>
          );
        }}
      </Form>
    </div>
  );
};

export default ChangeCar;
