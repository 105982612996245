import { Fragment, useState } from 'react';
import { Query } from 'shared';
import { FormikProps } from 'formik';
import {
  useParams,
  useHistory,
  // Link
} from 'react-router-dom';
import { PrinterIcon } from '@heroicons/react/solid';
import {
  Form,
  Modal,
  ContactStatus,
  NotificationMessage,
  ErrorPage,
} from '../../../../shared';
import { Button } from '../../../../../styles/components';
import Steps from '../steps';
// import moment from 'moment';
import FormAction from '../form_action';
import { dataFormat } from '../../../../../utils';
import useStore from '../../../../../ui-state/ui';
import PersonalInfo from './form_section/personal_data';
import FormHeader from '../form_header';
import VehicleInfo from './form_section/vehicles_data';
import SBInsuranceForm from '../sb_contract/sb-insurance-form';
import ContractInfo from './form_section/contract_data';
import ContractOverview from './form_section/contract_overview';
import { validationSchema, setInitialValues } from '../form_data';
import { statusStyles } from '../../utils';
import config from 'shared/config';
import type { ICntractConfig } from '../types';

type paramsProps = {
  id: string;
};

const NewContract = ({
  contractConfig,
  hasSBInsurance,
  isLightProduct = false,
}: {
  contractConfig: Array<ICntractConfig>;
  hasSBInsurance: boolean;
  isLightProduct?: boolean;
}) => {
  const [openTab, setOpenTab] = useState<string | number>(0);
  const [showNotification, setShow] = useState(false);
  const [serverError, setError] = useState('');
  const [headlinError, setHeadlinError] = useState('');
  const [pdfUrl, setPdfUrl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCar, setSelectedCar] = useState<{
    name: string;
    brand: string;
    id: number;
    vehicle_owner_id: number;
  }>();
  const { id } = useParams<paramsProps>();
  const history = useHistory();
  const idCheckSingleValue = useStore((state) => state.idCheckSingleValue);

  const {
    FetchIDCheckDataById,
    SubmitDataToserver,
    FetchDictionarysData,
    FetchNewContractData,
    FetchContractDefaultCosts,
  } = Query();
  const { data: newContractData, isLoading: loadingContractData } =
    FetchNewContractData({
      key: `new-contract-${id}`,
      url: `contracts/new?id_check_id=${id}`,
    });

  const { data: dictionaryData } = FetchDictionarysData({
    key: 'dictionary-data',
  });
  const { data: defaltContactCost } = FetchContractDefaultCosts({
    key: 'default-costs',
  });
  // console.log('🚀 ~ ============:', defaltContactCost);
  const { data: IDCheckData } = FetchIDCheckDataById({
    id: id,
    key: `id_check-${id}`,
  });
  // console.log('newContractData', newContractData);
  //@ts-ignore
  // console.log('🚀 ~ contractConfig:', contractConfig[Number(openTab)]);
  const fieldClassName =
    'mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm';
  const labelClass = 'block text-sm font-medium text-gray-700';
  const propsClass = {
    fieldClassName,
    labelClass,
  };
  const { title, subTitle } = contractConfig[Number(openTab)];

  const { mutateAsync, reset, isLoading } = SubmitDataToserver({
    url: '/contracts',
  });

  const handleSubmitContract = async (values: any) => {
    const {
      start_of_use,
      end_of_use,
      kind,
      // legal_checked,
      tagesgrundpreis,
      inkl_km_tagesgrundpreis,
      zusatz_km,
      stundenpauschale,
      inkl_km_stundenpauschale,
      fahrzeugkaution,
      odometer_value,
      additional_costs_attributes,
      additional_notes,
      vin,
      short_description,
      license_plate,
      pre_damage,
      salutation,
      prename,
      surname,
      email,
      identity_document_number,
      identity_document_type,
      identity_document_number_drive,
      drivers_liscense_issuing_authority,
      drivers_license_classes,
      telephone_number,
      street,
      city,
      postal_code,
      house,
      with_teilkasko,
      teilkasko_selbstbeteiligung_amount,
      with_vollkasko,
      vollkasko_selbstbeteiligung_amount,
      date_of_birth,
      disscount,
      sb_sum,
      payment_method,
      SBInsurance,
    } = values;
    // console.log('🚀 ~ values:', values);
    const formData = {
      contract: {
        id_check_attributes: {
          id: Number(id),
          identity_document_number: identity_document_number,
          identity_document_type: identity_document_type,
          drivers_liscense_id_check_attributes: {
            id:
              newContractData &&
              newContractData.id_check_short &&
              newContractData.id_check_short.drivers_liscense_id_check
                ? newContractData.id_check_short.drivers_liscense_id_check.id
                : '',
            identity_document_number: identity_document_number_drive,
            drivers_license_classes: drivers_license_classes,
            drivers_liscense_issuing_authority:
              drivers_liscense_issuing_authority,
          },
        },
        start_of_use: start_of_use,
        end_of_use: end_of_use,
        vehicle_returned_at: '',
        kind: kind,
        additional_notes: additional_notes,
        // legal_checked: legal_checked,
        tagesgrundpreis: tagesgrundpreis,
        inkl_km_tagesgrundpreis: inkl_km_tagesgrundpreis,
        zusatz_km: zusatz_km,
        stundenpauschale: stundenpauschale,
        inkl_km_stundenpauschale: inkl_km_stundenpauschale,
        fahrzeugkaution: fahrzeugkaution,
        odometer_value_at_start: odometer_value,
        odometer_value_at_end: '',
        additional_costs_attributes: additional_costs_attributes,
        //  additional_costs_attributes.map(
        //   (price: { name: string; value: string }) => ({
        //     name: price.name,
        //     value: String(Number(price.value) * 100),
        //   }),
        // ),
        with_teilkasko: with_teilkasko,
        teilkasko_selbstbeteiligung_amount: teilkasko_selbstbeteiligung_amount,
        with_vollkasko: with_vollkasko,
        vollkasko_selbstbeteiligung_amount: vollkasko_selbstbeteiligung_amount,
        vehicle_attributes: {
          ...selectedCar,
          short_description: short_description,
          license_plate: license_plate,
          vin: vin,
          pre_damage: pre_damage,
          odometer_value: odometer_value,
        },
        customer_attributes: {
          salutation: salutation,
          prename: prename,
          surname: surname,
          email: email,
          date_of_birth: date_of_birth,
          telephone_number: telephone_number,
          address_attributes: {
            street: street,
            house: house,
            postal_code: postal_code,
            city: city,
          },
        },
        ...(hasSBInsurance &&
          SBInsurance === 'selected' && {
            sb_insurance_contract_attributes: {
              start_date: start_of_use,
              end_date: end_of_use,
              id_check_id: Number(id),
              kind: kind,
              contract_data_json: {
                vin: vin,
                license_plate: license_plate,
                disscount: disscount,
                sb_sum: sb_sum,
                payment_method: payment_method,
                // obj_1: {
                //   obj_1attr_key1: 'attr_value1',
                //   obj_1attr_key2: 'attr_value2',
                // },
              },
            },
          }),
      },
    };
    // console.log('🚀 ~ handleSubmitContract ~ formData:', formData);

    try {
      const res = await mutateAsync(formData);
      // console.log('res', res);
      if (res) {
        if (showNotification) {
          setShow(false);
        }
        const contractPdfUrl =
          res.data.unsigned_contract_file && res.data.unsigned_contract_file
            ? res.data.unsigned_contract_file.url
            : undefined;
        if (contractPdfUrl) {
          setPdfUrl(`${config.baseURL}${contractPdfUrl}`);
        }
        setOpenDialog(true);
        reset();
      }
    } catch (e) {
      //@ts-ignore
      if (e.response && e.response.status === 422) {
        //@ts-ignore
        setError(e.response.data.errors[0]);
        //@ts-ignore
        if (e.response.data?.headline) {
          //@ts-ignore
          setHeadlinError(e.response.data.headline);
        }
        setShow(true);
      }
    }
  };

  if (loadingContractData) return null;

  if (!newContractData || isLightProduct) return <ErrorPage />;
  return (
    <div className="flex flex-col -m-5 pb-8">
      <Steps openTab={openTab} currentStepObject={contractConfig} />
      <div className="flex mx-1 sm:mx-6 lg:mx-5 py-5">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Vertrag erstellen
        </h2>
      </div>
      <div className="lg:flex  mx-1 sm:mx-6 lg:mx-5 h-full  flex-wrap justify-between">
        <main className="lg:flex shadow-md rounded-md bg-white lg:w-8/12">
          {/* Primary column */}
          <section
            aria-labelledby="primary-heading"
            className="flex-1 flex flex-col">
            {/* steps content start */}
            <Form
              // validateOnBlur={false}
              // validateOnMount={true}
              submitForm={handleSubmitContract}
              validationSchema={validationSchema}
              initialValues={setInitialValues(newContractData, hasSBInsurance)}>
              {({ values }: FormikProps<any>) => {
                //  console.log('🚀 ~ values:', newContractData);
                return (
                  <>
                    <div className={'px-4 sm:px-6 pt-5'}>
                      <FormHeader title={title} subTitle={subTitle} />
                    </div>
                    <div className={openTab === 0 ? 'flex flex-col' : 'hidden'}>
                      <div className="py-6 px-4 space-y-6 sm:p-6">
                        <PersonalInfo
                          {...propsClass}
                          documentType={
                            newContractData.id_check_short
                              .identity_document_type
                          }
                          dictionaryData={dictionaryData}
                        />
                      </div>
                    </div>

                    <div
                      className={openTab === 1 ? 'flex  flex-col' : 'hidden'}>
                      <div className="py-6 px-4 space-y-6 sm:p-6">
                        <VehicleInfo
                          {...propsClass}
                          setSelectedCar={setSelectedCar}
                        />
                      </div>
                    </div>

                    {/* weiter info ============================ */}

                    <div className={openTab === 2 ? 'flex flex-col' : 'hidden'}>
                      <div className="py-6 px-4 space-y-6 sm:p-6">
                        <ContractInfo
                          {...propsClass}
                          selectedCar={selectedCar}
                          openTab={openTab}
                          dictionaryData={dictionaryData}
                          defaltContactCost={defaltContactCost}
                        />
                      </div>
                    </div>
                    {/* SBInsuranceForm info ============================ */}

                    {hasSBInsurance ? (
                      <div
                        className={openTab === 3 ? 'flex  flex-col' : 'hidden'}>
                        <div className="py-6 px-4 space-y-6 sm:px-6 sm:py-1">
                          <SBInsuranceForm
                            isPremiumCustomer
                            hasSBInsurance={hasSBInsurance}
                            {...propsClass}
                            {...values}
                            openTab={openTab}
                            dictionaryData={dictionaryData}
                            setSelectedCar={setSelectedCar}
                          />
                        </div>
                      </div>
                    ) : null}

                    <div
                      className={
                        contractConfig.length - 1 === openTab
                          ? 'flex  flex-col'
                          : 'hidden'
                      }>
                      <ContractOverview
                        {...values}
                        {...selectedCar}
                        hasSBInsurance={hasSBInsurance}
                      />
                    </div>

                    <div className={'py-6 space-y-6 sm:p-6'}>
                      <FormAction
                        // errors={errors}
                        hasSBInsurance={hasSBInsurance}
                        isLoading={isLoading}
                        openTab={openTab}
                        setOpenTab={setOpenTab}
                      />
                    </div>
                  </>
                );
              }}
            </Form>
            {/* steps content end */}
          </section>
        </main>

        <aside className="lg:w-3/12 lg:block flex-col">
          {idCheckSingleValue && (
            <div className="mt-0 sm:py-4 lg:py-0 w-full">
              <div className="mb-5">
                <ContactStatus
                  //@ts-ignore
                  status={statusStyles[idCheckSingleValue.approval_state]}
                  title={'Ausweisdokument'.toUpperCase()}>
                  <p className="text-sm text-gray-700  whitespace-pre-line xl:w-full md:w-7/12 block">
                    Zuletzt aktualisiert: {/* @ts-ignore */}
                    {dataFormat({
                      date: idCheckSingleValue?.created_at,
                      option: {
                        hour: true,
                        minute: true,
                      },
                    })}
                  </p>
                  {IDCheckData && (
                    <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                      Beauftragt von: {IDCheckData.user.name}
                      {/* {moment(idCheckSingleValue?.created_at)} */}
                    </p>
                  )}
                </ContactStatus>
              </div>
              {newContractData &&
                newContractData.id_check_short &&
                newContractData.id_check_short.drivers_liscense_id_check &&
                newContractData.id_check_short.drivers_liscense_id_check
                  .approval_state && (
                  <ContactStatus
                    // status={
                    //   //@ts-ignore
                    //   statusStyles[
                    //     newContractData.id_check_short.drivers_liscense_id_check
                    //       .approval_state
                    //   ]
                    // }
                    status={
                      //@ts-ignore
                      newContractData.id_check_short.drivers_liscense_id_check
                        .approval_state !== 'NOT_AVAILABLE'
                        ? //@ts-ignore
                          statusStyles[
                            newContractData.id_check_short
                              .drivers_liscense_id_check.approval_state
                          ]
                        : {
                            status: 'bg-gray-400 text-white',
                            label: 'Nicht verfügbar'.toUpperCase(),
                            contractStaus: {
                              border: 'border-gray-600',
                              bg: 'bg-gray-700',
                            },
                          }
                    }
                    title={'Führerschein'.toUpperCase()}>
                    <p className="text-sm text-gray-700  whitespace-pre-line xl:w-full md:w-7/12 block">
                      Zuletzt aktualisiert: {/* @ts-ignore */}
                      {dataFormat({
                        date: idCheckSingleValue?.created_at,
                        option: {
                          hour: true,
                          minute: true,
                        },
                      })}
                    </p>
                    {IDCheckData && (
                      <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                        Beauftragt von: {IDCheckData.user.name}
                        {/* {moment(idCheckSingleValue?.created_at)} */}
                      </p>
                    )}
                  </ContactStatus>
                )}
            </div>
          )}
        </aside>

        <Modal
          hasIcon
          setOpen={setOpenDialog}
          open={openDialog}
          title="Sie haben den Vertrag erfolgreich generiert."
          subtile="Sie können den Vertrag jetzt drucken oder zum Vorgang gehen.">
          <div className="mt-5 sm:mt-6">
            <a href={pdfUrl} target="_blank" rel="noreferrer">
              <Button
                startIcon={<PrinterIcon className="h-5 w-5 text-white mr-2" />}
                onClick={() => {
                  setOpenDialog(false);
                  history.push('/id_check');
                }}
                label="Vertrag drucken"
                type="primary"
                className="w-full justify-center"
              />
            </a>
          </div>
          {/* <div className="mt-5 sm:mt-6">
            <Button
              onClick={() => null}
              label="Ja, sicher"
              type="primary"
              className="w-full justify-center "
            />
          </div> */}
          <div className="mt-5 sm:mt-6">
            <Button
              onClick={() => {
                setOpenDialog(false);
                history.push('/id_check');
              }}
              label="Zum Vorgang"
              type="default"
              className="w-full justify-center "
            />
          </div>
        </Modal>
      </div>
      <NotificationMessage
        isError
        title={headlinError || 'Server Error'}
        subTitle={serverError}
        notify={showNotification}
        setShow={setShow}
      />
    </div>
  );
};

export default NewContract;
