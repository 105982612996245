import {
  PaperClipIcon,
  PencilIcon,
  MailIcon,
  // ExclamationCircleIcon,
} from '@heroicons/react/outline';
import { CheckCircleIcon } from '@heroicons/react/solid';
import {
  ContactStatus,
  FetchHandlerWrapper,
  Form,
  // NotificationMessage,
} from '../../shared';
import { Query, Contract } from 'shared';
import useStore from '../../../ui-state/ui';
import { statusStyles } from './utils/data';

import { useAuthContext } from '../../../hooks';
import { dataFormat } from '../../../utils';
import { Link, useRouteMatch } from 'react-router-dom';
import config from 'shared/config';
import dayjs from 'dayjs';
import SBContractOverview from './new_contract/sb_contract/overview';
import { Button, Slideover } from '../../../styles/components';
import * as yup from 'yup';
import { useState } from 'react';
import { classNames } from '../../../styles/utils';
import { Field, FormikProps } from 'formik';

// const mailList = [
//   {
//     id: 1,
//     title: 'mt@voidmod.com',
//     href: '#',
//     author: { name: 'Leslie Alexander', href: '#' },
//     date: '2d ago',
//     dateTime: '2023-01-23T22:34Z',
//     status: 'active',
//     totalComments: 24,
//   },
//   {
//     id: 2,
//     title: 'sp@voidmod.com',
//     href: '#',
//     author: { name: 'Leslie Alexander', href: '#' },
//     date: '3d ago',
//     dateTime: '2023-01-23T22:34Z',
//     status: 'active',
//     totalComments: 24,
//   },
//   {
//     id: 3,
//     title: 'sa@voidmod.com',
//     href: '#',
//     author: { name: 'Leslie Alexander', href: '#' },
//     date: '1 week ago',
//     dateTime: '2023-01-23T22:34Z',
//     status: 'active',
//     totalComments: 24,
//   },
// ];
const AttachmentSection = ({
  children,
  isSignatureReuired,
  id,
  handleChange,
  checked,
  toggleSideBar,
  mailList = [],
}: {
  children: React.ReactNode;
  isSignatureReuired: boolean;
  mailList?: Array<any>;
  id: number;
  checked: boolean;
  handleChange: () => void;
  toggleSideBar: (value: boolean) => void;
}) => {
  return (
    <div className="py-7">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 bg-orange-500 p-3 border-2 rounded-lg">
        <div className="sm:col-span-2">
          <dt className="text-md font-medium text-white">Anhang</dt>
          <dd className="mt-1 text-sm text-gray-900 bg-white">
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              {children}
            </ul>
          </dd>
          {isSignatureReuired && (
            <dd className="text-sm text-gray-900 bg-white rounded-lg mt-8">
              <ul className="border border-gray-200 rounded-md p-4">
                <h3 className="font-semibold leading-6 text-lg text-gray-700">
                  Digitale Unterschrift
                </h3>
                <p className={`font-medium sm:text-sm text-gray-500 py-2`}>
                  Mit meiner Unterschrift bestätige ich, dass ich den
                  Fahrzeugüberlassungsvertrag gelesen und verstandnen habe.
                </p>
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      checked={checked}
                      onChange={handleChange}
                      // onChange={handle}
                      id="digital_sinature"
                      name="digital_sinature"
                      type="checkbox"
                      aria-describedby="digital_sinature-description"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="ml-2">
                    {/* <label
                      htmlFor="data-privacy"
                      className="font-medium text-gray-900">
                      Comments
                    </label> */}
                    <label
                      className="text-gray-600 text-sm"
                      htmlFor="digital_sinature">
                      Ich erkläre mich mit den Inhalten dieses
                      Fahrzeugübberlassungsvertrages einverstanden.
                    </label>
                  </div>
                </div>
                <div className="flex flex-col py-3 lg:mt-0 w-full">
                  <Link
                    to={`/id_check/online_signature_pdf/${id}`}
                    className="w-full flex flex-col">
                    <Button
                      disabled={checked ? false : true}
                      className="justify-center"
                      startIcon={<PencilIcon className="w-5 h-5 mx-4" />}
                      type={checked ? 'primary' : 'disabled'}
                      size="large"
                      // onClick={() => {
                      //   setOpen(!open);
                      //   setNewCar('NEWCAR');
                      // }}
                      label="Vertrag unterschreiben"
                    />
                  </Link>
                </div>
              </ul>
            </dd>
          )}

          <dd className="text-sm text-gray-900 bg-white rounded-lg mt-8">
            <ul className="border border-gray-200 rounded-md p-4">
              <h3 className="font-semibold leading-6 text-lg text-gray-700">
                Fahrzeugüberlassungsvertrag per E-Mail versenden.
              </h3>
              {/* <p className={`font-medium sm:text-sm text-gray-500 py-2`}>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
              </p> */}

              <div className="flex flex-col py-3 lg:mt-0 w-full">
                <Button
                  // disabled={checked ? false : true}
                  className="justify-center"
                  startIcon={<MailIcon className="w-5 h-5 mx-4" />}
                  type="secondary"
                  size="large"
                  onClick={() => {
                    toggleSideBar(true);
                  }}
                  label="Email Senden"
                />
              </div>
            </ul>
          </dd>
        </div>
      </dl>
    </div>
  );
};
const DetailsListScetion = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="lg:flex-shrink-0">
      <div className="px-2">
        <ul className="divide-y divide-gray-200">{children}</ul>
      </div>
    </div>
  );
};
const ContractDetails = () => {
  const [checked, setChecked] = useState(false);
  const [isOpen, setSideOpen] = useState(false);

  const { params } = useRouteMatch();
  const idCheckSingleValue = useStore((state) => state.idCheckSingleValue);
  const {
    SubmitDataToserver,
    FetchContractDataById,
    FetchContractSendViaEmailList,
  } = Query();

  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  const { plan_type } = user;
  const isSecure = plan_type === 'premium';
  // const history = useHistory();
  const contractId =
    idCheckSingleValue && idCheckSingleValue.contract_short
      ? idCheckSingleValue.contract_short.id
      : params
      ? //@ts-ignore
        params.id
      : undefined;

  const {
    mutateAsync: SubmitContractViaEmail,
    reset: resetSubmitContractViaEmail,
    // isLoading: isSubmitting,
  } = SubmitDataToserver({
    url: `${config.apiURL}/contracts/${contractId}/send_contract_email`,
  });
  const {
    data: mailList,
    // isError: mailIsError,
    // isLoading: mailIsLoading,
    refetch,
  } = FetchContractSendViaEmailList({
    url: `${config.apiURL}/contracts/${contractId}/new_contract_email`,
  });

  // console.log("🚀 ~ ContractDetails ~ headQuearterData:", headQuearterData)

  const { data, isError, isLoading } = FetchContractDataById({
    url: `${config.apiURL}/contracts/${contractId}`,
    key: `contract-${contractId}`,
    enabled: !!contractId,
  });
  // console.log('🚀 ~ ContractDetails ~ mailList:', headQuearterData, data);
  // console.log('🚀 ~ ContractDetails ~ data:', data);
  const pdfAttachment = [
    {
      name: 'Fahrzeugüberlassungsvertrag',
      url: data
        ? data.unsigned_contract_file.url
          ? `${config.baseURL}${data.unsigned_contract_file.url}`
          : null
        : null,
      id: 0,
    },
    {
      name: 'Fahrzeugüberlassungsvertrag signiert',
      url: data
        ? data.signed_contract_file.url
          ? `${config.baseURL}${data.signed_contract_file.url}`
          : null
        : null,
      id: 5,
    },
    {
      name: 'Fahrzeugüberlassungsvertrag signiert, inkl. Fahrzeurückgabe',
      url: data
        ? data.signed_contract_with_vehicle_return_file.url
          ? `${config.baseURL}${data.signed_contract_with_vehicle_return_file.url}`
          : null
        : null,
      id: 5,
    },
    {
      name: 'Selbstbeteiligung unterschrieben',
      url: data
        ? data.sb_insurance_contract?.signed_contract_file?.url
          ? `${config.baseURL}${data.sb_insurance_contract.signed_contract_file.url}`
          : null
        : null,
      id: 2,
    },
    {
      name: 'Selbstbeteiligung',
      url: data
        ? data.sb_insurance_contract?.unsigned_contract_file?.url
          ? `${config.baseURL}${data.sb_insurance_contract.unsigned_contract_file.url}`
          : null
        : null,
      id: 1,
    },
  ];

  const onSubmit = async (values: { email: string }) => {
    const { email } = values;

    try {
      const res = await SubmitContractViaEmail({
        recipient: email,
      });
      if (res) {
        resetSubmitContractViaEmail();
        setSideOpen(false);
        refetch();
      }
    } catch (e) {
      console.log('error', e);
    }
  };
  const handleChange = () => {
    setChecked(!checked);
  };
  // const isSignatureReuired = true;
  // console.log('pdfAttachment', user);
  return (
    <div className="flex flex-col h-full pt-5">
      <FetchHandlerWrapper
        data={data}
        isError={isError}
        isLoading={isLoading}
        isArray={false}>
        {(data: Contract) => {
          const identity_document_type_translated =
            data.id_check_short.identity_document_type === 'IDENTITY_CARD'
              ? 'Personalausweis'
              : 'Reisepass';
          const identity_document_type_drive_translated = 'Führerschein';
          // console.log('data', data);
          return (
            <>
              <div className="flex flex-col">
                <div className="xl:flex flex-wrap justify-between">
                  <main className="xl:flex shadow-md rounded-md bg-white xl:w-8/12">
                    {/* Primary column */}
                    <section
                      aria-labelledby="primary-heading"
                      className="flex-1 flex flex-col">
                      {/* steps content start */}

                      <div className={'flex flex-col mx-1 sm:mx-6 lg:mx-5'}>
                        <DetailsListScetion>
                          {/* 
         we can later map the data based on how the the strucure for can and use data will be 
         driven from the api
         
         */}
                          {[data.customer].map((customer) => (
                            <li key={customer.id} className="py-7">
                              <h3 className="text-lg font-semibold mb-3">
                                Persönliche Daten
                              </h3>
                              {data.id_check_short && (
                                <>
                                  <div>
                                    <h3 className="text-md font-semibold mb-3 text-gray-900">
                                      Personalausweis
                                    </h3>
                                    <div className="flex  justify-between pb-4">
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Vorname
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            data.id_check_short
                                              .prename_from_id_document
                                          }
                                        </dd>
                                      </div>
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Name
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            data.id_check_short
                                              .surname_from_id_document
                                          }
                                        </dd>
                                      </div>
                                    </div>
                                    <div className="flex  justify-between pb-4">
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Ausweistyp
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {identity_document_type_translated}
                                        </dd>
                                      </div>
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Ausweisnummer
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            data.id_check_short
                                              .identity_document_number
                                          }
                                        </dd>
                                      </div>
                                    </div>
                                    <div className="flex justify-between pt-4">
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Geburtsdatum
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {data.id_check_short.date_of_birth}
                                        </dd>
                                      </div>
                                    </div>
                                  </div>

                                  {/* end of Personalausweis section */}

                                  <div className="w-full border-t border-gray-200 my-6" />

                                  {/* start fuhrerschein section */}
                                  <div className="flex flex-col">
                                    <h3 className="text-md font-semibold mb-3 text-gray-900">
                                      Führerschein
                                    </h3>
                                    <div className="flex  justify-between pb-4">
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Vorname
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            data.id_check_short
                                              .drivers_liscense_id_check
                                              .prename_from_id_document
                                          }
                                        </dd>
                                      </div>
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Name
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            data.id_check_short
                                              .drivers_liscense_id_check
                                              .surname_from_id_document
                                          }
                                        </dd>
                                      </div>
                                    </div>

                                    <div className="flex  justify-between pb-4">
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Ausweistyp
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            identity_document_type_drive_translated
                                          }
                                        </dd>
                                      </div>
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Führerscheinnummer
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            data.id_check_short
                                              .drivers_liscense_id_check
                                              .identity_document_number
                                          }
                                        </dd>
                                      </div>
                                    </div>
                                    <div className="flex justify-between py-4">
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Geburtsdatum
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {
                                            data.id_check_short
                                              .drivers_liscense_id_check
                                              .date_of_birth
                                          }
                                        </dd>
                                      </div>
                                    </div>
                                    <div className="flex  justify-between pb-4">
                                      {data.id_check_short
                                        .drivers_liscense_id_check
                                        .drivers_liscense_issuing_authority && (
                                        <div className="sm:col-span-1 w-1/2">
                                          <dt className="text-md font-medium text-gray-500">
                                            Ausstellungsbehörde
                                          </dt>
                                          <dd className="mt-1 text-sm font-semibold text-gray-900">
                                            {
                                              data.id_check_short
                                                .drivers_liscense_id_check
                                                .drivers_liscense_issuing_authority
                                            }
                                          </dd>
                                        </div>
                                      )}

                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Führerscheinklassen
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          {data.id_check_short
                                            .drivers_liscense_id_check
                                            .drivers_license_classes
                                            ? data.id_check_short
                                                .drivers_liscense_id_check
                                                .drivers_license_classes
                                            : 'N/A'}
                                        </dd>
                                      </div>
                                    </div>
                                    <div className="flex  justify-between pb-4">
                                      <div className="sm:col-span-1 w-1/2">
                                        <dt className="text-md font-medium text-gray-500">
                                          Anmerkung:
                                        </dt>
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          Die Führerscheinklasse wurde überprüft
                                          und ist gültig.
                                        </dd>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              {/* end of Fuhrerschein section */}
                              <div className="w-full border-t border-gray-200 my-6" />

                              <div className="">
                                <div className="flex  justify-between">
                                  <div className="sm:col-span-1 w-1/2">
                                    <dt className="text-md font-medium text-gray-500">
                                      Kunde
                                    </dt>
                                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                                      {customer.prename} {customer.surname}
                                    </dd>
                                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                                      {customer.telephone_number}
                                    </dd>
                                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                                      {customer.email}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1 w-1/2">
                                    <dt className="text-md font-medium  text-gray-500">
                                      Adresse
                                    </dt>
                                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                                      <span className="break-words block">
                                        {customer.address.street},
                                        {customer.address.house}
                                      </span>
                                      <span className="break-words">
                                        {customer.address.postal_code}{' '}
                                        {customer.address.city}
                                      </span>
                                    </dd>
                                  </div>
                                </div>
                                <div className="flex justify-between pt-4">
                                  <div className="sm:col-span-1 w-1/2">
                                    <dt className="text-md font-medium text-gray-500">
                                      Geburtsdatum
                                    </dt>
                                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                                      {data.id_check_short.date_of_birth}
                                    </dd>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                          {[data.vehicle].map((vehicle) => (
                            <li key={vehicle.id} className="py-7">
                              <h3 className="text-lg font-semibold mb-2">
                                Fahrzeug
                              </h3>
                              <div className="flex  justify-between">
                                <div className="sm:col-span-1 w-6/12">
                                  <dt className="mt-1 text-md font-medium text-gray-500">
                                    Marke & Typ
                                  </dt>
                                  <dd className="mt-0 text-sm font-semibold text-gray-900">
                                    {vehicle.brand} {vehicle.name}
                                  </dd>
                                  <dt className="mt-3 text-md font-medium text-gray-500">
                                    FIN
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {vehicle.vin}
                                  </dd>
                                  <dt className="mt-3 text-md font-medium text-gray-500">
                                    KM-Stand (Übergabe)
                                  </dt>
                                  <dd className="mt-0 text-sm font-semibold text-gray-900">
                                    {data.odometer_value_at_start}
                                  </dd>
                                </div>
                                <div className="sm:col-span-1 w-6/12">
                                  <dt className="mt-1 text-md font-medium  text-gray-500">
                                    Amtliches Kennzeichen
                                  </dt>
                                  <dd className="mt-0 text-sm font-semibold text-gray-900">
                                    {vehicle.license_plate}
                                  </dd>
                                  <dt className="mt-3 text-md font-medium  text-gray-500">
                                    Schäden
                                  </dt>
                                  <dd className="mt-1 text-sm font-semibold text-gray-900">
                                    {vehicle.predamage
                                      ? vehicle.predamage
                                      : 'keine Angabe'}
                                  </dd>
                                  <dt className="mt-3 text-md font-medium  text-gray-500">
                                    KM-Stand (Rückgabe)
                                  </dt>
                                  <dd className="mt-1 text-sm font-semibold text-gray-900">
                                    {data.odometer_value_at_end}
                                  </dd>
                                </div>
                              </div>
                              <div className="sm:col-span-1 mt-3">
                                <dt className="my-1 text-md font-medium  text-gray-500">
                                  Sonstige Notizen
                                </dt>
                                <p>
                                  {vehicle.short_description
                                    ? vehicle.short_description
                                    : 'keine Angabe'}
                                </p>
                              </div>
                            </li>
                          ))}
                          {
                            <li className="py-7">
                              <h3 className="text-lg font-semibold mb-2">
                                Weitere Vertragsdaten
                              </h3>
                              <div className="col-span-1 mb-2">
                                <dt className="mt-1 text-md font-medium text-gray-500">
                                  Vertragsart
                                </dt>
                                <dd className="mt-0 text-sm font-semibold text-gray-900">
                                  {data.kind}
                                </dd>
                              </div>
                              <div className="flex  justify-between">
                                <div className="sm:col-span-1 w-1/2">
                                  <dt className="mt-1 text-md font-medium text-gray-500">
                                    Übergabe
                                  </dt>
                                  <dd className="mt-0 text-sm font-semibold text-gray-900">
                                    {dayjs(data.start_of_use).format(
                                      'DD.MM.YYYY',
                                    )}{' '}
                                    |{' '}
                                    {/* {moment(data.start_of_use).format('HH:MM')} */}
                                    {dayjs(data.start_of_use).format('HH:mm')}
                                  </dd>
                                  <dt className="mt-3 text-md font-medium text-gray-500">
                                    Kosten
                                  </dt>
                                  <dl className="py-1">
                                    {data.tagesgrundpreis &&
                                    data.tagesgrundpreis.cents !== 0 ? (
                                      <div className="py-1 my-1">
                                        <dt className="text-sm font-medium text-gray-500">
                                          Tagesgrundpreis
                                        </dt>

                                        <dd className="mt-1 text-sm text-gray-900 ">
                                          {data.tagesgrundpreis.cents / 100} €
                                        </dd>
                                      </div>
                                    ) : null}
                                    {data.stundenpauschale &&
                                    data.stundenpauschale.cents !== 0 ? (
                                      <div className="py-1 ">
                                        <dt className="text-sm font-medium text-gray-500">
                                          Stundenpauschale
                                        </dt>

                                        <dd className="mt-1 text-sm text-gray-900 ">
                                          {data.stundenpauschale.cents / 100} €
                                        </dd>
                                      </div>
                                    ) : null}
                                    {data.zusatz_km ? (
                                      <div className="py-1 ">
                                        <dt className="text-sm font-medium text-gray-500">
                                          Zusatzkilometer
                                        </dt>

                                        <dd className="mt-1 text-sm text-gray-900 ">
                                          {data.zusatz_km.cents / 100} €
                                        </dd>
                                      </div>
                                    ) : null}
                                    {data.fahrzeugkaution &&
                                    data.fahrzeugkaution.cents !== 0 ? (
                                      <div className="py-1 ">
                                        <dt className="text-sm font-medium text-gray-500">
                                          Fahrzeugkaution
                                        </dt>

                                        <dd className="mt-1 text-sm text-gray-900 ">
                                          {data.fahrzeugkaution.cents / 100} €
                                        </dd>
                                      </div>
                                    ) : null}
                                    {data.additional_costs &&
                                    data.additional_costs.length > 0 ? (
                                      <div className="py-1 ">
                                        {data.additional_costs.map(
                                          (item: any) => {
                                            if (item.name !== '') {
                                              return (
                                                <>
                                                  <dt className="text-sm font-medium text-gray-500">
                                                    {item.name}
                                                  </dt>

                                                  <dd className="mt-1 text-sm text-gray-900 ">
                                                    {item.value.cents} €
                                                  </dd>
                                                </>
                                              );
                                            }
                                            return null;
                                          },
                                        )}
                                      </div>
                                    ) : null}
                                    {data.tagesgrundpreis.cents === 0 &&
                                      !data.stundenpauschale &&
                                      !data.zusatz_km &&
                                      data.fahrzeugkaution.cents === 0 &&
                                      data.additional_costs.length === 0 && (
                                        <dd className="mt-1 text-sm font-semibold text-gray-900">
                                          keine Angabe
                                        </dd>
                                      )}
                                  </dl>
                                </div>
                                <div className="sm:col-span-1 w-1/2">
                                  <dt className="mt-1 text-md font-medium  text-gray-500">
                                    Rückgabe
                                  </dt>
                                  <dd className="mt-0 text-sm font-semibold text-gray-900">
                                    {dayjs(data.end_of_use).format(
                                      'DD.MM.YYYY',
                                    )}{' '}
                                    | {dayjs(data.end_of_use).format('HH:mm')}
                                  </dd>
                                  <dt className="mt-3 text-md font-medium  text-gray-500">
                                    Versicherung
                                  </dt>

                                  {data.with_teilkasko ? (
                                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                                      Teilkasko mit{' '}
                                      {data
                                        .teilkasko_selbstbeteiligung_amount_cents
                                        .cents / 100}
                                      ,- EUR Selbstbeteiligung
                                    </dd>
                                  ) : null}
                                  {data.with_vollkasko ? (
                                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                                      Vollkasko mit{' '}
                                      {data
                                        .vollkasko_selbstbeteiligung_amount_cents
                                        .cents / 100}
                                      ,- EUR Selbstbeteiligung
                                    </dd>
                                  ) : null}
                                  {!data.with_teilkasko &&
                                    !data.with_vollkasko && (
                                      <dd className="mt-1 text-sm font-semibold text-gray-900">
                                        keine Angabe
                                      </dd>
                                    )}
                                </div>
                              </div>
                              <div className="sm:col-span-1 mt-3">
                                <dt className="my-1 text-md font-medium  text-gray-500">
                                  Sonstige Notizen
                                </dt>
                                <p>
                                  {data.additional_notes
                                    ? data.additional_notes
                                    : 'keine Angabe'}
                                </p>
                              </div>
                            </li>
                          }
                          {data.sb_insurance_contract && (
                            <li className="pt-1 pb-7">
                              {/* <h3 className="text-lg font-semibold ">
                              Selbstbeteiligungschutz
                            </h3> */}
                              <SBContractOverview
                                ddClassName={
                                  'mt-1 text-sm font-semibold text-gray-900'
                                }
                                dtClassName="mt-3 text-md font-medium  text-gray-500"
                                license_plate={
                                  data.sb_insurance_contract?.contract_data_json
                                    .license_plate
                                }
                                isContractOverviewPage
                                kind={data.sb_insurance_contract?.kind}
                                vin={
                                  data.sb_insurance_contract?.contract_data_json
                                    .vin
                                }
                                start_of_use={data.start_of_use}
                                end_of_use={data.end_of_use}
                                disscount={
                                  data.sb_insurance_contract?.contract_data_json
                                    .disscount
                                }
                                sb_sum={
                                  data.sb_insurance_contract?.contract_data_json
                                    .sb_sum
                                }
                                payment_method={
                                  data.sb_insurance_contract?.contract_data_json
                                    .payment_method
                                }
                              />
                            </li>
                          )}
                        </DetailsListScetion>
                      </div>

                      {/* steps content end */}
                    </section>
                  </main>

                  <aside className="xl:w-3/12 xl:block flex-col mt-7 xl:mt-0">
                    <ContactStatus
                      //@ts-ignore
                      status={statusStyles[data.id_check_short.approval_state]}
                      title={'Ausweisdokument'.toUpperCase()}>
                      <p className="text-sm text-gray-700">
                        {`Zuletzt aktualisiert: ${dataFormat({
                          date: data.id_check_short.created_at,
                          option: {
                            hour: true,
                          },
                        })}`}
                      </p>
                      {data.id_check_short && data.id_check_short.user && (
                        <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                          Beauftragt von: {data.id_check_short.user.name}
                          {/* {moment(idCheckSingleValue?.created_at)} */}
                        </p>
                      )}
                    </ContactStatus>
                    {
                      //@ts-ignore
                      isSecure &&
                        data.id_check_short.drivers_liscense_id_check &&
                        data.id_check_short.drivers_liscense_id_check
                          .approval_state && (
                          <div className="mt-7">
                            <ContactStatus
                              status={
                                //@ts-ignore
                                statusStyles[
                                  data.id_check_short.drivers_liscense_id_check
                                    .approval_state
                                ]
                              }
                              title={'Führerschein'.toUpperCase()}>
                              <p className="text-sm text-gray-700">
                                {`Zuletzt aktualisiert: ${dataFormat({
                                  date: data.id_check_short.created_at,
                                  option: {
                                    hour: true,
                                  },
                                })}`}
                              </p>
                              {data.id_check_short && data.id_check_short.user && (
                                <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                                  Beauftragt von:{' '}
                                  {data.id_check_short.user.name}
                                  {/* {moment(idCheckSingleValue?.created_at)} */}
                                </p>
                              )}
                            </ContactStatus>
                          </div>
                        )
                    }

                    {pdfAttachment.filter((item: any) => item.url).length ? (
                      <AttachmentSection
                        mailList={mailList}
                        toggleSideBar={setSideOpen}
                        checked={checked}
                        handleChange={handleChange}
                        isSignatureReuired={
                          !data.has_contract_signatures &&
                          data?.dealer?.uses_digital_signing
                        }
                        id={contractId}>
                        {pdfAttachment
                          .filter((item: any) => item.url)
                          .map((item: any) => (
                            <li
                              className="pl-3 pr-4 py-4 flex lg:items-center lg:justify-between text-sm lg:flex-row flex-col"
                              key={item.id}>
                              <div className="w-0 flex-1 flex items-center">
                                <PaperClipIcon
                                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-2 flex-1 w-0">
                                  {item.name}
                                </span>
                              </div>

                              <div className="lg:ml-4 flex-shrink-0 mt-1 lg:mt-0">
                                <a
                                  href={item.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="font-medium text-orange-500 hover:text-orange-700">
                                  Anzeigen
                                </a>
                              </div>
                            </li>
                          ))}
                      </AttachmentSection>
                    ) : null}
                  </aside>
                </div>
              </div>
            </>
          );
        }}
      </FetchHandlerWrapper>
      <Slideover
        open={isOpen}
        toggle={setSideOpen}
        title={'Fahrzeugüberlassungsvertrag versenden'}
        // subTitle={'Bitte geben Sie den gewünschten Email address'}
        style={{
          subtitleStyle: 'text-white',
          titleStyle: 'text-white',
          closeIconColor: 'text-white',
          headerStyle: 'bg-orange-500',
        }}>
        <Form
          submitForm={onSubmit}
          validationSchema={yup.object({
            email: yup
              .string()
              .email(
                'Die eingegebene E-Mail-Adresse ist ungültig, bitte überprüfen Sie das Format.',
              )
              .defined('E-Mail ist erforderlich')
              .typeError(
                'Die eingegebene E-Mail-Adresse ist ungültig, bitte überprüfen Sie das Format.',
              ),
          })}
          initialValues={{
            email: data && data.customer ? data.customer.email : '',
          }}>
          {({
            errors,
            isSubmitting,
            dirty,
            values,
            submitCount,
            touched,
            setFieldValue,
          }: FormikProps<any>) => {
            // console.log('🚀 ~ ContractDetails ~ vvalues:', values);
            return (
              <dd className="text-sm text-gray-900 bg-white rounded-lg mt-2">
                <ul>
                  <h3 className="font-semibold leading-6 text-lg text-gray-700">
                    via Email senden
                  </h3>
                  <p
                    className={`font-medium sm:text-sm text-gray-500 pb-6 pt-1`}>
                    Durch betätigen des Senden-Buttons wird die letzte Version
                    des Fahrzeugüberlassungsvertrages an die angegebene
                    E-Mailadresse verschickt. Die E-Mailadresse kann bei Bedarf
                    überschrieben werden.
                  </p>
                  <div
                    className={classNames(
                      touched ? 'my-2' : 'my-1',
                      'relative  rounded-md shadow-sm',
                    )}>
                    <Field
                      placeholder="Email"
                      name="email"
                      type="email"
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                    />
                  </div>
                  <div className="flex flex-col mb-3 lg:mt-0 w-full">
                    {touched && Object.keys(errors).length > 0 && (
                      <div className="sm:col-span-3">
                        <p className="text-red-400 pt-2 text-sm font-semibold">
                          {Object.values(errors)[0]}
                        </p>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col my-1 lg:mt-0 w-full">
                    <button
                      disabled={isSubmitting || submitCount > 4}
                      className={classNames(
                        Object.keys(errors).length === 0 &&
                          !isSubmitting &&
                          submitCount <= 4
                          ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                          : 'bg-gray-400 cursor-not-allowed',
                        'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      )}
                      type="submit">
                      Senden
                    </button>
                  </div>
                  <h2 className="text-sm font-semibold leading-6 text-gray-900 py-3">
                    Emails:
                  </h2>
                  <ul className="my-4 space-y-6">
                    {mailList &&
                      mailList.length &&
                      mailList.map(
                        (activityItem: any, activityItemIdx: number) => (
                          <li
                            key={activityItem.id}
                            className="relative flex gap-x-4">
                            <div
                              className={classNames(
                                activityItemIdx === mailList.length - 1
                                  ? 'h-6'
                                  : '-bottom-6',
                                'absolute left-0 top-0 flex w-6 justify-center',
                              )}>
                              <div className="w-px bg-gray-200" />
                            </div>
                            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                              <CheckCircleIcon
                                aria-hidden="true"
                                className="h-10 w-10 text-green-400"
                              />
                            </div>
                            <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                              <span className="font-medium text-gray-900">
                                {activityItem.recipient}
                              </span>{' '}
                              {/* {activityItem.type} the invoice. */}
                            </p>
                            <time
                              dateTime={activityItem.sent_at}
                              className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                              {` ${dataFormat({
                                date: data.id_check_short.created_at,
                                option: {
                                  hour: true,
                                },
                              })}`}
                            </time>
                          </li>
                        ),
                      )}
                  </ul>
                </ul>
              </dd>
            );
          }}
        </Form>
      </Slideover>
    </div>
  );
};

export default ContractDetails;
