// import { Link } from 'react-router-dom';
import ErrorLogo from 'assets/images/error_page.png';

const ErrorPage = () => {
  return (
    <div className="pt-16 pb-12 flex flex-col ">
      <main className="flex flex-col justify-center max-w-3xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 flex justify-center ">
          <a href="/" className="inline-flex">
            <span className="sr-only">Workflow</span>
            <img className="h-20 w-auto" src={ErrorLogo} alt="" />
          </a>
        </div>
        <div className="py-5">
          <div className="text-center">
            {/* <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
              404 error
            </p> */}
            <h1 className="mt-2 text-2xl font-extrabold text-orange-500 tracking-tight sm:text-3xl">
              Diese Seite ist nicht verfügbar
            </h1>
            <p className="mt-2 text-base text-gray-700">
              Die von Ihnen angeforderten Inhalte können derzeit nicht angezeigt
              werden. Sie sind eventuell vorübergehend nicht verfügbar, der von
              Ihnen angeklickte Link kann abgelaufen sein oder Sie haben keine
              Berechtigung zum Abrufen dieser Seite.
            </p>
            {/* <div className="mt-6">
              <Link
                to="/dashboard"
                className="text-base font-medium text-orange-500 hover:text-orange-500">
                Zurück<span aria-hidden="true"> &rarr;</span>
              </Link>
            </div> */}
          </div>
        </div>
      </main>
      {/* <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Contact Support
          </a>
          <span
            className="inline-block border-l border-gray-300"
            aria-hidden="true"
          />
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Status
          </a>
          <span
            className="inline-block border-l border-gray-300"
            aria-hidden="true"
          />
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600">
            Twitter
          </a>
        </nav>
      </footer> */}
    </div>
  );
};

export default ErrorPage;
