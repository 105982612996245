import { ExclamationCircleIcon, CheckIcon } from '@heroicons/react/solid';

const FieldWithFeedBack = ({
  children,
  error,
  active = false,
}: {
  children: React.ReactNode;
  error: any;
  active?: boolean;
}) => {
  return (
    <div className="mt-1 relative rounded-md shadow-sm">
      {children}
      {/* adding active props to beeing able to remove the icon from some field based on status 'requred' || 'notrequired' */}
      {active ? (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {/* @ts-ignore */}
          {error === false ? null : typeof error === 'string' ? (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : (
            <CheckIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FieldWithFeedBack;
