import { Fragment, useState } from 'react';

import { useParams } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/solid';

import { Query, IDCheck } from 'shared';
import { Field } from 'formik';

import {
  statusStyles,
  customerPageFormField,
  customerPageValidationSchema,
} from './utils';
import { classNames } from '../../../styles/utils';
// import { Button } from '../../../styles/components';
import { FetchHandlerWrapper, Form, ContactStatus } from '../../shared';
// import useStore from '../../../ui-state/ui';
import { dataFormat } from '../../../utils';
import { useAuthContext } from '../../../hooks';
// import config from 'shared/config';

type paramsProps = {
  customerId: string;
};

interface ICustomerDetails extends IDCheck {
  canChangeField?: boolean;
  title?: string;
  subTitle?: string;
  isSecure?: boolean;
  isDriveLic?: boolean;
}
const PersonaInfo = ({
  identity_document_type,
  identity_document_number,
  surname_from_id_document,
  prename_from_id_document,
  date_of_birth,
  canChangeField,
  title = 'Persönliche Daten',
  subTitle,
  drivers_liscense_id_check,
  isSecure = true,
  isDriveLic = false,
}: ICustomerDetails) => {
  const [editable, setEditable] = useState(false);
  // console.log('date_of_birth', date_of_birth);
  return (
    <div className="lg:w-8/12 py-4 mt-4 w-full">
      <Form
        submitForm={(values) => null}
        validationSchema={customerPageValidationSchema}
        initialValues={{
          prename: !isDriveLic
            ? prename_from_id_document
            : drivers_liscense_id_check.prename_from_id_document
            ? drivers_liscense_id_check.prename_from_id_document
            : 'nicht verfügbar',
          surname: !isDriveLic
            ? surname_from_id_document
            : drivers_liscense_id_check.surname_from_id_document ||
              'nicht verfügbar',
          identity_document_type: isDriveLic
            ? drivers_liscense_id_check.identity_document_type
            : identity_document_type,
          identity_document_number: isDriveLic
            ? drivers_liscense_id_check.identity_document_number
            : identity_document_number,
          date_of_birth: date_of_birth ? date_of_birth : '',
        }}>
        <div className="md:flex md:items-center md:justify-between xl:border-b md:space-x-4 lg:px-4 pb-2">
          <div>
            <h1 className="lg:text-xl font-bold text-gray-900">{title}</h1>
            {subTitle && (
              <p className="mt-1 text-sm text-gray-500">{subTitle}</p>
            )}
          </div>

          <div className="mt-4 flex space-x-3 md:mt-0">
            {canChangeField && (
              <button
                onClick={() => setEditable(!editable)}
                type={editable ? 'button' : 'submit'}
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900">
                {!editable ? (
                  <PencilIcon
                    className="-ml-1 mr-2 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : null}
                <span>{!editable ? 'Edit' : 'Speichern'}</span>
              </button>
            )}
          </div>
        </div>
        {/* <div className="xl:flex  xl:w-full hidden" /> */}

        {isSecure ? (
          <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 py-2 px-4">
            {customerPageFormField.map(
              (field: { name: string; label: string }) => (
                <div key={field.name} className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">
                    {/* this line is because we need to change the name of lable for Führerscheinnummer instead of Ausweisnummer  */}
                    {isDriveLic &&
                    drivers_liscense_id_check.identity_document_type ===
                      'DRIVERS_LISCENSE' &&
                    field.label === 'Ausweisnummer'
                      ? 'Führerscheinnummer'
                      : field.label}
                  </dt>

                  <dd className="mt-1 text-sm text-gray-900 ">
                    <Field
                      disabled={!editable}
                      type="text"
                      name={field.name}
                      className={classNames(
                        editable ? 'border-gray-300 border px-2' : '',
                        'block w-full h-8 sm:text-sm rounded-md bg-white border-0',
                      )}
                    />
                  </dd>
                </div>
              ),
            )}
          </dl>
        ) : (
          <p className="py-2 px-4 text-sm text-gray-500">kein Angabe</p>
        )}
      </Form>
    </div>
  );
};

const UserDetailspage = () => {
  const auth = useAuthContext();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  const { plan_type, plan_version, extend_with_drivers_license } = user;
  const isSecure = plan_type === 'premium';
  const isLightProduct = plan_version === 'light';
  const isJustIdCheck =
    isLightProduct && !isSecure && !extend_with_drivers_license;
  // console.log('isSecure', isJustIdCheck);
  const { customerId } = useParams<paramsProps>();
  // const idCheckSingleValue = useStore((state) => state.idCheckSingleValue);
  const { FetchIDCheckDataById } = Query();
  const {
    data: IDCheckData,
    isError,
    isLoading,
    // isSuccess,
  } = FetchIDCheckDataById({
    id: customerId,
    key: `id_check-${customerId}`,
  });
  // console.log('=================', IDCheckData);

  return (
    <div className="flex flex-col">
      <FetchHandlerWrapper
        isArray={false}
        isError={isError}
        isLoading={isLoading}
        data={IDCheckData}>
        {(data: IDCheck) => {
          // console.log('data id chek', data);
          return (
            <>
              <div className="mt-8">
                <div className="flex flex-col xl:flex-row bg-white rounded-lg  shadow-md p-3 justify-between w-full">
                  <PersonaInfo
                    {...data}
                    canChangeField={false}
                    title="Persönliche Daten - Ausweisdokument"
                    subTitle=""
                  />
                  <div className="flex flex-col justify-between mt-4 xl:w-3/12 sm:ml-3">
                    <ContactStatus
                      //@ts-ignore
                      status={statusStyles[data.approval_state]}
                      title={'Ausweisdokument'.toUpperCase()}>
                      <p className="text-sm text-gray-700  whitespace-pre-line xl:w-full mt-3">
                        {`Zuletzt aktualisiert: ${dataFormat({
                          date: data.created_at,
                          option: {
                            hour: true,
                          },
                        })}`}
                      </p>

                      <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                        Beauftragt von: {data.user.name}
                        {/* {moment(idCheckSingleValue?.created_at)} */}
                      </p>
                    </ContactStatus>

                    {/* REMOVE LINK TO DOCS BASED ON CLIENT REQUIREMENT FOR DATA PRIVACY */}

                    {/* {data.scan_to_mail_email1 && data.scan_to_mail_email1.url && (
                      <div className="flex justify-between align-middle mt-4  w-full">
                        <p className="text-lg font-semibold text-gray-700 mt-3">
                          Ausweisdokument:
                        </p>
                        <a
                          href={`${config.baseURL}${data.scan_to_mail_email1.url}`}
                          target="_blank"
                          rel="noreferrer">
                          <Button
                            onClick={() => null}
                            //   disabled={action?.disable}
                            label={'Scan 1 ansehen'}
                            type={'secondary'}
                            size="small"
                            className="w-full  border border-transparent shadow-sm px-4 py-3 text-bold font-medium text-white sm:text-sm bg-gray-700"
                          />
                        </a>
                      </div>
                    )} */}
                    {/* {data.scan_to_mail_email2 && data.scan_to_mail_email2.url && (
                      <div className="flex justify-between align-middle mt-4">
                        <p className="text-lg font-semibold text-gray-700 mt-3">
                          Ausweisdokument:
                        </p>
                        <a
                          href={`${config.baseURL}${data.scan_to_mail_email2.url}`}
                          target="_blank"
                          rel="noreferrer">
                          <Button
                            onClick={() => null}
                            //   disabled={action?.disable}
                            label={'Scan 2 ansehen'}
                            type={'secondary'}
                            size="small"
                            className="w-full  border border-transparent shadow-sm px-4 py-3 text-bold font-medium text-white sm:text-sm bg-gray-700"
                          />
                        </a>
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
              {!isJustIdCheck && data.drivers_liscense_id_check && (
                <div className="mt-8">
                  <div className="flex flex-col xl:flex-row bg-white rounded-lg  shadow-md p-3 justify-between w-full">
                    <PersonaInfo
                      {...data}
                      date_of_birth={
                        data.drivers_liscense_id_check.date_of_birth
                      }
                      isDriveLic
                      isSecure={isSecure}
                      canChangeField={false}
                      title="Persönliche Daten - Führerschein"
                      subTitle=""
                    />
                    <div className="flex flex-col justify-between mt-4 xl:w-3/12 sm:ml-3">
                      {data.drivers_liscense_id_check.approval_state !== null &&
                        data.drivers_liscense_id_check.approval_state !==
                          'NOT_AVAILABLE' && (
                          <ContactStatus
                            status={
                              //@ts-ignore
                              statusStyles[
                                data.drivers_liscense_id_check.approval_state
                              ]
                            }
                            title={'Führerschein'.toUpperCase()}>
                            <p className="text-sm text-gray-700 whitespace-pre-line xl:w-full mt-3">
                              {`Zuletzt aktualisiert: ${dataFormat({
                                date: data.created_at,
                                option: {
                                  hour: true,
                                },
                              })}`}
                            </p>

                            <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                              Beauftragt von: {data.user.name}
                              {/* {moment(idCheckSingleValue?.created_at)} */}
                            </p>
                          </ContactStatus>
                        )}
                      {/* REMOVE LINK TO DOCS BASED ON CLIENT REQUIREMENT FOR DATA PRIVACY */}

                      {/* {data.drivers_liscense_id_check &&
                        data.drivers_liscense_id_check.scan_to_mail_email1 && (
                          <div className="flex justify-between align-middle mt-4">
                            <p className="text-lg font-semibold text-gray-700 mt-3">
                              Führerschein:
                            </p>
                            <a
                              href={`${config.baseURL}${data.drivers_liscense_id_check.scan_to_mail_email1.url}`}
                              target="_blank"
                              rel="noreferrer">
                              <Button
                                onClick={() => null}
                                label={'Scan 1 ansehen'}
                                type={'secondary'}
                                size="small"
                                className="w-full  border border-transparent shadow-sm px-4 py-3 text-bold font-medium text-white sm:text-sm bg-gray-700"
                              />
                            </a>
                          </div>
                        )} */}

                      {/* {data.drivers_liscense_id_check &&
                        data.drivers_liscense_id_check.scan_to_mail_email2 && (
                          <div className="flex justify-between align-middle mt-4">
                            <p className="text-lg font-semibold text-gray-700 mt-3">
                              Führerschein:
                            </p>
                            <a
                              href={`${config.baseURL}${data.drivers_liscense_id_check.scan_to_mail_email2.url}`}
                              target="_blank"
                              rel="noreferrer">
                              <Button
                                onClick={() => null}
                                label={'Scan 2 ansehen'}
                                type={'secondary'}
                                size="small"
                                className="w-full  border border-transparent shadow-sm px-4 py-3 text-bold font-medium text-white sm:text-sm bg-gray-700"
                              />
                            </a>
                          </div>
                        )} */}
                    </div>
                  </div>
                </div>
              )}
            </>
          );
        }}
      </FetchHandlerWrapper>
    </div>
  );
};

export default UserDetailspage;
