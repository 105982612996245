import { useImperativeHandle, forwardRef, useRef, useEffect } from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface DigitalSignatureProps {
  penColor?: string;
  name: string;
  onEnd?: (dataUrl: string) => void;
  onSignatureData?: (dataUrl: any) => void;
  imageSrc?: string;
  setFieldValue?: any;
  width?: number;
  height?: number;
}
type Ref = any;
const DigitalSignature = forwardRef<Ref, DigitalSignatureProps>(
  (
    {
      penColor = 'black',
      name,
      onSignatureData,
      imageSrc,
      setFieldValue,
      width,
      height,
    },
    ref,
  ) => {
    const canvasRef = useRef<SignatureCanvas | null>(null);

    useImperativeHandle(ref, () => ({
      clear,
    }));

    const clear = () => {
      // console.log('clear');
      if (!canvasRef) return;
      canvasRef.current?.clear();
      // setTrimmedDataURL(null);
      if (onSignatureData) {
        onSignatureData((signatureData: any) => {
          return {
            ...signatureData,
            [name]: null,
          };
        });
      } else {
        setFieldValue(name, null);
      }
    };

    const trim = () => {
      if (!canvasRef) return;
      //@ts-ignore
      const trimmedCanvas = canvasRef?.current?.getTrimmedCanvas();
      //  console.log('🚀 ~ trim ~ trimmedCanvas:', trimmedCanvas);
      if (trimmedCanvas) {
        // setTrimmedDataURL(trimmedCanvas.toDataURL('image/png'));
        if (onSignatureData) {
          onSignatureData((signatureData: any) => {
            return {
              ...signatureData,
              [name]: trimmedCanvas.toDataURL('image/png'),
            };
          });
        } else {
          setFieldValue(name, trimmedCanvas.toDataURL('image/png'));
        }
      }
    };
    useEffect(() => {
      if (!canvasRef) return;
      canvasRef.current?.clear();
    }, [name]);
    return (
      <div className="container flex flex-col justify-center">
        <div className="h-full relative">
          {/* {imageSrc && (
            <div className="flex sigContainer max-h-72 pt-8">
              <div className="flex sigPad w-full h-full">
                <img
                  src={imageSrc}
                  className="w-full h-auto object-center"
                  alt="signature-img"
                />
              </div>
            </div>
          )} */}
          {/* {!imageSrc && ( */}
          <div
            className={`border-2 ${
              setFieldValue ? 'border-gray-200' : 'border-black'
            } sigContainer flex`}>
            <SignatureCanvas
              ref={canvasRef}
              penColor={penColor}
              onEnd={() => {
                if (canvasRef && canvasRef?.current) {
                  trim();
                }
              }}
              canvasProps={{
                className: 'sigPad',
                width: width || undefined,
                height: height || undefined,
              }}
            />
          </div>
          {/* )} */}
        </div>
      </div>
    );
  },
);

export default DigitalSignature;
