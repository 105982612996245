import {
  useRouteMatch,
  Switch,
  Route,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { Query, User } from 'shared';
import CalenderHeader from './calender_events/header';
// import Main from './main';
// import UserDetailspage from './legitimation_details';
// import NewLegitimation from './new_legitimation';
// import NewContract from './new_contract';
// import ContractDetails from './contract_details';
import CalenderEvent from './calender_events/calender_events';
import VehiclesTable from '../vehicles/vehicles_table';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
// const CheckIdPage = ({
//   user_owner_type,
//   user_owner_id,
//   plan_type,
//   plan_version,
//   extend_with_drivers_license = true,
// }: User) => {

// };

// export default CheckIdPage;
dayjs.locale('de');

const Vehicles = (user: User) => {
  // console.log('user====', user);
  let { path } = useRouteMatch();
  let location = useLocation();
  const matchobject = matchPath(location.pathname, {
    path: `${path}/calender`,
  });
  const { FetchHeadquarterDataById } = Query();
  //   const isSecure = plan_type === 'premium';
  //   const isLightProduct = plan_version === 'light';
  //   const isJustIdCheck =
  //     isLightProduct && !isSecure && !extend_with_drivers_license;
  const { data: headquarterData, isLoading } = FetchHeadquarterDataById({
    key: `vheadquarterId-${user?.headquarter?.id}`,
    id: user?.headquarter?.id,
  });
  // console.log('🚀 ~ CalenderEvent ~ headquarterData:', location);
  return (
    <div className="h-full p-5 bg-gray-100">
      {matchobject ? <CalenderHeader /> : null}
      <Switch>
        <Route exact path={path}>
          <VehiclesTable
            csvImportFromSeller={user?.uses_import_from_eautoseller_csv}
          />
        </Route>

        <Route exact path={`${path}/calender/:id`}>
          <CalenderEvent
            currentUerEmail={user?.email}
            isCalenderAvaliable={headquarterData?.uses_reservations}
            isLoadingCalenderInfo={isLoading}
          />
        </Route>
        {/* <Route exact path={`${path}/contract_details/:id`}>
           <ContractDetails />
         </Route> */}
      </Switch>
    </div>
  );
};

export default Vehicles;
