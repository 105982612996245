import { defineAbility } from '@casl/ability';

const ability = (user: any) => {
  //@ts-ignore
  // console.log('user,user===', user);
  return defineAbility((can, cannot) => {
    if (user && user.role === 'Admin') {
      can('manage', 'all');
      // can('create', 'New_legitimation');
    }
    if (
      user &&
      user.role === 'Nutzer' &&
      user.user_owner_type === 'Dealer::Headquarter'
    ) {
      // can('read', 'personal_setting');
      // can('read', 'HeadQuarter');
      // can('read', 'branch_offices');
      // can('read', 'user');
      can('read', 'all');
      can('update', 'vehicles');
      // cannot('download', 'contract');
      // cannot('update', 'HeadQuarter_info');
      // cannot('update', 'branch_offices');
      // cannot('update', 'user');
      // cannot('create', 'user');
    }
    if (
      user &&
      user.role === 'Admin' &&
      user.user_owner_type === 'Dealer::BranchOffice'
    ) {
      can('manage', 'all');
      cannot('update', 'HeadQuarter_info');
      cannot('download', 'contract');
      cannot('create', 'branch_offices');
    }
    if (
      user &&
      user.role === 'Nutzer' &&
      user.user_owner_type === 'Dealer::BranchOffice'
    ) {
      can('read', 'all');
      can('update', 'vehicles');
    }
  });
};
export default ability;
