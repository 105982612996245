import { useState, useEffect } from 'react';
import { TrashIcon } from '@heroicons/react/outline';
// import { Link } from 'react-router-dom';
import { IEmailsList, Query } from 'shared';

import {
  SearchSortHeader,
  FetchHandlerWrapper,
  Tabs,
  currentTabProp,
  Modal,
} from '../../shared';

import {
  Button,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableData,
} from '../../../styles/components';
import { classNames } from '../../../styles/utils';
import { dataFormat } from '../../../utils';

import config from 'shared/config';
import dayjs from 'dayjs';

const columnsScanToMail = ['Datum', 'Zeit', 'Bezeichnung', 'Aktionen'];
const sorter = [
  { name: 'Datum', status: 'recieved_at', id: 0 },
  // { name: 'Aktive Vorgänge', status: 'IN_PROGRESS', id: 1, hasCounter: true },
  {
    name: 'Bezeichnung',
    status: 'subject',
    id: 1,
  },
  // { name: 'verwendet', status: 'assigned', id: 3 },
];
const tabs = [
  {
    name: 'Neu',
    status: 'unassigned',
    id: 1,
  },
  { name: 'Alle', status: 'all', id: 0 },
  // { name: 'Aktive Vorgänge', status: 'IN_PROGRESS', id: 1, hasCounter: true },

  { name: 'Verwendet', status: 'assigned', id: 2 },
];

const ScanToMailPage = () => {
  const [page, setPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedScanId, setSelectedScanId] = useState<number | undefined>(
    undefined,
  );
  const [current, setCurrent] = useState<currentTabProp>({
    status: 'unassigned',
    id: 1,
  });
  const [selected, setSelected] = useState();
  const { FetchUScanToMainData, DeleteIdScan } = Query();

  const {
    data: scanToMailData,
    isError,
    isLoading,
    isFetching,
    refetch,
  } = FetchUScanToMainData({
    key: 'scan-to-mail',
    page: page,
    searchQuery: undefined,
    //@ts-ignore
    sortBy: selected ? selected!.status : undefined,
    //@ts-ignore
    context: current ? current.status : 'all',
  });
  const { mutateAsync } = DeleteIdScan();

  const handleDelete = async (id: number) => {
    try {
      await mutateAsync(`scan_to_mail/emails/${selectedScanId}`);
      setOpenModal(false);
      setSelectedScanId(undefined);
      refetch();
    } catch (e) {
      console.log('e', e);
    }
  };

  useEffect(() => {
    if (scanToMailData) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, selected]);
  return (
    <div className="px-6">
      {/* <div className="pt-3 pb-1">
        <p className="text-gray-400">Eingang Scans</p>
      </div> */}

      {/* table section */}
      <div className="pt-3">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:align-middle">
          <div className="lg:w-6/12 hidden lg:flex">
            <h2 className="text-3xl leading-6 font-extrabold text-gray-900 py-4">
              Eingang Scans
            </h2>
          </div>
          <div className="lg:w-6/12 lg:py-4 w-full">
            <SearchSortHeader
              divider={false}
              selectMenuItem={sorter}
              onSelect={setSelected}
              selected={selected}
              // title="Eingang Scans"
              // subtitle="Abgeschlossene Vorgänge"
            />
          </div>
        </div>
        <div className="py-3">
          <Tabs
            tabs={tabs}
            onSelect={setCurrent}
            current={current ? current.id : null}
            counter={0}
          />
        </div>
        <div className="h-full flex-col flex overflow-x-auto overflow-y-hidden lg:overflow-hidden">
          {/* @ts-ignore */}
          <FetchHandlerWrapper
            mailList
            emptyDataMessage={{
              title: 'Alles erledigt für heute!',
              subtitle: 'Genießen Sie Ihren Tag.',
            }}
            isError={isError}
            isLoading={isLoading}
            data={scanToMailData ? scanToMailData : undefined}>
            {(data: IEmailsList) => (
              <TableContainer>
                <Table
                  isFetching={isFetching}
                  hasPagination
                  setPage={setPage}
                  currentpage={page}
                  {...scanToMailData.pagination}>
                  <TableHead>
                    {columnsScanToMail.map((item: string, index) => (
                      <TableCell
                        key={item}
                        className={classNames(
                          index !== columnsScanToMail.length - 1 ? '' : 'w-20',
                          'px-6 py-3 text-left text-xs font-lg text-gray-600 uppercase',
                        )}>
                        {item}
                      </TableCell>
                    ))}
                  </TableHead>
                  <TableBody>
                    {data &&
                      //@ts-ignore
                      data.emails.map((data) => {
                        return (
                          <TableRow key={data.id} action={() => null}>
                            <TableData className={`text-gray-500 font-sm`}>
                              {dataFormat({ date: data.recieved_at })}
                            </TableData>
                            <TableData className={`text-gray-500`}>
                              {/* {moment(data.recieved_at).format('HH:MM')} */}
                              {dayjs(data.recieved_at).format('HH:mm')}
                            </TableData>

                            <TableData className={`text-gray-500`}>
                              {data.subject}
                            </TableData>

                            <TableData className={`font-medium`}>
                              <div className="flex flex-row-reverse">
                                <Button
                                  onClick={() => {
                                    setSelectedScanId(data.id);
                                    setOpenModal(!openModal);
                                  }}
                                  startIcon={
                                    <TrashIcon className="w-5 h-5 text-black mx-3" />
                                  }
                                />
                                <div className="p-1">
                                  <a
                                    href={`${config.baseURL}${data.pdf_attachment_file.url}`}
                                    target="_blank"
                                    rel="noreferrer">
                                    <Button
                                      onClick={() => null}
                                      //   disabled={action?.disable}
                                      label={'Scan ansehen'}
                                      //@ts-ignore
                                      type={'secondary'}
                                      size="small"
                                      className="flex justify-center"
                                    />
                                  </a>
                                </div>
                              </div>
                            </TableData>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </FetchHandlerWrapper>
        </div>
      </div>
      <Modal
        type="warning"
        hasIcon
        setOpen={setOpenModal}
        open={openModal}
        title="Dokument löschen"
        subtile="Sind Sie sicher, dass Sie diesen Scan löschen möchten?">
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={handleDelete}
            label={'Löschen'}
            type="primary"
            className={
              'w-full justify-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 bg-red-600 hover:bg-red-700'
            }
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            label="Abbrechen"
            type="default"
            className="w-full justify-center hover:bg-gray-50 hover:text-black"
          />
        </div>
      </Modal>
    </div>
  );
};

export default ScanToMailPage;
