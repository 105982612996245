import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PrivacyStaticData, privacyDataPro } from 'shared';
import DataPrivacy from './data_privacy';
import Impressum from './impressum';
import AboutPage from './about';
import ContactForm from './contact_form';

type paramsProps = {
  itemId: 'contact' | 'datenschutz' | 'impressum' | 'about';
};

const PrivacyPages = () => {
  const { itemId } = useParams<paramsProps>();
  const [privacyItemData, setPrivacyItemData] = useState<privacyDataPro>();

  const Component = {
    //@ts-ignore
    datenschutz: () => <DataPrivacy {...privacyItemData} />,
    //@ts-ignore
    impressum: () => <Impressum {...privacyItemData} />,

    //@ts-ignore
    about: () => <AboutPage {...privacyItemData} />,
    //@ts-ignore
    contact: () => <ContactForm />,
  };

  const SelectedComponent = Component[itemId];

  useEffect(() => {
    const data = PrivacyStaticData.filter(
      (item: privacyDataPro) => item.id === itemId,
    );
    setPrivacyItemData(data[0]);
  }, [itemId]);
  if (!privacyItemData) return null;
  return (
    <>
      {/* @ts-ignore */}
      <SelectedComponent />
    </>
  );
};

export default PrivacyPages;
