import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ProvideAuth } from '../context';
import {
  LoginPage,
  RegistrationPage,
  ResetPasswordPage,
} from '../components/screens';
import Layout from '../components/layout';
import IDCheckMobile from '../components/screens/ID_check/new_legitimation/id_check_mobile';
import PrivateRoute from './private_routes';
import ValidateTowFactAuth from '../components/screens/login/validate_tow_fac_auth';

// const routes = [
//   {
//     path: '/login',
//     component: LoginPage,
//     routes: [
//       {
//         path: '/login',
//         component: LoginPage,
//         exact: true,
//       },
//       {
//         path: '/login/validate',
//         component: ValidateTowFactAuth,
//         exact: true,
//       },
//     ],
//   },
// ];
// function RouteWithSubRoutes(route: any) {
//   return (
//     <Route
//       path={route.path}
//       render={(props) => (
//         // pass the sub-routes down to keep nesting
//         <route.component {...props} routes={route.routes} />
//       )}
//     />
//   );
// }

export default function Navigations() {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          <Route path="/register">
            <RegistrationPage />
          </Route>

          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route path="/login/two_factor_auth_challenge">
            <ValidateTowFactAuth />
          </Route>

          <Route path="/reset_password">
            <ResetPasswordPage />
          </Route>
          <Route
            exact
            path="/id_scan_mobile/:token"
            component={IDCheckMobile}
          />

          <Route path="/">
            {/* we wrapped the Private routers with Layout so the header and navbar don not render on each rout */}
            <Layout>
              <PrivateRoute />
            </Layout>
          </Route>
          <Redirect
            to={{
              pathname: '/login',
              state: { from: '/' },
            }}
            exact
          />
        </Switch>
      </Router>
    </ProvideAuth>
  );
}
