import { useState } from 'react';
import { PhoneIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Field, FormikProps } from 'formik';
import { Form, Modal } from '../../shared';
import { classNames } from '../../../styles/utils';
import { Query } from 'shared';
import { Button } from '../../../styles/components';

const contactFormValidation = yup.object({
  name: yup
    .string()
    .min(
      5,
      'Bitte beachten Sie, dass der Name mindestens 5 Zeichen lang sein mus',
    )
    .defined('Name ist erforderlich.')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  email: yup
    .string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse ein')
    .defined('E-Mail ist erforderlich.'),
  phone: yup
    .number()
    .typeError(
      'Bitte geben Sie eine gültige Telefonnummer ein. Telefonnummern dürfen nur Zahlen enthalten.',
    ),
  message: yup.string().required(' Nachricht ist erforderlich.'),
});

export default function ContactForm() {
  const [showNotification, setShow] = useState(false);
  const { SubmitDataToserver } = Query();
  const history = useHistory();
  const { mutateAsync } = SubmitDataToserver({
    url: 'contact_forms',
  });
  const handleFormSubmit = async (
    values: {
      name: string;
      email: string;
      phone: number;
      message: string;
    },
    formikBag: any,
  ) => {
    const { setErrors, resetForm } = formikBag;
    const { name, email, phone, message } = values;
    const formData = {
      contact_form: {
        email,
        name,
        phone,
        message,
      },
    };
    try {
      await mutateAsync(formData);
      setShow(true);
      resetForm();
    } catch (e) {
      setErrors({
        error:
          'Das hat leider nicht funktioniert. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut',
      });
    }
  };
  const filedClassName =
    'w-full p-2 mt-1 block border-gray-300 border rounded-md shadow-sm text-blue-gray-900 sm:text-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm';
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
              Kontaktformular
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Wenn Sie Fragen haben, einfach mehr wissen wollen oder mit uns
              sprechen möchten, dann freuen wir uns über Ihre Anfrage oder Ihren
              Anruf.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="text-gray-700 font-semibold">
                  Kundenberatung und Support:
                </dt>
              </div>
              <div className="mt-2">
                <dt className="text-gray-700">Büro Andernach:</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">+49 2632 498883-3</span>
                </dd>
              </div>
              {/* <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">support@example.com</span>
                </dd>
              </div> */}
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <Form
              submitForm={handleFormSubmit}
              validationSchema={contactFormValidation}
              initialValues={{
                name: '',
                email: '',
                phone: '',
                message: '',
              }}>
              {({
                errors,
                isSubmitting,
                dirty,
                // values,
                submitCount,
                touched,
              }: FormikProps<any>) => {
                return (
                  <div className="mt-4">
                    <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                      <div className="sm:col-span-6">
                        <label htmlFor="name" className="sr-only">
                          Full name
                        </label>
                        <Field
                          placeholder="Vorname & Nachname"
                          className={filedClassName}
                          type="text"
                          name="name"
                        />
                      </div>
                      <div className="sm:col-span-6">
                        <label htmlFor="email" className="sr-only">
                          E-Mail Adresse
                        </label>
                        <Field
                          placeholder="E-Mail Adresse"
                          className={filedClassName}
                          type="text"
                          name="email"
                        />
                      </div>

                      <div className="sm:col-span-6">
                        <label htmlFor="phone" className="sr-only">
                          Mobilnummer
                        </label>

                        <Field
                          placeholder="Mobilnummer"
                          className={filedClassName}
                          type="text"
                          name="phone"
                        />
                      </div>
                      <div className="sm:col-span-6">
                        <label htmlFor="message" className="sr-only">
                          Nachricht
                        </label>
                        <Field name="message" type="textarea">
                          {({ field }: any) => (
                            <textarea
                              placeholder="Nachricht"
                              {...field}
                              className="p-1 md:h-32 shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border-gray-300 border rounded-md"
                            />
                          )}
                        </Field>
                      </div>
                    </div>
                    <div
                      className={`flex align-middle ${
                        Object.keys(errors).length > 0
                          ? 'justify-between '
                          : 'justify-end'
                      }`}>
                      {touched && Object.keys(errors).length > 0 && (
                        <div className="pt-5">
                          <p className="text-red-400 text-sm font-semibold">
                            {Object.values(errors)[0]}
                          </p>
                        </div>
                      )}
                      <div className={`pt-5 flex justify-end`}>
                        <button
                          disabled={!dirty || isSubmitting || submitCount > 4}
                          className={classNames(
                            dirty &&
                              Object.keys(errors).length === 0 &&
                              !isSubmitting &&
                              submitCount <= 4
                              ? 'bg-orange-500 hover:bg-gray-600-700 cursor-pointer'
                              : 'bg-gray-400 cursor-not-allowed',
                            'ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500',
                          )}
                          type="submit">
                          Senden
                        </button>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
      <Modal
        hasIcon
        setOpen={setShow}
        open={showNotification}
        title="Vielen Dank für Ihre Anfrage!"
        subtile="Wir setzen uns umgehend mit Ihnen in Verbindung">
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setShow(false);
              history.push('/privacy');
            }}
            label="okay"
            type="primary"
            className="w-full justify-center "
          />
        </div>
      </Modal>
    </div>
  );
}
