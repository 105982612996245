/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Switch } from '@headlessui/react';
// import { Query } from 'shared';
// import { Modal, NotificationMessage } from '../../shared';
// import { classNames } from '../../../styles/utils';
// import { Button } from '../../../styles/components';
import { CheckIcon } from '@heroicons/react/outline';
// import useStore from '../../../ui-state/ui';

const PersonalInfo = ({ id }: { id: number }) => {
  // const auth = useAuthContext();
  // const { UpdateUserData, FetchUserDataById } = Query();
  // const saveUserToStorage = useStore((state) => state.saveUserToStorage);

  // const { data: userData, refetch } = FetchUserDataById({
  //   key: `user-${id}`,
  //   id: id,
  // });
  // const is2authEnabled = userData?.uses_two_k_auth;
  // const [showNotification, setShow] = useState(false);
  // const [enabled, setEnabled] = useState(is2authEnabled);
  // const [openModal, setOpenModal] = useState(false);
  // const [is2authEnabled, set2facEnable] = useState(is2authActive);
  // console.log('🚀 ~ file: setting.tsx:20 ~ is2authActive:', userData);

  // const {
  //   mutateAsync: updateUserData,
  //   error: errorUpdatingUserData,
  //   // isSuccess,
  //   // data: updatedUserData,
  //   data,
  // } = UpdateUserData({
  //   url: `users/${id}`,
  // });
  // const handleOpenModal = async () => {
  //   setOpenModal(true);
  //   setEnabled(!enabled);
  // };

  // console.log('user', userData);
  // const handleTowFacAuthRequest = async (isActive: boolean) => {
  //   try {
  //     // console.log('activalte requet send', isActive);
  //     await updateUserData({
  //       uses_two_k_auth: isActive,
  //     });

  //     setShow(true);
  //     refetch();
  //     setOpenModal(false);
  //   } catch (e) {
  //     //@ts-ignore
  //     // const errorObject = e.response && e.response.data;
  //     // console.log('error enable fac', errorObject);
  //   }
  // };
  // useEffect(() => {
  //   // change the stored user object in local storage
  //   if (
  //     data &&
  //     data.status === 200 &&
  //     data.data.uses_two_k_auth !== is2authEnabled &&
  //     saveUserToStorage
  //   ) {
  //     // console.log('🚀 ~ save new user to storage:', data);

  //     localStorage.setItem(`user`, window.JSON.stringify(data.data));
  //   }
  // }, [data]);

  return (
    <div className="py-2 lg:py-0 px-6 flex flex-col overflow-hidden">
      <h1 className="text-3xl font-extrabold text-blue-gray-900 pb-3">
        Sicherheit
      </h1>
      <Switch.Group as="div" className="flex items-center justify-between mt-4">
        <span className="flex flex-grow flex-col">
          <Switch.Label
            as="span"
            className="text-lg leading-6 font-medium"
            passive>
            Zwei-Faktor-Authentifizierung
          </Switch.Label>
          <Switch.Description as="span" className="text-sm text-gray-500">
            Ihr Konto ist durch eine Zwei-Faktor-Authentifizierung geschützt.
            Bei jeder Anmeldung erhalten Sie eine Mail mit einem zusätzlichen
            Sicherheitscode, den Sie bitte zusätzlich zu Ihrem Password
            eingeben.
          </Switch.Description>
        </span>
        {/* <Switch
        
          disabled
          checked={is2authEnabled}
          onChange={handleOpenModal}
          className={classNames(
            is2authEnabled ? 'bg-orange-600' : 'bg-gray-600',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-600 focus:ring-offset-2',
          )}>
          <span
            aria-hidden="true"
            className={classNames(
              is2authEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
            )}
          />
        </Switch> */}
        <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-200">
          <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
        </div>
      </Switch.Group>
      {/* <NotificationMessage
        title="NutzerIn erfolgreich aktualisiert"
        subTitle=""
        isError={!!errorUpdatingUserData}
        notify={showNotification}
        setShow={setShow}
      /> */}
      {/* <Modal
        type="warning"
        hasIcon
        setOpen={setOpenModal}
        open={openModal}
        title={
          is2authEnabled
            ? 'Zwei-Faktor-Authentifizierung  deaktivieren'
            : 'Zwei-Faktor-Authentifizierung  aktivieren (empfohlen)'
        }
        subtile={
          is2authEnabled
            ? 'Sind Sie sicher, dass Sie die Zwei-Faktor-Authentifizierung  deaktiveren möchten? Der/die Nutzer/in erhält keine Benachrichtigung über die Freischaltung.'
            : 'Schützen Sie Ihr Konto jedes Mal, wenn Sie sich einloggen, mit einem einmaligen Sicherheitscode zusätzlich zu Ihrem Passwort.'
        }>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => handleTowFacAuthRequest(!is2authEnabled)}
            label={is2authEnabled ? 'Deaktivieren' : 'Aktvieren'}
            type="primary"
            className={classNames(
              !is2authEnabled
                ? ''
                : 'focus:ring-red-500 bg-red-600 hover:bg-red-700',
              'w-full justify-center focus:outline-none focus:ring-2 focus:ring-offset-2',
            )}
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            label="Abbrechen"
            type="default"
            className="w-full justify-center hover:bg-gray-50 hover:text-black"
          />
        </div>
      </Modal> */}
    </div>
  );
};

export default React.memo(PersonalInfo);

// {errorUpdatingUserData ? (
//   <div className="text-red-400 text-sm font-semibold">
//     {/* @ts-ignore */}
//     {errorUpdatingUserData.response
//       ? // @ts-ignore
//         errorUpdatingUserData.response.data.error
//       : 'server error'}
//   </div>
// ) : null}
