// import { useState, useEffect } from 'react';

import { Fragment } from 'react';
import { Customer, Query, ContractShortProp } from 'shared';
import { FetchHandlerWrapper } from '../../../shared';
import { dataFormat } from '../../../../utils';
import { classNames } from '../../../../styles/utils';
import { statusStyles } from '../../ID_check/utils';
import { Link } from 'react-router-dom';
// import { MailIcon, DeviceMobileIcon } from '@heroicons/react/solid';
import useStore from '../../../../ui-state/ui';

const CustomersDetails = ({ item }: { item: Customer }) => {
  const { FetchCustomerDataById } = Query();
  const {
    data: cutomerData,
    isError,
    isLoading,
  } = FetchCustomerDataById({
    id: item.id,
    key: `customer-${item.id}`,
  });
  const setContractId = useStore((state) => state.setContractId);
  return (
    <FetchHandlerWrapper
      isError={isError}
      isArray={false}
      isLoading={isLoading}
      data={cutomerData ? cutomerData : undefined}>
      {(data: Customer) => (
        <div className="flex flex-col">
          <ul>
            <li className="py-2">
              <h3 className="text-lg font-semibold mb-3">Persönliche Daten</h3>
              <div className="flex  justify-between">
                <div className="sm:col-span-1">
                  <dt className="text-md text-gray-500 font-semibold">Kunde</dt>
                  <dd className="mt-1 text-sm font-semibold text-gray-900">
                    {data.prename} {data.surname}
                  </dd>
                  <dd className="mt-1 text-sm font-semibold text-gray-900">
                    {data.telephone_number}
                  </dd>
                  <dd className="mt-1 text-sm font-semibold text-gray-900">
                    {data.email}
                  </dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-md font-medium  text-gray-500">
                    Adresse
                  </dt>
                  {data.address ? (
                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                      <span className="break-words block">
                        {data.address.street},{data.address.house}
                      </span>
                      <span className="break-words">
                        {data.address.postal_code} {data.address.city}
                      </span>
                    </dd>
                  ) : (
                    <dd className="mt-1 text-sm font-semibold text-gray-900">
                      <span className="break-words block">not added yet</span>
                    </dd>
                  )}
                </div>
              </div>
              <div>
                <div className="sm:col-span-1 pt-4">
                  <dt>
                    <h2 className="text-md text-gray-500 font-semibold">
                      ID Checks
                    </h2>
                  </dt>

                  {data.id_checks && data.id_checks.length > 0 ? (
                    <Fragment>
                      <ul className="divide-y divide-gray-200">
                        {data.id_checks.map((id_check) => (
                          <li key={id_check.id} className="py-4">
                            <div className="flex space-x-3">
                              <span
                                className={classNames(
                                  //@ts-ignore
                                  statusStyles[id_check.approval_state].status,
                                  'h-4 w-4 rounded-full flex items-center justify-center',
                                )}
                                aria-hidden="true">
                                <span
                                  className={classNames(
                                    //@ts-ignore
                                    statusStyles[id_check.approval_state]
                                      .status,

                                    'h-2 w-2 rounded-full',
                                  )}
                                />
                              </span>
                              <div className="flex-1 space-y-1">
                                <div className="flex items-center justify-between">
                                  <h3 className="text-sm font-semibold text-gray-900">
                                    {
                                      // @ts-ignore
                                      statusStyles[id_check.approval_state]
                                        .label
                                    }
                                  </h3>
                                  <p className="text-sm text-gray-500">
                                    {dataFormat({
                                      date: id_check.created_at,
                                      option: {
                                        hour: true,
                                      },
                                    })}
                                  </p>
                                </div>
                                <p className="mt-1 text-sm font-semibold text-gray-900">
                                  {id_check.prename} {id_check.surname}
                                </p>
                                <p className="mt-1 text-sm font-semibold text-gray-900">
                                  {id_check.identity_document_type}
                                </p>
                                <p className="mt-1 text-sm font-semibold text-gray-900">
                                  {id_check.identity_document_number}
                                </p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ) : (
                    <dd>keine Angabe</dd>
                  )}
                </div>
                <div className="sm:col-span-1 pt-4">
                  <dt>
                    <h2 className="text-md font-semibold text-gray-500">
                      Fahrzeugüberlassungsvertrag:
                    </h2>
                  </dt>
                  {/* @ts-ignore */}
                  {data.contracts && data.contracts.length > 0 ? (
                    <Fragment>
                      <ul className="divide-y divide-gray-200">
                        {data.contracts.map((contract: ContractShortProp) => (
                          <Link
                            to={`/id_check/contract_details/${contract.id}`}
                            key={contract.id}>
                            <li
                              className="py-4"
                              onClick={() => {
                                setContractId(contract.id);
                              }}>
                              <div className="flex space-x-3">
                                <span
                                  className={classNames(
                                    //@ts-ignore
                                    !contract.active
                                      ? 'bg-green-200 text-green-800'
                                      : 'bg-orange-200 text-yellow-800',
                                    'h-4 w-4 rounded-full flex items-center justify-center',
                                  )}
                                  aria-hidden="true">
                                  <span
                                    className={classNames(
                                      //@ts-ignore
                                      !contract.active
                                        ? 'bg-green-200 text-green-800'
                                        : 'bg-orange-200 text-yellow-800',

                                      'h-2 w-2 rounded-full',
                                    )}
                                  />
                                </span>
                                <div className="flex-1 space-y-1">
                                  <div className="flex items-center justify-between">
                                    <h3 className="text-sm font-semibold text-gray-900">
                                      ID{contract.id} -{' '}
                                      {contract.active &&
                                      !contract.vehicle_returned_at
                                        ? 'Offen'
                                        : 'Abgeschlossen'}
                                    </h3>
                                    <p className="text-sm text-gray-500">
                                      {dataFormat({
                                        date: contract.created_at,
                                        option: {
                                          hour: true,
                                        },
                                      })}
                                    </p>
                                  </div>
                                  <p className="mt-1 text-sm font-semibold text-gray-900">
                                    {contract.customer_prename}{' '}
                                    {contract.customer_surname}
                                  </p>
                                  <p className="mt-1 text-sm font-semibold text-gray-900">
                                    {contract.kind}
                                  </p>
                                  {/* <p className="mt-1 text-sm font-semibold text-gray-900">
                                  {contract.identity_document_number}
                                </p> */}
                                </div>
                              </div>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    </Fragment>
                  ) : (
                    <dd>keine Angabe</dd>
                  )}
                </div>
              </div>
            </li>
          </ul>
        </div>
      )}
    </FetchHandlerWrapper>
  );
};

export default CustomersDetails;
