import { isFunction } from '../../../utils';
type ListProps = {
  children: any;
  items: any;
  onItemClick?: (item: any) => void;
  styles: {
    ul: string;
    li?: string;
  };
};
const List = ({ children, items, onItemClick, styles }: ListProps) => {
  const { ul, li } = styles;
  if (items.length === 0) return null;

  return (
    <ul className={ul}>
      {items.map((item: any) => (
        <li
          key={item.id}
          className={li ? li : undefined}
          onClick={() => {
            if (typeof onItemClick === 'function') {
              onItemClick(item);
            }
          }}>
          {isFunction(children)
            ? (children as (data: any) => React.ReactNode)(item)
            : null}
        </li>
      ))}
    </ul>
  );
};
export default List;
