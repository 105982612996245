import dayjs from 'dayjs';
import { flattenDeep } from 'lodash';

interface IDayjsRange {
  from: string;
  to: string;
  stepNumber?: number;
  stepIncrement?: 'day' | 'month' | 'year' | 'week' | 'hours';
  format?: string;
  direction?: 'subtract' | 'add';
  title?: any;
  id?: any;
  user?: {
    prename: string;
    surname: string;
    email: string;
  };
}
type IVehiclesReservation = {
  id?: number;
  start_date: string;
  vehicle_id: number;
  end_date: string;
  title: string;
  from: string;
  to: string;
};

function getDatesInRange(startDate: any, endDate: any) {
  const start = new Date(new Date(startDate).setUTCHours(0, 0, 0, 0));
  const end = new Date(new Date(endDate).setUTCHours(0, 0, 0, 0));

  const date = new Date(start.getTime());

  const dates = [];

  while (date <= end) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}
function dayjsRange({
  from = '',
  to = '',
  // stepNumber = 1,
  // stepIncrement = 'day',
  // format = '',
  // direction = 'add',
  user,
  title = '',
  id = '',
}: IDayjsRange) {
  // const list = [];

  //@ts-ignore
  const text = [...getDatesInRange(from, to)];

  if (text.length === 0 || !text) return [];
  return text.map((item: any) => {
    return {
      date: item,
      title,
      id,
      from,
      to,
      start_time: dayjs(from, 'hh:mm'),
      ent_time: dayjs(to, 'hh:mm'),
      user: user ? `${user.prename} ${user.surname}` : 'N/A',
      user_email: user ? user.email : 'N/A',
    };
  });
}

export const dateRangeBasedOnReservation = (
  reservations: IVehiclesReservation[],
) => {
  //   console.log('reservations', reservations);
  if (!reservations || reservations.length === 0) return [];

  const rangeEvent = reservations.map((items: any) => {
    return dayjsRange({
      from: items.start_date,
      to: items.end_date,
      //@ts-ignore
      start_time: dayjs(items.start_time, 'hh:mm'),
      ent_time: dayjs(items.ent_time, 'hh:mm'),
      id: items.id,
      title: items.note,
      user: items.user,
      email: items.user_email,
    });
  });
  return flattenDeep(rangeEvent);
};
