import * as yup from 'yup';
import { dataFormat } from '../../../../utils';
import { IDCheck } from 'shared';

import { parse, isDate } from 'date-fns';

function parseDateString(value: any, originalValue: any) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd.MM.yyyy', new Date());

  return parsedDate;
}
// const phoneRegExp = /^\+4[139] \(0\d+\) \d{9,}$/;

const today = new Date();
export const contractFormData = [
  {
    name: 'start_of_use',
    label: 'Ausgabe',
    gridClass: 'col-span-6',
    type: 'text',
    required: true,
  },
  {
    name: 'end_at',
    label: 'Rückgabe',
    gridClass: 'col-span-6',
    type: 'text',
    required: true,
  },
  {
    name: 'cost',
    label: 'Kostenlos',
    gridClass: 'col-span-6',
    subTitle: 'Kosten',
    extraField: true,
    type: 'checkbox',
    containerClass: 'flex items-center',
    filedClassName:
      'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded',
  },
  {
    name: 'insurance_type',
    label: 'Teilkaskoversicherung:',
    gridClass: 'col-span-6',
    subTitle: 'Versicherung',
    type: 'radio',
    filedClassName:
      'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300',
  },
  {
    name: 'notes',
    label: 'Straße, Hausnummer',
    gridClass: 'col-span-6',
    subTitle: 'Versicherung',
    type: 'checkbox',
    filedClassName:
      'focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded',
  },
];

export const personalDataFormArray = [
  {
    name: 'email',
    label: 'E-Mail Adresse',
    gridClass: 'col-span-6 sm:col-span-3',
    type: 'auto_complente_text',
    required: true,
  },
  {
    name: 'salutation',
    label: 'Anrede',
    type: 'list',
    gridClass: 'col-span-3 sm:col-span-6 w-6/12 selectdiv',
    required: true,
  },
  {
    name: 'prename',
    label: 'Vorname',
    gridClass: 'col-span-6 sm:col-span-3',
    type: 'text',
    disabled: false,
    required: true,
  },
  {
    name: 'surname',
    label: 'Nachname',
    disabled: false,
    gridClass: 'col-span-6 sm:col-span-3',
    type: 'text',
    required: true,
  },
  {
    name: 'identity_document_type',
    label: 'Ausweistyp',
    gridClass: 'col-span-6 sm:col-span-3',
    disabled: true,
    type: 'other_type_translated',
    required: true,
  },
  {
    name: 'identity_document_number',
    label: 'Ausweisnummer',
    gridClass: 'col-span-6 sm:col-span-3',
    disabled: false,
    type: 'text',
    required: true,
  },
  {
    name: 'identity_document_type_drive',
    label: 'Fahrerlaubnis',
    gridClass: 'col-span-6 sm:col-span-3',
    disabled: true,
    type: 'drive_liscense_translated',
    required: true,
  },
  {
    name: 'identity_document_number_drive',
    label: 'Führerscheinnummer',
    gridClass: 'col-span-6 sm:col-span-3',
    disabled: false,
    type: 'text',
    required: true,
  },
  {
    name: 'drivers_liscense_issuing_authority',
    label: 'Ausstellungsbehörde',
    gridClass: 'col-span-6 sm:col-span-3',
    disabled: false,
    type: 'text',
    required: true,
  },
  {
    name: 'drivers_license_classes',
    label: 'Führerscheinklassen',
    gridClass: 'col-span-6 sm:col-span-3',
    disabled: false,
    type: 'text',
    required: true,
  },
  {
    name: 'date_of_birth',
    label: 'Geburtsdatum',
    gridClass: 'col-span-6 sm:col-span-3',
    disabled: false,
    type: 'text',
    required: true,
  },

  {
    name: 'is_valid_drivelicense',
    label: 'Gültige Führerscheinklassen',
    gridClass: 'col-span-3 sm:col-span-6',
    disabled: false,
    type: 'checkbox',
    required: true,
  },

  {
    name: 'street',
    label: 'Straße',
    gridClass: 'col-span-6',
    type: 'text',
    required: true,
  },
  {
    name: 'house',
    label: 'Hausnummer',
    gridClass: 'col-span-6 sm:col-span-3 lg:col-span-2',
    type: 'text',
    required: true,
  },
  {
    name: 'postal_code',
    label: 'PLZ',
    gridClass: 'col-span-6 sm:col-span-3 lg:col-span-2',
    type: 'text',
    required: true,
  },
  {
    name: 'city',
    label: 'Ort',
    gridClass: 'col-span-6 sm:col-span-3 lg:col-span-2',
    type: 'text',
    required: true,
  },

  {
    name: 'telephone_number',
    label: 'Mobilnummer',
    gridClass: 'col-span-6',
    subTitle: 'Kontaktdaten',
    type: 'text',
    required: true,
  },
];

export const selectCarDataArray = [
  {
    name: 'searchCar',
    label: 'Fahrzeugsuche',
    gridClass: 'col-span-6 ',
    type: 'search-box',
    disabled: false,
    required: true,
  },
  {
    name: 'brand',
    label: 'Marke & Modell',
    gridClass: 'col-span-6',
    type: 'text',
    required: true,
    disabled: true,
  },
  // {
  //   name: 'name',
  //   label: 'Model',
  //   gridClass: 'col-span-6 ',
  //   type: 'checkbox-dependent',
  // },

  {
    name: 'vin',
    label: 'FIN/ VIN',
    gridClass: 'col-span-6',
    type: 'text',
    required: true,
    disabled: true,
  },
  {
    name: 'license_plate',
    label: 'Kennzeichen',
    gridClass: 'col-span-6 sm:col-span-3 lg:col-span-2',
    type: 'text',
    required: true,
  },
  {
    name: 'odometer_value',
    label: 'KM Stand (Übergabe)',
    gridClass: 'col-span-6 sm:col-span-3 lg:col-span-2',
    type: 'text',
    required: true,
    disabled: false,
  },
  {
    name: 'pre_damage',
    label: 'Schäden',
    gridClass: 'col-span-6',
    type: 'textarea',
    disabled: false,
  },
  {
    name: 'short_description',
    label: 'Sonstige Notizen',
    gridClass: 'col-span-6',
    type: 'textarea',
    disabled: false,
  },
];
export const setInitialValues = (
  newContractData: any,
  hasSBInsurance: boolean,
) => ({
  salutation: '',
  prename: newContractData
    ? newContractData.customer?.prename !== 'Not Available'
      ? newContractData.customer?.prename
      : ''
    : '',
  surname: newContractData
    ? newContractData.customer.surname !== 'Not Available'
      ? newContractData.customer.surname
      : ''
    : '',
  identity_document_type: newContractData
    ? newContractData.id_check_short.identity_document_type
    : '',
  identity_document_number: newContractData
    ? newContractData.id_check_short.identity_document_number !==
      'Not Available'
      ? newContractData.id_check_short.identity_document_number
      : ''
    : '',
  street:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.street
      : '',
  postal_code:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.postal_code
      : '',
  city:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.city
      : '',
  house:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.house
      : '',

  date_of_birth:
    newContractData && newContractData.customer.date_of_birth
      ? dataFormat({
          //@ts-ignore
          date: newContractData.customer.date_of_birth,
        })
      : '',
  identity_document_number_drive:
    newContractData && newContractData.id_check_short.drivers_liscense_id_check
      ? newContractData.id_check_short.drivers_liscense_id_check
          .identity_document_number !== 'Not Available'
        ? newContractData.id_check_short.drivers_liscense_id_check
            .identity_document_number
        : ''
      : '',
  identity_document_type_drive:
    newContractData && newContractData.id_check_short.drivers_liscense_id_check
      ? newContractData.id_check_short.drivers_liscense_id_check
          .identity_document_type
      : '',
  drivers_liscense_issuing_authority:
    newContractData && newContractData.id_check_short.drivers_liscense_id_check
      ? newContractData.id_check_short.drivers_liscense_id_check
          .drivers_liscense_issuing_authority
      : '',
  drivers_license_classes:
    newContractData && newContractData.id_check_short.drivers_liscense_id_check
      ? newContractData.id_check_short.drivers_liscense_id_check
          .drivers_license_classes
      : '',
  telephone_number: newContractData
    ? newContractData.customer.telephone_number
    : null,
  email: newContractData ? newContractData.customer.email : '',
  is_valid_drivelicense: false,
  pre_damage: '',
  license_plate: '',
  car: {},
  brand: '',
  // vin: '',
  short_description: '',
  odometer_value: null,
  start_of_use: '',
  end_of_use: '',
  kind: '',
  isFree: true,
  tagesgrundpreis: 0,
  stundenpauschale: 0,
  fahrzeugkaution: 0,
  with_vollkasko: false,
  vollkasko_selbstbeteiligung_amount: 0,
  with_teilkasko: false,
  teilkasko_selbstbeteiligung_amount: 0,
  additional_notes: '',
  additional_costs_attributes: [
    {
      name: '',
      value: 0,
    },
  ],
  ...(hasSBInsurance && {
    license_plate: '',
    vin: '',
    disscount: 0,
    sb_sum: 0,
    payment_method: 'card',
    // obj_1: {
    //   obj_1attr_key1: 'attr_value1',
    //   obj_1attr_key2: 'attr_value2',
    // },
  }),
});
export const setSBInsuranceContractInitialValues = (
  idCheckData: IDCheck,
  newContractData: any,
) => ({
  prename: idCheckData
    ? idCheckData?.prename_from_id_document !== 'Not Available'
      ? idCheckData?.prename_from_id_document
      : ''
    : '',
  surname: idCheckData
    ? idCheckData?.surname_from_id_document !== 'Not Available'
      ? idCheckData?.surname_from_id_document
      : ''
    : '',
  identity_document_type: idCheckData?.identity_document_type || '',
  identity_document_number:
    idCheckData?.identity_document_number !== 'Not Available'
      ? idCheckData?.identity_document_number
      : '',
  street:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.street
      : '',
  postal_code:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.postal_code
      : '',
  city:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.city
      : '',
  house:
    newContractData && newContractData.customer.address
      ? newContractData.customer.address.house
      : '',
  salutation: '',
  date_of_birth:
    newContractData && newContractData.customer.date_of_birth
      ? dataFormat({
          //@ts-ignore
          date: newContractData.customer.date_of_birth,
        })
      : '',
  identity_document_number_drive:
    idCheckData?.drivers_liscense_id_check?.identity_document_number !==
    'Not Available'
      ? idCheckData?.drivers_liscense_id_check?.identity_document_number
      : '',
  identity_document_type_drive:
    idCheckData?.drivers_liscense_id_check?.identity_document_type !==
    'Not Available'
      ? idCheckData?.drivers_liscense_id_check?.identity_document_type
      : '',
  drivers_liscense_issuing_authority:
    idCheckData?.drivers_liscense_id_check
      ?.drivers_liscense_issuing_authority || '',
  drivers_license_classes:
    idCheckData?.drivers_liscense_id_check?.drivers_license_classes || '',
  telephone_number: null,
  email: '',
  is_valid_drivelicense: false,
  license_plate: '',
  start_date: '',
  end_date: '',
  kind: '',
  sb_sum: 0,
  payment_method: '',
  vin: '',
  SBInsurance: 'selected',
  disscount: '',
  isPickupdateBeforeReturnDate: true,
});
export const validationSchema = yup.object({
  email: yup
    .string()
    .email(
      'Die eingegebene E-Mail-Adresse ist ungültig, bitte überprüfen Sie das Format.',
    )
    .defined('E-Mail ist erforderlich')
    .typeError(
      'Die eingegebene E-Mail-Adresse ist ungültig, bitte überprüfen Sie das Format.',
    ),
  // .notRequired()
  // .nullable()
  salutation: yup
    .string()
    .required('Die Andrede ist erforderlich')
    .nope(['Bitte auswählen'], 'Andrede ist erforderlich '),
  prename: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Vorname mindestens 3 Zeichen lang sein muss',
    )
    .defined('Vorname ist erforderlich')
    // .nope(['Not Available'],'Bitte geben Sie den Vornamen ein')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  surname: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Nachname mindestens 3 Zeichen lang sein muss',
    )
    .defined('Bitte geben Sie den Nachnamen ein')
    .required(),
  // .nope(['Not Available'],'Bitte geben Sie den Vornamen ein'),

  identity_document_type: yup
    .string()
    .required('Der Ausweistyp ist erforderlich'),
  identity_document_number: yup
    .mixed()
    .required('Die Ausweisnummer ist erforderlich'),
  identity_document_type_drive: yup.string(),
  // .required('Die Fahrerlaubnis ist erforderlich'),
  identity_document_number_drive: yup
    .mixed()
    .required('Führerscheinnummer ist erforderlich'),
  drivers_liscense_issuing_authority: yup
    .mixed()
    .required('Die Ausstellungsbehörde ist erforderlich'),
  // .required('Die Ausstellungsbehörde ist erforderlich'),
  drivers_license_classes: yup
    .mixed()
    .required('Die Führerscheinklassen ist erforderlich'),
  // .required('Die Führerscheinklassen sind erforderlich'),
  date_of_birth: yup
    .date()
    .typeError(
      'Bitte geben Sie ein gültiges Geburtsdatum im Format TT.MM.JJJJ ein.',
    )
    .transform(parseDateString)
    .max(
      today,
      'Bitte geben Sie ein gültiges Geburtsdatum im Format TT.MM.JJJJ ein.',
    )
    .required('Das Geburtsdatum ist erforderlich'),
  // .required('Das Geburtsdatum ist erforderlich'),
  street: yup.string().required('Die Straße ist erforderlich'),
  house: yup.mixed().required('Die Hausnummer ist erforderlich'),
  postal_code: yup
    .number()
    .typeError(
      'Die Postleitzahl darf nur aus Zahlen bestehen. Bitte geben Sie eine gültige Postleitzahl ein.',
    )
    .min(5, 'mindestens 5 Zeichen')
    .required('Die PLZ ist erforderlich'),
  city: yup.string().defined('Der Ort ist erforderlich'),
  is_valid_drivelicense: yup
    .bool()
    .oneOf(
      [true],
      'Bitte bestätigen Sie die Überprüfung und Gültigkeit der Führerscheinklassen',
    )
    .required('isvalid drive_liscense'),
  telephone_number: yup
    .number()
    // .min(8, 'mindestens 8 Zeichen')
    .required('Die Mobilnummer ist erforderlich.')
    .typeError(
      'Die eingegebene Mobilnummer ist ungültig, bitte überprüfen Sie das Format und verwenden Sie nur Zahlen.',
    ),
  // .matches(phoneRegExp, {
  //   message:
  //     'Die eingegebene Mobilnummer ist ungültig, bitte überprüfen Sie das Format und verwenden Sie nur Zahlen.',
  //   excludeEmptyString: false,
  // })
  // .min(5, 'Die Mobilnummer muss mindestens 5 Zeichen lang sein')
  // .notRequired()
  // ,
  brand: yup
    .string()
    .required('Bitte wählen Sie ein Fahrzeug aus der Fahrzeugsuche aus.'),
  vin: yup
    .mixed()
    .required('Bitte wählen Sie ein Fahrzeug aus der Fahrzeugsuche aus.'),
  license_plate: yup.mixed().required('Kennzeichen ist erforderlich'),
  pre_damage: yup.string(),
  odometer_value: yup
    .number()
    .nullable()
    .typeError('Der Kilometerstand darf nur aus Zahlen bestehen.')
    .required('KM Stand (Übergabe) ist erforderlich'),
  start_of_use: yup.date().required('Abholung ist erforderlich'),
  end_of_use: yup.date().required('Rückgabe ist erforderlich'),
  isFree: yup.boolean(),
  tagesgrundpreis: yup.number().nullable(),
  inkl_km_tagesgrundpreis: yup.number().nullable(),
  zusatz_km: yup.number().nullable(),
  stundenpauschale: yup.number().nullable(),
  inkl_km_stundenpauschale: yup.number().nullable(),
  fahrzeugkaution: yup.number().nullable(),
  additional_costs_attributes: yup.array(),
  kind: yup.string().required('Vertragsart ist erforderlich'),
  with_teilkasko: yup.boolean(),
  teilkasko_selbstbeteiligung_amount: yup
    .number()
    // .required('selbstbeteiligung ist erforderlich')
    .when('with_teilkasko', (with_teilkasko: boolean, schema: any) => {
      if (with_teilkasko)
        return schema.required('Teilkasko Selbstbeteiligung ist erforderlich');
    }),
  with_vollkasko: yup.boolean(),
  vollkasko_selbstbeteiligung_amount: yup
    .number()
    // .required('selbstbeteiligung ist erforderlich')
    .when('with_vollkasko', (with_vollkasko: boolean, schema: any) => {
      if (with_vollkasko)
        return schema.required('Vollkasko Selbstbeteiligung ist erforderlich');
    }),
  isPickupdateBeforeReturnDate: yup
    .string()
    .required(
      'Die Rückgabezeit darf nicht vor der Abholzeit liegen. Bitte überpürfen Sie das Datum und die Uhrzeit',
    ),
  additional_notes: yup.string().nullable(),
  // .oneOf([yup.ref('insurance')], 'Passwords must match')
});

export const SBvalidationSchema = yup.object({
  email: yup
    .string()
    .email(
      'Die eingegebene E-Mail-Adresse ist ungültig, bitte überprüfen Sie das Format.',
    )
    .defined('E-Mail ist erforderlich')
    .typeError(
      'Die eingegebene E-Mail-Adresse ist ungültig, bitte überprüfen Sie das Format.',
    ),
  prename: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Vorname mindestens 3 Zeichen lang sein mus',
    )
    .defined('Der Vorname ist erforderlich')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  surname: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Nachname mindestens 3 Zeichen lang sein muss',
    )
    .defined('Bitte geben Sie den Nachnamen ein'),
  salutation: yup
    .string()
    .required('Die Andrede ist erforderlich')
    .nope(['Bitte auswählen'], 'Andrede ist erforderlich '),
  identity_document_type: yup
    .string()
    .required('Der Ausweistyp ist erforderlich'),
  identity_document_number: yup
    .mixed()
    .required('Die Ausweisnummer ist erforderlich'),
  identity_document_type_drive: yup.string(),
  // .required('Die Fahrerlaubnis ist erforderlich'),
  identity_document_number_drive: yup
    .mixed()
    .required('Führerscheinnummer ist erforderlich'),
  // .required('Die Führerschein-Nummer ist erforderlich'),
  drivers_liscense_issuing_authority: yup
    .mixed()
    .required('Die Ausstellungsbehörde ist erforderlich'),
  // .required('Die Ausstellungsbehörde ist erforderlich'),
  drivers_license_classes: yup
    .mixed()
    .required('Die Führerscheinklassen ist erforderlich'),
  // .required('Die Führerscheinklassen sind erforderlich'),
  date_of_birth: yup
    .date()
    .typeError(
      'Bitte geben Sie ein gültiges Geburtsdatum im Format TT.MM.JJJJ ein.',
    )
    .transform(parseDateString)
    .max(
      today,
      'Bitte geben Sie ein gültiges Geburtsdatum im Format TT.MM.JJJJ ein.',
    )
    .required('Das Geburtsdatum ist erforderlich'),
  // .required('Das Geburtsdatum ist erforderlich'),
  street: yup.string().required('Die Straße ist erforderlich'),
  house: yup.mixed().required('Die Hausnummer ist erforderlich'),
  postal_code: yup
    .number()
    .min(5, 'mindestens 5 Zeichen')
    .defined('Die PLZ ist erforderlich'),
  city: yup.string().defined('Der Ort ist erforderlich'),
  is_valid_drivelicense: yup
    .bool()
    .oneOf(
      [true],
      'Bitte bestätigen Sie die Überprüfung und Gültigkeit der Führerscheinklassen',
    )
    .required('isvalid drive_liscense'),
  telephone_number: yup
    .number()
    // .min(8, 'mindestens 8 Zeichen')
    .required('Die Mobilnummer ist erforderlich.')
    .typeError(
      'Die eingegebene Mobilnummer ist ungültig, bitte überprüfen Sie das Format und verwenden Sie nur Zahlen.',
    ),
  // .matches(phoneRegExp, {
  //   message:
  //     'Die eingegebene Mobilnummer ist ungültig, bitte überprüfen Sie das Format und verwenden Sie nur Zahlen',
  //   excludeEmptyString: false,
  // })
  // .required('FIN/ VIN ist erforderlich')
  // SBInsurance: yup.string(),
  kind: yup.string().required('Vertragsart ist erforderlich'),
  vin: yup.mixed().required('VIN ist erforderlich'),
  license_plate: yup.mixed().required('Kennzeichen ist erforderlich'),

  start_date: yup.date().required('Abholung ist erforderlich'),
  end_date: yup.date().required('Rückgabe ist erforderlich'),

  disscount: yup
    .number()
    .required('Versicherte Selbstbeteiligung muss ausgefüllt werden')
    .max(2500, 'Versicherte Selbstbeteiligung darf max. 2.500€ betragen'),
  sb_sum: yup.number().nullable(),
  payment_method: yup.string().required('Zahlungsmethode ist erforderlich'),
  // .oneOf([yup.ref('insurance')], 'Passwords must match')
});
