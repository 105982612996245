import React from 'react';
import { getClasses } from '../../utils';
import { NavLink } from 'react-router-dom';

import './nav.scss';

type NavProp = {
  value?: number;
  onChange?: any;
  children?: React.ReactNode;
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
  color?: string;
  className?: string;
};

type NavItemProps = {
  value?: number;
  selected?: boolean;
  onChange?: any;
  icon?: React.ReactNode;
  label?: string;
  className?: string;
  component?: any;
  to: string;
  selectedClass?: string;
};
let Item: React.FC<NavItemProps> = ({
  onChange,
  value,
  // component = "a",
  icon,
  label,
  className,
  to,
  selectedClass,
}) => {
  // const Component = component
  const classes = `${className ? className : ''}`.trim();

  const handleChange = (event: any) => {
    if (onChange) {
      onChange(event, value);
      // console.log("event", event.target.id)
    }
  };

  return (
    //@ts-ignore
    <NavLink
      activeClassName={selectedClass}
      to={to}
      className={classes}
      onClick={(e: any) => handleChange(e)}>
      {icon}
      {label}
    </NavLink>
  );
};
const Nav = ({
  children,
  height,
  width,
  margin,
  padding,
  color,
  className,
}: NavProp) => {
  const classes = getClasses({
    margin,
    padding,
    color,
    height,
    width,
  });

  return (
    <nav
      className={`${classes.length > 0 ? classes : ''} ${
        className ? className : ''
      }`}>
      {children}
    </nav>
  );
};

Nav.Item = Item;

export default Nav;
