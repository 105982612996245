const CarIcon = ({
  color = '#ffffff',
  className,
}: {
  color?: string;
  className?: string;
}) => {
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      // className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M20.75 20.75C19.509 20.75 18.5 19.741 18.5 18.5V17.75H6.5V18.5C6.5 19.741 5.491 20.75 4.25 20.75C3.009 20.75 2 19.741 2 18.5V17.621C1.115 17.308 0.5 16.46 0.5 15.5V13.249C0.5 12.891 0.574 12.543 0.72 12.213C0.724 12.203 0.736 12.177 0.749 12.152C0.868 11.895 1.04 11.649 1.249 11.439L3.189 9.499L2.439 8.749H1.25C0.836 8.75 0.5 8.414 0.5 8C0.5 7.586 0.836 7.25 1.25 7.25H2.75C2.95 7.25 3.139 7.328 3.28 7.47L3.956 8.146L5.247 4.697C5.684 3.532 6.813 2.75 8.056 2.75H16.941C18.184 2.75 19.313 3.532 19.749 4.697L21.041 8.148L21.72 7.47C21.86 7.33 22.053 7.25 22.25 7.25H23.75C24.164 7.25 24.5 7.586 24.5 8C24.5 8.414 24.164 8.75 23.75 8.75H22.561L21.811 9.5L23.751 11.44C23.961 11.65 24.133 11.897 24.261 12.172C24.27 12.19 24.279 12.209 24.287 12.229C24.426 12.541 24.5 12.89 24.5 13.249V15.5C24.5 16.46 23.885 17.308 23 17.621V18.5C23 19.741 21.991 20.75 20.75 20.75ZM20 18.5C20 18.914 20.336 19.25 20.75 19.25C21.164 19.25 21.5 18.914 21.5 18.5V17.75H20V18.5ZM3.5 18.5C3.5 18.914 3.836 19.25 4.25 19.25C4.664 19.25 5 18.914 5 18.5V17.75H3.5V18.5ZM2 15.5C2 15.914 2.336 16.25 2.75 16.25H22.25C22.664 16.25 23 15.914 23 15.5V13.25H19.871C19.671 13.25 19.483 13.328 19.341 13.469L18.28 14.53C18.138 14.672 17.95 14.75 17.75 14.75C17.55 14.75 17.361 14.672 17.22 14.53C17.079 14.388 17 14.2 17 14C17 13.8 17.078 13.611 17.22 13.47L18.281 12.409C18.706 11.984 19.271 11.75 19.871 11.75H21.939L20.439 10.25H4.561L3.061 11.75H5.129C5.73 11.75 6.295 11.984 6.719 12.409L7.78 13.47C7.922 13.611 8 13.8 8 14C8 14.2 7.922 14.389 7.78 14.53C7.638 14.672 7.45 14.75 7.25 14.75C7.05 14.75 6.861 14.672 6.72 14.53L5.659 13.469C5.52 13.33 5.327 13.25 5.129 13.25H2V15.5ZM19.666 8.75L18.345 5.223C18.127 4.641 17.563 4.25 16.941 4.25H8.057C7.435 4.25 6.871 4.641 6.653 5.223L5.332 8.75H19.666Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CarIcon;
