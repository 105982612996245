import { useState } from 'react';
import {
  useLocation,
  useHistory,
  useRouteMatch,
  matchPath,
  match,
} from 'react-router-dom';
import { Query } from 'shared';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import NavItems from '../../layout/nav_with_items';
import { Button, Nav, SelectList } from '../../../styles/components';
import { IdCheck } from '../../../styles/icons';
import useStore from '../../../ui-state/ui';
import { Modal } from '../../shared';
import { useAuthContext } from '../../../hooks';

const selectValues = [
  { name: 'Datum', value: 'created_at', id: 0 },
  { name: 'Status', value: 'approval_state', id: 1 },
  { name: 'Kunde', value: 'surname', id: 2 },
  { name: 'Abschluss ohne Vertrag', value: 'discarded_at', id: 3 },
];

const handleDynamicRoutes = (
  match: match | null,
  url?: string,
  customer?: {
    prename: string;
    salutation: string;
    surname: string;
  },
  contactId?: number,
):
  | {
      name: string;
      url: string;
      pageTitle: string;
    }
  | undefined => {
  const params = match ? match.params : undefined;
  //@ts-ignore
  const topicId = params ? params.topicId : undefined;
  if (!topicId) return undefined;
  let linkItemObject;
  if (topicId === 'new_legitimation') {
    linkItemObject = {
      name: 'Legitimation',
      url: `${url}/new_legitimation`,
      pageTitle: 'Neue Legitimation',
    };
  } else if (topicId === 'contract_details') {
    linkItemObject = {
      name: `#${contactId}`,
      url: `${url}/contract_details/${contactId}`,
      pageTitle: 'Fahrzeugüberlassungsvertrag',
    };
  } else if (topicId === 'sb_contract_details') {
    linkItemObject = {
      name: `#${contactId}`,
      url: `${url}/sb_contract_details/${contactId}`,
      pageTitle: 'Selbstbeteiligungschutz Vertrag',
    };
  } else {
    linkItemObject = {
      name: `ID${topicId}`,
      url: `${url}/${topicId}`,
      pageTitle: customer
        ? `${customer.salutation} ${customer.prename} ${customer.surname}`
        : '',
    };
  }
  return linkItemObject;
};

const IDcheckHeader = ({ contractType }: { contractType?: string }) => {
  const [open, setOpen] = useState(false);
  const auth = useAuthContext();

  let location = useLocation();
  let history = useHistory();
  let { path, url } = useRouteMatch();
  const localStorageUser = window.localStorage.getItem('user');
  const parsedObje = localStorageUser
    ? JSON.parse(localStorageUser)
    : undefined;
  const user = auth.user ? auth.user : parsedObje ? parsedObje : undefined;
  const { role, plan_version } = user;
  const isAdmin = role === 'Admin';
  const isLightProduct = plan_version === 'light';

  const [modaleStatus, setModalStatus] = useState<{
    status:
      | 'ISREJECTED'
      | 'NAMEDOESNOTMATCH'
      | 'NAMEDOESNOTMATCHANDREJECTED'
      | 'CLOSINGWITHOUTCONTRACT'
      | null;
  }>({
    status: null,
  });
  const sorteValue = useStore((state) => state.sorteValue);
  const selectedContractId = useStore((state) => state.selectedContractId);

  const setSortedValue = useStore((state) => state.onSelectSortValue);
  const setClosingWithoutContract = useStore(
    (state) => state.setClosingWithoutContract,
  );
  const { disCardLegitimation, FetchIDCheckDataById } = Query();

  const matchobject = matchPath<{
    topicId: string | undefined;
  }>(location.pathname, {
    path: `${path}/:topicId`,
  });

  const id = matchobject ? matchobject?.params.topicId : undefined;
  const contactId = selectedContractId ? selectedContractId : undefined;
  const islightWithSBInsurance =
    contractType === 'lightWithSBInsurance' && isLightProduct;
  const {
    data: LegitimationDetailsData,
    // error,
    // isSuccess,
  } = FetchIDCheckDataById({
    id: id,
    key: `id_check-${id}`,
    enabled: !!Number(id),
  });
  // console.log(
  //   '🚀 ~ IDcheckHeader ~ LegitimationDetailsData:',
  //   LegitimationDetailsData,
  // );

  const idCheckSingleValue = useStore((state) => state.idCheckSingleValue);
  // console.log(
  //   '🚀 ~ file: header.tsx:95 ~ IDcheckHeader ~ idCheckSingleValue:',
  //   // error,
  //   id,
  //   url,
  //   // contactId,
  // );
  const customerShort = idCheckSingleValue
    ? idCheckSingleValue.customer_short
    : undefined;

  const paramsobject = matchobject ? matchobject.params : null;
  const headerDeatails = handleDynamicRoutes(
    matchobject,
    url,
    customerShort,
    contactId,
  );

  const items = [
    {
      name: 'ID Check',
      url: url,
      icon: ChevronRightIcon,
    },
    {
      name: headerDeatails ? headerDeatails.name : '',
      url: headerDeatails ? headerDeatails.url : 'undefined',
      icon: ChevronRightIcon,
    },
  ];
  const isIdRejectd =
    LegitimationDetailsData &&
    LegitimationDetailsData.approval_state === 'REJECTED';
  const isNameOnIdCardMatchNameOnDriveLiecense =
    LegitimationDetailsData &&
    LegitimationDetailsData.drivers_liscense_id_check.is_persons_name_matched;
  const isClosedWithoutContract =
    LegitimationDetailsData && LegitimationDetailsData.discarded_at;

  const handleCreatContract = () => {
    if (isIdRejectd && !isNameOnIdCardMatchNameOnDriveLiecense) {
      // console.log(
      //   'NAMEDOESNOTMATCHANDREJECTED',
      //   isNameOnIdCardMatchNameOnDriveLiecense,
      // );
      setModalStatus({ status: 'NAMEDOESNOTMATCHANDREJECTED' });
      setOpen(true);
      //@ts-ignore
    } else if (isIdRejectd) {
      // console.log('isIdRejectd', isIdRejectd);
      setModalStatus({ status: 'ISREJECTED' });
      setOpen(true);
    } else {
      history.push(`${path}/new_contract/${id}`);
    }
  };
  const handleCreatSBContract = () => {
    if (isIdRejectd && !isNameOnIdCardMatchNameOnDriveLiecense) {
      // console.log(
      //   'NAMEDOESNOTMATCHANDREJECTED',
      //   isNameOnIdCardMatchNameOnDriveLiecense,
      // );
      setModalStatus({ status: 'NAMEDOESNOTMATCHANDREJECTED' });
      setOpen(true);
      //@ts-ignore
    } else if (isIdRejectd) {
      // console.log('isIdRejectd', isIdRejectd);
      setModalStatus({ status: 'ISREJECTED' });
      setOpen(true);
    } else {
      history.push(`${path}/new_sb_contract/${id}`);
    }
  };
  const onAccept = () => {
    if (modaleStatus.status === 'CLOSINGWITHOUTCONTRACT') {
      const LegitimationId = LegitimationDetailsData?.id;
      //@ts-ignore
      closeWithoutContract(LegitimationId);
      setClosingWithoutContract(true);
      history.push(`${path}`);
      setOpen(false);
    } else {
      setOpen(false);
      if (islightWithSBInsurance) {
        history.push(`${path}/new_sb_contract/${id}`);
      } else {
        history.push(`${path}/new_contract/${id}`);
      }
    }
  };
  const handleCloseWithoutContract = () => {
    setModalStatus({ status: 'CLOSINGWITHOUTCONTRACT' });
    setOpen(true);
  };
  const closeWithoutContract = async (id: number) => {
    await disCardLegitimation({ id: id });
  };

  const modalText = {
    CLOSINGWITHOUTCONTRACT: 'Ohne Vertrag abschließen?',
    ISREJECTED: (
      <p className="font-normal">
        Die <span className="font-semibold">Legitimation wurde abgelehnt</span>.
        Sind Sie sich sicher, dass Sie trotz abgelehnter Legitimation einen
        Vertrag erstellen wollen?
      </p>
    ),
    NAMEDOESNOTMATCH: (
      <p className="font-normal">
        Es wurde festgestellt, dass die{' '}
        <span className="font-semibold">
          Namen mit den Informationen auf dem Personalausweis und dem
          Führerschein nicht übereinstimmen
        </span>
        . Möchten Sie trotzdem einen Vertrag erstellen?
      </p>
    ),
    NAMEDOESNOTMATCHANDREJECTED: (
      <p className="font-normal">
        Es wurde festgestellt,{' '}
        <span className="font-semibold">
          Namen mit den Informationen auf dem Personalausweis und dem
          Führerschein nicht übereinstimmen.
        </span>{' '}
        Möchten Sie trotzdem einen Vertrag erstellen?
      </p>
    ),
  };

  if (!LegitimationDetailsData && paramsobject) return null;
  return (
    <div>
      <div>
        {paramsobject !== null && (
          <nav className="sm:hidden" aria-label="Back">
            {/* mobile btn */}
            <Nav.Item
              label="Back"
              to={url}
              icon={
                <ChevronLeftIcon
                  className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-500"
                  aria-hidden="true"
                />
              }
              className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-800"
            />
          </nav>
        )}
        <NavItems
          // verticalitems={false}
          selectedClass="text-black"
          items={
            headerDeatails
              ? items
              : [
                  {
                    name: '',
                    url: url,
                    icon: undefined,
                  },
                ]
          }
          navClassName="hidden sm:flex flex items-center space-x-4"
          itemClassName="flex flex-row-reverse items-center text-sm font-medium text-gray-500 hover:text-gray-700"
        />
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {headerDeatails && headerDeatails.pageTitle
              ? headerDeatails.pageTitle
              : 'ID Checks'}
          </h2>
        </div>
        {paramsobject &&
          !isClosedWithoutContract &&
          paramsobject.topicId !== 'new_legitimation' &&
          paramsobject.topicId !== 'contract_details' &&
          !isLightProduct && (
            <div className="mt-4 flex flex-col md:flex-row md:mt-0 md:ml-4 md:w-6/12 sm:w-full md:items-end justify-items-end justify-end">
              {/* <div>
              <Button
                startIcon={<TrashIcon className="w-5 h-5 mr-3" />}
                type="default"
                size="medium"
                onClick={() => null}
                label="Vorgang löschen"
                // className="ml-3"
              />
            </div> */}
              <div>
                <Button
                  type="primary"
                  size="medium"
                  onClick={handleCloseWithoutContract}
                  label="ohne Vertrag abschließen"
                  className="mb-3 md:mb-0 w-full justify-center"
                />
              </div>
              <div>
                <Button
                  type="primary"
                  size="medium"
                  onClick={handleCreatContract}
                  label="Vertrag erstellen"
                  className="md:ml-3 w-full justify-center"
                />
              </div>
            </div>
          )}
        {paramsobject &&
          paramsobject.topicId !== 'new_legitimation' &&
          paramsobject.topicId !== 'new_sb_contract' &&
          paramsobject.topicId !== 'sb_contract_details' &&
          islightWithSBInsurance && (
            <div className="mt-4 flex flex-col md:flex-row md:mt-0 md:ml-4 md:w-3/12 sm:w-full md:items-end justify-items-end justify-end">
              <div>
                <Button
                  type="primary"
                  size="medium"
                  onClick={handleCreatSBContract}
                  label="SB-Schutz buchen"
                  className="md:ml-3 w-full justify-center"
                />
              </div>
            </div>
          )}
        {paramsobject === null && (
          <div className="mt-4 flex flex-col md:flex-row md:mt-0 md:ml-4 md:w-96 sm:w-full">
            {isAdmin ? (
              <div className="w-full items-center shadow-sm border border-gray-300 bg-white md:z-10">
                <SelectList
                  items={selectValues}
                  defaultValue={'Sortieren nach'}
                  selected={sorteValue ? sorteValue : ''}
                  onSelect={setSortedValue}
                />
              </div>
            ) : null}
            <div className="flex flex-shrink w-full md:justify-end">
              <Button
                startIcon={<IdCheck className="w-5 h-5 mr-3" />}
                type="primary"
                size="small"
                onClick={() => history.push(`${path}/new_legitimation`)}
                label="ID Check starten"
                className="md:ml-3 mt-2 md:mt-0 w-full py-3"
              />
            </div>
          </div>
        )}
      </div>

      <Modal
        setOpen={setOpen}
        open={open}
        title={modaleStatus.status ? modalText[modaleStatus.status] : ''}
        // subtile="lorem ipsum brety breaken testing subtitle to go for anothre place to go"
      >
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={onAccept}
            label="Ja, sicher"
            type="primary"
            className="w-full justify-center "
          />
        </div>
        <div className="mt-5 sm:mt-6">
          <Button
            onClick={() => {
              setOpen(false);
              setModalStatus({ status: null });
            }}
            label="Abbrechen"
            type="default"
            className="w-full justify-center "
          />
        </div>
      </Modal>
    </div>
  );
};

export default IDcheckHeader;
