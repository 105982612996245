/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useRef,
  // Fragment, useEffect,
  useState,
} from 'react';
import {
  Field,
  useFormikContext,
  //  useFormikContext, FormikValues
} from 'formik';
// import { Query, IDictionary } from 'shared';
// import Downshift from 'downshift';
import { Form } from '../../../../shared';
// import FieldWithFeedBack from '../checktastic_contract/form_section/field_input_with_feedback';

// import { classNames } from '../../../../../styles/utils';
import DatePicker from '../date_picker';
import TimePicker from '../time_picker';

import FormInputCurrencyField from '../checktastic_contract/form_section/custom_input';

import moment from 'moment';
import { Query } from 'shared';
import config from 'shared/config';

type SBInsuranceProp = {
  openTab: number;
  labelClass: string;
  fieldClassName: string;
  hasSBInsurance: boolean;
  isPremiumCustomer: boolean;
  dictionaryData: any;
  license_plate: string;
};

const SBInsurance = ({
  labelClass,
  fieldClassName,
  openTab,
  isPremiumCustomer,
  hasSBInsurance,
  dictionaryData,
  license_plate,
  //@ts-ignore
  start_of_use,
  //@ts-ignore
  SBInsurance,
  //@ts-ignore
  end_of_use,
  //@ts-ignore
  vin,
}: SBInsuranceProp) => {
  // console.log('🚀 ~ SBInsurance:', SBInsurance);
  const {
    values: {
      kind,
      end_date,
      start_date,
      with_teilkasko,
      with_vollkasko,
      teilkasko_selbstbeteiligung_amount,
      vollkasko_selbstbeteiligung_amount,
    },

    setFieldValue,
    errors,
    dirty,
  } = useFormikContext<any>();
  const { FetchSbContractValue } = Query();

  // console.log('🚀 ~ kind:', start_date, end_date);
  // const [startTime, setStartTime] = useState<any>();
  // const [endTime, setEndTime] = useState<any>(
  //   end_of_use
  //     ? {
  //         //@ts-ignore
  //         hours: dayjs(end_of_use).$H,
  //         //@ts-ignore
  //         minutes: dayjs(end_of_use).$M,
  //       }
  //     : null,
  // );
  const [startDateObj, setStartDate] = useState(
    start_of_use ? moment(start_of_use) : null,
  );
  const [endtDateObj, setEndDate] = useState(
    end_of_use ? moment(end_of_use) : null,
  );

  const {
    data: sbContractValue,
    isLoading,
    isFetching,
    isFetched,
    refetch,
  } = FetchSbContractValue({
    // fetch sbValues based of start and end date
    url: `${config.apiURL}/sb_insurance/cost_sets?start_date=${
      isPremiumCustomer ? start_of_use : start_date
    }&end_date=${isPremiumCustomer ? end_of_use : end_date}&kind=${kind}`,
    enabled: isPremiumCustomer ? !!end_of_use && !!kind : !!end_date && !!kind,
    key: `sbContractValue-${openTab}`,
  });

  useEffect(() => {
    if (startDateObj && !isPremiumCustomer) {
      //@ts-ignore
      setFieldValue('start_date', startDateObj._d);
    }
  }, [startDateObj, setFieldValue]);

  useEffect(() => {
    if (endtDateObj && !isPremiumCustomer) {
      //@ts-ignore
      setFieldValue('end_date', endtDateObj._d);
    }
  }, [endtDateObj, setFieldValue]);

  useEffect(() => {
    // for Light product we need to fetch the SB contract value based on the start and end date and the kind of the contract
    if (!isLoading && !isFetching && !!end_date && !!kind) {
      // console.log('🚀 ~ end_date:', end_date, start_date);
      refetch();
    }
    if (sbContractValue && isFetched) {
      setFieldValue('sb_sum', sbContractValue?.cents / 100);
    }
  }, [end_date, start_date, kind, sbContractValue]);

  // useEffect(() => {
  //   if (
  //     !vollkasko_selbstbeteiligung_amount &&
  //     !teilkasko_selbstbeteiligung_amount
  //   ) {
  //     setFieldValue('SBInsurance', 'notSelected');
  //   }
  // }, [teilkasko_selbstbeteiligung_amount, vollkasko_selbstbeteiligung_amount]);

  const isNoInsuranceSelected = !with_vollkasko;
  // const summeErlassSbValue = teilkasko_selbstbeteiligung_amount || teilkasko_selbstbeteiligung_amount;
  // console.log('🚀 ~ summeErlassSbValue:', summeErlassSbValue);
  return (
    <div className="border-b-0 col-span-6 mt-1 space-y-4">
      {/* hiding the checkbox if there is cost from backed */}
      {/* if the product type light then no need to add SB as extra */}
      {isPremiumCustomer && (
        <div className="flex items-start  flex-col">
          <div role="group" aria-labelledby="my-radio-group">
            <label className="font-bold text-gray-500 text-sm">
              <Field
                type="radio"
                name="SBInsurance"
                value="selected"
                disabled={isNoInsuranceSelected}
              />
              <span className="ml-2">Ja</span>
            </label>
            <label className="ml-4 font-bold text-gray-500 text-sm">
              <Field type="radio" name="SBInsurance" value="notSelected" />
              <span className="ml-2">Nein</span>
            </label>
          </div>
          {isNoInsuranceSelected && (
            <div className="flex items-start mt-2">
              <p className="text-orange-500 font-semibold">
                Um den SB-Schutz buchen zu können, bitte auf der vorherigen
                Seite Vollkasko auswählen.
              </p>
            </div>
          )}
        </div>
      )}

      {SBInsurance === 'selected' && (
        <div className="grid grid-cols-6 gap-6">
          {isPremiumCustomer && (
            <div className="col-span-6 pt-10">
              <h3 className="text-2xl leading-6 font-bold text-gray-900">
                Übersicht SB-Schutz
              </h3>
              {/* <p className="text-gray-500 my-1">
                Bitte wählen Sie kostenfrei, oder entscheiden Sie auf Tages-
                oder Stundenbasis. Die Kosten werden im nächsten Schritt zu
                einer Übersicht
              </p> */}
            </div>
          )}
          {/* typea */}
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="start_of_use" className={`${labelClass} mb-1`}>
              Type
            </label>

            {/* @ts-ignore */}
            <div className="w-full block ">
              <Field
                isDisabled={isPremiumCustomer}
                // value={isPremiumCustomer ? kind : null}
                autoComplete="off"
                name="kind"
                component={Form.SelectField}
                options={
                  dictionaryData
                    ? dictionaryData
                      ? Object.values(
                          //@ts-ignore
                          dictionaryData.contract_kinds,
                        ).map((item: any) => ({
                          value: item.name,
                          label: `${item.name}`,
                        }))
                      : { value: '', label: '' }
                    : []
                  // : [{ value: `${kind}`, label: kind }]
                }
              />
            </div>
          </div>
          {/* number */}
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor={'sb-vin'} className={`${labelClass} mb-1`}>
              FIN/ VIN
            </label>

            {/* @ts-ignore */}
            <div className="w-full block">
              <Field
                value={isPremiumCustomer ? vin : undefined}
                disabled={isPremiumCustomer}
                type="text"
                name={isPremiumCustomer ? 'sb-vin' : 'vin'}
                autoComplete="off"
                className={fieldClassName}
              />
            </div>
          </div>
          <div className="col-span-6 sm:col-span-3">
            <label
              htmlFor={'sb-license_plate'}
              className={`${labelClass} mb-1`}>
              Kennzeichen
            </label>

            {/* @ts-ignore */}
            <div className="w-full block">
              <Field
                value={isPremiumCustomer ? license_plate : undefined}
                disabled={isPremiumCustomer}
                type="text"
                name={isPremiumCustomer ? 'sb-license_plate' : 'license_plate'}
                autoComplete="off"
                className={fieldClassName}
              />
            </div>
            {/* <label className={`${labelClass} mt-2`}>
              (Eingabe-Format{' '}
              <span className="text-gray-900 font-bold">D XY 1234</span> oder{' '}
              <span className="text-red-500 font-bold">D 0123</span>)
            </label> */}
          </div>
          {/* start */}
          <div className={'col-span-6'}>
            <div className="grid grid-cols-6 gap-6">
              {/* @ts-ignore */}
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor={'start_date'} className={`${labelClass} mb-1`}>
                  Beginn der Fahrt
                </label>
                <Field name={'start_date'} type="textarea">
                  {({ field }: any) => (
                    <DatePicker
                      disabled={isPremiumCustomer}
                      id="start_date"
                      dateObject={
                        // DatePicker componrnt is expecting a moment object
                        isPremiumCustomer ? moment(start_of_use) : startDateObj
                      }
                      setDate={setStartDate}
                      isOutsideRange={
                        isPremiumCustomer
                          ? null
                          : (date: any) => {
                              const isbefore = date.isAfter(
                                //@ts-ignore
                                endtDateObj,
                                'day',
                              );
                              const isToday = date.isBefore(moment(), 'day');

                              return isbefore || isToday;
                            }
                      }
                    />
                  )}
                </Field>
              </div>
              <div className="col-span-6 sm:col-span-3">
                <label htmlFor="end_date" className={`${labelClass} mb-1`}>
                  Ende der Fahrt
                </label>

                <Field name={'end_date'} type="textarea">
                  {({ field }: any) => (
                    <DatePicker
                      disabled={isPremiumCustomer}
                      isOutsideRange={(date: any) => {
                        return date.isBefore(
                          //@ts-ignore
                          moment(isPremiumCustomer ? end_of_use : start_date),
                          'day',
                        );
                      }}
                      dateObject={
                        // DatePicker componrnt is expecting a moment object
                        isPremiumCustomer ? moment(end_of_use) : endtDateObj
                      }
                      id="end_date"
                      setDate={setEndDate}
                    />
                  )}
                </Field>
              </div>
            </div>
          </div>
          {/* enddate */}

          {/* summe */}
          <div className="col-span-6 sm:col-span-3">
            {/* @ts-ignore */}
            <div className="w-full block">
              <Field type="text" name="disscount" disabled={isPremiumCustomer}>
                {({ field }: any) => (
                  <FormInputCurrencyField
                    disabled={isPremiumCustomer}
                    // defaultValue={
                    //   sbContractValue ? Number(sbContractValue?.cents / 100) : 0
                    // }
                    type="number"
                    label="Versicherte Selbstbeteiligung"
                    field={field}
                  />
                )}
              </Field>
            </div>
          </div>
          {/* additional booking */}
          <div className="col-span-6 sm:col-span-3">
            {/* <h3 className="text-2xl leading-6 font-bold text-gray-900">
              Weiterberechnung SB-Schutz
            </h3>
            <p className="text-gray-500 my-1">
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
            </p> */}

            {/* @ts-ignore */}
            <div className="w-full block">
              <Field type="text" name="sb_sum">
                {({ field }: any) => (
                  <FormInputCurrencyField
                    // this field is always disabled because the data is coming from the sbContractValue
                    disabled
                    // value={sbContractValue?.cents / 100}
                    defaultValue={
                      sbContractValue ? Number(sbContractValue?.cents / 100) : 0
                    }
                    type="number"
                    label="Gebühr"
                    field={field}
                  />
                )}
              </Field>
            </div>
          </div>

          {/* payment type */}
          <div className="col-span-6 sm:col-span-3">
            <label htmlFor="payment" className={`${labelClass} mb-1`}>
              Zahlungsmethode:
            </label>

            {/* @ts-ignore */}
            <div className="w-full block pr-3">
              <div role="group" aria-labelledby="my-radio-group">
                <label className="text-gray-900 text-sm">
                  <Field type="radio" name="payment_method" value="cash" />
                  <span className="ml-2">Barzahlung</span>
                </label>
                <label className="ml-4 text-gray-900 text-sm">
                  <Field type="radio" name="payment_method" value="card" />
                  <span className="ml-2">Kartenzahlung</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SBInsurance;
