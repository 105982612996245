import Logo from 'assets/images/logo-tastic-new.png';
import { version } from '../../../../package.json';

const AboutPage = () => {
  return (
    <div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Über Checktastic
          </h2>
        </div>
      </div>

      <div className="mt-4">
        <div className="mt-5">
          {/* <h2 className="text-xl font-bold text-gray-900 sm:text-xl sm:truncate">
            Diese Anwendung ist ein Produkt der 24relations GmbH.
          </h2> */}
          <div className="md:flex w-full justify-start">
            <div className="flex flex-col justify-between">
              {/* <p className="mt-2 text-md text-gray-500 max-w-2xl">
                Fahrzeug-Überlassung neu gedacht.
                <span className="font-bold block mt-1">
                  Und sicher gemacht.
                </span>
              </p> */}

              <p className="font-bold text-md text-gray-500 max-w-2xl mt-3 lg:mt-0">
                Aktuelle Version: {version}
                {/* <span className="block font-normal text-sm">
                  Erstellt am: 30.09.2021
                </span> */}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="lg:w-5/12">
            <div className="flex md:justify-center mr-8 mt-5 lg:mt-0">
              <img
                className="mx-auto h-auto w-auto"
                src={Logo}
                alt="Workflow"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
