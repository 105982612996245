import './index.scss';
import { classNames } from '../../utils';

const Spinner = ({ className }: { className?: string }) => {
  return (
    <div
      className={classNames('la-line-spin-fade-rotating', className, 'block')}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default Spinner;
