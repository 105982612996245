import dayjs from 'dayjs';
import { classNames } from '../../../../../styles/utils';

const SBContractOverview = ({
  kind,
  start_of_use,
  end_of_use,
  vin,
  disscount,
  sb_sum,
  payment_method,
  dtClassName,
  ddClassName,
  isContractOverviewPage,
  license_plate,
  start_date,
  end_date,
  isPremiumCustomer = true,
}: {
  start_date?: string;
  end_date?: string;
  isPremiumCustomer?: boolean;
  kind: string;
  start_of_use: string;
  end_of_use: string;
  vin: string;
  disscount: number;
  sb_sum: string;
  payment_method: string;
  dtClassName?: string;
  ddClassName?: string;
  isContractOverviewPage?: boolean;
  license_plate: string;
}) => {
  let startOfSbContract = dayjs(
    isPremiumCustomer ? start_of_use : start_date,
  ).format('DD.MM.YYYY');
  let endOfSbContract = dayjs(isPremiumCustomer ? end_of_use : end_date).format(
    'DD.MM.YYYY',
  );

  return (
    <div
      className={classNames(
        `${
          !isContractOverviewPage ? 'sm:p-6' : ''
        } "grid grid-cols-6 gap-1 py-6"`,
      )}>
      <div className="col-span-6 mt-5">
        <h3
          className={classNames(
            `${
              !isContractOverviewPage ? 'font-medium' : 'font-semibold'
            } text-lg leading-6  text-gray-900`,
          )}>
          SB-Schutz
        </h3>
      </div>
      <div className="col-span-6">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>Vertragsart</dt>

            <dd className={ddClassName}>{kind}</dd>
          </div>
        </dl>
      </div>
      <div className="col-span-6">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>Kennzeichen</dt>

            <dd className={ddClassName}>{license_plate}</dd>
          </div>
        </dl>
      </div>
      <div className="col-span-6">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>FIN/ VIN</dt>

            <dd className={ddClassName}>{vin}</dd>
          </div>
        </dl>
      </div>
      <div className="col-span-3 ">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>Beginn der Fahrt</dt>

            <dd className={ddClassName}>
              {startOfSbContract}
              {/* |{' '} */}
              {/* {dayjs(start_of_use).format('HH:mm')} */}
            </dd>
          </div>
        </dl>
      </div>
      <div className="col-span-3 ">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>Ende der Fahrt</dt>

            <dd className={ddClassName}>{endOfSbContract}</dd>
          </div>
        </dl>
      </div>

      <div className="col-span-6">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>Versicherte Selbstbeteiligung</dt>

            <dd className={ddClassName}>{disscount ? disscount : 0},- EUR</dd>
          </div>
        </dl>
      </div>

      <div className="col-span-6">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>Gebühr</dt>

            <dd className={ddClassName}>{sb_sum},- EUR</dd>
          </div>
        </dl>
      </div>

      <div className="col-span-6">
        <dl className="py-1">
          <div className="py-1 ">
            <dt className={dtClassName}>Zahlungsmethode</dt>

            <dd className={ddClassName}>
              {payment_method === 'card' ? 'Kartenzahlung' : 'Barzahlung'}{' '}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};
export default SBContractOverview;
