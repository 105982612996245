/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useState } from 'react';
import { Query } from 'shared';
import { FormikProps } from 'formik';
import {
  useParams,
  useHistory,
  //  Link
} from 'react-router-dom';
import { PrinterIcon } from '@heroicons/react/solid';
import {
  Form,
  Modal,
  ContactStatus,
  NotificationMessage,
  ErrorPage,
} from '../../../../shared';
import { Button } from '../../../../../styles/components';
import Steps from '../steps';
// import moment from 'moment';
import FormAction from '../form_action';
import { dataFormat } from '../../../../../utils';
import useStore from '../../../../../ui-state/ui';
import PersonalInfo from '../checktastic_contract/form_section/personal_data';
import FormHeader from '../form_header';
import {
  SBvalidationSchema,
  // validationSchema,
  setSBInsuranceContractInitialValues,
} from '../form_data';
import SBInsurance from './sb-insurance-form';

// import { validationSchema, setInitialValues } from './form_section/form_data';
import { statusStyles } from '../../utils';
// import config from 'shared/config';
import { ICntractConfig } from '../types';
import SBContractOverview from '../sb_contract/overview';
import config from 'shared/config';

type paramsProps = {
  id: string;
};

const NewSBInsuranceContract = ({
  contractConfig,
  contractType,
}: {
  contractConfig: Array<ICntractConfig>;
  contractType?:
    | 'contractPremium'
    | 'lightWithSBInsurance'
    | 'premumWithSBInsurance';
}) => {
  const [openTab, setOpenTab] = useState<string | number>(0);
  const [showNotification, setShow] = useState(false);

  const [serverError, setError] = useState('');
  // const [pdfUrl, setPdfUrl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  // const [selectedCar, setSelectedCar] = useState<{
  //   name: string;
  //   brand: string;
  //   vehicle_owner_id: number;
  // }>();
  const { id } = useParams<paramsProps>();
  const history = useHistory();
  const idCheckSingleValue = useStore((state) => state.idCheckSingleValue);

  const {
    FetchIDCheckDataById,
    SubmitDataToserver,
    FetchDictionarysData,
    FetchNewContractData,
    // FetchContractDefaultCosts,
  } = Query();
  const { data: newContractData, isLoading: loadingContractData } =
    FetchNewContractData({
      key: `new-contract-${id}`,
      url: `sb_insurance/contracts/new?id_check_id=${id}`,
    });

  const { data: dictionaryData } = FetchDictionarysData({
    key: 'dictionary-data',
  });
  //   const { data: defaltContactCost } = FetchContractDefaultCosts({
  //     key: 'default-costs',
  //   });

  const { data: IDCheckData } = FetchIDCheckDataById({
    id: id,
    key: `id_check-${id}`,
  });
  console.log('newContractData', newContractData);
  // console.log('IDCheckData=======', IDCheckData);
  const fieldClassName =
    'mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm';
  const labelClass = 'block text-sm font-medium text-gray-700';
  const propsClass = {
    fieldClassName,
    labelClass,
  };
  const { title, subTitle } = contractConfig[Number(openTab)];

  const { mutateAsync, reset, isLoading } = SubmitDataToserver({
    url: '/sb_insurance/contracts',
  });

  const handleSubmitContract = async (values: any) => {
    // console.log('🚀 ~ handleSubmitContract ~ values:', values);
    const {
      start_date,
      end_date,
      kind,
      vin,
      disscount,
      salutation,
      prename,
      surname,
      email,
      identity_document_number,
      identity_document_type,
      identity_document_number_drive,
      drivers_liscense_issuing_authority,
      drivers_license_classes,
      telephone_number,
      street,
      city,
      postal_code,
      house,
      payment_method,
      sb_sum,
      date_of_birth,
      license_plate,
    } = values;

    const formData = {
      sb_insurance_contract: {
        start_date: start_date,
        end_date: end_date,
        id_check_id: Number(id),
        kind: kind,
        contract_data_json: {
          vin: vin,
          license_plate: license_plate,
          disscount: disscount,
          sb_sum: sb_sum,
          payment_method: payment_method,
        },
        id_check_attributes: {
          id: Number(id),
          identity_document_number,
          identity_document_type,
          drivers_liscense_id_check_attributes: {
            id:
              newContractData &&
              newContractData.id_check_short &&
              newContractData.id_check_short.drivers_liscense_id_check
                ? newContractData.id_check_short.drivers_liscense_id_check.id
                : '',
            identity_document_number: identity_document_number_drive,
            drivers_license_classes: drivers_license_classes,
            drivers_liscense_issuing_authority:
              drivers_liscense_issuing_authority,
          },
        },

        customer_attributes: {
          salutation: salutation,
          prename: prename,
          surname: surname,
          email: email,
          date_of_birth: date_of_birth,
          telephone_number: telephone_number,
          address_attributes: {
            street: street,
            house: house,
            postal_code: postal_code,
            city: city,
          },
        },
      },
    };
    // console.log('🚀 ~ handleSubmitContract ~ formData:', formData);
    try {
      const res = await mutateAsync(formData);
      // console.log('res', res);
      if (res) {
        if (showNotification) {
          setShow(false);
        }
        // we disable the pdf view because of the cliend requirement.

        // const contractPdfUrl =
        //   res.data.unsigned_contract_file && res.data.unsigned_contract_file
        //     ? res.data.unsigned_contract_file.url
        //     : undefined;
        // if (contractPdfUrl) {
        //   setPdfUrl(`${config.baseURL}${contractPdfUrl}`);
        // }
        setOpenDialog(true);
        reset();
      }
    } catch (e) {
      //@ts-ignore
      if (e.response) {
        //@ts-ignore
        // console.log('e.response', e.response.data);
        //@ts-ignore
        if (e.response.status === 500) {
          setError('Server Error');
          setShow(true);
        }
        //@ts-ignore
        if (e.response.status === 422) {
          //@ts-ignore
          setError(e.response.data.errors[0]);
          setShow(true);
        }
      }
    }
  };
  const ddClassName = 'mt-1 text-sm text-gray-900 ';
  const dtClassName = 'text-sm font-medium text-gray-500';

  if (loadingContractData) return null;
  if (contractType !== 'lightWithSBInsurance') return <ErrorPage />;

  return (
    <div className="flex flex-col -m-5 pb-8">
      <Steps openTab={openTab} currentStepObject={contractConfig} />
      <div className="flex mx-1 sm:mx-6 lg:mx-5 py-5">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
          Selbstbeteiligungschutz
        </h2>
      </div>
      <div className="lg:flex  mx-1 sm:mx-6 lg:mx-5 h-full  flex-wrap justify-between">
        <main className="lg:flex shadow-md rounded-md bg-white lg:w-8/12">
          {/* Primary column */}
          <section
            aria-labelledby="primary-heading"
            className="flex-1 flex flex-col">
            {/* steps content start */}
            <Form
              // validateOnMount={false}
              submitForm={handleSubmitContract}
              validationSchema={SBvalidationSchema}
              //passing the initial values of IDCheckData to the form because
              initialValues={setSBInsuranceContractInitialValues(
                IDCheckData,
                newContractData,
              )}>
              {({ values, errors }: FormikProps<any>) => {
                const {
                  identity_document_type,
                  identity_document_number,
                  // identity_document_type_drive,
                  identity_document_number_drive,
                  drivers_liscense_issuing_authority,
                  drivers_license_classes,
                  telephone_number,
                  street,
                  city,
                  postal_code,
                  house,
                  prename,
                  surname,
                  email,
                  date_of_birth,
                } = values;
                const identity_document_type_translated =
                  identity_document_type === 'IDENTITY_CARD'
                    ? 'Personalausweis'
                    : 'Reisepass';
                const identity_document_type_drive_translated = 'Führerschein';
                return (
                  <>
                    <div className={'px-4 sm:px-6 pt-5'}>
                      <FormHeader title={title} subTitle={subTitle} />
                    </div>
                    <div className={openTab === 0 ? 'flex flex-col' : 'hidden'}>
                      <div className="py-6 px-4 space-y-6 sm:p-6">
                        <PersonalInfo
                          {...propsClass}
                          documentType={'IDENTITY_CARD'}
                          dictionaryData={dictionaryData}
                        />
                        {/* teets */}
                      </div>
                    </div>

                    {/* SBInsurance info ============================ */}

                    <div
                      className={openTab === 1 ? 'flex  flex-col' : 'hidden'}>
                      <div className="py-6 px-4 space-y-6 sm:px-6 sm:py-1">
                        <SBInsurance
                          //   isPremiumCustomer
                          hasSBInsurance
                          {...propsClass}
                          {...values}
                          openTab={openTab}
                          dictionaryData={dictionaryData}
                          //  setSelectedCar={setSelectedCar}
                        />
                      </div>
                    </div>

                    <div
                      className={openTab === 2 ? 'flex  flex-col' : 'hidden'}>
                      {/* extend the SB overview with personal data */}
                      <div className="grid grid-cols-6 gap-1 py-6 sm:p-6">
                        {/* personal */}
                        <div className="col-span-6">
                          <h3 className="text-lg leading-6 font-semibold text-gray-900">
                            Persönliche Daten
                          </h3>
                        </div>
                        <div className="col-span-3">
                          <dl className="pb-4">
                            <div className="pb-0">
                              <dt className={dtClassName}>Ausweistyp</dt>

                              <dd className={ddClassName}>
                                {identity_document_type_translated}
                              </dd>
                            </div>
                          </dl>
                        </div>

                        <div className="col-span-3">
                          <dl className="pb-1">
                            <div className="pb-0">
                              <dt className={dtClassName}>Ausweisnummer</dt>

                              <dd className={ddClassName}>
                                {identity_document_number}
                              </dd>
                            </div>
                          </dl>
                        </div>
                        <div className="col-span-3">
                          <dl className="pb-4">
                            <div className="pb-0">
                              <dt className={dtClassName}>Ausweistyp</dt>

                              <dd className={ddClassName}>
                                {identity_document_type_drive_translated}
                              </dd>
                            </div>
                          </dl>
                        </div>

                        <div className="col-span-3">
                          <dl className="pb-1">
                            <div className="pb-0">
                              <dt className={dtClassName}>Ausweisnummer</dt>

                              <dd className={ddClassName}>
                                {identity_document_number_drive}
                              </dd>
                            </div>
                          </dl>
                        </div>
                        <div className="col-span-3">
                          <dl className="pb-4">
                            <div className="pb-0">
                              <dt className={dtClassName}>
                                Ausstellungsbehörde
                              </dt>

                              <dd className={ddClassName}>
                                {drivers_liscense_issuing_authority}
                              </dd>
                            </div>
                          </dl>
                        </div>

                        <div className="col-span-3">
                          <dl className="pb-1">
                            <div className="pb-0">
                              <dt className={dtClassName}>
                                Führerscheinklassen
                              </dt>

                              <dd className={ddClassName}>
                                {drivers_license_classes}
                              </dd>
                            </div>
                          </dl>
                        </div>
                        <div className="col-span-6">
                          <dl className="pb-1">
                            <div className="pb-0">
                              <dt className={dtClassName}>Anmerkung:</dt>
                              <dt className={ddClassName}>
                                Die Führerscheinklasse wurde überprüft und ist
                                gültig.
                              </dt>
                            </div>
                          </dl>
                        </div>
                        <div className="col-span-3">
                          <dl className="pb-1">
                            <div className="pb-0">
                              <dt className={dtClassName}>Kunde</dt>

                              <dd className={ddClassName}>
                                {prename} {surname}
                              </dd>
                              <dd className={ddClassName}>
                                {telephone_number}
                              </dd>
                              <dd className={ddClassName}>{email}</dd>
                            </div>
                          </dl>
                        </div>
                        <div className="col-span-3">
                          <dl className="pb-1">
                            <div className="pb-0">
                              <dt className={dtClassName}>Adresse</dt>

                              <dd className={ddClassName}>
                                {street}, {house}
                              </dd>
                              <dd className={ddClassName}>
                                {postal_code} {city}
                              </dd>
                            </div>
                          </dl>
                        </div>
                        <div className="col-span-3">
                          <dl className="pb-1">
                            <div className="pb-0">
                              <dt className={dtClassName}>Geburtsdatum</dt>

                              <dd className={ddClassName}>{date_of_birth}</dd>
                            </div>
                          </dl>
                        </div>
                      </div>
                      <div
                        className="col-span-6 inset-0 flex w-full"
                        aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <SBContractOverview
                        {...values}
                        dtClassName={dtClassName}
                        ddClassName={ddClassName}
                        isPremiumCustomer={false}
                      />
                    </div>

                    <div className={'py-6 space-y-6 sm:p-6'}>
                      <FormAction
                        isLightProduct
                        hasSBInsurance={contractType === 'lightWithSBInsurance'}
                        //  errors={errors}
                        isLoading={isLoading}
                        openTab={openTab}
                        setOpenTab={setOpenTab}
                      />
                    </div>
                  </>
                );
              }}
            </Form>
            {/* steps content end */}
          </section>
        </main>

        <aside className="lg:w-3/12 lg:block flex-col">
          {idCheckSingleValue && (
            <div className="mt-0 sm:py-4 lg:py-0 w-full">
              <div className="mb-5">
                <ContactStatus
                  //@ts-ignore
                  status={statusStyles[idCheckSingleValue.approval_state]}
                  title={'Ausweisdokument'.toUpperCase()}>
                  <p className="text-sm text-gray-700  whitespace-pre-line xl:w-full md:w-7/12 block">
                    Zuletzt aktualisiert: {/* @ts-ignore */}
                    {dataFormat({
                      date: idCheckSingleValue?.created_at,
                      option: {
                        hour: true,
                        minute: true,
                      },
                    })}
                  </p>
                  {IDCheckData && (
                    <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                      Beauftragt von: {IDCheckData.user.name}
                      {/* {moment(idCheckSingleValue?.created_at)} */}
                    </p>
                  )}
                </ContactStatus>
              </div>
            </div>
          )}
          {newContractData &&
            newContractData.id_check_short &&
            newContractData.id_check_short.drivers_liscense_id_check &&
            newContractData.id_check_short.drivers_liscense_id_check
              .approval_state && (
              <ContactStatus
                // status={
                //   //@ts-ignore
                //   statusStyles[
                //     newContractData.id_check_short.drivers_liscense_id_check
                //       .approval_state
                //   ]
                // }
                status={
                  //@ts-ignore
                  newContractData.id_check_short.drivers_liscense_id_check
                    .approval_state !== 'NOT_AVAILABLE'
                    ? //@ts-ignore
                      statusStyles[
                        newContractData.id_check_short.drivers_liscense_id_check
                          .approval_state
                      ]
                    : {
                        status: 'bg-gray-400 text-white',
                        label: 'Nicht verfügbar'.toUpperCase(),
                        contractStaus: {
                          border: 'border-gray-600',
                          bg: 'bg-gray-700',
                        },
                      }
                }
                title={'Führerschein'.toUpperCase()}>
                <p className="text-sm text-gray-700  whitespace-pre-line xl:w-full md:w-7/12 block">
                  Zuletzt aktualisiert:
                  {dataFormat({
                    //@ts-ignore
                    date: idCheckSingleValue?.created_at,
                    option: {
                      hour: true,
                      minute: true,
                    },
                  })}
                </p>
                {IDCheckData && (
                  <p className="text-sm text-gray-700  whitespace-pre-line xl:full mt-3">
                    Beauftragt von: {IDCheckData.user.name}
                    {/* {moment(idCheckSingleValue?.created_at)} */}
                  </p>
                )}
              </ContactStatus>
            )}
        </aside>

        <Modal
          hasIcon
          setOpen={setOpenDialog}
          open={openDialog}
          title="Sie haben den SB-Schutz erfolgreich gebucht."
          subtile="">
          {/* <div className="mt-5 sm:mt-6">
            <a href={pdfUrl} target="_blank" rel="noreferrer">
              <Button
                startIcon={<PrinterIcon className="h-5 w-5 text-white mr-2" />}
                onClick={() => {
                  setOpenDialog(false);
                  history.push('/id_check');
                }}
                label="Vertrag drucken"
                type="primary"
                className="w-full justify-center"
              />
            </a>
          </div> */}
          {/* <div className="mt-5 sm:mt-6">
            <Button
              onClick={() => null}
              label="Ja, sicher"
              type="primary"
              className="w-full justify-center "
            />
          </div> */}
          <div className="mt-5 sm:mt-6">
            <Button
              onClick={() => {
                setOpenDialog(false);
                history.push('/id_check');
              }}
              label="Zum Vorgang"
              type="default"
              className="w-full justify-center "
            />
          </div>
        </Modal>
      </div>
      <NotificationMessage
        isError
        title="Server Error"
        subTitle={serverError}
        notify={showNotification}
        setShow={setShow}
      />
    </div>
  );
};

export default NewSBInsuranceContract;
