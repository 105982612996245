import * as yup from 'yup';

export const personalInfovalidationSchema = yup.object({
  prename: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Vorname mindestens 3 Zeichen lang sein mus',
    )
    .defined('Vorname ist erforderlich.')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  surname: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Nachname mindestens 3 Zeichen lang sein muss',
    )
    .defined('Nachname ist erforderlich.')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  telephone_number: yup
    .number()
    .min(5, 'mindestens 5 Zeichen')
    .typeError('Die Mobilnummer ist erforderlich'),
  profileurl: yup.mixed(),
  currentpassword: yup.string(),
  // .min(6, 'Das Passwort muss aus min. 6 Zeichen bestehen')
  newpassword: yup.string(),
  // .min(12, 'Das Passwort muss aus min. 12 Zeichen bestehen')
  repeatpass: yup.string(),
  // .oneOf([yup.ref('newpassword')], 'Passwörter sind nicht identisch')
});
export const addBracnhOfficeValidationSchema = (newBranch?: boolean) =>
  yup.object({
    name: yup
      .string()
      .min(
        4,
        'Bitte beachten Sie, dass der Name mindestens 4 Zeichen lang sein mus',
      )
      .defined('Standortname ist erforderlich')
      .required(),
    tax_number: yup.mixed(),
    street: yup.string().required('Die Straße ist erforderlich'),
    house: yup.mixed().required('Die Hausnummer ist erforderlich'),
    postal_code: yup
      .number()
      .min(5, 'mindestens 5 Zeichen')
      .defined('Die PLZ ist erforderlich'),
    city: yup.string().defined('Der Ort ist erforderlich'),
    accepted: newBranch
      ? yup
          .bool()
          .oneOf([true], 'checkbox ist erforderlich')
          .required('checkbox ist erforderlich')
      : yup.bool(),
  });

export const headquarterValidationSchema = yup.object({
  name: yup
    .string()
    .min(
      5,
      'Bitte beachten Sie, dass der Name mindestens 5 Zeichen lang sein mus',
    )
    .defined('Name ist erforderlich')
    .required(),

  // profileurl: yup.mixed(),
});

export const addMemberValidationSchema = yup.object({
  prename: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Vorname mindestens 3 Zeichen lang sein mus',
    )
    .defined('Der Vorname ist erforderlich')
    .required()
    .matches(
      /^[ a-zA-Z0-9äöüÄÖÜß]+$/,
      'Leerzeichen oder Sonderzeichen sind nicht möglich',
    ),
  surname: yup
    .string()
    .min(
      3,
      'Bitte beachten Sie, dass der Nachname mindestens 3 Zeichen lang sein muss',
    )
    .defined('Bitte geben Sie den Nachnamen ein'),
  email: yup
    .string()
    .email('Bitte geben Sie eine gültige E-Mail Adresse ein')
    .defined('E-Mail ist erforderlich'),
  user_owner_id: yup.string().ensure().required('Zentrale ist erforderlich'),
  role: yup.string().ensure().required('Role ist erforderlich'),
});

export const addCarValidationSchema = yup.object({
  brand: yup.string(),
  // CarKind: yup.string().required('Modell ist erforderlich'),
  name: yup.string(),
  main_driver: yup.string().nullable(),
  license_plate: yup.mixed(),
  city: yup.string(),
  odometer_value: yup
    .number()
    .typeError('Der Kilometerstand darf nur aus Zahlen bestehen.'),
  short_description: yup.string().nullable(),
});
export const bookCarValidationschema = yup.object({
  brand: yup.string(),
  // CarKind: yup.string().required('Modell ist erforderlich'),
  name: yup.string(),
  // main_driver:yup.string(),
  start_date: yup
    .date()
    .typeError('Das Abholungdatum muss ausgefüllt werden.')
    .required('Das Rückgabedatum muss ausgefüllt werden.'),
  license_plate: yup.mixed(),
  city: yup.string(),
  end_date: yup
    .date()
    .typeError('Das Rückgabedatum muss ausgefüllt werden.')
    .required('Das Rückgabedatum muss ausgefüllt werden.'),
  isPickupdateBeforeReturnDate: yup
    .string()
    .required(
      'Die Rückgabezeit darf nicht vor der Abholzeit liegen. Bitte überpürfen Sie das Datum und die Uhrzeit',
    ),
  description: yup
    .string()
    .typeError('Fügen Sie eine Notiz hinzu, z.B. den Name der Reservierung.')
    .required('Fügen Sie eine Notiz hinzu, z.B. den Name der Reservierung.'),
});
