import { Button } from '../../../styles/components';
import { MailIcon } from '@heroicons/react/solid';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ReactComponent as ChatIcon } from 'assets/images/icon-chat-ct.svg';

const offices = [
  {
    id: 1,
    title: 'Leitung Vertrieb & Marketing',
    location: 'Büro Andernach:',
    person: 'Oliver Fett',
    address: ['Rennweg 60', '56626 Andernach'],
    phone: '+49 2632 498883-8',
  },
  {
    id: 2,
    title: 'Kundenberatung & Projektmanagement',
    location: 'Büro Andernach:',
    person: 'Uwe Selzer',
    address: ['Rennweg 60', '56626 Andernach'],
    phone: '+49 2632 498883-3',
  },
  // {
  //   id: 3,
  //   title: 'Technik & Support',
  //   location: 'Büro Gelting:',
  //   person: 'Thomas Merkel',
  //   address: ['Hugo-Weiss-Str. 31', '81827 München'],
  //   phone: '+49 89262014870',
  // },
];

const HelpAndContact = () => {
  let history = useHistory();

  let { path } = useRouteMatch();
  return (
    <div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Hilfe & Kontakt
          </h2>
        </div>
      </div>

      <div className="mt-4">
        <div className="mt-5">
          <h2 className="text-xl font-bold text-gray-900 sm:text-xl sm:truncate">
            Nehmen Sie Kontakt mit uns auf.
          </h2>
          <div className="md:flex w-full">
            <p className="mt-2 text-md text-gray-500 max-w-2xl">
              Wenn Sie Fragen haben, einfach mehr wissen wollen oder mit uns
              über eine mögliche Zusammenarbeit sprechen möchten, dann freuen
              wir uns über Ihre Anfrage oder Ihren Anruf. Wenn Sie möchten,
              können uns auch gerne eine Nachricht über unser Kontaktformular
              hinterlassen.
            </p>
            <div className="lg:w-5/12  flex md:justify-center mt-5 lg:mt-1">
              <ChatIcon />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <Button
          onClick={() => history.push(`${path}/contact`)}
          label="Kontaktformular"
          type="primary"
          startIcon={<MailIcon className="h-5 w-5 mr-2" />}
        />
      </div>
      <div className="max-w-7xl mx-auto py-20 xl:mx-0">
        {/* <h2 id="officesHeading" className="text-3xl font-extrabold text-warm-gray-900">
              Our offices
            </h2>
            <p className="mt-6 text-lg text-warm-gray-500 max-w-3xl">
              Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus
              dui laoreet diam sed lacus, fames.
            </p> */}
        <div className="mt-10 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
          {offices.map((office) => (
            <div key={office.id}>
              <h3 className="text-lg font-medium text-warm-gray-900">
                {office.title}
              </h3>
              <p className="text-base text-gray-500">{office.location}</p>
              <p className="text-base text-gray-500">{office.person}</p>
              <p className="text-base text-gray-500">
                {office.address.map((line) => (
                  <span key={line} className="block">
                    {line}
                  </span>
                ))}
              </p>
              <p className="text-base text-gray-500">Fon: {office.phone}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HelpAndContact;
