type configProp = {
  height?: string;
  width?: string;
  padding?: string;
  margin?: string;
  color?: string;
  shape?: 'rounded-full' | 'rounded-md';
};

const getClasses = (...config: configProp[]) => {
  if (config.length === 1) {
    const props = config[0];
    const height = props.height ? props['height'] : '';
    const width = props.width ? props.width : '';
    const padding = props.padding ? props.padding : '';
    const margin = props.margin ? props.margin : '';
    const color = props.color ? props.color : '';
    const shape = props.shape ? props.shape : '';
    //  console.log(config)
    const combinedClasses = [height, width, padding, margin, color, shape];
    return combinedClasses.filter(Boolean).join(' ');
  }

  return '';
};

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default getClasses;
