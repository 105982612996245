import { Fragment } from 'react';

import { SearchIcon, SortAscendingIcon } from '@heroicons/react/solid';

import { SelectList } from '../../styles/components';

const SearchSortHeader = ({
  handleSearch,
  onSelect,
  selected,
  selectMenuItem,
  searchPLaceholder,
  selectDefaultValue,
  subtitle,
  title,
  divider = true,
}: {
  handleSearch?: React.Dispatch<string>;
  onSelect: React.Dispatch<undefined>;
  selected: any;
  selectMenuItem: any;
  searchPLaceholder?: string;
  selectDefaultValue?: string;
  title?: string;
  subtitle?: string;
  divider?: boolean;
}) => {
  return (
    <Fragment>
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <div>
          <h3 className="text-lg leading-6 font-bold text-gray-900">{title}</h3>
          {subtitle && (
            <p className="text-gray-500 text-sm font-semibold">{subtitle}</p>
          )}
        </div>
        <div className="flex flex-col lg:flex-row lg:justify-end  mt-3 lg:mt-0">
          {handleSearch && (
            <div className="relative h-11 shadow-sm w-full md:w-64">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <input
                type="search"
                name="search"
                onChange={(event: React.FormEvent<HTMLInputElement>) =>
                  handleSearch(event.currentTarget.value)
                }
                id="search"
                className="h-full w-full pl-10 pr-1 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-white border-0"
                placeholder={searchPLaceholder ? searchPLaceholder : 'Search'}
              />
            </div>
          )}

          <div className="flex order-2 h-11 shadow-sm w-full md:w-64 mt-3 lg:mt-0">
            <div className="inset-y-0 left-0 flex items-center pointer-events-none">
              <SortAscendingIcon
                className="h-5 w-8 text-gray-400 -mr-2 ml-1"
                aria-hidden="true"
              />
            </div>
            <div className="w-full">
              <SelectList
                items={selectMenuItem}
                defaultValue={
                  selectDefaultValue ? selectDefaultValue : 'Sortieren nach'
                }
                selected={selected}
                onSelect={(value: any) => {
                  if (onSelect) {
                    onSelect(value);
                  }
                  return '';
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {divider && <div className="w-full border-t border-gray-300 mt-3" />}
    </Fragment>
  );
};

export default SearchSortHeader;
