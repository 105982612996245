import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
} from "react-query"

// Create a client
const queryClient = new QueryClient()

const  QueryClientProviderClient=  ({children}:{children:any}) => (
  <QueryClientProvider client={queryClient}>
    {children}
  </QueryClientProvider>
)

export default QueryClientProviderClient;