import { useState } from 'react';
import { SingleDatePicker } from 'react-dates';

const DatePicker = ({
  setDate,
  dateObject,
  isDayBlocked,
  id,
  isOutsideRange,
  disabled,
}: {
  setDate: (date: any) => void;
  dateObject: any;
  isDayBlocked?: (day: moment.Moment) => boolean;
  id: string;
  isOutsideRange?: any;
  disabled?: boolean;
}) => {
  const [focused, setFocused] = useState({ focused: false });

  return (
    <SingleDatePicker
      disabled={disabled}
      keepOpenOnDateSelect={false}
      weekDayFormat={'ddd'}
      dayAriaLabelFormat="de"
      placeholder="Datum auswähen"
      displayFormat={'DD.MM.YYYY'}
      focused={focused.focused}
      onFocusChange={(focused) => setFocused(focused)}
      readOnly
      // regular
      //@ts-ignore
      isOutsideRange={isOutsideRange}
      block
      // anchorDirection="left"
      numberOfMonths={1}
      small
      isDayBlocked={isDayBlocked}
      id={id}
      //@ts-ignore
      onDateChange={(date) => {
        setDate(date);
      }}
      //@ts-ignore
      date={dateObject && dateObject}
    />
  );
};

export default DatePicker;
