import { Fragment } from 'react';
import { classNames } from '../../../../styles/utils';
//@ts-ignore
import Select, { Option } from 'react-select';
import { generateHours, generatMinutes } from '../utils';

const colourStyles = {
  option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f97316'
        : isFocused
        ? '#fb923b'
        : null,
      border: 0,
    };
  },
  control: (provided: any) => ({
    ...provided,
    boxShadow: 'none',
    border: 'none',
  }),
  menu: (provided: any) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
  }),
};

const TimePicker = ({
  pickerValue,
  setValue,
  disabled = false,
  cb,
  hoursValue,
  minutesValue,
  defaultValueMood = false,
  type = 'full',
  disabledHours,
}: {
  pickerValue: any;
  setValue: (ob: any) => void;
  disabled?: boolean;
  cb?: ({ name, value }: { name: string; value: string }) => void;
  hoursValue?: number;
  minutesValue?: number;
  defaultValueMood?: boolean;
  type?: 'full' | 'short';

  // hours to be disabled (incase user seleced the date and we need to disable the hours that are already passed)
  disabledHours?: number;
}) => {
  const minutesOption = generatMinutes({ type: type });
  const hoursOPtion = generateHours({
    type: type,
    hoursValue: Number(disabledHours || 0),
  });

  const handleChange = ({ value }: any, name: string) => {
    if (typeof cb === 'function') {
      cb({ name, value });
    }
    setValue((old: any) => {
      return {
        ...old,
        [name]: value,
      };
    });
  };

  return (
    <div
      className={classNames(
        disabled ? 'bg-gray-100' : 'bg-transparent',
        'block w-full',
      )}>
      <div className="flex items-center border align-middle border-gray-300 rounded-md">
        <div
          className={classNames(
            `${
              (pickerValue && pickerValue.hours) || defaultValueMood
                ? 'w-10'
                : 'w-44'
            }`,
          )}>
          <Select
            isSearchable={false}
            isDisabled={disabled}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
            value={
              defaultValueMood
                ? hoursOPtion.filter(
                    (hour) => Number(hour.value) === hoursValue,
                  )
                : undefined
            }
            placeholder="Uhrzeit auswählen"
            options={hoursOPtion}
            styles={colourStyles}
            name="hours"
            onChange={(values: Option) => handleChange(values, 'hours')}
            // onBlur={field.onBlur}
          />
        </div>

        {(pickerValue || defaultValueMood) && (
          <>
            <span className="text-lg font-bold mr-3">:</span>

            <div className="w-24">
              <Select
                isDisabled={disabled}
                value={
                  defaultValueMood
                    ? minutesOption.filter(
                        (minute) => Number(minute.value) === minutesValue,
                      )
                    : undefined
                }
                isSearchable={false}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => null,
                }}
                // defaultInputValue="00"
                placeholder="00"
                options={minutesOption}
                styles={colourStyles}
                name="minutes"
                onChange={(values: any) => handleChange(values, 'minutes')}
                // onBlur={field.onBlur}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TimePicker;
